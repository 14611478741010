export {
  decodeJsonResponse,
  unsafeDecodeJsonResponse,
} from "./lib/decode-response";
export { encodeJsonBody, encodeNoBody } from "./lib/encode-body";

// eslint-disable-next-line no-restricted-syntax
export * as RestClientError from "./lib/rest-client-error";
// eslint-disable-next-line no-restricted-syntax
export * as RestServerError from "./lib/rest-server-error";
// eslint-disable-next-line no-restricted-syntax
export * as BatchValidationError from "./lib/batch-validation-error";

export { RestServiceLive } from "./lib/rest-service-live";

export { RestService } from "./lib/rest-service";
export type { RestServiceShape } from "./lib/rest-service";

export type {
  DecodeResponse,
  EncodeBody,
  EncodeBodyOptions,
  EncodeNoBody,
  EncodeNoBodyOptions,
  MethodOptions,
  RestError,
  WithBodyOptions,
  WithoutBodyOptions,
} from "./lib/rest-types";
