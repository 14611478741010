/**
 * Logs the User in -- creates a "token" cookie.
 * HTTP Method: POST
 * Pathname: /login
 * @function login
 * @memberof AuthAPI
 * @param {AuthAPILoginPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<EnderSessionResponse>}
 */
import { Effect, Function as F } from "effect";

import type { TwilioClientVerificationCodeChannel } from "@ender/shared/generated/com.ender.common.arch.client";
import type { EnderSessionResponse } from "@ender/shared/generated/ender.api.misc.response";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type AuthAPILoginSearchParams = {
  token?: string | undefined;
};

type AuthAPILoginBodyParams = {
  /**
   * The 2-factor channel (SMS or EMAIL)
   */
  channel?: TwilioClientVerificationCodeChannel | undefined;
  /**
   * The 2-factor auth code
   */
  code: string;
  /**
   * The email of the User logging in
   */
  email: string;
  force2Fa?: boolean | undefined;
  initialLogin?: boolean | undefined;
  /**
   * The login password
   */
  password: string;
};

type AuthAPILoginPayload = AuthAPILoginSearchParams & AuthAPILoginBodyParams;

function loginUnsafeEffect(payload: AuthAPILoginPayload) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, EnderSessionResponse>({
        body,
        decode: unsafeDecodeJsonResponse<EnderSessionResponse>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/login",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function login(
  payload: AuthAPILoginPayload,
  options?: { signal?: AbortSignal },
): Promise<EnderSessionResponse> {
  return F.pipe(payload, loginUnsafeEffect, Effect.scoped, runPromise)(options);
}

export { login };
export type {
  AuthAPILoginBodyParams,
  AuthAPILoginPayload,
  AuthAPILoginSearchParams,
};
