/**
 * HTTP Method: DELETE
 * Pathname: /permissions/roles/{roleId}
 * @function deleteRole
 * @memberof PermissionsAPI
 * @param {PermissionsAPIDeleteRolePayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type PermissionsAPIDeleteRolePathParams = {
  roleId: EnderId;
};

type PermissionsAPIDeleteRoleSearchParams = {
  token?: string | undefined;
};

type PermissionsAPIDeleteRolePayload = PermissionsAPIDeleteRolePathParams &
  PermissionsAPIDeleteRoleSearchParams;

function deleteRoleUnsafeEffect(payload: PermissionsAPIDeleteRolePayload) {
  const { roleId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "DELETE" }),
        pathname: `/permissions/roles/${roleId}`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function deleteRole(
  payload: PermissionsAPIDeleteRolePayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    deleteRoleUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { deleteRole };
export type {
  PermissionsAPIDeleteRolePathParams,
  PermissionsAPIDeleteRolePayload,
  PermissionsAPIDeleteRoleSearchParams,
};
