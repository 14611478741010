/**
 * HTTP Method: POST
 * Pathname: /units/{unitId}/photoOrdering
 * @function updatePhotoOrdering
 * @memberof PhotosAPI
 * @param {PhotosAPIUpdatePhotoOrderingPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type PhotosAPIUpdatePhotoOrderingPathParams = {
  unitId: EnderId;
};

type PhotosAPIUpdatePhotoOrderingSearchParams = {
  token?: string | undefined;
};

type PhotosAPIUpdatePhotoOrderingBodyParams = {
  ordering: EnderId[];
};

type PhotosAPIUpdatePhotoOrderingPayload =
  PhotosAPIUpdatePhotoOrderingPathParams &
    PhotosAPIUpdatePhotoOrderingSearchParams &
    PhotosAPIUpdatePhotoOrderingBodyParams;

function updatePhotoOrderingUnsafeEffect(
  payload: PhotosAPIUpdatePhotoOrderingPayload,
) {
  const { unitId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/units/${unitId}/photoOrdering`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function updatePhotoOrdering(
  payload: PhotosAPIUpdatePhotoOrderingPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    updatePhotoOrderingUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { updatePhotoOrdering };
export type {
  PhotosAPIUpdatePhotoOrderingBodyParams,
  PhotosAPIUpdatePhotoOrderingPathParams,
  PhotosAPIUpdatePhotoOrderingPayload,
  PhotosAPIUpdatePhotoOrderingSearchParams,
};
