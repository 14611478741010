/**
 * HTTP Method: GET
 * Pathname: /featureFlags/values
 * @function getFeatureFlagValues
 * @memberof FeatureFlagAPI
 * @param {FeatureFlagAPIGetFeatureFlagValuesPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<GetFeatureFlagValuesResponse[]>}
 */
import { Effect, Function as F } from "effect";

import type { GetFeatureFlagValuesResponse } from "@ender/shared/generated/ender.api.misc.response";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type FeatureFlagAPIGetFeatureFlagValuesSearchParams = {
  token?: string | undefined;
};

type FeatureFlagAPIGetFeatureFlagValuesPayload =
  FeatureFlagAPIGetFeatureFlagValuesSearchParams;

function getFeatureFlagValuesUnsafeEffect(
  payload: FeatureFlagAPIGetFeatureFlagValuesPayload,
) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, GetFeatureFlagValuesResponse[]>({
        body,
        decode: unsafeDecodeJsonResponse<GetFeatureFlagValuesResponse[]>({}),
        encode: encodeNoBody({ method: "GET" }),
        pathname: "/featureFlags/values",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getFeatureFlagValues(
  payload: FeatureFlagAPIGetFeatureFlagValuesPayload,
  options?: { signal?: AbortSignal },
): Promise<GetFeatureFlagValuesResponse[]> {
  return F.pipe(
    payload,
    getFeatureFlagValuesUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getFeatureFlagValues };
export type {
  FeatureFlagAPIGetFeatureFlagValuesPayload,
  FeatureFlagAPIGetFeatureFlagValuesSearchParams,
};
