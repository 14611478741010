import { Data } from "effect";

type BatchValidationErrorValue = {
  errors: string[];
  warnings: string[];
};

// The FE representation of the BE `BatchValidationException` see: common.ender.com/src/com/ender/common/misc/BatchValidationResult.java
class BatchValidationError extends Data.TaggedError<"BatchValidationError">(
  "BatchValidationError",
)<BatchValidationErrorValue> {}

function of(args: BatchValidationErrorValue) {
  return new BatchValidationError(args);
}

export { BatchValidationError, of };
