import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const JiraClientIssueTypeValues = ["BUG", "STORY", "TASK"] as const;

const JiraClientIssueTypeEffectSchema = Schema.Literal(
  ...JiraClientIssueTypeValues,
);

type JiraClientIssueType = Schema.Schema.Type<
  typeof JiraClientIssueTypeEffectSchema
>;

const JiraClientIssueTypeEnum = castEnum(JiraClientIssueTypeEffectSchema);

function randomJiraClientIssueType(): JiraClientIssueType {
  return rand(JiraClientIssueTypeValues);
}

export {
  JiraClientIssueTypeEffectSchema,
  JiraClientIssueTypeEnum,
  JiraClientIssueTypeValues,
  randomJiraClientIssueType,
};
export type { JiraClientIssueType };
