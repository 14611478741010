"use client";

import { Modal } from "@ender/shared/ds/modal";
import { MultiFactorAuthenticationFormController } from "../form/multi-factor-authentication-form.controller";
type MultiFactorAuthenticationModalViewProps = {
  opened: boolean;
  onClose?: () => void;
  onDone: () => void;
};
function MultiFactorAuthenticationModal(props: MultiFactorAuthenticationModalViewProps) {
  const {
    opened,
    onDone,
    onClose
  } = props;
  return <Modal onClose={onClose} title="Two Factor" opened={opened} data-sentry-element="Modal" data-sentry-component="MultiFactorAuthenticationModal" data-sentry-source-file="multi-factor-authentication-modal.tsx">
      <MultiFactorAuthenticationFormController onDone={onDone} data-sentry-element="MultiFactorAuthenticationFormController" data-sentry-source-file="multi-factor-authentication-modal.tsx" />
    </Modal>;
}
export { MultiFactorAuthenticationModal };
export type { MultiFactorAuthenticationModalViewProps };