import { useCallback, useEffect, useRef } from "react";
import { UNDEFINED } from "@ender/shared/constants/general";
import { useBoolean } from "@ender/shared/hooks/use-boolean";
import { useRefLatest } from "@ender/shared/hooks/use-ref-latest";
type TimeoutId = ReturnType<typeof globalThis.setTimeout>;
type UseTimeoutProps = {
  onDone: () => void;
};
function useTimeout(props: UseTimeoutProps): {
  isRunning: boolean;
  startTimer: (delayMs: number) => void;
} {
  const {
    onDone
  } = props;
  const onDoneRef = useRefLatest(onDone);
  const [isRunning, {
    setTrue: _setRunning,
    setFalse: _setStopped
  }] = useBoolean(false);
  const timeoutIdRef = useRef<TimeoutId | undefined>(UNDEFINED);
  const setRunning = useCallback((timeoutId: TimeoutId) => {
    timeoutIdRef.current = timeoutId;
    _setRunning();
  }, [_setRunning]);
  const setStopped = useCallback(() => {
    globalThis.clearTimeout(timeoutIdRef.current);
    timeoutIdRef.current = UNDEFINED;
    _setStopped();
  }, [_setStopped]);
  const startTimer = useCallback((delayMs: number) => {
    setStopped();
    const _timeoutId = globalThis.setTimeout(() => {
      setStopped();
      onDoneRef.current && onDoneRef.current();
    }, delayMs);
    setRunning(_timeoutId);
  }, [onDoneRef, setRunning, setStopped]);

  // We need to stop the timer if the component unmounts
  useEffect(() => {
    return () => {
      // The ref value 'timeoutIdRef.current' will likely have changed by the time this effect cleanup function runs
      // But that's exactly what we want so don't copy ref pointer to a local var in teh effect or we'll end up
      // clearing the wrong timer.
      // eslint-disable-next-line react-hooks/exhaustive-deps
      setStopped();
    };
  }, [setStopped]);
  return {
    isRunning,
    startTimer
  };
}
export { useTimeout };