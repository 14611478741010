/**
 * HTTP Method: POST
 * Pathname: /users/{targetUserId}
 * @function updateUser
 * @memberof UsersAPI
 * @param {UsersAPIUpdateUserPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type UsersAPIUpdateUserPathParams = {
  targetUserId: EnderId;
};

type UsersAPIUpdateUserSearchParams = {
  token?: string | undefined;
};

type UsersAPIUpdateUserBodyParams = {
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  json: any;
};

type UsersAPIUpdateUserPayload = UsersAPIUpdateUserPathParams &
  UsersAPIUpdateUserSearchParams &
  UsersAPIUpdateUserBodyParams;

function updateUserUnsafeEffect(payload: UsersAPIUpdateUserPayload) {
  const { targetUserId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/users/${targetUserId}`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function updateUser(
  payload: UsersAPIUpdateUserPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    updateUserUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { updateUser };
export type {
  UsersAPIUpdateUserBodyParams,
  UsersAPIUpdateUserPathParams,
  UsersAPIUpdateUserPayload,
  UsersAPIUpdateUserSearchParams,
};
