"use client";

import { useMutation } from "@tanstack/react-query";
import { Option as O, Predicate as P } from "effect";
import { useCallback, useMemo } from "react";
import type { EnderId } from "@ender/shared/core";
import { AuthAPI } from "@ender/shared/generated/ender.api.misc";
import type { EnderSessionResponse } from "@ender/shared/generated/ender.api.misc.response";
import { useAuthActor } from "../context/auth-actor.context";
import { AuthEventEnum } from "../machine/auth.types";
import type { VendorItem } from "./vendor-select-form.view";
import { VendorSelectFormView } from "./vendor-select-form.view";
type VendorSelectFormControllerProps = {
  onCancel: () => void;
  onDone: () => void;
};
function VendorSelectFormController(props: VendorSelectFormControllerProps) {
  const {
    onCancel,
    onDone
  } = props;
  const {
    mutateAsync: changeActiveVendorAsync,
    error,
    isLoading
  } = useMutation({
    mutationFn: AuthAPI.changeActiveVendor
  });
  const [authSnapshot, sendAuthEvent] = useAuthActor();
  const {
    context: {
      session
    }
  } = authSnapshot;
  const errorMessage: O.Option<string> = useMemo(() => {
    if (P.isNotNullable(error)) {
      return O.some(`${error}`);
    }
    return O.none();
  }, [error]);
  const availableVendors = useMemo(() => {
    return O.match(session, {
      onNone: () => [] as VendorItem[],
      onSome: (s): VendorItem[] => s.vendors
    });
  }, [session]);
  const handleVendorSelect = useCallback((vendorId: EnderId) => {
    changeActiveVendorAsync({
      vendorId
    }).then((session: EnderSessionResponse | undefined) => {
      sendAuthEvent({
        payload: O.fromNullable(session),
        type: AuthEventEnum.SET_SESSION
      });
      onDone();
    });
  }, [changeActiveVendorAsync, onDone, sendAuthEvent]);
  return <VendorSelectFormView availableVendors={availableVendors} errorMessage={errorMessage} isFetching={isLoading} onCancelClick={onCancel} onVendorSelect={handleVendorSelect} data-sentry-element="VendorSelectFormView" data-sentry-component="VendorSelectFormController" data-sentry-source-file="vendor-select-form.controller.tsx" />;
}
export { VendorSelectFormController };