/**
 * HTTP Method: POST
 * Pathname: /createCounty
 * @function createCounty
 * @memberof CountiesAPI
 * @param {CountiesAPICreateCountyPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<string>}
 */
import { Effect, Function as F } from "effect";

import type { Percent, SerializesInto } from "@ender/shared/core";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type CountiesAPICreateCountySearchParams = {
  token?: string | undefined;
};

type CountiesAPICreateCountyBodyParams = {
  name: string;
  tptTaxRate: SerializesInto<Percent>;
  zipcodes: string[];
};

type CountiesAPICreateCountyPayload = CountiesAPICreateCountySearchParams &
  CountiesAPICreateCountyBodyParams;

function createCountyUnsafeEffect(payload: CountiesAPICreateCountyPayload) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, string>({
        body,
        decode: unsafeDecodeJsonResponse<string>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/createCounty",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function createCounty(
  payload: CountiesAPICreateCountyPayload,
  options?: { signal?: AbortSignal },
): Promise<string> {
  return F.pipe(
    payload,
    createCountyUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { createCounty };
export type {
  CountiesAPICreateCountyBodyParams,
  CountiesAPICreateCountyPayload,
  CountiesAPICreateCountySearchParams,
};
