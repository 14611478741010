"use client";

import { useQuery } from "@tanstack/react-query";
import { Predicate as P } from "effect";
import { useMemo } from "react";
import { LoadingSpinner } from "@ender/shared/ds/loading-spinner";
import { Stack } from "@ender/shared/ds/stack";
import { UsersAPI } from "@ender/shared/generated/ender.api.core";
import type { GetUserResponse } from "@ender/shared/generated/ender.api.core.response";
import type { Session } from "../machine/auth.types";
type UserProviderProps = {
  session: Session;
  children: (user: GetUserResponse) => JSX.Element;
};
function UserProvider(props: UserProviderProps) {
  const {
    session,
    children
  } = props;
  const payload = useMemo(() => ({
    targetId: session.user.id,
    vendorId: session.activeVendor?.id
  }), [session]);
  const {
    data: user,
    error,
    isFetching
  } = useQuery({
    queryFn: async ({
      queryKey: [, _payload],
      signal
    }) => await UsersAPI.getUser(_payload, {
      signal
    }),
    queryKey: ["UsersAPI.getUser", payload] as const
  });
  if (isFetching) {
    return <Stack align="center" justify="center" fullHeight fullWidth>
        <LoadingSpinner />
      </Stack>;
  }
  if (error || P.isNullable(user)) {
    return <div>Failed to get user: {`${error}`}</div>;
  }
  return children(user);
}
export { UserProvider };