"use client";

import { useMutation } from "@tanstack/react-query";
import { Function as F, Option as O, Predicate as P, String as Str } from "effect";
import { useCallback, useMemo } from "react";
import { AuthAPI } from "@ender/shared/generated/ender.api.misc";
import type { EnderSessionResponse } from "@ender/shared/generated/ender.api.misc.response";
import { useAuthActor } from "../context/auth-actor.context";
import { AuthEventEnum } from "../machine/auth.types";
import type { ChangePasswordFormOutput } from "./change-password-form.utils";
import { useChangePasswordForm } from "./change-password-form.utils";
import { ChangePasswordFormView } from "./change-password-form.view";
type ChangePasswordFormControllerProps = {
  onCancel: () => void;
  onDone: () => void;
};
function ChangePasswordFormController(props: ChangePasswordFormControllerProps) {
  const {
    onCancel,
    onDone
  } = props;
  const {
    mutateAsync: setPasswordAsync,
    error,
    isLoading
  } = useMutation({
    mutationFn: AuthAPI.setPassword
  });
  const [authSnapshot, sendAuthEvent] = useAuthActor();
  const {
    context: {
      session
    }
  } = authSnapshot;
  const userDisplayName = useMemo(() => {
    return F.pipe(session, O.map(s => s.user.displayName), O.filter(Str.isNonEmpty));
  }, [session]);
  const errorMessage: O.Option<string> = useMemo(() => {
    if (P.isNotNullable(error)) {
      return O.some(`${error}`);
    }
    if (O.isNone(userDisplayName)) {
      return O.some("Sorry, but this password reset link is no longer valid.");
    }
    return O.none();
  }, [userDisplayName, error]);
  const form = useChangePasswordForm();
  const handleFormSubmit = useCallback((changePasswordFormValues: ChangePasswordFormOutput) => {
    setPasswordAsync(changePasswordFormValues).then((session: EnderSessionResponse | undefined) => {
      sendAuthEvent({
        payload: O.fromNullable(session),
        type: AuthEventEnum.SET_SESSION
      });
      onDone();
    });
  }, [sendAuthEvent, setPasswordAsync, onDone]);
  return <ChangePasswordFormView errorMessage={errorMessage} form={form} isFetching={isLoading} onCancelClick={onCancel} onFormSubmit={handleFormSubmit} userDisplayName={userDisplayName} data-sentry-element="ChangePasswordFormView" data-sentry-component="ChangePasswordFormController" data-sentry-source-file="change-password-form.controller.tsx" />;
}
export { ChangePasswordFormController };