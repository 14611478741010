export type {
  OwnershipGroupsAPIAddFirmToOwnershipGroupBodyParams,
  OwnershipGroupsAPIAddFirmToOwnershipGroupPayload,
  OwnershipGroupsAPIAddFirmToOwnershipGroupSearchParams,
} from "./add-firm-to-ownership-group";
export type {
  OwnershipGroupsAPIAddFundToOwnershipGroupBodyParams,
  OwnershipGroupsAPIAddFundToOwnershipGroupPayload,
  OwnershipGroupsAPIAddFundToOwnershipGroupSearchParams,
} from "./add-fund-to-ownership-group";
export type {
  OwnershipGroupsAPICreateOwnershipGroupBodyParams,
  OwnershipGroupsAPICreateOwnershipGroupPayload,
  OwnershipGroupsAPICreateOwnershipGroupSearchParams,
} from "./create-ownership-group";
export type {
  OwnershipGroupsAPIDeleteOwnershipGroupBodyParams,
  OwnershipGroupsAPIDeleteOwnershipGroupPayload,
  OwnershipGroupsAPIDeleteOwnershipGroupSearchParams,
} from "./delete-ownership-group";
export type {
  OwnershipGroupsAPIGetOwnershipGroupAssociationsBodyParams,
  OwnershipGroupsAPIGetOwnershipGroupAssociationsPayload,
  OwnershipGroupsAPIGetOwnershipGroupAssociationsSearchParams,
} from "./get-ownership-group-associations";
export type {
  OwnershipGroupsAPIGetOwnershipGroupsPayload,
  OwnershipGroupsAPIGetOwnershipGroupsSearchParams,
} from "./get-ownership-groups";
export { OwnershipGroupsAPI } from "./ownership-groups-api";
export type {
  OwnershipGroupsAPIRemoveFirmFromOwnershipGroupBodyParams,
  OwnershipGroupsAPIRemoveFirmFromOwnershipGroupPayload,
  OwnershipGroupsAPIRemoveFirmFromOwnershipGroupSearchParams,
} from "./remove-firm-from-ownership-group";
export type {
  OwnershipGroupsAPIRemoveFundFromOwnershipGroupBodyParams,
  OwnershipGroupsAPIRemoveFundFromOwnershipGroupPayload,
  OwnershipGroupsAPIRemoveFundFromOwnershipGroupSearchParams,
} from "./remove-fund-from-ownership-group";
export type {
  OwnershipGroupsAPIRenameOwnershipGroupBodyParams,
  OwnershipGroupsAPIRenameOwnershipGroupPayload,
  OwnershipGroupsAPIRenameOwnershipGroupSearchParams,
} from "./rename-ownership-group";
