"use client";

import { useRouter } from "next/navigation";
import { useCallback } from "react";
import { Modal } from "@ender/shared/ds/modal";
import { useBoolean } from "@ender/shared/hooks/use-boolean";
import { useAuthActor } from "../context/auth-actor.context";
import { ForgotPasswordFormController } from "../form/forgot-password-form.controller";
import { AuthStateEnum } from "../machine/auth.types";
import { RedirectModalController } from "./redirect-modal.controller";
type AuthModalControllerProps = {
  redirectUrl: string;
};
function ForgotPasswordModalController(props: AuthModalControllerProps) {
  const {
    redirectUrl
  } = props;
  const router = useRouter();
  const [snapshot] = useAuthActor();
  const {
    value: authState
  } = snapshot;
  const [success, successHandlers] = useBoolean(false);
  const handleSignIn = useCallback(() => {
    router.push("/login");
  }, [router]);
  if (authState === AuthStateEnum.AUTHENTICATED) {
    return <RedirectModalController message="You are already logged in" redirectUrl={redirectUrl} title="Authenticated" />;
  }
  if (success) {
    return <RedirectModalController message="Password reset email sent" redirectUrl="/login" title="Email Sent" />;
  }
  return <Modal title="" opened data-sentry-element="Modal" data-sentry-component="ForgotPasswordModalController" data-sentry-source-file="forgot-password-modal.controller.tsx">
      <ForgotPasswordFormController onDone={successHandlers.setTrue} onSignIn={handleSignIn} data-sentry-element="ForgotPasswordFormController" data-sentry-source-file="forgot-password-modal.controller.tsx" />
    </Modal>;
}
export { ForgotPasswordModalController };