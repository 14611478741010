import { fetchAssets } from "./fetch-assets";
import { getOwnershipTree } from "./get-ownership-tree";
import { getTopLevelFirms } from "./get-top-level-firms";
import { setOwners } from "./set-owners";
import { setOwnership } from "./set-ownership";

const OwnershipAPI = {
  fetchAssets,
  getOwnershipTree,
  getTopLevelFirms,
  setOwners,
  setOwnership,
};

export { OwnershipAPI };
