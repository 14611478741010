import { Array as A, Function as F, Predicate as P, Record as R } from "effect";
import type { StoreApi } from "zustand";
import { useStore } from "zustand";

import type { ObjectKeys } from "@ender/shared/types/general";

import type { Selectors } from "./types";

function createBoundUseStore<State extends {}>(
  store: StoreApi<State>,
): <U>(selector?: (state: State) => U) => State | U {
  return function useBoundStore<U>(selector?: (state: State) => U) {
    // Workaround so react won't think we are calling the hook conditionally
    const storeHook = useStore;
    return P.isNullable(selector)
      ? storeHook(store)
      : storeHook(store, selector);
  };
}

function createBoundUseStoreWithSelectors<State extends {}>(
  store: StoreApi<State>,
): (<U>(selector?: (state: State) => U) => State | U) & {
  use: Selectors<typeof store>;
} {
  const useBoundStore = createBoundUseStore<State>(store);

  // TS is not able to infer the types correct so we need to specify them
  const initialState: State = store.getInitialState();
  const selectors: Selectors<typeof store> = F.pipe(
    initialState,
    R.toEntries,
    A.map(([key]: [ObjectKeys<State>, unknown]) => {
      function useSelector() {
        return useStore(store, (_state: State) => _state[key]);
      }
      return [key, useSelector];
    }),
    Object.fromEntries,
  );

  return Object.assign(useBoundStore, {
    use: selectors,
  });
}

export { createBoundUseStore, createBoundUseStoreWithSelectors };
