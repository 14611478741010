"use client";

import { Schema } from "@effect/schema";
import { effectTsResolver } from "@hookform/resolvers/effect-ts";
import { useState } from "react";

import type { FormProps, UseFormReturn } from "@ender/form-system/base";
import { useForm } from "@ender/form-system/base";

const LoginFormSchema = Schema.Struct({
  email: Schema.String.pipe(
    Schema.nonEmptyString({ message: () => "Email is required" }),
    Schema.pattern(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, {
      message: () => "Please enter a valid email address",
    }),
  ),
  password: Schema.String,
});
type LoginFormOutput = Schema.Schema.Type<typeof LoginFormSchema>;
type LoginFormInput = Schema.Schema.Encoded<typeof LoginFormSchema>;

type UseLoginFormReturn = UseFormReturn<LoginFormInput, {}, LoginFormOutput>;
type LoginFormProps = FormProps<UseLoginFormReturn>;

function defaultInitialValues(): LoginFormOutput {
  return {
    email: "",
    password: "",
  };
}

type UseLoginFormArgs = {
  initialValues?: () => LoginFormOutput;
};

function useLoginForm(args?: UseLoginFormArgs) {
  const { initialValues = defaultInitialValues } = args ?? {};
  const [defaultValues] = useState(initialValues);

  return useForm<LoginFormOutput>({
    defaultValues: defaultValues,
    resolver: effectTsResolver(LoginFormSchema),
  });
}

export { LoginFormSchema, useLoginForm };
export type {
  LoginFormInput,
  LoginFormOutput,
  LoginFormProps,
  UseLoginFormArgs,
  UseLoginFormReturn,
};
