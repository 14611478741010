export {
  FontSize,
  FontSizeValues,
  FontWeight,
  FontWeightValues,
  Text,
  TextAlign,
  TextColor,
} from "./lib/shared-ds-text";
export type {
  FontSizes,
  FontWeights,
  TextColors,
  TextProps,
} from "./lib/shared-ds-text";
