/**
 * HTTP Method: GET
 * Pathname: /properties/{propertyId}/photos.zip
 * @function downloadPropertyPhotos
 * @memberof PropertiesAPI
 * @param {PropertiesAPIDownloadPropertyPhotosPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type PropertiesAPIDownloadPropertyPhotosPathParams = {
  propertyId: EnderId;
};

type PropertiesAPIDownloadPropertyPhotosSearchParams = {
  token?: string | undefined;
};

type PropertiesAPIDownloadPropertyPhotosPayload =
  PropertiesAPIDownloadPropertyPhotosPathParams &
    PropertiesAPIDownloadPropertyPhotosSearchParams;

function downloadPropertyPhotosUnsafeEffect(
  payload: PropertiesAPIDownloadPropertyPhotosPayload,
) {
  const { propertyId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeNoBody({ method: "GET" }),
        pathname: `/properties/${propertyId}/photos.zip`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function downloadPropertyPhotos(
  payload: PropertiesAPIDownloadPropertyPhotosPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    downloadPropertyPhotosUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { downloadPropertyPhotos };
export type {
  PropertiesAPIDownloadPropertyPhotosPathParams,
  PropertiesAPIDownloadPropertyPhotosPayload,
  PropertiesAPIDownloadPropertyPhotosSearchParams,
};
