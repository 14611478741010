/**
 * HTTP Method: POST
 * Pathname: /admin/ender/systemStatus
 * @function setSystemStatus
 * @memberof AdminAPI
 * @param {AdminAPISetSystemStatusPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderSystemStatusServiceDBStatus } from "@ender/shared/generated/ender.service.misc";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type AdminAPISetSystemStatusSearchParams = {
  token?: string | undefined;
};

type AdminAPISetSystemStatusBodyParams = {
  dbStatus?: EnderSystemStatusServiceDBStatus | undefined;
  systemMessage?: string | undefined;
};

type AdminAPISetSystemStatusPayload = AdminAPISetSystemStatusSearchParams &
  AdminAPISetSystemStatusBodyParams;

function setSystemStatusUnsafeEffect(payload: AdminAPISetSystemStatusPayload) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/admin/ender/systemStatus",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function setSystemStatus(
  payload: AdminAPISetSystemStatusPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    setSystemStatusUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { setSystemStatus };
export type {
  AdminAPISetSystemStatusBodyParams,
  AdminAPISetSystemStatusPayload,
  AdminAPISetSystemStatusSearchParams,
};
