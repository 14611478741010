/**
 * HTTP Method: POST
 * Pathname: /tasks/{taskId}
 * @function updateTask
 * @memberof TasksAPI
 * @param {TasksAPIUpdateTaskPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<Task>}
 */
import { Effect, Function as F } from "effect";

import type {
  EnderId,
  LocalDate,
  LocalTime,
  Money,
  SerializesInto,
} from "@ender/shared/core";
import type { PayableCategoryRequest } from "@ender/shared/generated/ender.api.misc.request";
import type { AssigneeIds } from "@ender/shared/generated/ender.api.model.tasks";
import type {
  Task,
  TaskCloseReason,
  TaskTaskPriority,
  TaskTaskStatus,
} from "@ender/shared/generated/ender.model.task";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type TasksAPIUpdateTaskPathParams = {
  taskId: EnderId;
};

type TasksAPIUpdateTaskSearchParams = {
  token?: string | undefined;
};

type TasksAPIUpdateTaskBodyParams = {
  addCategories: PayableCategoryRequest[];
  assignees?: AssigneeIds | undefined;
  chargebackAmount?: SerializesInto<Money> | undefined;
  closeReason?: TaskCloseReason | undefined;
  closeReasonDescription?: string | undefined;
  costEstimate?: SerializesInto<Money> | undefined;
  description?: string | undefined;
  overrideReason?: string | undefined;
  permissionToEnter?: boolean | undefined;
  priority?: TaskTaskPriority | undefined;
  removeCategories: PayableCategoryRequest[];
  scheduledDate?: SerializesInto<LocalDate> | undefined;
  scheduledTime?: SerializesInto<LocalTime> | undefined;
  scheduledTimeEnd?: SerializesInto<LocalTime> | undefined;
  serviceDescription?: string | undefined;
  statusOverride?: TaskTaskStatus | undefined;
  targetDate?: SerializesInto<LocalDate> | undefined;
  targetTime?: SerializesInto<LocalTime> | undefined;
};

type TasksAPIUpdateTaskPayload = TasksAPIUpdateTaskPathParams &
  TasksAPIUpdateTaskSearchParams &
  TasksAPIUpdateTaskBodyParams;

function updateTaskUnsafeEffect(payload: TasksAPIUpdateTaskPayload) {
  const { taskId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, Task>({
        body,
        decode: unsafeDecodeJsonResponse<Task>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/tasks/${taskId}`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function updateTask(
  payload: TasksAPIUpdateTaskPayload,
  options?: { signal?: AbortSignal },
): Promise<Task> {
  return F.pipe(
    payload,
    updateTaskUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { updateTask };
export type {
  TasksAPIUpdateTaskBodyParams,
  TasksAPIUpdateTaskPathParams,
  TasksAPIUpdateTaskPayload,
  TasksAPIUpdateTaskSearchParams,
};
