/**
 * HTTP Method: POST
 * Pathname: /properties/{propertyId}/updateFixedExpense
 * @function updateFixedExpense
 * @memberof PropertiesAPI
 * @param {PropertiesAPIUpdateFixedExpensePayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type {
  EnderId,
  LocalDate,
  Money,
  SerializesInto,
} from "@ender/shared/core";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type PropertiesAPIUpdateFixedExpensePathParams = {
  propertyId: EnderId;
};

type PropertiesAPIUpdateFixedExpenseSearchParams = {
  token?: string | undefined;
};

type PropertiesAPIUpdateFixedExpenseBodyParams = {
  hoaDues?: SerializesInto<Money> | undefined;
  propertyInsurance?: SerializesInto<Money> | undefined;
  propertyTax?: SerializesInto<Money> | undefined;
  selfInsurance?: SerializesInto<Money> | undefined;
  year?: SerializesInto<LocalDate> | undefined;
};

type PropertiesAPIUpdateFixedExpensePayload =
  PropertiesAPIUpdateFixedExpensePathParams &
    PropertiesAPIUpdateFixedExpenseSearchParams &
    PropertiesAPIUpdateFixedExpenseBodyParams;

function updateFixedExpenseUnsafeEffect(
  payload: PropertiesAPIUpdateFixedExpensePayload,
) {
  const { propertyId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/properties/${propertyId}/updateFixedExpense`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function updateFixedExpense(
  payload: PropertiesAPIUpdateFixedExpensePayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    updateFixedExpenseUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { updateFixedExpense };
export type {
  PropertiesAPIUpdateFixedExpenseBodyParams,
  PropertiesAPIUpdateFixedExpensePathParams,
  PropertiesAPIUpdateFixedExpensePayload,
  PropertiesAPIUpdateFixedExpenseSearchParams,
};
