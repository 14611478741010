"use client";

import { Option as O } from "effect";
import { Button, ButtonVariant } from "@ender/shared/ds/button";
import { Group } from "@ender/shared/ds/group";
import { H1 } from "@ender/shared/ds/heading";
import type { RadioGroupData } from "@ender/shared/ds/radio-group";
import { RadioGroup } from "@ender/shared/ds/radio-group";
import { Text, TextColor } from "@ender/shared/ds/text";
import type { TwilioClientVerificationCodeChannel } from "@ender/shared/generated/com.ender.common.arch.client";
type MultiFactorChannelSelectFormViewProps = {
  availableChannels: RadioGroupData<TwilioClientVerificationCodeChannel>[];
  channel: O.Option<TwilioClientVerificationCodeChannel>;
  errorMessage: O.Option<string>;
  isCancelDisabled: boolean;
  isFetching: boolean;
  isSendCodeDisabled: boolean;
  onCancelClick: () => void;
  onChannelChange: (channel: O.Option<TwilioClientVerificationCodeChannel>) => void;
  onSendCodeClick: () => void;
};
function MultiFactorChannelSelectFormView(props: MultiFactorChannelSelectFormViewProps) {
  const {
    availableChannels,
    channel,
    errorMessage,
    isCancelDisabled,
    isFetching,
    isSendCodeDisabled,
    onCancelClick,
    onChannelChange,
    onSendCodeClick
  } = props;
  return <>
      <H1 data-sentry-element="H1" data-sentry-source-file="multi-factor-channel-select-form.view.tsx">Verify Your Account</H1>
      <label>Choose your verification method</label>
      <RadioGroup value={channel} onChange={onChannelChange} data={availableChannels} data-sentry-element="RadioGroup" data-sentry-source-file="multi-factor-channel-select-form.view.tsx" />
      {O.isSome(errorMessage) && <Text color={TextColor["red-500"]}>{O.getOrThrow(errorMessage)}</Text>}
      <Group data-sentry-element="Group" data-sentry-source-file="multi-factor-channel-select-form.view.tsx">
        <Button variant={ButtonVariant.outlined} onClick={onCancelClick} loading={isFetching} disabled={isCancelDisabled || isFetching} data-sentry-element="Button" data-sentry-source-file="multi-factor-channel-select-form.view.tsx">
          Cancel
        </Button>
        <Button onClick={onSendCodeClick} loading={isFetching} disabled={isSendCodeDisabled || isFetching} data-sentry-element="Button" data-sentry-source-file="multi-factor-channel-select-form.view.tsx">
          Send Code
        </Button>
      </Group>
    </>;
}
export { MultiFactorChannelSelectFormView };