/**
 * HTTP Method: POST
 * Pathname: /services/{serviceId}
 * @function updateService
 * @memberof TasksAPI
 * @param {TasksAPIUpdateServicePayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<RecurringService>}
 */
import { Effect, Function as F } from "effect";

import type {
  EnderId,
  LocalDate,
  Money,
  SerializesInto,
} from "@ender/shared/core";
import type {
  RecurringService,
  RecurringServiceScheduleFrequency,
  RecurringServiceScheduleType,
} from "@ender/shared/generated/ender.model.core.property";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type TasksAPIUpdateServicePathParams = {
  serviceId: EnderId;
};

type TasksAPIUpdateServiceSearchParams = {
  token?: string | undefined;
};

type TasksAPIUpdateServiceBodyParams = {
  amount?: SerializesInto<Money> | undefined;
  endDateInclusive?: SerializesInto<LocalDate> | undefined;
  glCategoryId?: EnderId | undefined;
  scheduleFrequency?: RecurringServiceScheduleFrequency | undefined;
  scheduleType?: RecurringServiceScheduleType | undefined;
};

type TasksAPIUpdateServicePayload = TasksAPIUpdateServicePathParams &
  TasksAPIUpdateServiceSearchParams &
  TasksAPIUpdateServiceBodyParams;

function updateServiceUnsafeEffect(payload: TasksAPIUpdateServicePayload) {
  const { serviceId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, RecurringService>({
        body,
        decode: unsafeDecodeJsonResponse<RecurringService>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/services/${serviceId}`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function updateService(
  payload: TasksAPIUpdateServicePayload,
  options?: { signal?: AbortSignal },
): Promise<RecurringService> {
  return F.pipe(
    payload,
    updateServiceUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { updateService };
export type {
  TasksAPIUpdateServiceBodyParams,
  TasksAPIUpdateServicePathParams,
  TasksAPIUpdateServicePayload,
  TasksAPIUpdateServiceSearchParams,
};
