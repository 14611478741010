"use client";

import { Modal } from "@ender/shared/ds/modal";
import { VendorSelectFormController } from "../form/vendor-select-form.controller";
type VendorSelectModalViewProps = {
  onCancel: () => void;
  onDone: () => void;
  opened: boolean;
};
function VendorSelectModal(props: VendorSelectModalViewProps) {
  const {
    onCancel,
    onDone,
    opened
  } = props;
  return <Modal title="" opened={opened} data-sentry-element="Modal" data-sentry-component="VendorSelectModal" data-sentry-source-file="vendor-select-modal.tsx">
      <VendorSelectFormController onCancel={onCancel} onDone={onDone} data-sentry-element="VendorSelectFormController" data-sentry-source-file="vendor-select-modal.tsx" />
    </Modal>;
}
export { VendorSelectModal };