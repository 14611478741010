import { ParseResult, Schema } from "@effect/schema";
import { randRecentDate } from "@ngneat/falso";
import type { Duration } from "date-fns";
import {
  add,
  formatWithOptions,
  isAfter,
  isBefore,
  isEqual,
  parseISO,
} from "date-fns/fp";
import { enUS } from "date-fns/locale";
import type { Either as E } from "effect";
import { Function as F, Match, Option as O } from "effect";

import type { Comparable } from "../comparable";
import * as LocalDate$ from "../local-date/local-date";
import { LocalTime$ } from "../local-time/local-time";
import type { Serializable } from "../serializable";
import type { Instant } from "./instant.types";

const DEFAULT_FORMAT = "MM/dd/yyyy h:mmaaa";
const SHORT_24_HOUR_FORMAT = "MM/dd/yyyy HH:mm";

// eslint-disable-next-line no-use-before-define
type Parsable = string | number | Date | Instant$ | null | undefined;

// eslint-disable-next-line no-use-before-define
class Instant$ implements Serializable, Comparable<Instant$> {
  // === Instance Properties ===
  private readonly value: Date;

  /**
   * Gets the year
   */
  get year(): number {
    return this.value.getFullYear();
  }

  /**
   * Gets the month (1-indexed)
   * eg. 1 for January, 8 for August, etc
   */
  get month(): number {
    return this.value.getMonth() + 1;
  }

  /**
   * Gets the day-of-the-month (An integer between 1 and 31)
   */
  get dayOfMonth(): number {
    return this.value.getDate();
  }

  get hour(): number {
    return this.value.getHours();
  }

  get minute(): number {
    return this.value.getMinutes();
  }

  get second(): number {
    return this.value.getSeconds();
  }

  get millisecond(): number {
    return this.value.getMilliseconds();
  }

  // === Instance Methods ===
  /**
   * We want to encourage using the static methods instead of the constructor.
   * @deprecated Use Instant$.parse, Instant$.safeParse or Instant$.of instead.
   */
  public constructor(input?: Parsable) {
    this.value = F.pipe(
      input,
      O.fromNullable,
      O.map((x: NonNullable<Parsable>) => {
        return Match.value(x).pipe(
          Match.when(Match.string, parseISO),
          Match.whenOr(Match.number, Match.date, (v) => new Date(v)),
          Match.orElse((v) => new Date(v.valueOf())),
        );
      }),
      O.getOrElse(() => new Date()),
    );
  }

  public add(duration: Duration): Instant$ {
    return F.pipe(this.value, add(duration), Instant$.of);
  }

  public isValid() {
    return !this.isInvalid();
  }

  public isInvalid() {
    return isNaN(this.value.getTime());
  }

  public isAfter(right: Instant$): boolean {
    return isAfter(right.toDate(), this.value);
  }

  public isBefore(right: Instant$): boolean {
    return isBefore(right.toDate(), this.value);
  }

  public isEqual(right: Instant$): boolean {
    return isEqual(right.toDate(), this.value);
  }

  public isBeforeOrEqual(right: Instant$) {
    return this.isBefore(right) || this.isEqual(right);
  }

  public isAfterOrEqual(right: Instant$) {
    return this.isAfter(right) || this.isEqual(right);
  }

  public compareTo(right: Instant$) {
    if (this.isBefore(right)) {
      return -1;
    } else if (this.isEqual(right)) {
      return 0;
    } else {
      return 1;
    }
  }

  /**
   * @returns a JavaScript Date representation of this class
   */
  public toDate(): Date {
    return this.value;
  }

  public toJSON(): Instant {
    return this.value.toISOString() as Instant;
  }

  public toLocalDate(): LocalDate$.LocalDate {
    return LocalDate$.of(this.value);
  }

  public toLocalTime(): LocalTime$ {
    return LocalTime$.of(this.value);
  }

  public toString(): string {
    // eg. 05/23/1234 6:44am
    return F.pipe(this.value, Instant$.defaultFormat);
  }

  public toShort24HourFormat(): string {
    // eg. 05/23/1234 18:44
    return F.pipe(this.value, Instant$.short24HourFormat);
  }

  /**
   * The number of milliseconds that has elapsed since the epoch (Midnight, January 1, 1970 UTC)
   */
  public valueOf(): number {
    return this.value.valueOf();
  }

  // === Static Properties ===
  private static Schema = Schema.Union(
    Schema.String,
    Schema.Number,
    Schema.instanceOf(Date),
    // eslint-disable-next-line no-use-before-define
    Schema.instanceOf(Instant$),
  ).pipe(
    Schema.transformOrFail(Schema.instanceOf(Instant$), {
      decode: (v, _, ast) => {
        const val = new Instant$(v);
        if (!val.isValid()) {
          return ParseResult.fail(
            new ParseResult.Transformation(
              ast,
              v,
              "Transformation",
              new ParseResult.Type(
                ast,
                v,
                "Provided value was not parseable to Instant$",
              ),
            ),
          );
        }
        return ParseResult.succeed(val);
      },
      encode: (v, _, ast) =>
        ParseResult.fail(
          new ParseResult.Forbidden(
            ast,
            v,
            "Encoding Instant$ back to parsable input is forbidden",
          ),
        ),
    }),
  );

  static defaultFormat = formatWithOptions({ locale: enUS }, DEFAULT_FORMAT);
  static short24HourFormat = formatWithOptions(
    { locale: enUS },
    SHORT_24_HOUR_FORMAT,
  );

  static get now(): Instant$ {
    return new Instant$(Date.now());
  }

  // === Static Methods ===

  /**
   * @returns the parsed Instant or the current time if the input is null or undefined.
   * @throws if the input is invalid.
   */
  public static of(input?: Parsable): Instant$ {
    return F.pipe(
      input,
      Schema.decodeUnknownOption(Instant$.Schema),
      O.getOrElse(F.constant(Instant$.now)),
    );
  }

  /**
   * @returns the O.some(instant) or O.none() if the input is null, undefined, or invalid.
   */
  public static parse(input?: Parsable): O.Option<Instant$> {
    return Schema.decodeUnknownOption(Instant$.Schema)(input);
  }

  /**
   * @returns the parsed Instant on the right or a ZodError on the left if the input is null, undefined, or invalid.
   */
  public static safeParse(
    input?: Parsable,
  ): E.Either<Instant$, ParseResult.ParseError> {
    return Schema.decodeUnknownEither(Instant$.Schema)(input);
  }

  /**
   * @deprecated Use the tree-shakable `randomInstant` import instead.
   */
  public static random(): Instant$ {
    // eslint-disable-next-line no-use-before-define
    return randomInstant();
  }
}

/**
 * Generate a random Instant using @ngneat/falso randRecentDate().
 * @returns Randomly generated Instant.
 */
function randomInstant(): Instant$ {
  return Instant$.of(randRecentDate());
}

export { Instant$, randomInstant };
