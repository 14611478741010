/**
 * HTTP Method: GET
 * Pathname: /pms/{pmId}/whiteLabelConfig
 * @function getWhiteLabelConfig
 * @memberof EnderAPI
 * @param {EnderAPIGetWhiteLabelConfigPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<EnderAPIGetWhiteLabelConfigResponse>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

import type { EnderAPIGetWhiteLabelConfigResponse } from "../ender-api-get-white-label-config-response";

type EnderAPIGetWhiteLabelConfigPathParams = {
  pmId: EnderId;
};

type EnderAPIGetWhiteLabelConfigSearchParams = {
  token?: string | undefined;
};

type EnderAPIGetWhiteLabelConfigPayload =
  EnderAPIGetWhiteLabelConfigPathParams &
    EnderAPIGetWhiteLabelConfigSearchParams;

function getWhiteLabelConfigUnsafeEffect(
  payload: EnderAPIGetWhiteLabelConfigPayload,
) {
  const { pmId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, EnderAPIGetWhiteLabelConfigResponse>({
        body,
        decode: unsafeDecodeJsonResponse<EnderAPIGetWhiteLabelConfigResponse>(
          {},
        ),
        encode: encodeNoBody({ method: "GET" }),
        pathname: `/pms/${pmId}/whiteLabelConfig`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getWhiteLabelConfig(
  payload: EnderAPIGetWhiteLabelConfigPayload,
  options?: { signal?: AbortSignal },
): Promise<EnderAPIGetWhiteLabelConfigResponse> {
  return F.pipe(
    payload,
    getWhiteLabelConfigUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getWhiteLabelConfig };
export type {
  EnderAPIGetWhiteLabelConfigPathParams,
  EnderAPIGetWhiteLabelConfigPayload,
  EnderAPIGetWhiteLabelConfigSearchParams,
};
