/**
 * HTTP Method: GET
 * Pathname: /pms/{pmId}
 * @function getPM
 * @memberof EnderAPI
 * @param {EnderAPIGetPMPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<GetPMResponse>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { GetPMResponse } from "@ender/shared/generated/ender.api.misc.response";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type EnderAPIGetPMPathParams = {
  pmId: EnderId;
};

type EnderAPIGetPMSearchParams = {
  token?: string | undefined;
};

type EnderAPIGetPMPayload = EnderAPIGetPMPathParams & EnderAPIGetPMSearchParams;

function getPMUnsafeEffect(payload: EnderAPIGetPMPayload) {
  const { pmId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, GetPMResponse>({
        body,
        decode: unsafeDecodeJsonResponse<GetPMResponse>({}),
        encode: encodeNoBody({ method: "GET" }),
        pathname: `/pms/${pmId}`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getPM(
  payload: EnderAPIGetPMPayload,
  options?: { signal?: AbortSignal },
): Promise<GetPMResponse> {
  return F.pipe(payload, getPMUnsafeEffect, Effect.scoped, runPromise)(options);
}

export { getPM };
export type {
  EnderAPIGetPMPathParams,
  EnderAPIGetPMPayload,
  EnderAPIGetPMSearchParams,
};
