import { getBankAccount } from "./get-bank-account";
import { getDwollaAccountsForCustomer } from "./get-dwolla-accounts-for-customer";
import { getDwollaCustomer } from "./get-dwolla-customer";
import { getDwollaTransfersForCustomer } from "./get-dwolla-transfers-for-customer";
import { getFirm } from "./get-firm";
import { getFirms } from "./get-firms";
import { getMoneyTransfer } from "./get-money-transfer";
import { searchMessages } from "./search-messages";
import { searchMoneyTransfers } from "./search-money-transfers";

const ModelExplorerAPI = {
  getBankAccount,
  getDwollaAccountsForCustomer,
  getDwollaCustomer,
  getDwollaTransfersForCustomer,
  getFirm,
  getFirms,
  getMoneyTransfer,
  searchMessages,
  searchMoneyTransfers,
};

export { ModelExplorerAPI };
