import { Schema } from "@effect/schema";
import type { Annotations, filter } from "@effect/schema/Schema";
import { nonEmptyString } from "@effect/schema/Schema";
import * as S from "effect/String";

import { isPossiblePhoneNumber } from "@ender/shared/utils/phone";

/**
 *  This Schema serves as an optional schema because "" is a valid phone string according to isPossiblePhoneNumber
 *
 *  In order to provide messaging about required input, you must refine the schema with
 *  PhoneEffectSchema.pipe(Schema.nonEmptyString({ message: () => "Phone is required" })),
 */
const PhoneEffectSchema = Schema.String.pipe(
  Schema.trimmed(),
  Schema.filter((value) => S.isEmpty(value) || isPossiblePhoneNumber(value), {
    message: () => "Must be a valid phone number",
  }),
);

/**
 * @category phone filter
 */
const nonEmptyPhone = <A extends string>(
  annotations?: Annotations.Filter<A>,
): (<I, R>(self: Schema.Schema<A, I, R>) => filter<Schema.Schema<A, I, R>>) =>
  nonEmptyString({
    description: "a non empty phone",
    ...annotations,
  });

export { nonEmptyPhone, PhoneEffectSchema };
