/**
 * HTTP Method: DELETE
 * Pathname: /units/zones/{zoneId}
 * @function deleteUnitZone
 * @memberof UnitZonesAPI
 * @param {UnitZonesAPIDeleteUnitZonePayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<UnitZone>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { UnitZone } from "@ender/shared/generated/ender.model.core.unit.zone";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type UnitZonesAPIDeleteUnitZonePathParams = {
  zoneId: EnderId;
};

type UnitZonesAPIDeleteUnitZoneSearchParams = {
  token?: string | undefined;
};

type UnitZonesAPIDeleteUnitZonePayload = UnitZonesAPIDeleteUnitZonePathParams &
  UnitZonesAPIDeleteUnitZoneSearchParams;

function deleteUnitZoneUnsafeEffect(
  payload: UnitZonesAPIDeleteUnitZonePayload,
) {
  const { zoneId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, UnitZone>({
        body,
        decode: unsafeDecodeJsonResponse<UnitZone>({}),
        encode: encodeJsonBody({ method: "DELETE" }),
        pathname: `/units/zones/${zoneId}`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function deleteUnitZone(
  payload: UnitZonesAPIDeleteUnitZonePayload,
  options?: { signal?: AbortSignal },
): Promise<UnitZone> {
  return F.pipe(
    payload,
    deleteUnitZoneUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { deleteUnitZone };
export type {
  UnitZonesAPIDeleteUnitZonePathParams,
  UnitZonesAPIDeleteUnitZonePayload,
  UnitZonesAPIDeleteUnitZoneSearchParams,
};
