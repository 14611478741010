/**
 * HTTP Method: GET
 * Pathname: /properties/{propertyId}/utilitiesPolicies
 * @function getUtilitiesPolicies
 * @memberof PropertiesAPI
 * @param {PropertiesAPIGetUtilitiesPoliciesPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<GetUtilitiesPoliciesResponse[]>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { GetUtilitiesPoliciesResponse } from "@ender/shared/generated/ender.api.core.response";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type PropertiesAPIGetUtilitiesPoliciesPathParams = {
  propertyId: EnderId;
};

type PropertiesAPIGetUtilitiesPoliciesSearchParams = {
  token?: string | undefined;
};

type PropertiesAPIGetUtilitiesPoliciesPayload =
  PropertiesAPIGetUtilitiesPoliciesPathParams &
    PropertiesAPIGetUtilitiesPoliciesSearchParams;

function getUtilitiesPoliciesUnsafeEffect(
  payload: PropertiesAPIGetUtilitiesPoliciesPayload,
) {
  const { propertyId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, GetUtilitiesPoliciesResponse[]>({
        body,
        decode: unsafeDecodeJsonResponse<GetUtilitiesPoliciesResponse[]>({}),
        encode: encodeNoBody({ method: "GET" }),
        pathname: `/properties/${propertyId}/utilitiesPolicies`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getUtilitiesPolicies(
  payload: PropertiesAPIGetUtilitiesPoliciesPayload,
  options?: { signal?: AbortSignal },
): Promise<GetUtilitiesPoliciesResponse[]> {
  return F.pipe(
    payload,
    getUtilitiesPoliciesUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getUtilitiesPolicies };
export type {
  PropertiesAPIGetUtilitiesPoliciesPathParams,
  PropertiesAPIGetUtilitiesPoliciesPayload,
  PropertiesAPIGetUtilitiesPoliciesSearchParams,
};
