/**
 * HTTP Method: GET
 * Pathname: /metrics/highLevelStats
 * @function getHighLevelStats
 * @memberof MetricsAPI
 * @param {MetricsAPIGetHighLevelStatsPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<MetricsAPIGetHighLevelStatsResponse>}
 */
import { Effect, Function as F } from "effect";

import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

import type { MetricsAPIGetHighLevelStatsResponse } from "../metrics-api-get-high-level-stats-response";

type MetricsAPIGetHighLevelStatsSearchParams = {
  token?: string | undefined;
};

type MetricsAPIGetHighLevelStatsPayload =
  MetricsAPIGetHighLevelStatsSearchParams;

function getHighLevelStatsUnsafeEffect(
  payload: MetricsAPIGetHighLevelStatsPayload,
) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, MetricsAPIGetHighLevelStatsResponse>({
        body,
        decode: unsafeDecodeJsonResponse<MetricsAPIGetHighLevelStatsResponse>(
          {},
        ),
        encode: encodeNoBody({ method: "GET" }),
        pathname: "/metrics/highLevelStats",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getHighLevelStats(
  payload: MetricsAPIGetHighLevelStatsPayload,
  options?: { signal?: AbortSignal },
): Promise<MetricsAPIGetHighLevelStatsResponse> {
  return F.pipe(
    payload,
    getHighLevelStatsUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getHighLevelStats };
export type {
  MetricsAPIGetHighLevelStatsPayload,
  MetricsAPIGetHighLevelStatsSearchParams,
};
