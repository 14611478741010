/**
 * HTTP Method: POST
 * Pathname: /photos/{photoId}/makePrimary
 * @function makePrimaryPhoto
 * @memberof PhotosAPI
 * @param {PhotosAPIMakePrimaryPhotoPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 * @deprecated
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type PhotosAPIMakePrimaryPhotoPathParams = {
  photoId: EnderId;
};

type PhotosAPIMakePrimaryPhotoSearchParams = {
  token?: string | undefined;
};

type PhotosAPIMakePrimaryPhotoPayload = PhotosAPIMakePrimaryPhotoPathParams &
  PhotosAPIMakePrimaryPhotoSearchParams;

function makePrimaryPhotoUnsafeEffect(
  payload: PhotosAPIMakePrimaryPhotoPayload,
) {
  const { photoId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/photos/${photoId}/makePrimary`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

/**
 * @deprecated
 */
function makePrimaryPhoto(
  payload: PhotosAPIMakePrimaryPhotoPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    makePrimaryPhotoUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { makePrimaryPhoto };
export type {
  PhotosAPIMakePrimaryPhotoPathParams,
  PhotosAPIMakePrimaryPhotoPayload,
  PhotosAPIMakePrimaryPhotoSearchParams,
};
