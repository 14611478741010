/**
 * HTTP Method: GET
 * Pathname: /current-user/vendors
 * @function getCurrentUserVendors
 * @memberof UsersAPI
 * @param {UsersAPIGetCurrentUserVendorsPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<GetCurrentUserVendorResponse[]>}
 */
import { Effect, Function as F } from "effect";

import type { GetCurrentUserVendorResponse } from "@ender/shared/generated/ender.api.misc.response";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type UsersAPIGetCurrentUserVendorsSearchParams = {
  token?: string | undefined;
};

type UsersAPIGetCurrentUserVendorsPayload =
  UsersAPIGetCurrentUserVendorsSearchParams;

function getCurrentUserVendorsUnsafeEffect(
  payload: UsersAPIGetCurrentUserVendorsPayload,
) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, GetCurrentUserVendorResponse[]>({
        body,
        decode: unsafeDecodeJsonResponse<GetCurrentUserVendorResponse[]>({}),
        encode: encodeNoBody({ method: "GET" }),
        pathname: "/current-user/vendors",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getCurrentUserVendors(
  payload: UsersAPIGetCurrentUserVendorsPayload,
  options?: { signal?: AbortSignal },
): Promise<GetCurrentUserVendorResponse[]> {
  return F.pipe(
    payload,
    getCurrentUserVendorsUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getCurrentUserVendors };
export type {
  UsersAPIGetCurrentUserVendorsPayload,
  UsersAPIGetCurrentUserVendorsSearchParams,
};
