/**
 * HTTP Method: POST
 * Pathname: /tasks/{taskId}/confirmWork
 * @function onTaskConfirmedByPM
 * @memberof TasksAPI
 * @param {TasksAPIOnTaskConfirmedByPMPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type TasksAPIOnTaskConfirmedByPMPathParams = {
  taskId: EnderId;
};

type TasksAPIOnTaskConfirmedByPMSearchParams = {
  token?: string | undefined;
};

type TasksAPIOnTaskConfirmedByPMPayload =
  TasksAPIOnTaskConfirmedByPMPathParams &
    TasksAPIOnTaskConfirmedByPMSearchParams;

function onTaskConfirmedByPMUnsafeEffect(
  payload: TasksAPIOnTaskConfirmedByPMPayload,
) {
  const { taskId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/tasks/${taskId}/confirmWork`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function onTaskConfirmedByPM(
  payload: TasksAPIOnTaskConfirmedByPMPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    onTaskConfirmedByPMUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { onTaskConfirmedByPM };
export type {
  TasksAPIOnTaskConfirmedByPMPathParams,
  TasksAPIOnTaskConfirmedByPMPayload,
  TasksAPIOnTaskConfirmedByPMSearchParams,
};
