import { deleteFirm } from "./delete-firm";
import { getFirm } from "./get-firm";
import { getFirms } from "./get-firms";
import { getStateConfigurations } from "./get-state-configurations";
import { newFirm } from "./new-firm";
import { searchFirms } from "./search-firms";
import { updateFirm } from "./update-firm";
import { updateFirmSettings } from "./update-firm-settings";
import { updateStateConfiguration } from "./update-state-configuration";

const FirmsAPI = {
  deleteFirm,
  getFirm,
  getFirms,
  getStateConfigurations,
  newFirm,
  searchFirms,
  updateFirm,
  updateFirmSettings,
  updateStateConfiguration,
};

export { FirmsAPI };
