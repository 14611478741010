/**
 * HTTP Method: POST
 * Pathname: /vendors/{vendorId}/approve
 * @function approvePendingVendor
 * @memberof VendorsAPI
 * @param {VendorsAPIApprovePendingVendorPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type VendorsAPIApprovePendingVendorPathParams = {
  vendorId: EnderId;
};

type VendorsAPIApprovePendingVendorSearchParams = {
  token?: string | undefined;
};

type VendorsAPIApprovePendingVendorBodyParams = {
  replacementVendorId?: EnderId | undefined;
};

type VendorsAPIApprovePendingVendorPayload =
  VendorsAPIApprovePendingVendorPathParams &
    VendorsAPIApprovePendingVendorSearchParams &
    VendorsAPIApprovePendingVendorBodyParams;

function approvePendingVendorUnsafeEffect(
  payload: VendorsAPIApprovePendingVendorPayload,
) {
  const { vendorId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/vendors/${vendorId}/approve`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function approvePendingVendor(
  payload: VendorsAPIApprovePendingVendorPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    approvePendingVendorUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { approvePendingVendor };
export type {
  VendorsAPIApprovePendingVendorBodyParams,
  VendorsAPIApprovePendingVendorPathParams,
  VendorsAPIApprovePendingVendorPayload,
  VendorsAPIApprovePendingVendorSearchParams,
};
