import { addInsurance } from "./add-insurance";
import { createAppliance } from "./create-appliance";
import { deleteAppliance } from "./delete-appliance";
import { getApplyInfo } from "./get-apply-info";
import { getUnitAmenities } from "./get-unit-amenities";
import { getUnitAppliances } from "./get-unit-appliances";
import { getUnitDetails } from "./get-unit-details";
import { getUnits } from "./get-units";
import { setUnitAmenities } from "./set-unit-amenities";
import { updateAppliance } from "./update-appliance";
import { updateUnit } from "./update-unit";

const UnitsAPI = {
  addInsurance,
  createAppliance,
  deleteAppliance,
  getApplyInfo,
  getUnitAmenities,
  getUnitAppliances,
  getUnitDetails,
  getUnits,
  setUnitAmenities,
  updateAppliance,
  updateUnit,
};

export { UnitsAPI };
