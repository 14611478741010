/**
 * HTTP Method: POST
 * Pathname: /photos/{photoId}
 * @function updatePhoto
 * @memberof PhotosAPI
 * @param {PhotosAPIUpdatePhotoPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type PhotosAPIUpdatePhotoPathParams = {
  photoId: EnderId;
};

type PhotosAPIUpdatePhotoSearchParams = {
  token?: string | undefined;
};

type PhotosAPIUpdatePhotoBodyParams = {
  comment?: string | undefined;
  isPrimary?: boolean | undefined;
};

type PhotosAPIUpdatePhotoPayload = PhotosAPIUpdatePhotoPathParams &
  PhotosAPIUpdatePhotoSearchParams &
  PhotosAPIUpdatePhotoBodyParams;

function updatePhotoUnsafeEffect(payload: PhotosAPIUpdatePhotoPayload) {
  const { photoId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/photos/${photoId}`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function updatePhoto(
  payload: PhotosAPIUpdatePhotoPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    updatePhotoUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { updatePhoto };
export type {
  PhotosAPIUpdatePhotoBodyParams,
  PhotosAPIUpdatePhotoPathParams,
  PhotosAPIUpdatePhotoPayload,
  PhotosAPIUpdatePhotoSearchParams,
};
