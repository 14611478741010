/**
 * HTTP Method: GET
 * Pathname: /firms/topLevel
 * @function getTopLevelFirms
 * @memberof OwnershipAPI
 * @param {OwnershipAPIGetTopLevelFirmsPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<Firm[]>}
 */
import { Effect, Function as F } from "effect";

import type { Firm } from "@ender/shared/generated/ender.model.core";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type OwnershipAPIGetTopLevelFirmsSearchParams = {
  token?: string | undefined;
};

type OwnershipAPIGetTopLevelFirmsPayload =
  OwnershipAPIGetTopLevelFirmsSearchParams;

function getTopLevelFirmsUnsafeEffect(
  payload: OwnershipAPIGetTopLevelFirmsPayload,
) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, Firm[]>({
        body,
        decode: unsafeDecodeJsonResponse<Firm[]>({}),
        encode: encodeNoBody({ method: "GET" }),
        pathname: "/firms/topLevel",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getTopLevelFirms(
  payload: OwnershipAPIGetTopLevelFirmsPayload,
  options?: { signal?: AbortSignal },
): Promise<Firm[]> {
  return F.pipe(
    payload,
    getTopLevelFirmsUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getTopLevelFirms };
export type {
  OwnershipAPIGetTopLevelFirmsPayload,
  OwnershipAPIGetTopLevelFirmsSearchParams,
};
