"use client";

import { createStore } from "zustand/vanilla";

import { UNDEFINED } from "@ender/shared/constants/general";
import type { ObjectValues } from "@ender/shared/types/general";
import { delay } from "@ender/shared/utils/promise";
import { createBoundUseStoreWithSelectors } from "@ender/shared/utils/store";

const EnvironmentEnum = {
  DEMO: "DEMO",
  DEVELOPER: "DEVELOPER",
  PRODUCTION: "PRODUCTION",
  SANDBOX: "SANDBOX",
  STAGING: "STAGING",
} as const;
type Environment = ObjectValues<typeof EnvironmentEnum>;

type EnvironmentStoreState = {
  authAppUrl: string;
  environment: Environment;
  isDeveloper: boolean;
  isProduction: boolean;
  isArrearsai: boolean;
};
const environmentStore = createStore<EnvironmentStoreState>()(
  (): EnvironmentStoreState => {
    return {
      authAppUrl: globalThis.location ? globalThis.location.toString() : "",
      environment: EnvironmentEnum.DEVELOPER,
      isArrearsai: false,
      isDeveloper: true,
      isProduction: false,
    };
  },
);
const useEnvironmentStore =
  createBoundUseStoreWithSelectors<EnvironmentStoreState>(environmentStore);

function setEnvironment(environment: Environment) {
  environmentStore.setState({
    environment,
    isDeveloper: environment === EnvironmentEnum.DEVELOPER,
    isProduction: environment === EnvironmentEnum.PRODUCTION,
  });
}

let initThenable: Promise<void> | undefined = UNDEFINED;

function initializeEnvironmentStore(): Promise<void> {
  if (!initThenable) {
    const init = async () => {
      // We use delay below to ensure this async call is delayed by at-least one tick to be compatible with RSC
      // It also avoids this React Error `Cannot update a component ... while rendering a different component`
      await delay(0);
      try {
        const response = await fetch("/node-api/environment");
        const {
          authAppUrl,
          environment,
          isArrearsai,
        }: {
          authAppUrl: string;
          environment: Environment;
          isArrearsai: boolean;
        } = await response.json();
        environmentStore.setState({ authAppUrl });
        environmentStore.setState({ isArrearsai });
        setEnvironment(environment);
      } finally {
        initThenable = UNDEFINED;
      }
    };
    initThenable = init();
  }
  return Promise.resolve(initThenable);
}

export {
  EnvironmentEnum,
  environmentStore,
  initializeEnvironmentStore,
  setEnvironment,
  useEnvironmentStore,
};
export type { Environment, EnvironmentStoreState };
