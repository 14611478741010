/**
 * HTTP Method: GET
 * Pathname: /model-explorer/firms
 * @function getFirms
 * @memberof ModelExplorerAPI
 * @param {ModelExplorerAPIGetFirmsPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<ModelExplorerAPIGetFirmsResponse>}
 */
import { Effect, Function as F } from "effect";

import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

import type { ModelExplorerAPIGetFirmsResponse } from "../model-explorer-api-get-firms-response";

type ModelExplorerAPIGetFirmsSearchParams = {
  token?: string | undefined;
};

type ModelExplorerAPIGetFirmsPayload = ModelExplorerAPIGetFirmsSearchParams;

function getFirmsUnsafeEffect(payload: ModelExplorerAPIGetFirmsPayload) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, ModelExplorerAPIGetFirmsResponse>({
        body,
        decode: unsafeDecodeJsonResponse<ModelExplorerAPIGetFirmsResponse>({}),
        encode: encodeNoBody({ method: "GET" }),
        pathname: "/model-explorer/firms",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getFirms(
  payload: ModelExplorerAPIGetFirmsPayload,
  options?: { signal?: AbortSignal },
): Promise<ModelExplorerAPIGetFirmsResponse> {
  return F.pipe(
    payload,
    getFirmsUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getFirms };
export type {
  ModelExplorerAPIGetFirmsPayload,
  ModelExplorerAPIGetFirmsSearchParams,
};
