/**
 * HTTP Method: GET
 * Pathname: /firms
 * @function getFirms
 * @memberof FirmsAPI
 * @param {FirmsAPIGetFirmsPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<Firm[]>}
 */
import { Effect, Function as F } from "effect";

import type { Firm } from "@ender/shared/generated/ender.model.core";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type FirmsAPIGetFirmsSearchParams = {
  token?: string | undefined;
};

type FirmsAPIGetFirmsPayload = FirmsAPIGetFirmsSearchParams;

function getFirmsUnsafeEffect(payload: FirmsAPIGetFirmsPayload) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, Firm[]>({
        body,
        decode: unsafeDecodeJsonResponse<Firm[]>({}),
        encode: encodeNoBody({ method: "GET" }),
        pathname: "/firms",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getFirms(
  payload: FirmsAPIGetFirmsPayload,
  options?: { signal?: AbortSignal },
): Promise<Firm[]> {
  return F.pipe(
    payload,
    getFirmsUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getFirms };
export type { FirmsAPIGetFirmsPayload, FirmsAPIGetFirmsSearchParams };
