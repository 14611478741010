/**
 * This runs as noTransaction so that if there is an error for one Application, it will still save changes for the
 others.
 * HTTP Method: POST
 * Pathname: /admin/applications/{applicationId}/loadTransunionReports
 * @function loadTransunionReports
 * @memberof AdminAPI
 * @param {AdminAPILoadTransunionReportsPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type AdminAPILoadTransunionReportsPathParams = {
  applicationId: EnderId;
};

type AdminAPILoadTransunionReportsSearchParams = {
  token?: string | undefined;
};

type AdminAPILoadTransunionReportsPayload =
  AdminAPILoadTransunionReportsPathParams &
    AdminAPILoadTransunionReportsSearchParams;

function loadTransunionReportsUnsafeEffect(
  payload: AdminAPILoadTransunionReportsPayload,
) {
  const { applicationId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/admin/applications/${applicationId}/loadTransunionReports`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function loadTransunionReports(
  payload: AdminAPILoadTransunionReportsPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    loadTransunionReportsUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { loadTransunionReports };
export type {
  AdminAPILoadTransunionReportsPathParams,
  AdminAPILoadTransunionReportsPayload,
  AdminAPILoadTransunionReportsSearchParams,
};
