/**
 * HTTP Method: POST
 * Pathname: /admin/fake-pm
 * @function createTestingPM
 * @memberof AdminAPI
 * @param {AdminAPICreateTestingPMPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type AdminAPICreateTestingPMSearchParams = {
  token?: string | undefined;
};

type AdminAPICreateTestingPMPayload = AdminAPICreateTestingPMSearchParams;

function createTestingPMUnsafeEffect(payload: AdminAPICreateTestingPMPayload) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/admin/fake-pm",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function createTestingPM(
  payload: AdminAPICreateTestingPMPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    createTestingPMUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { createTestingPM };
export type {
  AdminAPICreateTestingPMPayload,
  AdminAPICreateTestingPMSearchParams,
};
