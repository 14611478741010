/**
 * HTTP Method: GET
 * Pathname: /services/{serviceId}
 * @function getService
 * @memberof TasksAPI
 * @param {TasksAPIGetServicePayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<TasksAPIGetServiceResponse>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

import type { TasksAPIGetServiceResponse } from "../tasks-api-get-service-response";

type TasksAPIGetServicePathParams = {
  serviceId: EnderId;
};

type TasksAPIGetServiceSearchParams = {
  token?: string | undefined;
};

type TasksAPIGetServicePayload = TasksAPIGetServicePathParams &
  TasksAPIGetServiceSearchParams;

function getServiceUnsafeEffect(payload: TasksAPIGetServicePayload) {
  const { serviceId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, TasksAPIGetServiceResponse>({
        body,
        decode: unsafeDecodeJsonResponse<TasksAPIGetServiceResponse>({}),
        encode: encodeNoBody({ method: "GET" }),
        pathname: `/services/${serviceId}`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getService(
  payload: TasksAPIGetServicePayload,
  options?: { signal?: AbortSignal },
): Promise<TasksAPIGetServiceResponse> {
  return F.pipe(
    payload,
    getServiceUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getService };
export type {
  TasksAPIGetServicePathParams,
  TasksAPIGetServicePayload,
  TasksAPIGetServiceSearchParams,
};
