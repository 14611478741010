/**
 * Send a welcome email to the given PM user.
 * HTTP Method: POST
 * Pathname: /users/{targetUserId}/sendOnboardingEmail
 * @function sendOnboardingEmail
 * @memberof UsersAPI
 * @param {UsersAPISendOnboardingEmailPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type UsersAPISendOnboardingEmailPathParams = {
  targetUserId: EnderId;
};

type UsersAPISendOnboardingEmailSearchParams = {
  token?: string | undefined;
};

type UsersAPISendOnboardingEmailPayload =
  UsersAPISendOnboardingEmailPathParams &
    UsersAPISendOnboardingEmailSearchParams;

function sendOnboardingEmailUnsafeEffect(
  payload: UsersAPISendOnboardingEmailPayload,
) {
  const { targetUserId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/users/${targetUserId}/sendOnboardingEmail`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function sendOnboardingEmail(
  payload: UsersAPISendOnboardingEmailPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    sendOnboardingEmailUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { sendOnboardingEmail };
export type {
  UsersAPISendOnboardingEmailPathParams,
  UsersAPISendOnboardingEmailPayload,
  UsersAPISendOnboardingEmailSearchParams,
};
