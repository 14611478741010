/**
 * HTTP Method: GET
 * Pathname: /tasks/capacity/pm/{pmId}
 * @function getPMUserJobCapacities
 * @memberof TasksAPI
 * @param {TasksAPIGetPMUserJobCapacitiesPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<GetPMJobCapacityResponse[]>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { GetPMJobCapacityResponse } from "@ender/shared/generated/ender.api.misc.response";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type TasksAPIGetPMUserJobCapacitiesPathParams = {
  pmId: EnderId;
};

type TasksAPIGetPMUserJobCapacitiesSearchParams = {
  token?: string | undefined;
};

type TasksAPIGetPMUserJobCapacitiesPayload =
  TasksAPIGetPMUserJobCapacitiesPathParams &
    TasksAPIGetPMUserJobCapacitiesSearchParams;

function getPMUserJobCapacitiesUnsafeEffect(
  payload: TasksAPIGetPMUserJobCapacitiesPayload,
) {
  const { pmId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, GetPMJobCapacityResponse[]>({
        body,
        decode: unsafeDecodeJsonResponse<GetPMJobCapacityResponse[]>({}),
        encode: encodeNoBody({ method: "GET" }),
        pathname: `/tasks/capacity/pm/${pmId}`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getPMUserJobCapacities(
  payload: TasksAPIGetPMUserJobCapacitiesPayload,
  options?: { signal?: AbortSignal },
): Promise<GetPMJobCapacityResponse[]> {
  return F.pipe(
    payload,
    getPMUserJobCapacitiesUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getPMUserJobCapacities };
export type {
  TasksAPIGetPMUserJobCapacitiesPathParams,
  TasksAPIGetPMUserJobCapacitiesPayload,
  TasksAPIGetPMUserJobCapacitiesSearchParams,
};
