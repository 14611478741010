/**
 * HTTP Method: GET
 * Pathname: /tasks/tags
 * @function getExistingTaskTags
 * @memberof TasksAPI
 * @param {TasksAPIGetExistingTaskTagsPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<GetExistingTaskTagsResponse>}
 */
import { Effect, Function as F } from "effect";

import type { GetExistingTaskTagsResponse } from "@ender/shared/generated/ender.api.misc.response";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type TasksAPIGetExistingTaskTagsSearchParams = {
  token?: string | undefined;
};

type TasksAPIGetExistingTaskTagsPayload =
  TasksAPIGetExistingTaskTagsSearchParams;

function getExistingTaskTagsUnsafeEffect(
  payload: TasksAPIGetExistingTaskTagsPayload,
) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, GetExistingTaskTagsResponse>({
        body,
        decode: unsafeDecodeJsonResponse<GetExistingTaskTagsResponse>({}),
        encode: encodeNoBody({ method: "GET" }),
        pathname: "/tasks/tags",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getExistingTaskTags(
  payload: TasksAPIGetExistingTaskTagsPayload,
  options?: { signal?: AbortSignal },
): Promise<GetExistingTaskTagsResponse> {
  return F.pipe(
    payload,
    getExistingTaskTagsUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getExistingTaskTags };
export type {
  TasksAPIGetExistingTaskTagsPayload,
  TasksAPIGetExistingTaskTagsSearchParams,
};
