/**
 * HTTP Method: GET
 * Pathname: /tasks/{taskId}
 * @function getTaskDetails
 * @memberof TasksAPI
 * @param {TasksAPIGetTaskDetailsPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<GetTaskDetailsResponse>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { GetTaskDetailsResponse } from "@ender/shared/generated/ender.api.misc.response";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type TasksAPIGetTaskDetailsPathParams = {
  taskId: EnderId;
};

type TasksAPIGetTaskDetailsSearchParams = {
  token?: string | undefined;
};

type TasksAPIGetTaskDetailsPayload = TasksAPIGetTaskDetailsPathParams &
  TasksAPIGetTaskDetailsSearchParams;

function getTaskDetailsUnsafeEffect(payload: TasksAPIGetTaskDetailsPayload) {
  const { taskId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, GetTaskDetailsResponse>({
        body,
        decode: unsafeDecodeJsonResponse<GetTaskDetailsResponse>({}),
        encode: encodeNoBody({ method: "GET" }),
        pathname: `/tasks/${taskId}`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getTaskDetails(
  payload: TasksAPIGetTaskDetailsPayload,
  options?: { signal?: AbortSignal },
): Promise<GetTaskDetailsResponse> {
  return F.pipe(
    payload,
    getTaskDetailsUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getTaskDetails };
export type {
  TasksAPIGetTaskDetailsPathParams,
  TasksAPIGetTaskDetailsPayload,
  TasksAPIGetTaskDetailsSearchParams,
};
