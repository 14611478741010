/**
 * HTTP Method: GET
 * Pathname: /users/{userId}/nylasAccount
 * @function getNylasAccount
 * @memberof NylasAPI
 * @param {NylasAPIGetNylasAccountPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<NylasAPIGetNylasAccountResponse>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

import type { NylasAPIGetNylasAccountResponse } from "../nylas-api-get-nylas-account-response";

type NylasAPIGetNylasAccountPathParams = {
  userId: EnderId;
};

type NylasAPIGetNylasAccountSearchParams = {
  token?: string | undefined;
};

type NylasAPIGetNylasAccountPayload = NylasAPIGetNylasAccountPathParams &
  NylasAPIGetNylasAccountSearchParams;

function getNylasAccountUnsafeEffect(payload: NylasAPIGetNylasAccountPayload) {
  const { userId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, NylasAPIGetNylasAccountResponse>({
        body,
        decode: unsafeDecodeJsonResponse<NylasAPIGetNylasAccountResponse>({}),
        encode: encodeNoBody({ method: "GET" }),
        pathname: `/users/${userId}/nylasAccount`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getNylasAccount(
  payload: NylasAPIGetNylasAccountPayload,
  options?: { signal?: AbortSignal },
): Promise<NylasAPIGetNylasAccountResponse> {
  return F.pipe(
    payload,
    getNylasAccountUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getNylasAccount };
export type {
  NylasAPIGetNylasAccountPathParams,
  NylasAPIGetNylasAccountPayload,
  NylasAPIGetNylasAccountSearchParams,
};
