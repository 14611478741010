/**
 * HTTP Method: DELETE
 * Pathname: /unitTypes/{unitTypeId}
 * @function deleteUnitType
 * @memberof PropertiesAPI
 * @param {PropertiesAPIDeleteUnitTypePayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type PropertiesAPIDeleteUnitTypePathParams = {
  unitTypeId: EnderId;
};

type PropertiesAPIDeleteUnitTypeSearchParams = {
  token?: string | undefined;
};

type PropertiesAPIDeleteUnitTypePayload =
  PropertiesAPIDeleteUnitTypePathParams &
    PropertiesAPIDeleteUnitTypeSearchParams;

function deleteUnitTypeUnsafeEffect(
  payload: PropertiesAPIDeleteUnitTypePayload,
) {
  const { unitTypeId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "DELETE" }),
        pathname: `/unitTypes/${unitTypeId}`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function deleteUnitType(
  payload: PropertiesAPIDeleteUnitTypePayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    deleteUnitTypeUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { deleteUnitType };
export type {
  PropertiesAPIDeleteUnitTypePathParams,
  PropertiesAPIDeleteUnitTypePayload,
  PropertiesAPIDeleteUnitTypeSearchParams,
};
