import type { NotificationProps } from "@mantine/notifications";
import { showNotification } from "@mantine/notifications";
import { IconX } from "@tabler/icons-react";
import { EnderThemeColorEnum } from "@ender/shared/constants/mantine";
type ShowWarningNotificationParams = NotificationProps;
function showWarningNotification(params: ShowWarningNotificationParams) {
  const {
    title = "Error",
    ...props
  } = params;
  showNotification({
    color: "yellow",
    icon: <IconX color={EnderThemeColorEnum.WHITE} />,
    title,
    ...props
  });
}
export { showWarningNotification };
export type { ShowWarningNotificationParams };