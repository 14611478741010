/**
 * HTTP Method: GET
 * Pathname: /admin/sqlReports/{reportId}
 * @function getSqlReport
 * @memberof AdminAPI
 * @param {AdminAPIGetSqlReportPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<SQLReport>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { SQLReport } from "@ender/shared/generated/ender.model.reports";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type AdminAPIGetSqlReportPathParams = {
  reportId: EnderId;
};

type AdminAPIGetSqlReportSearchParams = {
  token?: string | undefined;
};

type AdminAPIGetSqlReportPayload = AdminAPIGetSqlReportPathParams &
  AdminAPIGetSqlReportSearchParams;

function getSqlReportUnsafeEffect(payload: AdminAPIGetSqlReportPayload) {
  const { reportId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, SQLReport>({
        body,
        decode: unsafeDecodeJsonResponse<SQLReport>({}),
        encode: encodeNoBody({ method: "GET" }),
        pathname: `/admin/sqlReports/${reportId}`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getSqlReport(
  payload: AdminAPIGetSqlReportPayload,
  options?: { signal?: AbortSignal },
): Promise<SQLReport> {
  return F.pipe(
    payload,
    getSqlReportUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getSqlReport };
export type {
  AdminAPIGetSqlReportPathParams,
  AdminAPIGetSqlReportPayload,
  AdminAPIGetSqlReportSearchParams,
};
