import type { Effect, Scope } from "effect";
import { Context } from "effect";

import type { MethodOptions, RestError } from "./rest-types";

class RestService extends Context.Tag("RestService")<
  // eslint-disable-next-line no-use-before-define
  RestService,
  <Body extends Partial<{ [key: string]: unknown }>, Result>(
    options: MethodOptions<Body, Result>,
  ) => Effect.Effect<Result, RestError, Scope.Scope>
>() {}
type RestServiceShape = Context.Tag.Service<RestService>;

export { RestService };
export type { RestServiceShape };
