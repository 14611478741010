import type { EnderId } from "@ender/shared/core";
import { randomEnderId } from "@ender/shared/core";

type EnderModelModelResponse = {
  id: EnderId;
};

function randomEnderModelModelResponse(
  params: Partial<EnderModelModelResponse> = {},
): EnderModelModelResponse {
  const { id = randomEnderId() } = params;

  return {
    id,
  };
}

export { randomEnderModelModelResponse };
export type { EnderModelModelResponse };
