/**
 * HTTP Method: POST
 * Pathname: /pm-settings
 * @function changePMSettings
 * @memberof EnderAPI
 * @param {EnderAPIChangePMSettingsPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { ModelSettingsSettingKey } from "@ender/shared/generated/ender.model.misc";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type EnderAPIChangePMSettingsSearchParams = {
  token?: string | undefined;
};

type EnderAPIChangePMSettingsBodyParams = {
  setting: ModelSettingsSettingKey;
  value: string;
};

type EnderAPIChangePMSettingsPayload = EnderAPIChangePMSettingsSearchParams &
  EnderAPIChangePMSettingsBodyParams;

function changePMSettingsUnsafeEffect(
  payload: EnderAPIChangePMSettingsPayload,
) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/pm-settings",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function changePMSettings(
  payload: EnderAPIChangePMSettingsPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    changePMSettingsUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { changePMSettings };
export type {
  EnderAPIChangePMSettingsBodyParams,
  EnderAPIChangePMSettingsPayload,
  EnderAPIChangePMSettingsSearchParams,
};
