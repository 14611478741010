/**
 * HTTP Method: GET
 * Pathname: /model-explorer/dwolla/customers/{dwollaCustomerId}
 * @function getDwollaCustomer
 * @memberof ModelExplorerAPI
 * @param {ModelExplorerAPIGetDwollaCustomerPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<ModelExplorerAPIGetDwollaCustomerResponse>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

import type { ModelExplorerAPIGetDwollaCustomerResponse } from "../model-explorer-api-get-dwolla-customer-response";

type ModelExplorerAPIGetDwollaCustomerPathParams = {
  dwollaCustomerId: EnderId;
};

type ModelExplorerAPIGetDwollaCustomerSearchParams = {
  token?: string | undefined;
};

type ModelExplorerAPIGetDwollaCustomerPayload =
  ModelExplorerAPIGetDwollaCustomerPathParams &
    ModelExplorerAPIGetDwollaCustomerSearchParams;

function getDwollaCustomerUnsafeEffect(
  payload: ModelExplorerAPIGetDwollaCustomerPayload,
) {
  const { dwollaCustomerId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, ModelExplorerAPIGetDwollaCustomerResponse>({
        body,
        decode:
          unsafeDecodeJsonResponse<ModelExplorerAPIGetDwollaCustomerResponse>(
            {},
          ),
        encode: encodeNoBody({ method: "GET" }),
        pathname: `/model-explorer/dwolla/customers/${dwollaCustomerId}`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getDwollaCustomer(
  payload: ModelExplorerAPIGetDwollaCustomerPayload,
  options?: { signal?: AbortSignal },
): Promise<ModelExplorerAPIGetDwollaCustomerResponse> {
  return F.pipe(
    payload,
    getDwollaCustomerUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getDwollaCustomer };
export type {
  ModelExplorerAPIGetDwollaCustomerPathParams,
  ModelExplorerAPIGetDwollaCustomerPayload,
  ModelExplorerAPIGetDwollaCustomerSearchParams,
};
