import { Array as A, Match as M, Predicate as P } from "effect";
import type { ReactNode } from "react";
import { isValidElement } from "react";

/**
 * Checks if a ReactNode is considered empty for rendering purposes.
 * Allows numeric values (including 0) but considers other falsy values as empty.
 */
export function isEmptyReactNode(node: ReactNode): boolean {
  return M.value(node).pipe(
    M.whenOr(
      M.nonEmptyString,
      M.number,
      P.compose(A.isArray<unknown>, A.isNonEmptyArray),
      isValidElement,
      () => false,
    ),
    M.orElse(() => true),
  );
}
