/**
 * HTTP Method: POST
 * Pathname: /approvalProcess/steps/{stepId}/name
 * @function updateStepName
 * @memberof ApprovalsAPI
 * @param {ApprovalsAPIUpdateStepNamePayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type ApprovalsAPIUpdateStepNamePathParams = {
  stepId: EnderId;
};

type ApprovalsAPIUpdateStepNameSearchParams = {
  token?: string | undefined;
};

type ApprovalsAPIUpdateStepNameBodyParams = {
  name: string;
};

type ApprovalsAPIUpdateStepNamePayload = ApprovalsAPIUpdateStepNamePathParams &
  ApprovalsAPIUpdateStepNameSearchParams &
  ApprovalsAPIUpdateStepNameBodyParams;

function updateStepNameUnsafeEffect(
  payload: ApprovalsAPIUpdateStepNamePayload,
) {
  const { stepId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/approvalProcess/steps/${stepId}/name`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function updateStepName(
  payload: ApprovalsAPIUpdateStepNamePayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    updateStepNameUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { updateStepName };
export type {
  ApprovalsAPIUpdateStepNameBodyParams,
  ApprovalsAPIUpdateStepNamePathParams,
  ApprovalsAPIUpdateStepNamePayload,
  ApprovalsAPIUpdateStepNameSearchParams,
};
