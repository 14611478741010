/**
 * HTTP Method: POST
 * Pathname: /vendors/{vendorId}/address
 * @function updateVendorAddress
 * @memberof VendorsAPI
 * @param {VendorsAPIUpdateVendorAddressPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type VendorsAPIUpdateVendorAddressPathParams = {
  vendorId: EnderId;
};

type VendorsAPIUpdateVendorAddressSearchParams = {
  token?: string | undefined;
};

type VendorsAPIUpdateVendorAddressBodyParams = {
  city: string;
  state: string;
  street: string;
  unit: string;
  zipcode: string;
};

type VendorsAPIUpdateVendorAddressPayload =
  VendorsAPIUpdateVendorAddressPathParams &
    VendorsAPIUpdateVendorAddressSearchParams &
    VendorsAPIUpdateVendorAddressBodyParams;

function updateVendorAddressUnsafeEffect(
  payload: VendorsAPIUpdateVendorAddressPayload,
) {
  const { vendorId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/vendors/${vendorId}/address`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function updateVendorAddress(
  payload: VendorsAPIUpdateVendorAddressPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    updateVendorAddressUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { updateVendorAddress };
export type {
  VendorsAPIUpdateVendorAddressBodyParams,
  VendorsAPIUpdateVendorAddressPathParams,
  VendorsAPIUpdateVendorAddressPayload,
  VendorsAPIUpdateVendorAddressSearchParams,
};
