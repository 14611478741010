import { Schema as Sch } from "@effect/schema";
import { randRecentDate } from "@ngneat/falso";

import { Schema } from "./internal/schema";
import type { LocalDate } from "./local-date";
import { makeFromDate, of } from "./local-date";

/**
 * This file is for utilities that operate on / depend on LocalDate$.
 * None of these utilities should be accessed from upstream, i.e. within the LocalDate$ class-
 * if so, they will cause circular dependencies.
 */

const LocalDateEffectSchema = Sch.typeSchema(Schema).annotations({
  arbitrary: () => (fc) => fc.date().map(makeFromDate),
});

/**
 * Generate a random LocalDate using @ngneat/falso randRecentDate().
 * @returns Randomly generated LocalDate.
 */
function randomLocalDate(): LocalDate {
  const date = randRecentDate();
  return of(date);
}

export { LocalDateEffectSchema, randomLocalDate };
