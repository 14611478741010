/**
 * HTTP Method: POST
 * Pathname: /pms/{pmId}/whiteLabelConfig
 * @function updateWhiteLabelConfig
 * @memberof EnderAPI
 * @param {EnderAPIUpdateWhiteLabelConfigPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type EnderAPIUpdateWhiteLabelConfigPathParams = {
  pmId: EnderId;
};

type EnderAPIUpdateWhiteLabelConfigSearchParams = {
  token?: string | undefined;
};

type EnderAPIUpdateWhiteLabelConfigBodyParams = {
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  whiteLabelConfig: any;
};

type EnderAPIUpdateWhiteLabelConfigPayload =
  EnderAPIUpdateWhiteLabelConfigPathParams &
    EnderAPIUpdateWhiteLabelConfigSearchParams &
    EnderAPIUpdateWhiteLabelConfigBodyParams;

function updateWhiteLabelConfigUnsafeEffect(
  payload: EnderAPIUpdateWhiteLabelConfigPayload,
) {
  const { pmId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/pms/${pmId}/whiteLabelConfig`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function updateWhiteLabelConfig(
  payload: EnderAPIUpdateWhiteLabelConfigPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    updateWhiteLabelConfigUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { updateWhiteLabelConfig };
export type {
  EnderAPIUpdateWhiteLabelConfigBodyParams,
  EnderAPIUpdateWhiteLabelConfigPathParams,
  EnderAPIUpdateWhiteLabelConfigPayload,
  EnderAPIUpdateWhiteLabelConfigSearchParams,
};
