/**
 * HTTP Method: POST
 * Pathname: /fetchAssets
 * @function fetchAssets
 * @memberof OwnershipAPI
 * @param {OwnershipAPIFetchAssetsPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<OwnershipAPIFetchAssetsResponse>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { ModelType } from "@ender/shared/generated/com.ender.common.model";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

import type { OwnershipAPIFetchAssetsResponse } from "../ownership-api-fetch-assets-response";

type OwnershipAPIFetchAssetsSearchParams = {
  token?: string | undefined;
};

type OwnershipAPIFetchAssetsBodyParams = {
  modelId: EnderId;
  modelType: ModelType;
};

type OwnershipAPIFetchAssetsPayload = OwnershipAPIFetchAssetsSearchParams &
  OwnershipAPIFetchAssetsBodyParams;

function fetchAssetsUnsafeEffect(payload: OwnershipAPIFetchAssetsPayload) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, OwnershipAPIFetchAssetsResponse>({
        body,
        decode: unsafeDecodeJsonResponse<OwnershipAPIFetchAssetsResponse>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/fetchAssets",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function fetchAssets(
  payload: OwnershipAPIFetchAssetsPayload,
  options?: { signal?: AbortSignal },
): Promise<OwnershipAPIFetchAssetsResponse> {
  return F.pipe(
    payload,
    fetchAssetsUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { fetchAssets };
export type {
  OwnershipAPIFetchAssetsBodyParams,
  OwnershipAPIFetchAssetsPayload,
  OwnershipAPIFetchAssetsSearchParams,
};
