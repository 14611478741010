/**
 * Unlike other APIs, this handler checks if the invoker OR the "original" user (masquerading as the invoker) has
 permission to the target User. This is to support masquerading operations.
 * HTTP Method: GET
 * Pathname: /users/{targetId}
 * @function getUser
 * @memberof UsersAPI
 * @param {UsersAPIGetUserPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<GetUserResponse>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { GetUserResponse } from "@ender/shared/generated/ender.api.core.response";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type UsersAPIGetUserPathParams = {
  targetId: EnderId;
};

type UsersAPIGetUserSearchParams = {
  token?: string | undefined;
  vendorId?: EnderId | undefined;
};

type UsersAPIGetUserPayload = UsersAPIGetUserPathParams &
  UsersAPIGetUserSearchParams;

function getUserUnsafeEffect(payload: UsersAPIGetUserPayload) {
  const { targetId, token, vendorId, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, GetUserResponse>({
        body,
        decode: unsafeDecodeJsonResponse<GetUserResponse>({}),
        encode: encodeNoBody({ method: "GET" }),
        pathname: `/users/${targetId}`,
        searchParams: [
          ["token", token],
          ["vendorId", vendorId],
        ],
      }),
    ),
  );
}

function getUser(
  payload: UsersAPIGetUserPayload,
  options?: { signal?: AbortSignal },
): Promise<GetUserResponse> {
  return F.pipe(
    payload,
    getUserUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getUser };
export type {
  UsersAPIGetUserPathParams,
  UsersAPIGetUserPayload,
  UsersAPIGetUserSearchParams,
};
