/**
 * HTTP Method: POST
 * Pathname: /units/areas/{areaId}/zones
 * @function createUnitZone
 * @memberof UnitZonesAPI
 * @param {UnitZonesAPICreateUnitZonePayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<UnitZone>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type {
  UnitZone,
  UnitZoneUnitZoneType,
} from "@ender/shared/generated/ender.model.core.unit.zone";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type UnitZonesAPICreateUnitZonePathParams = {
  areaId: EnderId;
};

type UnitZonesAPICreateUnitZoneSearchParams = {
  token?: string | undefined;
};

type UnitZonesAPICreateUnitZoneBodyParams = {
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  data?: any | undefined;
  description?: string | undefined;
  type: UnitZoneUnitZoneType;
};

type UnitZonesAPICreateUnitZonePayload = UnitZonesAPICreateUnitZonePathParams &
  UnitZonesAPICreateUnitZoneSearchParams &
  UnitZonesAPICreateUnitZoneBodyParams;

function createUnitZoneUnsafeEffect(
  payload: UnitZonesAPICreateUnitZonePayload,
) {
  const { areaId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, UnitZone>({
        body,
        decode: unsafeDecodeJsonResponse<UnitZone>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/units/areas/${areaId}/zones`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function createUnitZone(
  payload: UnitZonesAPICreateUnitZonePayload,
  options?: { signal?: AbortSignal },
): Promise<UnitZone> {
  return F.pipe(
    payload,
    createUnitZoneUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { createUnitZone };
export type {
  UnitZonesAPICreateUnitZoneBodyParams,
  UnitZonesAPICreateUnitZonePathParams,
  UnitZonesAPICreateUnitZonePayload,
  UnitZonesAPICreateUnitZoneSearchParams,
};
