/**
 * Delays the resolution of a promise by a specified amount of time.
 *
 * @param {number} timeInMilliseconds - The time to delay in milliseconds.
 * @param {Promise<T>} [promise] - The promise to delay. If not provided, returns a promise that resolves after the specified delay time.
 * @returns {Promise<T>} A promise that resolves with the result of the input promise after the specified delay time, or a promise that resolves after the specified delay time if no input promise is provided.
 */
const delay = <T>(
  timeInMilliseconds: number,
  promise?: Promise<T>,
): Promise<T | undefined> => {
  return new Promise<T | undefined>((resolve) => {
    setTimeout(() => {
      resolve(Promise.resolve(promise));
    }, timeInMilliseconds);
  });
};

export { delay };
