/**
 * HTTP Method: GET
 * Pathname: /units/{unitId}/photos
 * @function getPhotosForUnit
 * @memberof PhotosAPI
 * @param {PhotosAPIGetPhotosForUnitPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<PhotoServicePhotoInfo>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { PhotoServicePhotoInfo } from "@ender/shared/generated/ender.service.files";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type PhotosAPIGetPhotosForUnitPathParams = {
  unitId: EnderId;
};

type PhotosAPIGetPhotosForUnitSearchParams = {
  token?: string | undefined;
};

type PhotosAPIGetPhotosForUnitPayload = PhotosAPIGetPhotosForUnitPathParams &
  PhotosAPIGetPhotosForUnitSearchParams;

function getPhotosForUnitUnsafeEffect(
  payload: PhotosAPIGetPhotosForUnitPayload,
) {
  const { unitId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, PhotoServicePhotoInfo>({
        body,
        decode: unsafeDecodeJsonResponse<PhotoServicePhotoInfo>({}),
        encode: encodeNoBody({ method: "GET" }),
        pathname: `/units/${unitId}/photos`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getPhotosForUnit(
  payload: PhotosAPIGetPhotosForUnitPayload,
  options?: { signal?: AbortSignal },
): Promise<PhotoServicePhotoInfo> {
  return F.pipe(
    payload,
    getPhotosForUnitUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getPhotosForUnit };
export type {
  PhotosAPIGetPhotosForUnitPathParams,
  PhotosAPIGetPhotosForUnitPayload,
  PhotosAPIGetPhotosForUnitSearchParams,
};
