/**
 * HTTP Method: POST
 * Pathname: /fixtures/createTenantBankAccount
 * @function createTenantBankAccount
 * @memberof FixturesAPI
 * @param {FixturesAPICreateTenantBankAccountPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<BankAccount>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { BankAccount } from "@ender/shared/generated/ender.model.payments";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type FixturesAPICreateTenantBankAccountSearchParams = {
  token?: string | undefined;
};

type FixturesAPICreateTenantBankAccountBodyParams = {
  dwollaCustomerId?: EnderId | undefined;
  pmId: EnderId;
  userId: EnderId;
};

type FixturesAPICreateTenantBankAccountPayload =
  FixturesAPICreateTenantBankAccountSearchParams &
    FixturesAPICreateTenantBankAccountBodyParams;

function createTenantBankAccountUnsafeEffect(
  payload: FixturesAPICreateTenantBankAccountPayload,
) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, BankAccount>({
        body,
        decode: unsafeDecodeJsonResponse<BankAccount>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/fixtures/createTenantBankAccount",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function createTenantBankAccount(
  payload: FixturesAPICreateTenantBankAccountPayload,
  options?: { signal?: AbortSignal },
): Promise<BankAccount> {
  return F.pipe(
    payload,
    createTenantBankAccountUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { createTenantBankAccount };
export type {
  FixturesAPICreateTenantBankAccountBodyParams,
  FixturesAPICreateTenantBankAccountPayload,
  FixturesAPICreateTenantBankAccountSearchParams,
};
