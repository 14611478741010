/**
 * HTTP Method: GET
 * Pathname: /permissions
 * @function getPermissions
 * @memberof PermissionsAPI
 * @param {PermissionsAPIGetPermissionsPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<FunctionalPermission[]>}
 */
import { Effect, Function as F } from "effect";

import type { FunctionalPermission } from "@ender/shared/generated/ender.model.permissions";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type PermissionsAPIGetPermissionsSearchParams = {
  token?: string | undefined;
};

type PermissionsAPIGetPermissionsPayload =
  PermissionsAPIGetPermissionsSearchParams;

function getPermissionsUnsafeEffect(
  payload: PermissionsAPIGetPermissionsPayload,
) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, FunctionalPermission[]>({
        body,
        decode: unsafeDecodeJsonResponse<FunctionalPermission[]>({}),
        encode: encodeNoBody({ method: "GET" }),
        pathname: "/permissions",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getPermissions(
  payload: PermissionsAPIGetPermissionsPayload,
  options?: { signal?: AbortSignal },
): Promise<FunctionalPermission[]> {
  return F.pipe(
    payload,
    getPermissionsUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getPermissions };
export type {
  PermissionsAPIGetPermissionsPayload,
  PermissionsAPIGetPermissionsSearchParams,
};
