/**
 * HTTP Method: GET
 * Pathname: /vendors/{vendorId}/ledger
 * @function getVendorLedger
 * @memberof VendorsAPI
 * @param {VendorsAPIGetVendorLedgerPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<GetVendorLedgerResponse[]>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { GetVendorLedgerResponse } from "@ender/shared/generated/ender.api.misc.response";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type VendorsAPIGetVendorLedgerPathParams = {
  vendorId: EnderId;
};

type VendorsAPIGetVendorLedgerSearchParams = {
  token?: string | undefined;
};

type VendorsAPIGetVendorLedgerPayload = VendorsAPIGetVendorLedgerPathParams &
  VendorsAPIGetVendorLedgerSearchParams;

function getVendorLedgerUnsafeEffect(
  payload: VendorsAPIGetVendorLedgerPayload,
) {
  const { vendorId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, GetVendorLedgerResponse[]>({
        body,
        decode: unsafeDecodeJsonResponse<GetVendorLedgerResponse[]>({}),
        encode: encodeNoBody({ method: "GET" }),
        pathname: `/vendors/${vendorId}/ledger`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getVendorLedger(
  payload: VendorsAPIGetVendorLedgerPayload,
  options?: { signal?: AbortSignal },
): Promise<GetVendorLedgerResponse[]> {
  return F.pipe(
    payload,
    getVendorLedgerUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getVendorLedger };
export type {
  VendorsAPIGetVendorLedgerPathParams,
  VendorsAPIGetVendorLedgerPayload,
  VendorsAPIGetVendorLedgerSearchParams,
};
