/**
 * HTTP Method: GET
 * Pathname: /admin/phoneLines
 * @function getAdminPhoneLines
 * @memberof AdminAPI
 * @param {AdminAPIGetAdminPhoneLinesPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<AdminAPIGetAdminPhoneLinesResponse>}
 */
import { Effect, Function as F } from "effect";

import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

import type { AdminAPIGetAdminPhoneLinesResponse } from "../admin-api-get-admin-phone-lines-response";

type AdminAPIGetAdminPhoneLinesSearchParams = {
  token?: string | undefined;
};

type AdminAPIGetAdminPhoneLinesPayload = AdminAPIGetAdminPhoneLinesSearchParams;

function getAdminPhoneLinesUnsafeEffect(
  payload: AdminAPIGetAdminPhoneLinesPayload,
) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, AdminAPIGetAdminPhoneLinesResponse>({
        body,
        decode: unsafeDecodeJsonResponse<AdminAPIGetAdminPhoneLinesResponse>(
          {},
        ),
        encode: encodeNoBody({ method: "GET" }),
        pathname: "/admin/phoneLines",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getAdminPhoneLines(
  payload: AdminAPIGetAdminPhoneLinesPayload,
  options?: { signal?: AbortSignal },
): Promise<AdminAPIGetAdminPhoneLinesResponse> {
  return F.pipe(
    payload,
    getAdminPhoneLinesUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getAdminPhoneLines };
export type {
  AdminAPIGetAdminPhoneLinesPayload,
  AdminAPIGetAdminPhoneLinesSearchParams,
};
