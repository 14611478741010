/**
 * HTTP Method: POST
 * Pathname: /tasks/statusCount
 * @function getTasksStatusCount
 * @memberof TasksAPI
 * @param {TasksAPIGetTasksStatusCountPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<GetTasksStatusCountResponse>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { GetTasksStatusCountResponse } from "@ender/shared/generated/ender.api.misc.response";
import type {
  TaskTaskPriority,
  TaskTaskType,
} from "@ender/shared/generated/ender.model.task";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type TasksAPIGetTasksStatusCountSearchParams = {
  token?: string | undefined;
};

type TasksAPIGetTasksStatusCountBodyParams = {
  assigneeIds: EnderId[];
  priorities: TaskTaskPriority[];
  propertyId?: EnderId | undefined;
  targetDate: string;
  type?: TaskTaskType | undefined;
  unitId?: EnderId | undefined;
  vendorId?: EnderId | undefined;
};

type TasksAPIGetTasksStatusCountPayload =
  TasksAPIGetTasksStatusCountSearchParams &
    TasksAPIGetTasksStatusCountBodyParams;

function getTasksStatusCountUnsafeEffect(
  payload: TasksAPIGetTasksStatusCountPayload,
) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, GetTasksStatusCountResponse>({
        body,
        decode: unsafeDecodeJsonResponse<GetTasksStatusCountResponse>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/tasks/statusCount",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getTasksStatusCount(
  payload: TasksAPIGetTasksStatusCountPayload,
  options?: { signal?: AbortSignal },
): Promise<GetTasksStatusCountResponse> {
  return F.pipe(
    payload,
    getTasksStatusCountUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getTasksStatusCount };
export type {
  TasksAPIGetTasksStatusCountBodyParams,
  TasksAPIGetTasksStatusCountPayload,
  TasksAPIGetTasksStatusCountSearchParams,
};
