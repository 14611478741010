import { Schema } from "@effect/schema";
import { isValid, ulid } from "ulidx";
import { z } from "zod";

/**
 * the reason this is a separate function from `isEnderId`, and is not a predicate,
 * is because the EnderId type has not yet been created at this point in the file.
 */
function isValidEnderId(input: string): boolean {
  // The BE uses "EXTERNAL" as a special value for EnderId
  // https://github.com/EnderHub/ender/blob/687f496071380066fdc68d42e891f91be0f00cd6/ender.com/src/ender/api/accounting/InvoicesAPI.java#L1077C1-L1077C106
  return input === "EXTERNAL" || isValid(input);
}

/**
 * @deprecated DO NOT USE THIS IN FORMS. USE EnderIdFormSchema INSTEAD.
 */
const EnderIdBrandSchema = Schema.String.pipe(
  Schema.filter(isValidEnderId, {
    message: () => "Invalid EnderId - not a valid ULID",
  }),
  Schema.brand("EnderId"),
);
type EnderId = Schema.Schema.Type<typeof EnderIdBrandSchema>;

const EnderIdFormSchema = Schema.typeSchema(EnderIdBrandSchema);

// type ParsableToEnderId = Schema.Schema.Encoded<typeof EnderIdEffectSchema>;

/**
 * @deprecated this should only be used in zod types/generated files. For
 * most other purposes you should use EnderIdFormSchema
 */
const EnderIdSchema = z.custom<EnderId>(isValidEnderId, {
  message: "Invalid EnderId - not a valid ULID",
});

/**
 * Check if a given string is a valid EnderId (ULID).
 * @param input - The string to check.
 * @returns Boolean indicating whether the string is a valid EnderId or not.
 */
function isEnderId(input: string): input is EnderId {
  return isValidEnderId(input);
}

/**
 * Parse and validate a given input string as an EnderId.
 * @param input - The string to be parsed and validated.
 * @returns Validated EnderId if the input is correct.
 * @throws Error if the input is not a valid EnderId.
 */
function ParseEnderId(input: string): EnderId {
  return Schema.decodeUnknownSync(EnderIdBrandSchema)(input);
}

/**
 * Generate a random EnderId using ULID.
 * @returns Randomly generated EnderId.
 */
function randomEnderId(): EnderId {
  return ulid() as EnderId;
}

export {
  EnderIdBrandSchema,
  EnderIdFormSchema,
  EnderIdSchema,
  isEnderId,
  ParseEnderId,
  randomEnderId,
};
export type { EnderId };
