"use client";

import { useRouter } from "next/navigation";
import { useCallback } from "react";
import { Modal } from "@ender/shared/ds/modal";
import { ChangePasswordFormController } from "../form/change-password-form.controller";
type ChangePasswordModalViewProps = {};
function ChangePasswordModalController(_props: ChangePasswordModalViewProps) {
  const router = useRouter();
  const handleCancel = useCallback(() => {
    router.push("/login");
  }, [router]);
  const handleDone = useCallback(() => {
    router.push("/login");
  }, [router]);
  return <Modal title="" opened data-sentry-element="Modal" data-sentry-component="ChangePasswordModalController" data-sentry-source-file="change-password-modal.controller.tsx">
      <ChangePasswordFormController onCancel={handleCancel} onDone={handleDone} data-sentry-element="ChangePasswordFormController" data-sentry-source-file="change-password-modal.controller.tsx" />
    </Modal>;
}
export { ChangePasswordModalController };