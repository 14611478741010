/**
 * HTTP Method: GET
 * Pathname: /properties/{propertyId}/amenities
 * @function getAmenities
 * @memberof PropertiesAPI
 * @param {PropertiesAPIGetAmenitiesPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<AmenityAmenityType[]>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { AmenityAmenityType } from "@ender/shared/generated/ender.model.core.property";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type PropertiesAPIGetAmenitiesPathParams = {
  propertyId: EnderId;
};

type PropertiesAPIGetAmenitiesSearchParams = {
  token?: string | undefined;
};

type PropertiesAPIGetAmenitiesPayload = PropertiesAPIGetAmenitiesPathParams &
  PropertiesAPIGetAmenitiesSearchParams;

function getAmenitiesUnsafeEffect(payload: PropertiesAPIGetAmenitiesPayload) {
  const { propertyId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, AmenityAmenityType[]>({
        body,
        decode: unsafeDecodeJsonResponse<AmenityAmenityType[]>({}),
        encode: encodeNoBody({ method: "GET" }),
        pathname: `/properties/${propertyId}/amenities`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getAmenities(
  payload: PropertiesAPIGetAmenitiesPayload,
  options?: { signal?: AbortSignal },
): Promise<AmenityAmenityType[]> {
  return F.pipe(
    payload,
    getAmenitiesUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getAmenities };
export type {
  PropertiesAPIGetAmenitiesPathParams,
  PropertiesAPIGetAmenitiesPayload,
  PropertiesAPIGetAmenitiesSearchParams,
};
