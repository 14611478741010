import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const TwilioClientVerificationCodeChannelValues = ["SMS", "EMAIL"] as const;

const TwilioClientVerificationCodeChannelEffectSchema = Schema.Literal(
  ...TwilioClientVerificationCodeChannelValues,
);

type TwilioClientVerificationCodeChannel = Schema.Schema.Type<
  typeof TwilioClientVerificationCodeChannelEffectSchema
>;

const TwilioClientVerificationCodeChannelEnum = castEnum(
  TwilioClientVerificationCodeChannelEffectSchema,
);

function randomTwilioClientVerificationCodeChannel(): TwilioClientVerificationCodeChannel {
  return rand(TwilioClientVerificationCodeChannelValues);
}

export {
  randomTwilioClientVerificationCodeChannel,
  TwilioClientVerificationCodeChannelEffectSchema,
  TwilioClientVerificationCodeChannelEnum,
  TwilioClientVerificationCodeChannelValues,
};
export type { TwilioClientVerificationCodeChannel };
