/**
 * HTTP Method: POST
 * Pathname: /getUsers
 * @function getUsers
 * @memberof UsersAPI
 * @param {UsersAPIGetUsersPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<Partial<Record<EnderId, GetUsersResponse>>>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { GetUsersResponse } from "@ender/shared/generated/ender.api.core.response";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type UsersAPIGetUsersSearchParams = {
  token?: string | undefined;
};

type UsersAPIGetUsersBodyParams = {
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  json: any;
};

type UsersAPIGetUsersPayload = UsersAPIGetUsersSearchParams &
  UsersAPIGetUsersBodyParams;

function getUsersUnsafeEffect(payload: UsersAPIGetUsersPayload) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, Partial<Record<EnderId, GetUsersResponse>>>({
        body,
        decode: unsafeDecodeJsonResponse<
          Partial<Record<EnderId, GetUsersResponse>>
        >({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/getUsers",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getUsers(
  payload: UsersAPIGetUsersPayload,
  options?: { signal?: AbortSignal },
): Promise<Partial<Record<EnderId, GetUsersResponse>>> {
  return F.pipe(
    payload,
    getUsersUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getUsers };
export type {
  UsersAPIGetUsersBodyParams,
  UsersAPIGetUsersPayload,
  UsersAPIGetUsersSearchParams,
};
