import { Schema } from "@effect/schema";

import { LocalDate$ } from "@ender/shared/core";

/**
 * @deprecated use LocalDateEffectSchema from `@ender/shared/core`
 */
const LocalDateEffectSchema = Schema.typeSchema(LocalDate$.Schema).annotations({
  arbitrary: () => (fc) => fc.date().map(LocalDate$.makeFromDate),
});

export { LocalDateEffectSchema };
