/**
 * HTTP Method: POST
 * Pathname: /archive
 * @function archive
 * @memberof EnderAPI
 * @param {EnderAPIArchivePayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { ModelType } from "@ender/shared/generated/com.ender.common.model";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type EnderAPIArchiveSearchParams = {
  token?: string | undefined;
};

type EnderAPIArchiveBodyParams = {
  modelId: EnderId;
  modelType: ModelType;
};

type EnderAPIArchivePayload = EnderAPIArchiveSearchParams &
  EnderAPIArchiveBodyParams;

function archiveUnsafeEffect(payload: EnderAPIArchivePayload) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/archive",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function archive(
  payload: EnderAPIArchivePayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    archiveUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { archive };
export type {
  EnderAPIArchiveBodyParams,
  EnderAPIArchivePayload,
  EnderAPIArchiveSearchParams,
};
