/**
 * HTTP Method: POST
 * Pathname: /auth0Login
 * @function auth0Login
 * @memberof AuthAPI
 * @param {AuthAPIAuth0LoginPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<EnderSessionResponse>}
 */
import { Effect, Function as F } from "effect";

import type { EnderSessionResponse } from "@ender/shared/generated/ender.api.misc.response";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type AuthAPIAuth0LoginBodyParams = {
  token: string;
};

type AuthAPIAuth0LoginPayload = AuthAPIAuth0LoginBodyParams;

function auth0LoginUnsafeEffect(payload: AuthAPIAuth0LoginPayload) {
  const { ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, EnderSessionResponse>({
        body,
        decode: unsafeDecodeJsonResponse<EnderSessionResponse>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/auth0Login",
        searchParams: [],
      }),
    ),
  );
}

function auth0Login(
  payload: AuthAPIAuth0LoginPayload,
  options?: { signal?: AbortSignal },
): Promise<EnderSessionResponse> {
  return F.pipe(
    payload,
    auth0LoginUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { auth0Login };
export type { AuthAPIAuth0LoginBodyParams, AuthAPIAuth0LoginPayload };
