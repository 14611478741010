import {
  GROUPING_TEXT,
  ONES_TEXT,
  TEENS_TEXT,
  TENS_TEXT,
} from "../money.constants";
import type { NumberGrouping } from "../money.types";

function convertSectionToWords(
  num: string,
  idx: number,
  totalNumberArr: string[],
) {
  const [onesValue = 0, tensValue = 0, hundredsValue = 0] = [...num]
    .reverse()
    .map(Number);

  const groupingTextObj: NumberGrouping = {
    ...(hundredsValue !== 0 && {
      hundreds: `${ONES_TEXT[hundredsValue]} hundred`,
    }),
    ...(onesValue > 0 && {
      ...(tensValue === 1 && { teens: TEENS_TEXT[onesValue] }),
      ...(tensValue >= 2 && {
        hyphenatedTens: `${TENS_TEXT[tensValue]}-${ONES_TEXT[onesValue]}`,
      }),
    }),
    tens: TENS_TEXT[tensValue],
    ...((onesValue > 0 ||
      (onesValue === 0 && !hundredsValue && !tensValue)) && {
      ones: ONES_TEXT[onesValue],
    }),
    grouping: GROUPING_TEXT[idx],
  };

  // DETERMINE IF TENS OR ONES APPEAR AS A HYPHENATED FORM (Forty-one) OR AS A TEENS (Thirteen)
  if (groupingTextObj.hyphenatedTens || groupingTextObj.teens) {
    delete groupingTextObj.tens;
    delete groupingTextObj.ones;
  }

  /**
   * DETERMINE IF IT MAKES SENSE TO RETURN ZERO
   * note: This is true if the value being output is cents only (e.g., ZERO AND 15/100)
   */
  if (totalNumberArr.length > 1 && groupingTextObj.ones === "zero") {
    return "";
  }

  return Object.keys(groupingTextObj)
    .reduce((acc: string[], key: string) => {
      // ATTEMPT GATHERING THE CURRENT VALUE
      const textValue: NumberGrouping[keyof NumberGrouping] =
        groupingTextObj[key as keyof NumberGrouping];
      if (!textValue) {
        return acc;
      }

      acc.push(textValue);

      return acc;
    }, [])
    .join(" ");
}

function convertToWords(val: string): string {
  /**
   * the double reverse is to simplify the determining the number grouping convertSectionToWords
   * is working with and then undoing the reverse to put the written format into the correct order
   */
  return val
    .split(",")
    .reverse()
    .map(convertSectionToWords)
    .filter(Boolean)
    .reverse()
    .join(", ");
}

/** @internal */
const dollarsCentsFormatter = new Intl.NumberFormat("en-US", {
  currency: "USD",
  style: "currency",
});

/**
 * @internal
 * @description Formatter used when the return value expects no decimal place */
const dollarsOnlyFormatter = new Intl.NumberFormat("en-US", {
  currency: "USD",
  maximumFractionDigits: 0,
  minimumFractionDigits: 0,
  style: "currency",
});

export {
  convertSectionToWords,
  convertToWords,
  dollarsCentsFormatter,
  dollarsOnlyFormatter,
};
