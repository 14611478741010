/**
 * HTTP Method: POST
 * Pathname: /issues/{issueId}/attachments
 * @function addIssueAttachment
 * @memberof JiraAPI
 * @param {JiraAPIAddIssueAttachmentPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<JiraAPIAddIssueAttachmentResponse>}
 */
import { Effect, Function as F } from "effect";

import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

import type { JiraAPIAddIssueAttachmentResponse } from "../jira-api-add-issue-attachment-response";

type JiraAPIAddIssueAttachmentPathParams = {
  issueId: string;
};

type JiraAPIAddIssueAttachmentSearchParams = {
  token?: string | undefined;
};

type JiraAPIAddIssueAttachmentPayload = JiraAPIAddIssueAttachmentPathParams &
  JiraAPIAddIssueAttachmentSearchParams;

function addIssueAttachmentUnsafeEffect(
  payload: JiraAPIAddIssueAttachmentPayload,
) {
  const { issueId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, JiraAPIAddIssueAttachmentResponse>({
        body,
        decode: unsafeDecodeJsonResponse<JiraAPIAddIssueAttachmentResponse>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/issues/${issueId}/attachments`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function addIssueAttachment(
  payload: JiraAPIAddIssueAttachmentPayload,
  options?: { signal?: AbortSignal },
): Promise<JiraAPIAddIssueAttachmentResponse> {
  return F.pipe(
    payload,
    addIssueAttachmentUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { addIssueAttachment };
export type {
  JiraAPIAddIssueAttachmentPathParams,
  JiraAPIAddIssueAttachmentPayload,
  JiraAPIAddIssueAttachmentSearchParams,
};
