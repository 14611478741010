/**
 * HTTP Method: POST
 * Pathname: /approvalProcesses/search
 * @function searchApprovalProcesses
 * @memberof ApprovalsAPI
 * @param {ApprovalsAPISearchApprovalProcessesPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<GetApprovalProcessesResponse>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { GetApprovalProcessesResponse } from "@ender/shared/generated/ender.api.misc.response";
import type { ApprovalProcessType } from "@ender/shared/generated/ender.model.approvals";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type ApprovalsAPISearchApprovalProcessesSearchParams = {
  token?: string | undefined;
};

type ApprovalsAPISearchApprovalProcessesBodyParams = {
  firmId?: EnderId | undefined;
  ownershipGroupId?: EnderId | undefined;
  type: ApprovalProcessType;
};

type ApprovalsAPISearchApprovalProcessesPayload =
  ApprovalsAPISearchApprovalProcessesSearchParams &
    ApprovalsAPISearchApprovalProcessesBodyParams;

function searchApprovalProcessesUnsafeEffect(
  payload: ApprovalsAPISearchApprovalProcessesPayload,
) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, GetApprovalProcessesResponse>({
        body,
        decode: unsafeDecodeJsonResponse<GetApprovalProcessesResponse>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/approvalProcesses/search",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function searchApprovalProcesses(
  payload: ApprovalsAPISearchApprovalProcessesPayload,
  options?: { signal?: AbortSignal },
): Promise<GetApprovalProcessesResponse> {
  return F.pipe(
    payload,
    searchApprovalProcessesUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { searchApprovalProcesses };
export type {
  ApprovalsAPISearchApprovalProcessesBodyParams,
  ApprovalsAPISearchApprovalProcessesPayload,
  ApprovalsAPISearchApprovalProcessesSearchParams,
};
