import { ParseResult, Schema } from "@effect/schema";
import { randRecentDate } from "@ngneat/falso";
import type { Duration } from "date-fns";
import { parse } from "date-fns";
import {
  add,
  formatWithOptions,
  isAfter,
  isBefore,
  isEqual,
} from "date-fns/fp";
import { enUS } from "date-fns/locale";
import type { Either as E } from "effect";
import { Array as A, Function as F, Match, Option as O } from "effect";

import type { Comparable } from "../comparable";
import type { Serializable } from "../serializable";
import type { LocalTime } from "./local-time.types";

const INVALID_DATE = new Date(NaN);
const TIME_12_HOUR_FORMAT = "h:mm a";
const TIME_ISO_HOUR_FORMAT = "HH";
const TIME_ISO_MINUTE_FORMAT = "HH:mm";
const TIME_ISO_SECOND_FORMAT = "HH:mm:ss";
const TIME_ISO_FORMAT = "HH:mm:ss.SSS";

function parseString(v: string): Date {
  return F.pipe(
    [
      TIME_12_HOUR_FORMAT,
      TIME_ISO_HOUR_FORMAT,
      TIME_ISO_MINUTE_FORMAT,
      TIME_ISO_SECOND_FORMAT,
      TIME_ISO_FORMAT,
    ],
    A.map((formatString) => parse(v, formatString, 0)),
    A.findFirst((date) => !isNaN(date.getTime())),
    O.getOrElse(F.constant(INVALID_DATE)),
  );
}

// eslint-disable-next-line no-use-before-define
type Parsable = string | number | Date | LocalTime$ | null | undefined;

// eslint-disable-next-line no-use-before-define
class LocalTime$ implements Serializable, Comparable<LocalTime$> {
  // === Instance Properties ===
  private readonly value: Date;

  get hour(): number {
    return this.value.getHours();
  }

  get minute(): number {
    return this.value.getMinutes();
  }

  get second(): number {
    return this.value.getSeconds();
  }

  get millisecond(): number {
    return this.value.getMilliseconds();
  }

  // === Instance Methods ===
  /**
   * We want to encourage using the static methods instead of the constructor.
   * @deprecated Use LocalTime$.parse or LocalTime$.safeParse instead.
   */
  public constructor(input?: Parsable) {
    const date: Date = F.pipe(
      input,
      O.fromNullable,
      O.map((x: NonNullable<Parsable>) => {
        return Match.value(x).pipe(
          Match.when(Match.string, parseString),
          Match.whenOr(Match.number, Match.date, (v) => new Date(v)),
          Match.orElse((v) => new Date(v.valueOf())),
        );
      }),
      O.getOrElse(() => new Date()),
    );

    // Create a new date by dropping date part
    this.value = new Date(
      1970,
      0,
      1,
      date.getHours(),
      date.getMinutes(),
      date.getSeconds(),
      date.getMilliseconds(),
    );
  }

  public add(
    duration: Pick<Duration, "hours" | "minutes" | "seconds">,
  ): LocalTime$ {
    return F.pipe(this.value, add(duration), LocalTime$.of);
  }

  public isValid() {
    return !this.isInvalid();
  }

  public isInvalid() {
    return isNaN(this.value.getTime());
  }

  public isAfter(right: LocalTime$): boolean {
    return isAfter(right.toDate(), this.value);
  }

  public isBefore(right: LocalTime$): boolean {
    return isBefore(right.toDate(), this.value);
  }

  public isEqual(right: LocalTime$): boolean {
    return isEqual(right.toDate(), this.value);
  }

  public isAfterOrEqual(right: LocalTime$): boolean {
    return this.isAfter(right) || this.isEqual(right);
  }

  public isBeforeOrEqual(right: LocalTime$): boolean {
    return this.isBefore(right) || this.isEqual(right);
  }

  public compareTo(other: LocalTime$): number {
    if (this.isBefore(other)) {
      return -1;
    } else if (this.isEqual(other)) {
      return 0;
    } else {
      return 1;
    }
  }

  /**
   * @returns a JavaScript Date representation of this class
   */
  public toDate(): Date {
    return new Date(this.value.getTime());
  }

  public toJSON(): LocalTime {
    return F.pipe(this.value, LocalTime$.jsonFormat) as LocalTime;
  }

  public toString(): string {
    return F.pipe(this.value, LocalTime$.defaultFormat);
  }

  public to12HourString(): string {
    return F.pipe(this.value, LocalTime$.displayFormat);
  }

  /**
   * The number of milliseconds that has elapsed since the epoch (Midnight, January 1, 1970 UTC)
   */
  public valueOf(): number {
    return this.value.valueOf();
  }

  // === Static Properties ===
  static Schema = Schema.Union(
    Schema.String,
    Schema.Number,
    Schema.instanceOf(Date),
    // eslint-disable-next-line no-use-before-define
    Schema.instanceOf(LocalTime$),
  ).pipe(
    Schema.transformOrFail(Schema.instanceOf(LocalTime$), {
      decode: (v, _, ast) => {
        const val = new LocalTime$(v);
        if (!val.isValid()) {
          return ParseResult.fail(
            new ParseResult.Transformation(
              ast,
              v,
              "Transformation",
              new ParseResult.Type(
                ast,
                v,
                "Provided value was not parseable to LocalTime$",
              ),
            ),
          );
        }
        return ParseResult.succeed(val);
      },
      encode: (v, _, ast) =>
        ParseResult.fail(
          new ParseResult.Forbidden(
            ast,
            v,
            "Encoding LocalTime$ back to parsable input is forbidden",
          ),
        ),
    }),
  );

  static defaultFormat = formatWithOptions(
    { locale: enUS },
    TIME_ISO_MINUTE_FORMAT,
  );
  static displayFormat = formatWithOptions(
    { locale: enUS },
    TIME_12_HOUR_FORMAT,
  );
  static jsonFormat = formatWithOptions({ locale: enUS }, TIME_ISO_FORMAT);

  static get now(): LocalTime$ {
    return new LocalTime$(Date.now());
  }

  // === Static Methods ===

  /**
   * @returns the parsed LocalTime or the current time if the input is null or undefined.
   * @throws if the input is invalid.
   */
  public static of(input?: Parsable): LocalTime$ {
    return F.pipe(
      input,
      Schema.decodeUnknownOption(LocalTime$.Schema),
      O.getOrElse(F.constant(LocalTime$.now)),
    );
  }

  /**
   * @returns the O.some(LocalTime) or O.none() if the input is null, undefined, or invalid.
   */
  public static parse(input?: Parsable): O.Option<LocalTime$> {
    return Schema.decodeUnknownOption(LocalTime$.Schema)(input);
  }

  /**
   * @returns the parsed LocalTime on the right or a ZodError on the left if the input is null, undefined, or invalid.
   */
  public static safeParse(
    input?: Parsable,
  ): E.Either<LocalTime$, ParseResult.ParseError> {
    return Schema.decodeUnknownEither(LocalTime$.Schema)(input);
  }

  /**
   * @deprecated Use the tree-shakable `randomLocalTime` import instead.
   */
  public static random(): LocalTime$ {
    // eslint-disable-next-line no-use-before-define
    return randomLocalTime();
  }
}

/**
 * Generate a random LocalTime using @ngneat/falso randRecentDate().
 * @returns Randomly generated LocalTime.
 */
function randomLocalTime(): LocalTime$ {
  const date = randRecentDate();
  return LocalTime$.of(date);
}

export { LocalTime$, randomLocalTime };
