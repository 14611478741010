/**
 * HTTP Method: GET
 * Pathname: /properties/{propertyId}/photos
 * @function getPropertyPhotos
 * @memberof PropertiesAPI
 * @param {PropertiesAPIGetPropertyPhotosPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<PhotoServicePhotoInfo>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { PhotoServicePhotoInfo } from "@ender/shared/generated/ender.service.files";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type PropertiesAPIGetPropertyPhotosPathParams = {
  propertyId: EnderId;
};

type PropertiesAPIGetPropertyPhotosSearchParams = {
  token?: string | undefined;
};

type PropertiesAPIGetPropertyPhotosPayload =
  PropertiesAPIGetPropertyPhotosPathParams &
    PropertiesAPIGetPropertyPhotosSearchParams;

function getPropertyPhotosUnsafeEffect(
  payload: PropertiesAPIGetPropertyPhotosPayload,
) {
  const { propertyId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, PhotoServicePhotoInfo>({
        body,
        decode: unsafeDecodeJsonResponse<PhotoServicePhotoInfo>({}),
        encode: encodeNoBody({ method: "GET" }),
        pathname: `/properties/${propertyId}/photos`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getPropertyPhotos(
  payload: PropertiesAPIGetPropertyPhotosPayload,
  options?: { signal?: AbortSignal },
): Promise<PhotoServicePhotoInfo> {
  return F.pipe(
    payload,
    getPropertyPhotosUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getPropertyPhotos };
export type {
  PropertiesAPIGetPropertyPhotosPathParams,
  PropertiesAPIGetPropertyPhotosPayload,
  PropertiesAPIGetPropertyPhotosSearchParams,
};
