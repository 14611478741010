/**
 * HTTP Method: GET
 * Pathname: /pm/address
 * @function getPMAddress
 * @memberof EnderAPI
 * @param {EnderAPIGetPMAddressPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<Address | undefined>}
 */
import { Effect, Function as F } from "effect";

import type { Address } from "@ender/shared/generated/ender.model.core";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type EnderAPIGetPMAddressSearchParams = {
  token?: string | undefined;
};

type EnderAPIGetPMAddressPayload = EnderAPIGetPMAddressSearchParams;

function getPMAddressUnsafeEffect(payload: EnderAPIGetPMAddressPayload) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, Address | undefined>({
        body,
        decode: unsafeDecodeJsonResponse<Address | undefined>({}),
        encode: encodeNoBody({ method: "GET" }),
        pathname: "/pm/address",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getPMAddress(
  payload: EnderAPIGetPMAddressPayload,
  options?: { signal?: AbortSignal },
): Promise<Address | undefined> {
  return F.pipe(
    payload,
    getPMAddressUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getPMAddress };
export type { EnderAPIGetPMAddressPayload, EnderAPIGetPMAddressSearchParams };
