/**
 * HTTP Method: GET
 * Pathname: /properties/{propertyId}/unitTypes
 * @function getPropertyUnitTypes
 * @memberof PropertiesAPI
 * @param {PropertiesAPIGetPropertyUnitTypesPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<GetPropertyUnitTypesResponse[]>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { GetPropertyUnitTypesResponse } from "@ender/shared/generated/ender.api.core.response";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type PropertiesAPIGetPropertyUnitTypesPathParams = {
  propertyId: EnderId;
};

type PropertiesAPIGetPropertyUnitTypesSearchParams = {
  token?: string | undefined;
};

type PropertiesAPIGetPropertyUnitTypesPayload =
  PropertiesAPIGetPropertyUnitTypesPathParams &
    PropertiesAPIGetPropertyUnitTypesSearchParams;

function getPropertyUnitTypesUnsafeEffect(
  payload: PropertiesAPIGetPropertyUnitTypesPayload,
) {
  const { propertyId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, GetPropertyUnitTypesResponse[]>({
        body,
        decode: unsafeDecodeJsonResponse<GetPropertyUnitTypesResponse[]>({}),
        encode: encodeNoBody({ method: "GET" }),
        pathname: `/properties/${propertyId}/unitTypes`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getPropertyUnitTypes(
  payload: PropertiesAPIGetPropertyUnitTypesPayload,
  options?: { signal?: AbortSignal },
): Promise<GetPropertyUnitTypesResponse[]> {
  return F.pipe(
    payload,
    getPropertyUnitTypesUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getPropertyUnitTypes };
export type {
  PropertiesAPIGetPropertyUnitTypesPathParams,
  PropertiesAPIGetPropertyUnitTypesPayload,
  PropertiesAPIGetPropertyUnitTypesSearchParams,
};
