/**
 * Sends an email to the logged in User's email address which contains a link for them to verify their email address.
 * HTTP Method: POST
 * Pathname: /sendVerificationEmail
 * @function sendVerificationEmail
 * @memberof AuthAPI
 * @param {AuthAPISendVerificationEmailPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type AuthAPISendVerificationEmailSearchParams = {
  token?: string | undefined;
};

type AuthAPISendVerificationEmailPayload =
  AuthAPISendVerificationEmailSearchParams;

function sendVerificationEmailUnsafeEffect(
  payload: AuthAPISendVerificationEmailPayload,
) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/sendVerificationEmail",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function sendVerificationEmail(
  payload: AuthAPISendVerificationEmailPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    sendVerificationEmailUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { sendVerificationEmail };
export type {
  AuthAPISendVerificationEmailPayload,
  AuthAPISendVerificationEmailSearchParams,
};
