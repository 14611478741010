import { captureException, captureMessage } from "@sentry/react";
import { Predicate as P } from "effect";

import {
  BatchValidationError,
  RestServerError,
} from "@ender/shared/services/rest";
import {
  showErrorNotification,
  showWarningNotification,
} from "@ender/shared/utils/notifications";

type EnderErrorJson = {
  errors?: string[];
  warnings?: string[];
};

type EnderErrorProps = {
  json?: EnderErrorJson;
  response?: unknown;
};

type EnderErrorType = string | Error;

class EnderError {
  json: EnderErrorJson;
  response: unknown;

  constructor(props: EnderErrorProps = {}) {
    const { json = {}, response } = props;
    this.json = json;
    this.response = response;
  }

  static asEnderError(e: EnderErrorType) {
    let errorMsg: string;
    if (P.isString(e)) {
      errorMsg = e;
    } else {
      errorMsg = `${e.message}`;
    }

    return new EnderError({
      response: null,
      json: { errors: [errorMsg || "Unknown EnderError"], warnings: [] },
    });
  }
}

class FailError extends Error {
  constructor(message: string) {
    super(message);
    this.name = "FailError";
  }
}

type DuplicateEntryErrorProps = {
  entry: string;
  keyName: string;
};

// Duplicate entry '01FHXHEEJE9FZPRZQAQNXYX9NE-01FHXHEEKBDKMS3DAF3M240JJV-Test Subac' for key 'gl_category.pmId_parentId_accountName'
// entry: 01FHXHEEJE9FZPRZQAQNXYX9NE-01FHXHEEKBDKMS3DAF3M240JJV-Test Subac
// key: accountName
const duplicateErrorRegex = /Duplicate entry '(.*)' for key '(.*)'/;
const keyNameRegex = /.*_(.*)$/;

class DuplicateEntryError {
  entry: string;
  keyName: string;

  constructor({ entry, keyName }: DuplicateEntryErrorProps) {
    this.entry = entry;
    this.keyName = keyName;
  }

  static isDuplicateEntryMessage(message: string) {
    return duplicateErrorRegex.test(message);
  }

  static isDuplicateEntryEnderError(error: unknown) {
    if (error instanceof EnderError) {
      return error.json.errors?.some((message) =>
        DuplicateEntryError.isDuplicateEntryMessage(message),
      );
    }

    return false;
  }

  static fromMessage(message: string) {
    const match = message.match(duplicateErrorRegex);

    if (match == null) {
      return new DuplicateEntryError({ entry: "", keyName: "" });
    }

    const [, entry, key] = match;
    const keyNameMatch = key.match(keyNameRegex);
    const keyName = keyNameMatch?.[1] ?? key;

    return new DuplicateEntryError({ entry, keyName });
  }

  static fromEnderError(error: EnderErrorType) {
    const enderError = EnderError.asEnderError(error);
    const message = enderError.json.errors?.find((message) =>
      DuplicateEntryError.isDuplicateEntryMessage(message),
    );

    if (message == null) {
      return new DuplicateEntryError({ entry: "", keyName: "" });
    }

    return DuplicateEntryError.fromMessage(message);
  }
}

function handleBatchValidationError(
  e: BatchValidationError.BatchValidationError,
  showNotification: boolean,
) {
  if (e.errors.length > 0) {
    e.errors.forEach((message) => {
      showNotification && showErrorNotification({ message });
      captureException(new FailError(message));
    });
  } else if (e.warnings.length > 0) {
    e.warnings.forEach((message) => {
      showNotification && showWarningNotification({ message });
    });
  }
}
function handleRestServerError(
  e: RestServerError.RestServerError,
  showNotification: boolean,
) {
  showNotification && showErrorNotification({ message: e.toString() });
  captureException(e);
}

function fail(e: unknown, { showNotification = true } = {}) {
  if (P.isString(e)) {
    showNotification && showErrorNotification({ message: e });
    captureException(new FailError(e));
  } else if (e instanceof EnderError) {
    e.json.errors?.forEach((message) => {
      showNotification && showErrorNotification({ message });
      captureException(new FailError(message));
    });

    e.json.warnings?.forEach((message) => {
      showNotification && showWarningNotification({ message });
      captureMessage(message, { level: "warning" });
    });
  } else if (e instanceof BatchValidationError.BatchValidationError) {
    handleBatchValidationError(e, showNotification);
  } else if (e instanceof RestServerError.RestServerError) {
    handleRestServerError(e, showNotification);
  } else if (e instanceof Error) {
    showNotification && showErrorNotification({ message: e.message });
    captureException(e);
  } else {
    // this should never happen
    captureException(
      new FailError(
        `Unknown FailError: ${P.isRecord(e) ? JSON.stringify(e) : String(e)}`,
      ),
    );
  }
}

export { DuplicateEntryError, EnderError, fail };
