"use client";

import { Button, ButtonVariant } from "@ender/shared/ds/button";
import { Modal } from "@ender/shared/ds/modal";
import { Text } from "@ender/shared/ds/text";
import { pluralize } from "@ender/shared/utils/string";
type RedirectModalViewProps = {
  message: string;
  onRedirect: () => void;
  secondsRemaining: number;
  title: string;
};
function RedirectModalView(props: RedirectModalViewProps) {
  const {
    message,
    onRedirect,
    secondsRemaining,
    title
  } = props;
  return <Modal title={title} opened data-sentry-element="Modal" data-sentry-component="RedirectModalView" data-sentry-source-file="redirect-modal.view.tsx">
      <Text data-sentry-element="Text" data-sentry-source-file="redirect-modal.view.tsx">{message}</Text>
      <Text data-sentry-element="Text" data-sentry-source-file="redirect-modal.view.tsx">
        You will be redirected in {secondsRemaining}{" "}
        {pluralize("second", secondsRemaining)}.
      </Text>
      <Button variant={ButtonVariant.transparent} onClick={onRedirect} data-sentry-element="Button" data-sentry-source-file="redirect-modal.view.tsx">
        Go Now
      </Button>
    </Modal>;
}
export { RedirectModalView };