/**
 * HTTP Method: POST
 * Pathname: /tasks/vendors/assignees/search
 * @function searchForAssignableVendorUsers
 * @memberof TasksAPI
 * @param {TasksAPISearchForAssignableVendorUsersPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<TasksAPISearchForAssignableVendorUsersResponse[]>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

import type { TasksAPISearchForAssignableVendorUsersResponse } from "../tasks-api-search-for-assignable-vendor-users-response";

type TasksAPISearchForAssignableVendorUsersSearchParams = {
  token?: string | undefined;
};

type TasksAPISearchForAssignableVendorUsersBodyParams = {
  excludeIds?: EnderId[] | undefined;
  keyword: string;
  vendorId: EnderId;
};

type TasksAPISearchForAssignableVendorUsersPayload =
  TasksAPISearchForAssignableVendorUsersSearchParams &
    TasksAPISearchForAssignableVendorUsersBodyParams;

function searchForAssignableVendorUsersUnsafeEffect(
  payload: TasksAPISearchForAssignableVendorUsersPayload,
) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, TasksAPISearchForAssignableVendorUsersResponse[]>({
        body,
        decode: unsafeDecodeJsonResponse<
          TasksAPISearchForAssignableVendorUsersResponse[]
        >({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/tasks/vendors/assignees/search",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function searchForAssignableVendorUsers(
  payload: TasksAPISearchForAssignableVendorUsersPayload,
  options?: { signal?: AbortSignal },
): Promise<TasksAPISearchForAssignableVendorUsersResponse[]> {
  return F.pipe(
    payload,
    searchForAssignableVendorUsersUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { searchForAssignableVendorUsers };
export type {
  TasksAPISearchForAssignableVendorUsersBodyParams,
  TasksAPISearchForAssignableVendorUsersPayload,
  TasksAPISearchForAssignableVendorUsersSearchParams,
};
