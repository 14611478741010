"use client";

import { Function as F, Option as O } from "effect";
import { useRouter } from "next/navigation";
import { useCallback, useMemo } from "react";
import { useAuthActor } from "../context/auth-actor.context";
import type { LoginPayload } from "../machine/auth.types";
import { AuthEventEnum, AuthStateEnum } from "../machine/auth.types";
import { LoginModalController } from "./login-modal.controller";
import { MultiFactorAuthenticationModal } from "./multi-factor-authentication-modal";
import { RedirectModalController } from "./redirect-modal.controller";
import { VendorSelectModal } from "./vendor-select-modal";
type AuthModalControllerProps = {
  redirectUrl: string;
};
function AuthModalController(props: AuthModalControllerProps) {
  const {
    redirectUrl
  } = props;
  const router = useRouter();
  const [snapshot, sendEvent] = useAuthActor();
  const {
    value: authState,
    context: {
      session
    }
  } = snapshot;
  const isTermsAccepted = useMemo(() => O.match(session, {
    onNone: () => false,
    onSome: s => s.user.isTermsAccepted
  }), [session]);
  const handleForgotPassword = useCallback(() => {
    router.push("/forgot-password");
  }, [router]);
  const handleLogin = useCallback((payload: LoginPayload) => {
    sendEvent({
      payload,
      type: AuthEventEnum.LOGIN
    });
  }, [sendEvent]);
  if (authState === AuthStateEnum.AUTHENTICATED && !isTermsAccepted) {
    return <RedirectModalController message="Welcome to ender, we need you to agree to some terms of service before continuing" redirectUrl={`/welcome?redirectUrl=${redirectUrl}`} title="Authenticated" />;
  }
  if (authState === AuthStateEnum.AUTHENTICATED) {
    return <RedirectModalController message="You are already logged in" redirectUrl={redirectUrl} title="Authenticated" />;
  }
  if (authState === AuthStateEnum.WAITING_FOR_VENDOR_SELECTION) {
    return <VendorSelectModal opened onCancel={F.constVoid} onDone={F.constVoid} />;
  }
  if (authState === AuthStateEnum.WAITING_FOR_MULTI_FACTOR_AUTHENTICATION) {
    return <MultiFactorAuthenticationModal opened onDone={F.constVoid} />;
  }
  return <LoginModalController opened onForgotPassword={handleForgotPassword} onLogin={handleLogin} data-sentry-element="LoginModalController" data-sentry-component="AuthModalController" data-sentry-source-file="auth-modal.controller.tsx" />;
}
export { AuthModalController };