/**
 * Edit property details.
 * HTTP Method: POST
 * Pathname: /properties/{propertyId}
 * @function updateProperty
 * @memberof PropertiesAPI
 * @param {PropertiesAPIUpdatePropertyPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type PropertiesAPIUpdatePropertyPathParams = {
  propertyId: EnderId;
};

type PropertiesAPIUpdatePropertySearchParams = {
  token?: string | undefined;
};

type PropertiesAPIUpdatePropertyBodyParams = {
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  json: any;
};

type PropertiesAPIUpdatePropertyPayload =
  PropertiesAPIUpdatePropertyPathParams &
    PropertiesAPIUpdatePropertySearchParams &
    PropertiesAPIUpdatePropertyBodyParams;

function updatePropertyUnsafeEffect(
  payload: PropertiesAPIUpdatePropertyPayload,
) {
  const { propertyId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/properties/${propertyId}`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function updateProperty(
  payload: PropertiesAPIUpdatePropertyPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    updatePropertyUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { updateProperty };
export type {
  PropertiesAPIUpdatePropertyBodyParams,
  PropertiesAPIUpdatePropertyPathParams,
  PropertiesAPIUpdatePropertyPayload,
  PropertiesAPIUpdatePropertySearchParams,
};
