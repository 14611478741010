/**
 * HTTP Method: POST
 * Pathname: /approvalProcess/batchReject
 * @function batchReject
 * @memberof ApprovalsAPI
 * @param {ApprovalsAPIBatchRejectPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId, LocalDate, SerializesInto } from "@ender/shared/core";
import type { ModelType } from "@ender/shared/generated/com.ender.common.model";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type ApprovalsAPIBatchRejectSearchParams = {
  token?: string | undefined;
};

type ApprovalsAPIBatchRejectBodyParams = {
  comment?: string | undefined;
  modelIds: EnderId[];
  modelType: ModelType;
  periodDate?: SerializesInto<LocalDate> | undefined;
  rejectedToStepId?: EnderId | undefined;
  transactionDate?: SerializesInto<LocalDate> | undefined;
};

type ApprovalsAPIBatchRejectPayload = ApprovalsAPIBatchRejectSearchParams &
  ApprovalsAPIBatchRejectBodyParams;

function batchRejectUnsafeEffect(payload: ApprovalsAPIBatchRejectPayload) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/approvalProcess/batchReject",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function batchReject(
  payload: ApprovalsAPIBatchRejectPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    batchRejectUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { batchReject };
export type {
  ApprovalsAPIBatchRejectBodyParams,
  ApprovalsAPIBatchRejectPayload,
  ApprovalsAPIBatchRejectSearchParams,
};
