import { Function as F, Option as O, String as Str } from "effect";

import type { RadioGroupData } from "@ender/shared/ds/radio-group";

function toRadioGroupData<T extends string>(
  value: O.Option<string>,
  data: RadioGroupData<T>,
): O.Option<RadioGroupData<T>> {
  return F.pipe(
    value,
    O.filter(Str.isNonEmpty),
    O.map(() => data),
  );
}

function getOrEmpty<T>(
  value: O.Option<T>,
  onSome: (item: T) => string,
): string {
  return F.pipe(value, O.map(onSome), O.getOrElse(F.constant(Str.empty)));
}

function toErrorMessage(error: unknown): O.Option<string> {
  return F.pipe(
    O.isOption(error) ? error : O.fromNullable<unknown>(error),
    O.map((e: unknown) => `${e}`),
    O.filter(Str.isNonEmpty),
  );
}

export { getOrEmpty, toErrorMessage, toRadioGroupData };
