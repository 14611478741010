import { Function as F, Option as O, Predicate as P } from "effect";
import type { ActorLogicFrom, ActorRefFrom } from "xstate";
import { assign, emit, setup } from "xstate";

import { AuthAPI } from "@ender/shared/generated/ender.api.misc";

import type {
  AuthContext,
  AuthEmitEvents,
  AuthEvents,
  AuthTag,
  LoginPayload,
  Session,
} from "./auth.types";
import {
  AuthActorEnum,
  AuthEmitEventEnum,
  AuthEventEnum,
  AuthGuardEnum,
  AuthStateEnum,
  AuthTagEnum,
} from "./auth.types";
import {
  fromContext,
  fromDoneEvent,
  fromErrorEvent,
  fromEvent,
  fromRestApi,
  hasExpired,
} from "./auth.utils";

const authMachine = setup({
  actions: {},
  actors: {
    [AuthActorEnum.LOGIN]: fromRestApi(
      (payload: LoginPayload, options?: { signal?: AbortSignal }) =>
        AuthAPI.login(
          {
            code: "",
            ...payload,
          },
          options,
        ),
    ),
    [AuthActorEnum.SESSION_INFO]: fromRestApi(AuthAPI.getSessionInfo),
  },
  delays: {},
  guards: {
    [AuthGuardEnum.HAS_EXPIRED]: ({ context }: { context: AuthContext }) => {
      return O.match(context.session, {
        onNone: () => true,
        onSome: (session) => hasExpired(session.expiration),
      });
    },
    [AuthGuardEnum.IS_INITIALIZED]: ({ context }: { context: AuthContext }) => {
      return F.pipe(
        O.firstSomeOf<unknown>([
          context.error,
          context.loginPayload,
          context.session,
          context.token,
        ]),
        O.isSome,
      );
    },
    [AuthGuardEnum.HAS_SESSION]: ({ context }: { context: AuthContext }) => {
      return O.isSome(context.session);
    },
    [AuthGuardEnum.REQUIRES_MULTI_FACTOR_AUTH]: ({
      context,
    }: {
      context: AuthContext;
    }) => {
      return O.match(context.session, {
        onNone: () => true,
        onSome: (session) => session.requiresMultiFactorAuth,
      });
    },
    [AuthGuardEnum.REQUIRES_VENDOR_SELECTION]: ({
      context,
    }: {
      context: AuthContext;
    }) => {
      return O.match(context.session, {
        onNone: () => false,
        onSome: (session) => {
          const { activeVendor, user } = session;
          return user.isVendor && P.isNullable(activeVendor);
        },
      });
    },
  },
  types: {} as {
    context: AuthContext;
    emitted: AuthEmitEvents;
    events: AuthEvents;
    input: {
      // Use session for unit testing
      session: O.Option<Session>;
      token: O.Option<string>;
    };
    tags: AuthTag;
  },
}).createMachine({
  context: ({ input }) => ({
    error: O.none(),
    loginPayload: O.none(),
    session: input.session,
    token: input.token,
  }),
  id: "auth",
  initial: AuthStateEnum.UNINITIALIZED,
  states: {
    [AuthStateEnum.AUTHENTICATED]: {
      on: {
        [AuthEventEnum.LOGIN]: {
          actions: assign({
            loginPayload: F.flow(fromEvent.toLoginPayload, O.some),
          }),
          target: AuthStateEnum.WAITING_FOR_LOGIN,
        },
        [AuthEventEnum.SET_SESSION]: {
          actions: assign({
            session: fromEvent.toSession,
          }),
          target: AuthStateEnum.VERIFYING_SESSION,
        },
      },
      tags: [AuthTagEnum.AUTHENTICATED],
    },
    [AuthStateEnum.ERROR]: {
      enter: [
        emit(({ context }: { context: AuthContext }) => {
          return {
            error: context.error,
            type: AuthEmitEventEnum.ERROR,
          };
        }),
      ],
      exit: [
        assign({
          error: () => O.none(),
        }),
      ],
      on: {
        [AuthEventEnum.LOGIN]: {
          actions: assign({
            loginPayload: F.flow(fromEvent.toLoginPayload, O.some),
          }),
          target: AuthStateEnum.WAITING_FOR_LOGIN,
        },
      },
      tags: [AuthTagEnum.UNAUTHENTICATED],
    },
    [AuthStateEnum.UNAUTHENTICATED]: {
      on: {
        [AuthEventEnum.LOGIN]: {
          actions: assign({
            loginPayload: F.flow(fromEvent.toLoginPayload, O.some),
          }),
          target: AuthStateEnum.WAITING_FOR_LOGIN,
        },
      },
      tags: [AuthTagEnum.UNAUTHENTICATED],
    },
    [AuthStateEnum.UNINITIALIZED]: {
      always: [
        {
          guard: { type: AuthGuardEnum.HAS_SESSION },
          target: AuthStateEnum.VERIFYING_SESSION,
        },
        {
          target: AuthStateEnum.WAITING_FOR_SESSION_INFO,
        },
      ],
      tags: [AuthTagEnum.INITIALIZING, AuthTagEnum.TRANSITIONAL],
    },
    [AuthStateEnum.VERIFYING_SESSION]: {
      always: [
        {
          guard: { type: AuthGuardEnum.HAS_EXPIRED },
          target: AuthStateEnum.UNAUTHENTICATED,
        },
        {
          guard: { type: AuthGuardEnum.REQUIRES_MULTI_FACTOR_AUTH },
          target: AuthStateEnum.WAITING_FOR_MULTI_FACTOR_AUTHENTICATION,
        },
        {
          guard: { type: AuthGuardEnum.REQUIRES_VENDOR_SELECTION },
          target: AuthStateEnum.WAITING_FOR_VENDOR_SELECTION,
        },
        {
          target: AuthStateEnum.AUTHENTICATED,
        },
      ],
      tags: [AuthTagEnum.INITIALIZING, AuthTagEnum.TRANSITIONAL],
    },
    [AuthStateEnum.WAITING_FOR_LOGIN]: {
      invoke: {
        input: fromEvent.toLoginPayload,
        onDone: {
          actions: assign({
            session: fromDoneEvent.toSession,
          }),
          target: AuthStateEnum.VERIFYING_SESSION,
        },
        onError: {
          actions: assign({
            error: fromErrorEvent.toError,
          }),
          target: AuthStateEnum.ERROR,
        },
        src: AuthActorEnum.LOGIN,
      },
      tags: [AuthTagEnum.UNAUTHENTICATED, AuthTagEnum.LOADING],
    },
    [AuthStateEnum.WAITING_FOR_MULTI_FACTOR_AUTHENTICATION]: {
      on: {
        [AuthEventEnum.SET_LOGIN_PAYLOAD]: {
          actions: assign({
            loginPayload: fromEvent.getLoginPayload,
          }),
        },
        [AuthEventEnum.SET_SESSION]: {
          actions: assign({
            session: fromEvent.toSession,
          }),
          target: AuthStateEnum.VERIFYING_SESSION,
        },
      },
      tags: [AuthTagEnum.AUTHENTICATED, AuthTagEnum.DELEGATED],
    },
    [AuthStateEnum.WAITING_FOR_SESSION_INFO]: {
      invoke: {
        input: fromContext.toGetSessionInfoPayload,
        onDone: {
          actions: assign({
            session: fromDoneEvent.toSession,
            // Token is only useful on initialization, but we don't want to clear it onError
            token: () => O.none(),
          }),
          target: AuthStateEnum.VERIFYING_SESSION,
        },
        onError: [
          {
            actions: assign({
              error: fromErrorEvent.toError,
            }),
            guard: { type: AuthGuardEnum.IS_INITIALIZED },
            target: AuthStateEnum.ERROR,
          },
          {
            target: AuthStateEnum.UNAUTHENTICATED,
          },
        ],
        src: AuthActorEnum.SESSION_INFO,
      },
      tags: [
        AuthTagEnum.INITIALIZING,
        AuthTagEnum.UNAUTHENTICATED,
        AuthTagEnum.LOADING,
      ],
    },
    [AuthStateEnum.WAITING_FOR_VENDOR_SELECTION]: {
      on: {
        [AuthEventEnum.SET_SESSION]: {
          actions: assign({
            session: fromEvent.toSession,
          }),
          target: AuthStateEnum.VERIFYING_SESSION,
        },
      },
      tags: [AuthTagEnum.AUTHENTICATED, AuthTagEnum.DELEGATED],
    },
  },
});

type AuthActorLogic = ActorLogicFrom<typeof authMachine>;
type AuthActorRef = ActorRefFrom<AuthActorLogic>;

export { authMachine };
export type { AuthActorLogic, AuthActorRef };
