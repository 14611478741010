/**
 * HTTP Method: POST
 * Pathname: /appliances/{applianceId}
 * @function updateAppliance
 * @memberof UnitsAPI
 * @param {UnitsAPIUpdateAppliancePayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { ApplianceApplianceType } from "@ender/shared/generated/ender.model.core.unit";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type UnitsAPIUpdateAppliancePathParams = {
  applianceId: EnderId;
};

type UnitsAPIUpdateApplianceSearchParams = {
  token?: string | undefined;
};

type UnitsAPIUpdateApplianceBodyParams = {
  brand?: string | undefined;
  model?: string | undefined;
  serialCode?: string | undefined;
  type?: ApplianceApplianceType | undefined;
};

type UnitsAPIUpdateAppliancePayload = UnitsAPIUpdateAppliancePathParams &
  UnitsAPIUpdateApplianceSearchParams &
  UnitsAPIUpdateApplianceBodyParams;

function updateApplianceUnsafeEffect(payload: UnitsAPIUpdateAppliancePayload) {
  const { applianceId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/appliances/${applianceId}`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function updateAppliance(
  payload: UnitsAPIUpdateAppliancePayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    updateApplianceUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { updateAppliance };
export type {
  UnitsAPIUpdateApplianceBodyParams,
  UnitsAPIUpdateAppliancePathParams,
  UnitsAPIUpdateAppliancePayload,
  UnitsAPIUpdateApplianceSearchParams,
};
