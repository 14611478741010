/**
 * HTTP Method: POST
 * Pathname: /properties/operatingAccounts
 * @function getOperatingAccountIdByPropertyId
 * @memberof PropertiesAPI
 * @param {PropertiesAPIGetOperatingAccountIdByPropertyIdPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<Partial<Record<EnderId, EnderId>>>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { InvoiceInvoiceType } from "@ender/shared/generated/ender.model.payments.invoice";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type PropertiesAPIGetOperatingAccountIdByPropertyIdSearchParams = {
  token?: string | undefined;
};

type PropertiesAPIGetOperatingAccountIdByPropertyIdBodyParams = {
  invoiceType: InvoiceInvoiceType;
  propertyIds: EnderId[];
};

type PropertiesAPIGetOperatingAccountIdByPropertyIdPayload =
  PropertiesAPIGetOperatingAccountIdByPropertyIdSearchParams &
    PropertiesAPIGetOperatingAccountIdByPropertyIdBodyParams;

function getOperatingAccountIdByPropertyIdUnsafeEffect(
  payload: PropertiesAPIGetOperatingAccountIdByPropertyIdPayload,
) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, Partial<Record<EnderId, EnderId>>>({
        body,
        decode: unsafeDecodeJsonResponse<Partial<Record<EnderId, EnderId>>>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/properties/operatingAccounts",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getOperatingAccountIdByPropertyId(
  payload: PropertiesAPIGetOperatingAccountIdByPropertyIdPayload,
  options?: { signal?: AbortSignal },
): Promise<Partial<Record<EnderId, EnderId>>> {
  return F.pipe(
    payload,
    getOperatingAccountIdByPropertyIdUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getOperatingAccountIdByPropertyId };
export type {
  PropertiesAPIGetOperatingAccountIdByPropertyIdBodyParams,
  PropertiesAPIGetOperatingAccountIdByPropertyIdPayload,
  PropertiesAPIGetOperatingAccountIdByPropertyIdSearchParams,
};
