/**
 * HTTP Method: POST
 * Pathname: /notapi/properties
 * @function getProperties
 * @memberof PropertiesAPI
 * @param {PropertiesAPIGetPropertiesPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<PropertiesAPIGetPropertiesResponse>}
 * @deprecated
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

import type { PropertiesAPIGetPropertiesResponse } from "../properties-api-get-properties-response";

type PropertiesAPIGetPropertiesSearchParams = {
  token?: string | undefined;
};

type PropertiesAPIGetPropertiesBodyParams = {
  propertyIds: EnderId[];
};

type PropertiesAPIGetPropertiesPayload =
  PropertiesAPIGetPropertiesSearchParams & PropertiesAPIGetPropertiesBodyParams;

function getPropertiesUnsafeEffect(payload: PropertiesAPIGetPropertiesPayload) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, PropertiesAPIGetPropertiesResponse>({
        body,
        decode: unsafeDecodeJsonResponse<PropertiesAPIGetPropertiesResponse>(
          {},
        ),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/notapi/properties",
        searchParams: [["token", token]],
      }),
    ),
  );
}

/**
 * @deprecated
 */
function getProperties(
  payload: PropertiesAPIGetPropertiesPayload,
  options?: { signal?: AbortSignal },
): Promise<PropertiesAPIGetPropertiesResponse> {
  return F.pipe(
    payload,
    getPropertiesUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getProperties };
export type {
  PropertiesAPIGetPropertiesBodyParams,
  PropertiesAPIGetPropertiesPayload,
  PropertiesAPIGetPropertiesSearchParams,
};
