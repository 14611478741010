/**
 * HTTP Method: GET
 * Pathname: /permissions/modelType/{modelType}/modelId/{modelId}
 * @function getPermissionsByModel
 * @memberof PermissionsAPI
 * @param {PermissionsAPIGetPermissionsByModelPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<GetPermissionsByModelResponse>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { ModelType } from "@ender/shared/generated/com.ender.common.model";
import type { GetPermissionsByModelResponse } from "@ender/shared/generated/ender.api.misc.response";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type PermissionsAPIGetPermissionsByModelPathParams = {
  modelType: ModelType;
  modelId: EnderId;
};

type PermissionsAPIGetPermissionsByModelSearchParams = {
  token?: string | undefined;
};

type PermissionsAPIGetPermissionsByModelPayload =
  PermissionsAPIGetPermissionsByModelPathParams &
    PermissionsAPIGetPermissionsByModelSearchParams;

function getPermissionsByModelUnsafeEffect(
  payload: PermissionsAPIGetPermissionsByModelPayload,
) {
  const { modelType, modelId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, GetPermissionsByModelResponse>({
        body,
        decode: unsafeDecodeJsonResponse<GetPermissionsByModelResponse>({}),
        encode: encodeNoBody({ method: "GET" }),
        pathname: `/permissions/modelType/${modelType}/modelId/${modelId}`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getPermissionsByModel(
  payload: PermissionsAPIGetPermissionsByModelPayload,
  options?: { signal?: AbortSignal },
): Promise<GetPermissionsByModelResponse> {
  return F.pipe(
    payload,
    getPermissionsByModelUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getPermissionsByModel };
export type {
  PermissionsAPIGetPermissionsByModelPathParams,
  PermissionsAPIGetPermissionsByModelPayload,
  PermissionsAPIGetPermissionsByModelSearchParams,
};
