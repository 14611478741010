/**
 * Ends the current session. Removes the 'token' cookie.
 * HTTP Method: POST
 * Pathname: /logout
 * @function logout
 * @memberof AuthAPI
 * @param {AuthAPILogoutPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<EnderSessionResponse | undefined>}
 */
import { Effect, Function as F } from "effect";

import type { EnderSessionResponse } from "@ender/shared/generated/ender.api.misc.response";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type AuthAPILogoutSearchParams = {
  token?: string | undefined;
};

type AuthAPILogoutPayload = AuthAPILogoutSearchParams;

function logoutUnsafeEffect(payload: AuthAPILogoutPayload) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, EnderSessionResponse | undefined>({
        body,
        decode: unsafeDecodeJsonResponse<EnderSessionResponse | undefined>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/logout",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function logout(
  payload: AuthAPILogoutPayload,
  options?: { signal?: AbortSignal },
): Promise<EnderSessionResponse | undefined> {
  return F.pipe(
    payload,
    logoutUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { logout };
export type { AuthAPILogoutPayload, AuthAPILogoutSearchParams };
