import { Schema } from "@effect/schema";
import { z } from "zod";

import { LocalTime$ } from "./local-time";

/**
 * This file is for utilities that operate on / depend on LocalTime$.
 * None of these utilities should be accessed from upstream, i.e. within the LocalTime$ class-
 * if so, they will cause circular dependencies.
 */

/**
 * @deprecated this should only be used in generated types. For forms, use LocalTimeFormSchema
 */
const LocalTimeSchema = z.string().transform(LocalTime$.of);

const LocalTimeFormSchema = Schema.instanceOf(LocalTime$);

export { LocalTimeFormSchema, LocalTimeSchema };
