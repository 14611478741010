/**
 * HTTP Method: POST
 * Pathname: /search
 * @function omnisearch
 * @memberof SearchAPI
 * @param {SearchAPIOmnisearchPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<SearchAPIOmnisearchResponse[]>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { SearchServiceSearchType } from "@ender/shared/generated/ender.service.search";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

import type { SearchAPIOmnisearchResponse } from "../search-api-omnisearch-response";

type SearchAPIOmnisearchSearchParams = {
  token?: string | undefined;
};

type SearchAPIOmnisearchBodyParams = {
  excludeIds: EnderId[];
  keyword: string;
  limit?: number | undefined;
  propertyId?: EnderId | undefined;
  propertyIds: EnderId[];
  resultsOnEmpty?: boolean | undefined;
  showHidden?: boolean | undefined;
  showUnpurchased?: boolean | undefined;
  types: SearchServiceSearchType[];
  useCache?: boolean | undefined;
};

type SearchAPIOmnisearchPayload = SearchAPIOmnisearchSearchParams &
  SearchAPIOmnisearchBodyParams;

function omnisearchUnsafeEffect(payload: SearchAPIOmnisearchPayload) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, SearchAPIOmnisearchResponse[]>({
        body,
        decode: unsafeDecodeJsonResponse<SearchAPIOmnisearchResponse[]>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/search",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function omnisearch(
  payload: SearchAPIOmnisearchPayload,
  options?: { signal?: AbortSignal },
): Promise<SearchAPIOmnisearchResponse[]> {
  return F.pipe(
    payload,
    omnisearchUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { omnisearch };
export type {
  SearchAPIOmnisearchBodyParams,
  SearchAPIOmnisearchPayload,
  SearchAPIOmnisearchSearchParams,
};
