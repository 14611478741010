/**
 * HTTP Method: POST
 * Pathname: /vendors/{vendorId}/rmis/sync
 * @function syncVendorWithRmis
 * @memberof VendorsAPI
 * @param {VendorsAPISyncVendorWithRmisPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type VendorsAPISyncVendorWithRmisPathParams = {
  vendorId: EnderId;
};

type VendorsAPISyncVendorWithRmisSearchParams = {
  token?: string | undefined;
};

type VendorsAPISyncVendorWithRmisPayload =
  VendorsAPISyncVendorWithRmisPathParams &
    VendorsAPISyncVendorWithRmisSearchParams;

function syncVendorWithRmisUnsafeEffect(
  payload: VendorsAPISyncVendorWithRmisPayload,
) {
  const { vendorId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/vendors/${vendorId}/rmis/sync`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function syncVendorWithRmis(
  payload: VendorsAPISyncVendorWithRmisPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    syncVendorWithRmisUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { syncVendorWithRmis };
export type {
  VendorsAPISyncVendorWithRmisPathParams,
  VendorsAPISyncVendorWithRmisPayload,
  VendorsAPISyncVendorWithRmisSearchParams,
};
