import { addProperty } from "./add-property";
import { addUnit } from "./add-unit";
import { createUnitType } from "./create-unit-type";
import { deleteProperty } from "./delete-property";
import { deleteUnitType } from "./delete-unit-type";
import { downloadPropertyPhotos } from "./download-property-photos";
import { getAmenities } from "./get-amenities";
import { getAmenityTypes } from "./get-amenity-types";
import { getFixedExpenses } from "./get-fixed-expenses";
import { getOperatingAccountIdByPropertyId } from "./get-operating-account-id-by-property-id";
import { getOwners } from "./get-owners";
import { getOwnersForFirm } from "./get-owners-for-firm";
import { getProperties } from "./get-properties";
import { getProperties2 } from "./get-properties2";
import { getProperty } from "./get-property";
import { getPropertyCodeIdMap } from "./get-property-code-id-map";
import { getPropertyPhotos } from "./get-property-photos";
import { getPropertyUnitTypes } from "./get-property-unit-types";
import { getTptExcludedProperties } from "./get-tpt-excluded-properties";
import { getUnitsForProperty } from "./get-units-for-property";
import { getUtilitiesPolicies } from "./get-utilities-policies";
import { searchProperties } from "./search-properties";
import { searchPropertiesv2 } from "./search-propertiesv2";
import { setAmenities } from "./set-amenities";
import { setTptExcluded } from "./set-tpt-excluded";
import { setUtilitiesPolicies } from "./set-utilities-policies";
import { updateFixedExpense } from "./update-fixed-expense";
import { updateProperty } from "./update-property";
import { updateUnitType } from "./update-unit-type";

const PropertiesAPI = {
  addProperty,
  addUnit,
  createUnitType,
  deleteProperty,
  deleteUnitType,
  downloadPropertyPhotos,
  getAmenities,
  getAmenityTypes,
  getFixedExpenses,
  getOperatingAccountIdByPropertyId,
  getOwners,
  getOwnersForFirm,
  getProperties,
  getProperties2,
  getProperty,
  getPropertyCodeIdMap,
  getPropertyPhotos,
  getPropertyUnitTypes,
  getTptExcludedProperties,
  getUnitsForProperty,
  getUtilitiesPolicies,
  searchProperties,
  searchPropertiesv2,
  setAmenities,
  setTptExcluded,
  setUtilitiesPolicies,
  updateFixedExpense,
  updateProperty,
  updateUnitType,
};

export { PropertiesAPI };
