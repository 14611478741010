/**
 * HTTP Method: POST
 * Pathname: /playwright/endTest
 * @function endTest
 * @memberof FixturesAPI
 * @param {FixturesAPIEndTestPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type FixturesAPIEndTestSearchParams = {
  token?: string | undefined;
};

type FixturesAPIEndTestPayload = FixturesAPIEndTestSearchParams;

function endTestUnsafeEffect(payload: FixturesAPIEndTestPayload) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/playwright/endTest",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function endTest(
  payload: FixturesAPIEndTestPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    endTestUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { endTest };
export type { FixturesAPIEndTestPayload, FixturesAPIEndTestSearchParams };
