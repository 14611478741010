/**
 * Add insurance for a LEASE. TODO: This API should really be based on the leaseId. Basing on unitId is wrong.

 Warning: this can be used by EITHER tenants or PM's.
 * HTTP Method: POST
 * Pathname: /units/{unitId}/insurance
 * @function addInsurance
 * @memberof UnitsAPI
 * @param {UnitsAPIAddInsurancePayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type UnitsAPIAddInsurancePathParams = {
  unitId: EnderId;
};

type UnitsAPIAddInsuranceSearchParams = {
  token?: string | undefined;
};

type UnitsAPIAddInsurancePayload = UnitsAPIAddInsurancePathParams &
  UnitsAPIAddInsuranceSearchParams;

function addInsuranceUnsafeEffect(payload: UnitsAPIAddInsurancePayload) {
  const { unitId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/units/${unitId}/insurance`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function addInsurance(
  payload: UnitsAPIAddInsurancePayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    addInsuranceUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { addInsurance };
export type {
  UnitsAPIAddInsurancePathParams,
  UnitsAPIAddInsurancePayload,
  UnitsAPIAddInsuranceSearchParams,
};
