/**
 * HTTP Method: GET
 * Pathname: /featureFlag/{flagKey}
 * @function getFeatureFlag
 * @memberof FeatureFlagAPI
 * @param {FeatureFlagAPIGetFeatureFlagPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<FeatureFlag>}
 */
import { Effect, Function as F } from "effect";

import type {
  FeatureFlag,
  FeatureFlagFlagKey,
} from "@ender/shared/generated/ender.model.misc";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type FeatureFlagAPIGetFeatureFlagPathParams = {
  flagKey: FeatureFlagFlagKey;
};

type FeatureFlagAPIGetFeatureFlagSearchParams = {
  token?: string | undefined;
};

type FeatureFlagAPIGetFeatureFlagPayload =
  FeatureFlagAPIGetFeatureFlagPathParams &
    FeatureFlagAPIGetFeatureFlagSearchParams;

function getFeatureFlagUnsafeEffect(
  payload: FeatureFlagAPIGetFeatureFlagPayload,
) {
  const { flagKey, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, FeatureFlag>({
        body,
        decode: unsafeDecodeJsonResponse<FeatureFlag>({}),
        encode: encodeNoBody({ method: "GET" }),
        pathname: `/featureFlag/${flagKey}`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getFeatureFlag(
  payload: FeatureFlagAPIGetFeatureFlagPayload,
  options?: { signal?: AbortSignal },
): Promise<FeatureFlag> {
  return F.pipe(
    payload,
    getFeatureFlagUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getFeatureFlag };
export type {
  FeatureFlagAPIGetFeatureFlagPathParams,
  FeatureFlagAPIGetFeatureFlagPayload,
  FeatureFlagAPIGetFeatureFlagSearchParams,
};
