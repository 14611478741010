"use client";

import { Predicate as P } from "effect";
import { useRouter } from "next/navigation";
import { useCallback, useEffect, useState } from "react";
import { useTimeout } from "@ender/shared/hooks/use-timeout";
import { RedirectModalView } from "./redirect-modal.view";
const oneSecondMs = 1_000;
type UseSecondsCounterProps = {
  initialSeconds: number | (() => number);
  onDone?: () => void;
};
function useSecondsCounter(props: UseSecondsCounterProps): {
  resetCounter: (newCounter: number) => void;
  secondsRemaining: number;
} {
  const {
    initialSeconds,
    onDone
  } = props;
  const [secondsRemaining, setSecondsRemaining] = useState(initialSeconds);
  const {
    startTimer
  } = useTimeout({
    onDone: () => {
      setSecondsRemaining(current => {
        const next = Math.max(0, current - 1);
        if (next > 0) {
          startTimer(oneSecondMs);
        } else if (P.isNotNullable(onDone)) {
          onDone();
        }
        return next;
      });
    }
  });
  const resetCounter = useCallback((newCounter: number) => {
    setSecondsRemaining(newCounter);
    startTimer(oneSecondMs);
  }, [setSecondsRemaining, startTimer]);

  // Start the countdown on first mount using imperative API requires and effect
  useEffect(() => {
    startTimer(oneSecondMs);
  }, [startTimer]);
  return {
    resetCounter,
    secondsRemaining
  };
}
type RedirectModalControllerProps = {
  message: string;
  redirectUrl: string;
  title: string;
};
function RedirectModalController(props: RedirectModalControllerProps) {
  const {
    message,
    redirectUrl,
    title
  } = props;
  const router = useRouter();
  const handleRedirect = useCallback(() => {
    router.push(redirectUrl);
  }, [redirectUrl, router]);
  const {
    secondsRemaining
  } = useSecondsCounter({
    initialSeconds: () => 15,
    onDone: handleRedirect
  });
  return <RedirectModalView secondsRemaining={secondsRemaining} message={message} title={title} onRedirect={handleRedirect} data-sentry-element="RedirectModalView" data-sentry-component="RedirectModalController" data-sentry-source-file="redirect-modal.controller.tsx" />;
}
export { RedirectModalController };