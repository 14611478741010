/**
 * HTTP Method: GET
 * Pathname: /pms/mine
 * @function getMyPM
 * @memberof EnderAPI
 * @param {EnderAPIGetMyPMPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<GetPMResponse>}
 */
import { Effect, Function as F } from "effect";

import type { GetPMResponse } from "@ender/shared/generated/ender.api.misc.response";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type EnderAPIGetMyPMSearchParams = {
  token?: string | undefined;
};

type EnderAPIGetMyPMPayload = EnderAPIGetMyPMSearchParams;

function getMyPMUnsafeEffect(payload: EnderAPIGetMyPMPayload) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, GetPMResponse>({
        body,
        decode: unsafeDecodeJsonResponse<GetPMResponse>({}),
        encode: encodeNoBody({ method: "GET" }),
        pathname: "/pms/mine",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getMyPM(
  payload: EnderAPIGetMyPMPayload,
  options?: { signal?: AbortSignal },
): Promise<GetPMResponse> {
  return F.pipe(
    payload,
    getMyPMUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getMyPM };
export type { EnderAPIGetMyPMPayload, EnderAPIGetMyPMSearchParams };
