import { randSentence } from "@ngneat/falso";

import type { EnderId, Instant } from "@ender/shared/core";
import { randomEnderId, randomInstant } from "@ender/shared/core";

type FilesClientEnderFile = {
  authorId: EnderId;
  filename: string;
  id: EnderId;
  name: string;
  path: string;
  s3Url: string;
  timestamp: Instant;
};

function randomFilesClientEnderFile(
  params: Partial<FilesClientEnderFile> = {},
): FilesClientEnderFile {
  const {
    authorId = randomEnderId(),
    filename = randSentence(),
    id = randomEnderId(),
    name = randSentence(),
    path = randSentence(),
    s3Url = randSentence(),
    timestamp = randomInstant().toJSON(),
  } = params;

  return {
    authorId,
    filename,
    id,
    name,
    path,
    s3Url,
    timestamp,
  };
}

export { randomFilesClientEnderFile };
export type { FilesClientEnderFile };
