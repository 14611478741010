/**
 * HTTP Method: POST
 * Pathname: /approvalProcess/steps/{stepId}/addApprover
 * @function addApprover
 * @memberof ApprovalsAPI
 * @param {ApprovalsAPIAddApproverPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type ApprovalsAPIAddApproverPathParams = {
  stepId: EnderId;
};

type ApprovalsAPIAddApproverSearchParams = {
  token?: string | undefined;
};

type ApprovalsAPIAddApproverBodyParams = {
  userId: EnderId;
};

type ApprovalsAPIAddApproverPayload = ApprovalsAPIAddApproverPathParams &
  ApprovalsAPIAddApproverSearchParams &
  ApprovalsAPIAddApproverBodyParams;

function addApproverUnsafeEffect(payload: ApprovalsAPIAddApproverPayload) {
  const { stepId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/approvalProcess/steps/${stepId}/addApprover`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function addApprover(
  payload: ApprovalsAPIAddApproverPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    addApproverUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { addApprover };
export type {
  ApprovalsAPIAddApproverBodyParams,
  ApprovalsAPIAddApproverPathParams,
  ApprovalsAPIAddApproverPayload,
  ApprovalsAPIAddApproverSearchParams,
};
