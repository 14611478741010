/**
 * HTTP Method: GET
 * Pathname: /firms/{firmId}
 * @function getFirm
 * @memberof FirmsAPI
 * @param {FirmsAPIGetFirmPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<Firm>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { Firm } from "@ender/shared/generated/ender.model.core";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type FirmsAPIGetFirmPathParams = {
  firmId: EnderId;
};

type FirmsAPIGetFirmSearchParams = {
  token?: string | undefined;
};

type FirmsAPIGetFirmPayload = FirmsAPIGetFirmPathParams &
  FirmsAPIGetFirmSearchParams;

function getFirmUnsafeEffect(payload: FirmsAPIGetFirmPayload) {
  const { firmId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, Firm>({
        body,
        decode: unsafeDecodeJsonResponse<Firm>({}),
        encode: encodeNoBody({ method: "GET" }),
        pathname: `/firms/${firmId}`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getFirm(
  payload: FirmsAPIGetFirmPayload,
  options?: { signal?: AbortSignal },
): Promise<Firm> {
  return F.pipe(
    payload,
    getFirmUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getFirm };
export type {
  FirmsAPIGetFirmPathParams,
  FirmsAPIGetFirmPayload,
  FirmsAPIGetFirmSearchParams,
};
