/**
 * HTTP Method: POST
 * Pathname: /properties/{propertyId}/units
 * @function addUnit
 * @memberof PropertiesAPI
 * @param {PropertiesAPIAddUnitPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<AddUnitResponse>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { AddUnitResponse } from "@ender/shared/generated/ender.api.core.response";
import type {
  UnitCoolingSystem,
  UnitHeatingSystem,
  UnitLaundryType,
  UnitParkingType,
} from "@ender/shared/generated/ender.model.core.unit";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type PropertiesAPIAddUnitPathParams = {
  propertyId: EnderId;
};

type PropertiesAPIAddUnitSearchParams = {
  token?: string | undefined;
};

type PropertiesAPIAddUnitBodyParams = {
  bedrooms?: number | undefined;
  coolingSystem?: UnitCoolingSystem | undefined;
  fullBaths?: number | undefined;
  halfBaths?: number | undefined;
  heatingSystem?: UnitHeatingSystem | undefined;
  heightFeet?: number | undefined;
  laundryType?: UnitLaundryType | undefined;
  lengthFeet?: number | undefined;
  name: string;
  parkingType?: UnitParkingType | undefined;
  rotation?: number | undefined;
  sqft?: number | undefined;
  storageGroup: string;
  unitTypeId?: EnderId | undefined;
  visualDisplayLeftFeet?: number | undefined;
  visualDisplayTopFeet?: number | undefined;
  widthFeet?: number | undefined;
};

type PropertiesAPIAddUnitPayload = PropertiesAPIAddUnitPathParams &
  PropertiesAPIAddUnitSearchParams &
  PropertiesAPIAddUnitBodyParams;

function addUnitUnsafeEffect(payload: PropertiesAPIAddUnitPayload) {
  const { propertyId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, AddUnitResponse>({
        body,
        decode: unsafeDecodeJsonResponse<AddUnitResponse>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/properties/${propertyId}/units`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function addUnit(
  payload: PropertiesAPIAddUnitPayload,
  options?: { signal?: AbortSignal },
): Promise<AddUnitResponse> {
  return F.pipe(
    payload,
    addUnitUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { addUnit };
export type {
  PropertiesAPIAddUnitBodyParams,
  PropertiesAPIAddUnitPathParams,
  PropertiesAPIAddUnitPayload,
  PropertiesAPIAddUnitSearchParams,
};
