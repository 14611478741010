/**
 * HTTP Method: GET
 * Pathname: /ender/systemStatus
 * @function getSystemStatus
 * @memberof EnderAPI
 * @param {EnderAPIGetSystemStatusPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<EnderSystemStatusServiceSystemStatus>}
 */
import { Effect, Function as F } from "effect";

import type { EnderSystemStatusServiceSystemStatus } from "@ender/shared/generated/ender.service.misc";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type EnderAPIGetSystemStatusSearchParams = {
  token?: string | undefined;
};

type EnderAPIGetSystemStatusPayload = EnderAPIGetSystemStatusSearchParams;

function getSystemStatusUnsafeEffect(payload: EnderAPIGetSystemStatusPayload) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, EnderSystemStatusServiceSystemStatus>({
        body,
        decode: unsafeDecodeJsonResponse<EnderSystemStatusServiceSystemStatus>(
          {},
        ),
        encode: encodeNoBody({ method: "GET" }),
        pathname: "/ender/systemStatus",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getSystemStatus(
  payload: EnderAPIGetSystemStatusPayload,
  options?: { signal?: AbortSignal },
): Promise<EnderSystemStatusServiceSystemStatus> {
  return F.pipe(
    payload,
    getSystemStatusUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getSystemStatus };
export type {
  EnderAPIGetSystemStatusPayload,
  EnderAPIGetSystemStatusSearchParams,
};
