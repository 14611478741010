/**
 * HTTP Method: GET
 * Pathname: /vendors/{vendorId}/markets
 * @function getVendorMarkets
 * @memberof VendorsAPI
 * @param {VendorsAPIGetVendorMarketsPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<GetVendorMarketsResponse[]>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { GetVendorMarketsResponse } from "@ender/shared/generated/ender.api.misc.response";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type VendorsAPIGetVendorMarketsPathParams = {
  vendorId: EnderId;
};

type VendorsAPIGetVendorMarketsSearchParams = {
  token?: string | undefined;
};

type VendorsAPIGetVendorMarketsPayload = VendorsAPIGetVendorMarketsPathParams &
  VendorsAPIGetVendorMarketsSearchParams;

function getVendorMarketsUnsafeEffect(
  payload: VendorsAPIGetVendorMarketsPayload,
) {
  const { vendorId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, GetVendorMarketsResponse[]>({
        body,
        decode: unsafeDecodeJsonResponse<GetVendorMarketsResponse[]>({}),
        encode: encodeNoBody({ method: "GET" }),
        pathname: `/vendors/${vendorId}/markets`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getVendorMarkets(
  payload: VendorsAPIGetVendorMarketsPayload,
  options?: { signal?: AbortSignal },
): Promise<GetVendorMarketsResponse[]> {
  return F.pipe(
    payload,
    getVendorMarketsUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getVendorMarkets };
export type {
  VendorsAPIGetVendorMarketsPathParams,
  VendorsAPIGetVendorMarketsPayload,
  VendorsAPIGetVendorMarketsSearchParams,
};
