/**
 * Create a property
 * HTTP Method: POST
 * Pathname: /properties
 * @function addProperty
 * @memberof PropertiesAPI
 * @param {PropertiesAPIAddPropertyPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<AddPropertyResponse>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId, Money, SerializesInto } from "@ender/shared/core";
import type { AddPropertyResponse } from "@ender/shared/generated/ender.api.core.response";
import type { PropertyPropertyType } from "@ender/shared/generated/ender.model.core.property";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type PropertiesAPIAddPropertySearchParams = {
  token?: string | undefined;
};

type PropertiesAPIAddPropertyBodyParams = {
  declaredNumUnits: number;
  firmId: EnderId;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  json: any;
  lateFee?: SerializesInto<Money> | undefined;
  marketId?: EnderId | undefined;
  numGraceDays?: number | undefined;
  propertyClass: PropertyPropertyType;
};

type PropertiesAPIAddPropertyPayload = PropertiesAPIAddPropertySearchParams &
  PropertiesAPIAddPropertyBodyParams;

function addPropertyUnsafeEffect(payload: PropertiesAPIAddPropertyPayload) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, AddPropertyResponse>({
        body,
        decode: unsafeDecodeJsonResponse<AddPropertyResponse>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/properties",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function addProperty(
  payload: PropertiesAPIAddPropertyPayload,
  options?: { signal?: AbortSignal },
): Promise<AddPropertyResponse> {
  return F.pipe(
    payload,
    addPropertyUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { addProperty };
export type {
  PropertiesAPIAddPropertyBodyParams,
  PropertiesAPIAddPropertyPayload,
  PropertiesAPIAddPropertySearchParams,
};
