import { Schema } from "@effect/schema";
import { z } from "zod";

import { Instant$ } from "./instant";

/**
 * This file is for utilities that operate on / depend on Instant$.
 * None of these utilities should be accessed from upstream, i.e. within the Instant$ class-
 * if so, they will cause circular dependencies.
 */

/**
 * Schema for use in Effect Schema (Form System)
 */
const InstantFormSchema = Schema.instanceOf(Instant$);

/**
 * @deprecated this should only be used in generated types. For forms, use InstantFormSchema
 */
const InstantSchema = z.string().transform(Instant$.of);

export { InstantFormSchema, InstantSchema };
