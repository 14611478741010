/**
 * HTTP Method: POST
 * Pathname: /fixtures/createPMCompany
 * @function createPM
 * @memberof FixturesAPI
 * @param {FixturesAPICreatePMPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<PMCompany>}
 */
import { Effect, Function as F } from "effect";

import type { PMCompany } from "@ender/shared/generated/ender.model.core";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type FixturesAPICreatePMSearchParams = {
  token?: string | undefined;
};

type FixturesAPICreatePMPayload = FixturesAPICreatePMSearchParams;

function createPMUnsafeEffect(payload: FixturesAPICreatePMPayload) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, PMCompany>({
        body,
        decode: unsafeDecodeJsonResponse<PMCompany>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/fixtures/createPMCompany",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function createPM(
  payload: FixturesAPICreatePMPayload,
  options?: { signal?: AbortSignal },
): Promise<PMCompany> {
  return F.pipe(
    payload,
    createPMUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { createPM };
export type { FixturesAPICreatePMPayload, FixturesAPICreatePMSearchParams };
