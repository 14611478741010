/**
 * HTTP Method: GET
 * Pathname: /model-explorer/dwolla/customers/{dwollaCustomerId}/transfers
 * @function getDwollaTransfersForCustomer
 * @memberof ModelExplorerAPI
 * @param {ModelExplorerAPIGetDwollaTransfersForCustomerPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<ModelExplorerAPIGetDwollaTransfersForCustomerResponse>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

import type { ModelExplorerAPIGetDwollaTransfersForCustomerResponse } from "../model-explorer-api-get-dwolla-transfers-for-customer-response";

type ModelExplorerAPIGetDwollaTransfersForCustomerPathParams = {
  dwollaCustomerId: EnderId;
};

type ModelExplorerAPIGetDwollaTransfersForCustomerSearchParams = {
  token?: string | undefined;
};

type ModelExplorerAPIGetDwollaTransfersForCustomerPayload =
  ModelExplorerAPIGetDwollaTransfersForCustomerPathParams &
    ModelExplorerAPIGetDwollaTransfersForCustomerSearchParams;

function getDwollaTransfersForCustomerUnsafeEffect(
  payload: ModelExplorerAPIGetDwollaTransfersForCustomerPayload,
) {
  const { dwollaCustomerId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, ModelExplorerAPIGetDwollaTransfersForCustomerResponse>({
        body,
        decode:
          unsafeDecodeJsonResponse<ModelExplorerAPIGetDwollaTransfersForCustomerResponse>(
            {},
          ),
        encode: encodeNoBody({ method: "GET" }),
        pathname: `/model-explorer/dwolla/customers/${dwollaCustomerId}/transfers`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getDwollaTransfersForCustomer(
  payload: ModelExplorerAPIGetDwollaTransfersForCustomerPayload,
  options?: { signal?: AbortSignal },
): Promise<ModelExplorerAPIGetDwollaTransfersForCustomerResponse> {
  return F.pipe(
    payload,
    getDwollaTransfersForCustomerUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getDwollaTransfersForCustomer };
export type {
  ModelExplorerAPIGetDwollaTransfersForCustomerPathParams,
  ModelExplorerAPIGetDwollaTransfersForCustomerPayload,
  ModelExplorerAPIGetDwollaTransfersForCustomerSearchParams,
};
