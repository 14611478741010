/**
 * HTTP Method: POST
 * Pathname: /chat-info
 * @function getChatInfo
 * @memberof EnderAPI
 * @param {EnderAPIGetChatInfoPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<GetChatInfoResponse>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { ModelType } from "@ender/shared/generated/com.ender.common.model";
import type { GetChatInfoResponse } from "@ender/shared/generated/ender.api.misc.response";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type EnderAPIGetChatInfoSearchParams = {
  token?: string | undefined;
};

type EnderAPIGetChatInfoBodyParams = {
  isInternal: boolean;
  modelId?: EnderId | undefined;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  modelParams?: any | undefined;
  modelType: ModelType;
};

type EnderAPIGetChatInfoPayload = EnderAPIGetChatInfoSearchParams &
  EnderAPIGetChatInfoBodyParams;

function getChatInfoUnsafeEffect(payload: EnderAPIGetChatInfoPayload) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, GetChatInfoResponse>({
        body,
        decode: unsafeDecodeJsonResponse<GetChatInfoResponse>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/chat-info",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getChatInfo(
  payload: EnderAPIGetChatInfoPayload,
  options?: { signal?: AbortSignal },
): Promise<GetChatInfoResponse> {
  return F.pipe(
    payload,
    getChatInfoUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getChatInfo };
export type {
  EnderAPIGetChatInfoBodyParams,
  EnderAPIGetChatInfoPayload,
  EnderAPIGetChatInfoSearchParams,
};
