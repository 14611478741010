/**
 * HTTP Method: GET
 * Pathname: /properties/{propertyId}/owners
 * @function getOwners
 * @memberof PropertiesAPI
 * @param {PropertiesAPIGetOwnersPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<OwnershipServiceLegalEntityNode>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { OwnershipServiceLegalEntityNode } from "@ender/shared/generated/ender.service.misc";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type PropertiesAPIGetOwnersPathParams = {
  propertyId: EnderId;
};

type PropertiesAPIGetOwnersSearchParams = {
  token?: string | undefined;
};

type PropertiesAPIGetOwnersPayload = PropertiesAPIGetOwnersPathParams &
  PropertiesAPIGetOwnersSearchParams;

function getOwnersUnsafeEffect(payload: PropertiesAPIGetOwnersPayload) {
  const { propertyId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, OwnershipServiceLegalEntityNode>({
        body,
        decode: unsafeDecodeJsonResponse<OwnershipServiceLegalEntityNode>({}),
        encode: encodeNoBody({ method: "GET" }),
        pathname: `/properties/${propertyId}/owners`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getOwners(
  payload: PropertiesAPIGetOwnersPayload,
  options?: { signal?: AbortSignal },
): Promise<OwnershipServiceLegalEntityNode> {
  return F.pipe(
    payload,
    getOwnersUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getOwners };
export type {
  PropertiesAPIGetOwnersPathParams,
  PropertiesAPIGetOwnersPayload,
  PropertiesAPIGetOwnersSearchParams,
};
