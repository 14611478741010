/**
 * HTTP Method: POST
 * Pathname: /issues/{issueId}
 * @function updateIssue
 * @memberof JiraAPI
 * @param {JiraAPIUpdateIssuePayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { JiraClientIssueType } from "@ender/shared/generated/com.ender.common.arch.client";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type JiraAPIUpdateIssuePathParams = {
  issueId: string;
};

type JiraAPIUpdateIssueSearchParams = {
  token?: string | undefined;
};

type JiraAPIUpdateIssueBodyParams = {
  description: string;
  summary: string;
  type: JiraClientIssueType;
};

type JiraAPIUpdateIssuePayload = JiraAPIUpdateIssuePathParams &
  JiraAPIUpdateIssueSearchParams &
  JiraAPIUpdateIssueBodyParams;

function updateIssueUnsafeEffect(payload: JiraAPIUpdateIssuePayload) {
  const { issueId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/issues/${issueId}`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function updateIssue(
  payload: JiraAPIUpdateIssuePayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    updateIssueUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { updateIssue };
export type {
  JiraAPIUpdateIssueBodyParams,
  JiraAPIUpdateIssuePathParams,
  JiraAPIUpdateIssuePayload,
  JiraAPIUpdateIssueSearchParams,
};
