/**
 * HTTP Method: POST
 * Pathname: /users/{tenantId}/emergencyContacts
 * @function addEmergencyContact
 * @memberof UsersAPI
 * @param {UsersAPIAddEmergencyContactPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<EmergencyContact>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type {
  ApplicationRelationshipType,
  EmergencyContact,
} from "@ender/shared/generated/ender.model.leasing";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type UsersAPIAddEmergencyContactPathParams = {
  tenantId: EnderId;
};

type UsersAPIAddEmergencyContactSearchParams = {
  token?: string | undefined;
};

type UsersAPIAddEmergencyContactBodyParams = {
  email?: string | undefined;
  firstName: string;
  lastName: string;
  phone?: string | undefined;
  relationshipType: ApplicationRelationshipType;
};

type UsersAPIAddEmergencyContactPayload =
  UsersAPIAddEmergencyContactPathParams &
    UsersAPIAddEmergencyContactSearchParams &
    UsersAPIAddEmergencyContactBodyParams;

function addEmergencyContactUnsafeEffect(
  payload: UsersAPIAddEmergencyContactPayload,
) {
  const { tenantId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, EmergencyContact>({
        body,
        decode: unsafeDecodeJsonResponse<EmergencyContact>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/users/${tenantId}/emergencyContacts`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function addEmergencyContact(
  payload: UsersAPIAddEmergencyContactPayload,
  options?: { signal?: AbortSignal },
): Promise<EmergencyContact> {
  return F.pipe(
    payload,
    addEmergencyContactUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { addEmergencyContact };
export type {
  UsersAPIAddEmergencyContactBodyParams,
  UsersAPIAddEmergencyContactPathParams,
  UsersAPIAddEmergencyContactPayload,
  UsersAPIAddEmergencyContactSearchParams,
};
