/**
 * HTTP Method: GET
 * Pathname: /model-explorer/dwolla/customers/{dwollaCustomerId}/accounts
 * @function getDwollaAccountsForCustomer
 * @memberof ModelExplorerAPI
 * @param {ModelExplorerAPIGetDwollaAccountsForCustomerPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<ModelExplorerAPIGetDwollaAccountsForCustomerResponse>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

import type { ModelExplorerAPIGetDwollaAccountsForCustomerResponse } from "../model-explorer-api-get-dwolla-accounts-for-customer-response";

type ModelExplorerAPIGetDwollaAccountsForCustomerPathParams = {
  dwollaCustomerId: EnderId;
};

type ModelExplorerAPIGetDwollaAccountsForCustomerSearchParams = {
  token?: string | undefined;
};

type ModelExplorerAPIGetDwollaAccountsForCustomerPayload =
  ModelExplorerAPIGetDwollaAccountsForCustomerPathParams &
    ModelExplorerAPIGetDwollaAccountsForCustomerSearchParams;

function getDwollaAccountsForCustomerUnsafeEffect(
  payload: ModelExplorerAPIGetDwollaAccountsForCustomerPayload,
) {
  const { dwollaCustomerId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, ModelExplorerAPIGetDwollaAccountsForCustomerResponse>({
        body,
        decode:
          unsafeDecodeJsonResponse<ModelExplorerAPIGetDwollaAccountsForCustomerResponse>(
            {},
          ),
        encode: encodeNoBody({ method: "GET" }),
        pathname: `/model-explorer/dwolla/customers/${dwollaCustomerId}/accounts`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getDwollaAccountsForCustomer(
  payload: ModelExplorerAPIGetDwollaAccountsForCustomerPayload,
  options?: { signal?: AbortSignal },
): Promise<ModelExplorerAPIGetDwollaAccountsForCustomerResponse> {
  return F.pipe(
    payload,
    getDwollaAccountsForCustomerUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getDwollaAccountsForCustomer };
export type {
  ModelExplorerAPIGetDwollaAccountsForCustomerPathParams,
  ModelExplorerAPIGetDwollaAccountsForCustomerPayload,
  ModelExplorerAPIGetDwollaAccountsForCustomerSearchParams,
};
