/**
 * HTTP Method: POST
 * Pathname: /firms/{firmId}/stateConfiguration
 * @function updateStateConfiguration
 * @memberof FirmsAPI
 * @param {FirmsAPIUpdateStateConfigurationPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type FirmsAPIUpdateStateConfigurationPathParams = {
  firmId: EnderId;
};

type FirmsAPIUpdateStateConfigurationSearchParams = {
  token?: string | undefined;
};

type FirmsAPIUpdateStateConfigurationBodyParams = {
  defaultAPOperatingAccountId?: EnderId | undefined;
  defaultAROperatingAccountId?: EnderId | undefined;
  defaultEscrowAccountId?: EnderId | undefined;
  defaultOperatingAccountId?: EnderId | undefined;
  state: string;
};

type FirmsAPIUpdateStateConfigurationPayload =
  FirmsAPIUpdateStateConfigurationPathParams &
    FirmsAPIUpdateStateConfigurationSearchParams &
    FirmsAPIUpdateStateConfigurationBodyParams;

function updateStateConfigurationUnsafeEffect(
  payload: FirmsAPIUpdateStateConfigurationPayload,
) {
  const { firmId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/firms/${firmId}/stateConfiguration`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function updateStateConfiguration(
  payload: FirmsAPIUpdateStateConfigurationPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    updateStateConfigurationUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { updateStateConfiguration };
export type {
  FirmsAPIUpdateStateConfigurationBodyParams,
  FirmsAPIUpdateStateConfigurationPathParams,
  FirmsAPIUpdateStateConfigurationPayload,
  FirmsAPIUpdateStateConfigurationSearchParams,
};
