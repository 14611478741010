/**
 * HTTP Method: GET
 * Pathname: /issues
 * @function getIssuesForPM
 * @memberof JiraAPI
 * @param {JiraAPIGetIssuesForPMPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<JiraAPIGetIssuesForPMResponse>}
 */
import { Effect, Function as F } from "effect";

import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

import type { JiraAPIGetIssuesForPMResponse } from "../jira-api-get-issues-for-p-m-response";

type JiraAPIGetIssuesForPMSearchParams = {
  token?: string | undefined;
  limit?: number | undefined;
  offset?: number | undefined;
};

type JiraAPIGetIssuesForPMPayload = JiraAPIGetIssuesForPMSearchParams;

function getIssuesForPMUnsafeEffect(payload: JiraAPIGetIssuesForPMPayload) {
  const { token, limit, offset, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, JiraAPIGetIssuesForPMResponse>({
        body,
        decode: unsafeDecodeJsonResponse<JiraAPIGetIssuesForPMResponse>({}),
        encode: encodeNoBody({ method: "GET" }),
        pathname: "/issues",
        searchParams: [
          ["token", token],
          ["limit", limit],
          ["offset", offset],
        ],
      }),
    ),
  );
}

function getIssuesForPM(
  payload: JiraAPIGetIssuesForPMPayload,
  options?: { signal?: AbortSignal },
): Promise<JiraAPIGetIssuesForPMResponse> {
  return F.pipe(
    payload,
    getIssuesForPMUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getIssuesForPM };
export type { JiraAPIGetIssuesForPMPayload, JiraAPIGetIssuesForPMSearchParams };
