/**
 * HTTP Method: GET
 * Pathname: /buy/approvals
 * @function getDealPipeline
 * @memberof ApprovalsAPI
 * @param {ApprovalsAPIGetDealPipelinePayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<ApprovalsAPIGetDealPipelineResponse>}
 */
import { Effect, Function as F } from "effect";

import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

import type { ApprovalsAPIGetDealPipelineResponse } from "../approvals-api-get-deal-pipeline-response";

type ApprovalsAPIGetDealPipelineSearchParams = {
  token?: string | undefined;
};

type ApprovalsAPIGetDealPipelinePayload =
  ApprovalsAPIGetDealPipelineSearchParams;

function getDealPipelineUnsafeEffect(
  payload: ApprovalsAPIGetDealPipelinePayload,
) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, ApprovalsAPIGetDealPipelineResponse>({
        body,
        decode: unsafeDecodeJsonResponse<ApprovalsAPIGetDealPipelineResponse>(
          {},
        ),
        encode: encodeNoBody({ method: "GET" }),
        pathname: "/buy/approvals",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getDealPipeline(
  payload: ApprovalsAPIGetDealPipelinePayload,
  options?: { signal?: AbortSignal },
): Promise<ApprovalsAPIGetDealPipelineResponse> {
  return F.pipe(
    payload,
    getDealPipelineUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getDealPipeline };
export type {
  ApprovalsAPIGetDealPipelinePayload,
  ApprovalsAPIGetDealPipelineSearchParams,
};
