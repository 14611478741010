/**
 * HTTP Method: POST
 * Pathname: /fixtures/createTenantUser
 * @function createTenantUser
 * @memberof FixturesAPI
 * @param {FixturesAPICreateTenantUserPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<CreateTenantUserResponse>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { CreateTenantUserResponse } from "@ender/shared/generated/ender.api.misc.response";
import type { LeaseUserRoleLeaseUserFlag } from "@ender/shared/generated/ender.model.leasing";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type FixturesAPICreateTenantUserSearchParams = {
  token?: string | undefined;
};

type FixturesAPICreateTenantUserBodyParams = {
  isDwollaVerified?: boolean | undefined;
  leaseId: EnderId;
  roles: LeaseUserRoleLeaseUserFlag[];
};

type FixturesAPICreateTenantUserPayload =
  FixturesAPICreateTenantUserSearchParams &
    FixturesAPICreateTenantUserBodyParams;

function createTenantUserUnsafeEffect(
  payload: FixturesAPICreateTenantUserPayload,
) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, CreateTenantUserResponse>({
        body,
        decode: unsafeDecodeJsonResponse<CreateTenantUserResponse>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/fixtures/createTenantUser",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function createTenantUser(
  payload: FixturesAPICreateTenantUserPayload,
  options?: { signal?: AbortSignal },
): Promise<CreateTenantUserResponse> {
  return F.pipe(
    payload,
    createTenantUserUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { createTenantUser };
export type {
  FixturesAPICreateTenantUserBodyParams,
  FixturesAPICreateTenantUserPayload,
  FixturesAPICreateTenantUserSearchParams,
};
