import type { Option as O } from "effect";

import type { AuthAPILoginPayload } from "@ender/shared/generated/ender.api.misc";
import type { EnderSessionResponse } from "@ender/shared/generated/ender.api.misc.response";
import type { ObjectValues } from "@ender/shared/types/general";

type LoginPayload = Omit<AuthAPILoginPayload, "code" | "channel">;
type Session = Omit<EnderSessionResponse, "token"> & {
  requiresMultiFactorAuth: boolean;
};

const AuthActionEnum = {} as const;
type AuthAction = ObjectValues<typeof AuthActionEnum>;

const AuthActorEnum = {
  LOGIN: "LOGIN",
  SESSION_INFO: "SESSION_INFO",
} as const;
type AuthActor = ObjectValues<typeof AuthActorEnum>;

type AuthContext = {
  error: O.Option<unknown>;
  loginPayload: O.Option<LoginPayload>; // Store Login detail in memory to enable two-factor
  session: O.Option<Session>;
  token: O.Option<string>; // Temporarily stored in memory when sent in URL Search Params eg. Onboarding Links
};

const AuthEmitEventEnum = {
  ERROR: "ERROR",
} as const;
type AuthEmitEvent = ObjectValues<typeof AuthEmitEventEnum>;

type AuthEmitEvents = {
  type: typeof AuthEmitEventEnum.ERROR;
  error: O.Option<unknown>;
};

const AuthEventEnum = {
  LOGIN: "LOGIN",
  SET_LOGIN_PAYLOAD: "SET_LOGIN_PAYLOAD",
  SET_SESSION: "SET_SESSION",
} as const;
type AuthEvent = ObjectValues<typeof AuthEventEnum>;

type AuthEvents =
  | { type: typeof AuthEventEnum.LOGIN; payload: LoginPayload }
  | {
      type: typeof AuthEventEnum.SET_LOGIN_PAYLOAD;
      payload: O.Option<LoginPayload>;
    }
  | {
      type: typeof AuthEventEnum.SET_SESSION;
      payload: O.Option<EnderSessionResponse>;
    };

type AuthEventMap = {
  [E in AuthEvents as E["type"]]: E & {
    payload: E extends { payload: infer P } ? P : undefined;
  };
};

const AuthGuardEnum = {
  HAS_EXPIRED: "HAS_EXPIRED",
  HAS_SESSION: "HAS_SESSION",
  IS_INITIALIZED: "IS_INITIALIZED",
  REQUIRES_MULTI_FACTOR_AUTH: "REQUIRES_MULTI_FACTOR_AUTH",
  REQUIRES_VENDOR_SELECTION: "REQUIRES_VENDOR_SELECTION",
} as const;
type AuthGuard = ObjectValues<typeof AuthGuardEnum>;

const AuthStateEnum = {
  AUTHENTICATED: "AUTHENTICATED",
  ERROR: "ERROR",
  UNAUTHENTICATED: "UNAUTHENTICATED",
  UNINITIALIZED: "UNINITIALIZED",
  VERIFYING_SESSION: "VERIFYING_SESSION",
  WAITING_FOR_LOGIN: "WAITING_FOR_LOGIN",
  WAITING_FOR_MULTI_FACTOR_AUTHENTICATION:
    "WAITING_FOR_MULTI_FACTOR_AUTHENTICATION",
  WAITING_FOR_SESSION_INFO: "WAITING_FOR_SESSION_INFO",
  WAITING_FOR_VENDOR_SELECTION: "WAITING_FOR_VENDOR_SELECTION",
} as const;
type AuthState = ObjectValues<typeof AuthStateEnum>;

const AuthTagEnum = {
  AUTHENTICATED: "AUTHENTICATED",
  DELEGATED: "DELEGATED",
  INITIALIZING: "INITIALIZING",
  LOADING: "LOADING",
  TRANSITIONAL: "TRANSITIONAL",
  UNAUTHENTICATED: "UNAUTHENTICATED",
} as const;
type AuthTag = ObjectValues<typeof AuthTagEnum>;

export {
  AuthActionEnum,
  AuthActorEnum,
  AuthEmitEventEnum,
  AuthEventEnum,
  AuthGuardEnum,
  AuthStateEnum,
  AuthTagEnum,
};
export type {
  AuthAction,
  AuthActor,
  AuthContext,
  AuthEmitEvent,
  AuthEmitEvents,
  AuthEvent,
  AuthEventMap,
  AuthEvents,
  AuthGuard,
  AuthState,
  AuthTag,
  LoginPayload,
  Session,
};
