/**
 * HTTP Method: POST
 * Pathname: /issues
 * @function createIssueForPM
 * @memberof JiraAPI
 * @param {JiraAPICreateIssueForPMPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<JiraAPICreateIssueForPMResponse>}
 */
import { Effect, Function as F } from "effect";

import type { JiraClientIssueType } from "@ender/shared/generated/com.ender.common.arch.client";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

import type { JiraAPICreateIssueForPMResponse } from "../jira-api-create-issue-for-p-m-response";

type JiraAPICreateIssueForPMSearchParams = {
  token?: string | undefined;
};

type JiraAPICreateIssueForPMBodyParams = {
  description: string;
  summary: string;
  type: JiraClientIssueType;
};

type JiraAPICreateIssueForPMPayload = JiraAPICreateIssueForPMSearchParams &
  JiraAPICreateIssueForPMBodyParams;

function createIssueForPMUnsafeEffect(payload: JiraAPICreateIssueForPMPayload) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, JiraAPICreateIssueForPMResponse>({
        body,
        decode: unsafeDecodeJsonResponse<JiraAPICreateIssueForPMResponse>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/issues",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function createIssueForPM(
  payload: JiraAPICreateIssueForPMPayload,
  options?: { signal?: AbortSignal },
): Promise<JiraAPICreateIssueForPMResponse> {
  return F.pipe(
    payload,
    createIssueForPMUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { createIssueForPM };
export type {
  JiraAPICreateIssueForPMBodyParams,
  JiraAPICreateIssueForPMPayload,
  JiraAPICreateIssueForPMSearchParams,
};
