/**
 * HTTP Method: GET
 * Pathname: /tasks/{taskId}/mostRelevantContacts
 * @function getMostRelevantContacts
 * @memberof TasksAPI
 * @param {TasksAPIGetMostRelevantContactsPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<TasksAPIGetMostRelevantContactsResponse[]>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

import type { TasksAPIGetMostRelevantContactsResponse } from "../tasks-api-get-most-relevant-contacts-response";

type TasksAPIGetMostRelevantContactsPathParams = {
  taskId: EnderId;
};

type TasksAPIGetMostRelevantContactsSearchParams = {
  token?: string | undefined;
};

type TasksAPIGetMostRelevantContactsPayload =
  TasksAPIGetMostRelevantContactsPathParams &
    TasksAPIGetMostRelevantContactsSearchParams;

function getMostRelevantContactsUnsafeEffect(
  payload: TasksAPIGetMostRelevantContactsPayload,
) {
  const { taskId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, TasksAPIGetMostRelevantContactsResponse[]>({
        body,
        decode: unsafeDecodeJsonResponse<
          TasksAPIGetMostRelevantContactsResponse[]
        >({}),
        encode: encodeNoBody({ method: "GET" }),
        pathname: `/tasks/${taskId}/mostRelevantContacts`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getMostRelevantContacts(
  payload: TasksAPIGetMostRelevantContactsPayload,
  options?: { signal?: AbortSignal },
): Promise<TasksAPIGetMostRelevantContactsResponse[]> {
  return F.pipe(
    payload,
    getMostRelevantContactsUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getMostRelevantContacts };
export type {
  TasksAPIGetMostRelevantContactsPathParams,
  TasksAPIGetMostRelevantContactsPayload,
  TasksAPIGetMostRelevantContactsSearchParams,
};
