/**
 * HTTP Method: POST
 * Pathname: /tasks/{taskId}/tags
 * @function addTaskTag
 * @memberof TasksAPI
 * @param {TasksAPIAddTaskTagPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type TasksAPIAddTaskTagPathParams = {
  taskId: EnderId;
};

type TasksAPIAddTaskTagSearchParams = {
  token?: string | undefined;
};

type TasksAPIAddTaskTagBodyParams = {
  note?: string | undefined;
  tag: string;
};

type TasksAPIAddTaskTagPayload = TasksAPIAddTaskTagPathParams &
  TasksAPIAddTaskTagSearchParams &
  TasksAPIAddTaskTagBodyParams;

function addTaskTagUnsafeEffect(payload: TasksAPIAddTaskTagPayload) {
  const { taskId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/tasks/${taskId}/tags`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function addTaskTag(
  payload: TasksAPIAddTaskTagPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    addTaskTagUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { addTaskTag };
export type {
  TasksAPIAddTaskTagBodyParams,
  TasksAPIAddTaskTagPathParams,
  TasksAPIAddTaskTagPayload,
  TasksAPIAddTaskTagSearchParams,
};
