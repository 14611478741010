/**
 * HTTP Method: POST
 * Pathname: /tasks/assignees/search
 * @function searchForAssignableUsers
 * @memberof TasksAPI
 * @param {TasksAPISearchForAssignableUsersPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<TasksAPISearchForAssignableUsersResponse[]>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

import type { TasksAPISearchForAssignableUsersResponse } from "../tasks-api-search-for-assignable-users-response";

type TasksAPISearchForAssignableUsersSearchParams = {
  token?: string | undefined;
};

type TasksAPISearchForAssignableUsersBodyParams = {
  excludeIds?: EnderId[] | undefined;
  keyword: string;
  roleNames?: string[] | undefined;
};

type TasksAPISearchForAssignableUsersPayload =
  TasksAPISearchForAssignableUsersSearchParams &
    TasksAPISearchForAssignableUsersBodyParams;

function searchForAssignableUsersUnsafeEffect(
  payload: TasksAPISearchForAssignableUsersPayload,
) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, TasksAPISearchForAssignableUsersResponse[]>({
        body,
        decode: unsafeDecodeJsonResponse<
          TasksAPISearchForAssignableUsersResponse[]
        >({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/tasks/assignees/search",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function searchForAssignableUsers(
  payload: TasksAPISearchForAssignableUsersPayload,
  options?: { signal?: AbortSignal },
): Promise<TasksAPISearchForAssignableUsersResponse[]> {
  return F.pipe(
    payload,
    searchForAssignableUsersUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { searchForAssignableUsers };
export type {
  TasksAPISearchForAssignableUsersBodyParams,
  TasksAPISearchForAssignableUsersPayload,
  TasksAPISearchForAssignableUsersSearchParams,
};
