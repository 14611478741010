import type { Path } from "react-hook-form";
import type { FormInput, MakeFormPropsFromInputProps, UseFormReturn } from "@ender/form-system/base";
import { FormField } from "@ender/form-system/base";
import type { CheckboxProps } from "./shared-ds-checkbox";
import { Checkbox } from "./shared-ds-checkbox";
type FormCheckboxProps<
// eslint-disable-next-line @typescript-eslint/no-explicit-any
Form extends UseFormReturn<any, any, any>, Name extends Path<FormInput<Form>>> = MakeFormPropsFromInputProps<CheckboxProps, Form, Name>;
function FormCheckbox<
// eslint-disable-next-line @typescript-eslint/no-explicit-any
Form extends UseFormReturn<any, any, any>, Name extends Path<FormInput<Form>>>(props: FormCheckboxProps<Form, Name>) {
  const {
    form,
    name,
    ...rest
  } = props;
  return <FormField name={name} form={form} data-sentry-element="FormField" data-sentry-component="FormCheckbox" data-sentry-source-file="form-checkbox.tsx">
      {({
      field
    }) => {
      return <Checkbox {...rest} {...field} />;
    }}
    </FormField>;
}
export { FormCheckbox };
export type { FormCheckboxProps };