/**
 * HTTP Method: POST
 * Pathname: /uploadVendors
 * @function uploadVendors
 * @memberof VendorsAPI
 * @param {VendorsAPIUploadVendorsPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type VendorsAPIUploadVendorsSearchParams = {
  token?: string | undefined;
};

type VendorsAPIUploadVendorsPayload = VendorsAPIUploadVendorsSearchParams;

function uploadVendorsUnsafeEffect(payload: VendorsAPIUploadVendorsPayload) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/uploadVendors",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function uploadVendors(
  payload: VendorsAPIUploadVendorsPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    uploadVendorsUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { uploadVendors };
export type {
  VendorsAPIUploadVendorsPayload,
  VendorsAPIUploadVendorsSearchParams,
};
