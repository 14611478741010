import { Function as F, Option as O } from "effect";

import { AuthAPI } from "@ender/shared/generated/ender.api.misc";
import { environmentStore } from "@ender/shared/stores/environment-store";
import { fail } from "@ender/shared/utils/error";

import { authActorStore } from "./store/auth-actor.store";

function handleLogout() {
  const { actor } = authActorStore.getState();
  const [authSnapshot] = O.getOrThrow(actor);
  const {
    context: { session },
  } = authSnapshot;

  const nextLocation = F.pipe(
    session,
    O.flatMap((s) => {
      const isMasquerading = s.user.id !== s.originalUserId;
      return isMasquerading ? O.some("/admin") : O.none();
    }),
    O.getOrElse(() => environmentStore.getState().authAppUrl),
  );

  AuthAPI.logout({})
    .then(() => {
      // Logout changes the session so refresh the page.
      globalThis.location.assign(nextLocation);
    })
    .catch(fail);
}

export { handleLogout };
