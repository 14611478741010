import type {
  Cookies,
  HttpClientError,
  HttpClientResponse,
} from "@effect/platform";
import { HttpClient } from "@effect/platform";
import { Schema } from "@effect/schema";
import type { Ref, Scope } from "effect";
import {
  Either as E,
  Effect,
  Either,
  Function as F,
  Option as O,
  String as Str,
} from "effect";

import * as BatchValidationError from "./batch-validation-error";
import * as RestClientError from "./rest-client-error";
import * as RestServerError from "./rest-server-error";
import type {
  RestError,
  WithBodyOptions,
  WithoutBodyOptions,
} from "./rest-types";

// fastify returns errors as { error: "Internal Server Error" }
const FastifyResultSchema = Schema.Struct({
  error: Schema.String,
});
type FastifyResult = Schema.Schema.Type<typeof FastifyResultSchema>;

const BatchValidationResultSchema = Schema.Struct({
  errors: Schema.NullishOr(Schema.Array(Schema.String)),
  warnings: Schema.NullishOr(Schema.Array(Schema.String)),
});
type BatchValidationResult = Schema.Schema.Type<
  typeof BatchValidationResultSchema
>;

const BackendErrorSchema = Schema.Union(
  FastifyResultSchema.pipe(
    Schema.attachPropertySignature("_tag", "FastifyError"),
  ),
  BatchValidationResultSchema.pipe(
    Schema.attachPropertySignature("_tag", "BatchValidationResult"),
  ),
);

function mapRequestError(
  requestError: HttpClientError.RequestError,
): Effect.Effect<never, RestError> {
  return RestClientError.BadRequest(requestError.message);
}

function mapResponseError(
  responseError: HttpClientError.ResponseError,
): Effect.Effect<never, RestError> {
  const {
    response: { status, text },
    message,
  } = responseError;

  const lazyMessage = F.constant(message);
  const asRestError = (
    message: string,
  ): RestClientError.RestClientError | RestServerError.RestServerError => {
    if (400 <= status && status <= 499) {
      return F.pipe(message, RestClientError.forStatus(status));
    }
    return F.pipe(message, RestServerError.forStatus(status));
  };
  const handleFastifyResult = ({
    error: errorMessage,
  }: FastifyResult): E.Either<
    never,
    RestClientError.RestClientError | RestServerError.RestServerError
  > => {
    if (errorMessage === "Internal Server Error") {
      errorMessage = "Ender is currently unavailable. Please try again later.";
    }
    if (Str.isEmpty(errorMessage)) {
      errorMessage = "API failed with an empty error";
    }
    return F.pipe(errorMessage, asRestError, E.left);
  };
  const asBatchValidationResult = (
    result: BatchValidationResult,
  ): BatchValidationError.BatchValidationError =>
    BatchValidationError.of({
      errors: [...(result.errors ?? [])],
      warnings: [...(result.warnings ?? [])],
    });

  return F.pipe(
    text,
    Effect.andThen((messageText) => {
      return F.pipe(
        Either.try({
          catch: () => {
            // TODO: Find a way to strong type all BE error handling so we can drop this plain text fallback
            // If error text is empty, preserve the status code and use a generic message
            return F.pipe(
              messageText,
              O.fromNullable,
              O.getOrElse(lazyMessage),
              asRestError,
            );
          },
          try: () => JSON.parse(messageText),
        }),
        E.flatMap(Schema.decodeUnknownEither(BackendErrorSchema)),
        E.flatMap((decodedValue) => {
          if (decodedValue._tag === "BatchValidationResult") {
            return F.pipe(decodedValue, asBatchValidationResult, E.left);
          }
          return E.right(decodedValue);
        }),
        E.flatMap(handleFastifyResult),
      );
    }),
    Effect.catchTags({
      // When we fail to parse error text, preserve the status code
      ResponseError: (err): Effect.Effect<never, RestError> =>
        F.pipe(err.message, asRestError, E.left),
    }),
  );
}

function encodeBodyFor(args: { baseUrl: () => URL }) {
  const { baseUrl } = args;
  return <Body extends Partial<{ [key: string]: unknown }>, Result>(
    options: WithBodyOptions<Body, Result>,
  ) => {
    const { encode, decode: _, ...encodeOptions } = options;
    return encode({
      baseUrl: baseUrl(),
      ...encodeOptions,
    });
  };
}

function encodeNoBodyFor(args: { baseUrl: () => URL }) {
  const { baseUrl } = args;
  return <Result>(options: WithoutBodyOptions<Result>) => {
    const { encode, decode: _, ...encodeOptions } = options;
    return encode({
      baseUrl: baseUrl(),
      ...encodeOptions,
    });
  };
}

function httpExecutor(args: {
  client: HttpClient.HttpClient<
    HttpClientResponse.HttpClientResponse,
    HttpClientError.HttpClientError,
    Scope.Scope
  >;
  cookiesRef: Ref.Ref<Cookies.Cookies>;
}): HttpClient.HttpClient<
  HttpClientResponse.HttpClientResponse,
  RestError,
  Scope.Scope
> {
  const { client, cookiesRef } = args;
  return F.pipe(
    client,
    HttpClient.withCookiesRef(cookiesRef),
    HttpClient.filterStatusOk,
    HttpClient.catchTags({
      RequestError: mapRequestError,
      ResponseError: mapResponseError,
    }),
  );
}

export {
  encodeBodyFor,
  encodeNoBodyFor,
  httpExecutor,
  mapRequestError,
  mapResponseError,
};
