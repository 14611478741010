/**
 * HTTP Method: POST
 * Pathname: /audit-trail/modelTypes/{modelType}
 * @function getAuditTrailData
 * @memberof EnderAPI
 * @param {EnderAPIGetAuditTrailDataPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<GetAuditTrailDataResponse>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { GetAuditTrailDataResponse } from "@ender/shared/generated/ender.api.misc.response";
import type { ReportWidgetSortOrder } from "@ender/shared/generated/ender.model.reports";
import type { AuditTrailServiceOperation } from "@ender/shared/generated/ender.service.misc";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type EnderAPIGetAuditTrailDataPathParams = {
  modelType: string;
};

type EnderAPIGetAuditTrailDataSearchParams = {
  token?: string | undefined;
};

type EnderAPIGetAuditTrailDataBodyParams = {
  authorId?: EnderId | undefined;
  limit?: number | undefined;
  offset?: number | undefined;
  operations: AuditTrailServiceOperation[];
  sortOrder?: ReportWidgetSortOrder | undefined;
};

type EnderAPIGetAuditTrailDataPayload = EnderAPIGetAuditTrailDataPathParams &
  EnderAPIGetAuditTrailDataSearchParams &
  EnderAPIGetAuditTrailDataBodyParams;

function getAuditTrailDataUnsafeEffect(
  payload: EnderAPIGetAuditTrailDataPayload,
) {
  const { modelType, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, GetAuditTrailDataResponse>({
        body,
        decode: unsafeDecodeJsonResponse<GetAuditTrailDataResponse>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/audit-trail/modelTypes/${modelType}`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getAuditTrailData(
  payload: EnderAPIGetAuditTrailDataPayload,
  options?: { signal?: AbortSignal },
): Promise<GetAuditTrailDataResponse> {
  return F.pipe(
    payload,
    getAuditTrailDataUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getAuditTrailData };
export type {
  EnderAPIGetAuditTrailDataBodyParams,
  EnderAPIGetAuditTrailDataPathParams,
  EnderAPIGetAuditTrailDataPayload,
  EnderAPIGetAuditTrailDataSearchParams,
};
