import { Schema } from "@effect/schema";
import type { Annotations, filter } from "@effect/schema/Schema";
import { nonEmptyString } from "@effect/schema/Schema";

/**
 * This will return invalid if used alone
 */

const _emailPatternMatchSchema = Schema.String.pipe(
  Schema.pattern(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, {
    message: () => "Email is invalid",
  }),
);

/**
 * This accepts an empty string by default, allowing it to bypass the pattern matching.
 *
 * To make this required, use EmailEffectSchema.pipe(Schema.nonEmptyString({message: () => "Email is required"}))
 */
const EmailEffectSchema = Schema.Union(
  Schema.Literal(""),
  _emailPatternMatchSchema,
);

/**
 * @category email filter
 */
const nonEmptyEmail = <A extends string>(
  annotations?: Annotations.Filter<A>,
): (<I, R>(self: Schema.Schema<A, I, R>) => filter<Schema.Schema<A, I, R>>) =>
  nonEmptyString({
    description: "a non empty email",
    ...annotations,
  });

export { EmailEffectSchema, nonEmptyEmail };
