/**
 * HTTP Method: POST
 * Pathname: /verifyUser
 * @function verifyUser
 * @memberof UsersAPI
 * @param {UsersAPIVerifyUserPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type UsersAPIVerifyUserSearchParams = {
  token?: string | undefined;
};

type UsersAPIVerifyUserBodyParams = {
  emailVerificationToken: string;
};

type UsersAPIVerifyUserPayload = UsersAPIVerifyUserSearchParams &
  UsersAPIVerifyUserBodyParams;

function verifyUserUnsafeEffect(payload: UsersAPIVerifyUserPayload) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/verifyUser",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function verifyUser(
  payload: UsersAPIVerifyUserPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    verifyUserUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { verifyUser };
export type {
  UsersAPIVerifyUserBodyParams,
  UsersAPIVerifyUserPayload,
  UsersAPIVerifyUserSearchParams,
};
