import type { Path } from "react-hook-form";
import type { FormInput, MakeFormPropsFromInputProps, UseFormReturn } from "@ender/form-system/base";
import { FormField } from "@ender/form-system/base";
import type { RadioGroupProps } from "./shared-ds-radio-group";
import { RadioGroup } from "./shared-ds-radio-group";
type FormRadioGroupProps<T extends string,
// eslint-disable-next-line @typescript-eslint/no-explicit-any
Form extends UseFormReturn<any, any, any>, Name extends Path<FormInput<Form>>> = MakeFormPropsFromInputProps<RadioGroupProps<T>, Form, Name>;
function FormRadioGroup<T extends string,
// eslint-disable-next-line @typescript-eslint/no-explicit-any
Form extends UseFormReturn<any, any, any>, Name extends Path<FormInput<Form>>>(props: FormRadioGroupProps<T, Form, Name>) {
  const {
    form,
    name,
    ...rest
  } = props;
  return <FormField name={name} form={form} data-sentry-element="FormField" data-sentry-component="FormRadioGroup" data-sentry-source-file="ui-form-radio-group.tsx">
      {({
      field,
      error
    }) => {
      return <RadioGroup {...rest} {...field} error={error} />;
    }}
    </FormField>;
}
export { FormRadioGroup };
export type { FormRadioGroupProps };