/**
 * HTTP Method: GET
 * Pathname: /modelSetting
 * @function getModelSettings
 * @memberof EnderAPI
 * @param {EnderAPIGetModelSettingsPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<EnderAPIGetModelSettingsResponse>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { ModelType } from "@ender/shared/generated/com.ender.common.model";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

import type { EnderAPIGetModelSettingsResponse } from "../ender-api-get-model-settings-response";

type EnderAPIGetModelSettingsSearchParams = {
  token?: string | undefined;
  modelId: EnderId;
  modelType: ModelType;
};

type EnderAPIGetModelSettingsPayload = EnderAPIGetModelSettingsSearchParams;

function getModelSettingsUnsafeEffect(
  payload: EnderAPIGetModelSettingsPayload,
) {
  const { token, modelId, modelType, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, EnderAPIGetModelSettingsResponse>({
        body,
        decode: unsafeDecodeJsonResponse<EnderAPIGetModelSettingsResponse>({}),
        encode: encodeNoBody({ method: "GET" }),
        pathname: "/modelSetting",
        searchParams: [
          ["token", token],
          ["modelId", modelId],
          ["modelType", modelType],
        ],
      }),
    ),
  );
}

function getModelSettings(
  payload: EnderAPIGetModelSettingsPayload,
  options?: { signal?: AbortSignal },
): Promise<EnderAPIGetModelSettingsResponse> {
  return F.pipe(
    payload,
    getModelSettingsUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getModelSettings };
export type {
  EnderAPIGetModelSettingsPayload,
  EnderAPIGetModelSettingsSearchParams,
};
