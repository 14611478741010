/**
 * HTTP Method: GET
 * Pathname: /vendors
 * @function getVendors
 * @memberof VendorsAPI
 * @param {VendorsAPIGetVendorsPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<GetVendorsResponse[]>}
 */
import { Effect, Function as F } from "effect";

import type { GetVendorsResponse } from "@ender/shared/generated/ender.api.core.response";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type VendorsAPIGetVendorsSearchParams = {
  token?: string | undefined;
  excludeStatuses?: string | undefined;
};

type VendorsAPIGetVendorsPayload = VendorsAPIGetVendorsSearchParams;

function getVendorsUnsafeEffect(payload: VendorsAPIGetVendorsPayload) {
  const { token, excludeStatuses, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, GetVendorsResponse[]>({
        body,
        decode: unsafeDecodeJsonResponse<GetVendorsResponse[]>({}),
        encode: encodeNoBody({ method: "GET" }),
        pathname: "/vendors",
        searchParams: [
          ["token", token],
          ["excludeStatuses", excludeStatuses],
        ],
      }),
    ),
  );
}

function getVendors(
  payload: VendorsAPIGetVendorsPayload,
  options?: { signal?: AbortSignal },
): Promise<GetVendorsResponse[]> {
  return F.pipe(
    payload,
    getVendorsUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getVendors };
export type { VendorsAPIGetVendorsPayload, VendorsAPIGetVendorsSearchParams };
