/**
 * The API to look for a user by phone or email.
 * HTTP Method: GET
 * Pathname: /users
 * @function getFilteredUser
 * @memberof UsersAPI
 * @param {UsersAPIGetFilteredUserPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<GetFilteredUserResponse>}
 */
import { Effect, Function as F } from "effect";

import type { GetFilteredUserResponse } from "@ender/shared/generated/ender.api.core.response";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type UsersAPIGetFilteredUserSearchParams = {
  token?: string | undefined;
  email?: string | undefined;
  phone?: string | undefined;
};

type UsersAPIGetFilteredUserPayload = UsersAPIGetFilteredUserSearchParams;

function getFilteredUserUnsafeEffect(payload: UsersAPIGetFilteredUserPayload) {
  const { token, email, phone, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, GetFilteredUserResponse>({
        body,
        decode: unsafeDecodeJsonResponse<GetFilteredUserResponse>({}),
        encode: encodeNoBody({ method: "GET" }),
        pathname: "/users",
        searchParams: [
          ["token", token],
          ["email", email],
          ["phone", phone],
        ],
      }),
    ),
  );
}

function getFilteredUser(
  payload: UsersAPIGetFilteredUserPayload,
  options?: { signal?: AbortSignal },
): Promise<GetFilteredUserResponse> {
  return F.pipe(
    payload,
    getFilteredUserUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getFilteredUser };
export type {
  UsersAPIGetFilteredUserPayload,
  UsersAPIGetFilteredUserSearchParams,
};
