/**
 * HTTP Method: POST
 * Pathname: /vendors/{vendorId}
 * @function updateVendor
 * @memberof VendorsAPI
 * @param {VendorsAPIUpdateVendorPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { VendorVendorStatus } from "@ender/shared/generated/ender.model.core.vendor";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type VendorsAPIUpdateVendorPathParams = {
  vendorId: EnderId;
};

type VendorsAPIUpdateVendorSearchParams = {
  token?: string | undefined;
};

type VendorsAPIUpdateVendorBodyParams = {
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  address?: any | undefined;
  companyEmail?: string | undefined;
  companyPhone?: string | undefined;
  enableNotifications?: boolean | undefined;
  jobCapacity?: number | undefined;
  name?: string | undefined;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  paymentPolicy?: any | undefined;
  paymentTermDays?: number | undefined;
  status?: VendorVendorStatus | undefined;
};

type VendorsAPIUpdateVendorPayload = VendorsAPIUpdateVendorPathParams &
  VendorsAPIUpdateVendorSearchParams &
  VendorsAPIUpdateVendorBodyParams;

function updateVendorUnsafeEffect(payload: VendorsAPIUpdateVendorPayload) {
  const { vendorId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/vendors/${vendorId}`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function updateVendor(
  payload: VendorsAPIUpdateVendorPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    updateVendorUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { updateVendor };
export type {
  VendorsAPIUpdateVendorBodyParams,
  VendorsAPIUpdateVendorPathParams,
  VendorsAPIUpdateVendorPayload,
  VendorsAPIUpdateVendorSearchParams,
};
