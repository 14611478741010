/**
 * HTTP Method: GET
 * Pathname: /leases/{leaseId}/tasks
 * @function getTenantTasks
 * @memberof TasksAPI
 * @param {TasksAPIGetTenantTasksPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<TasksAPIGetTenantTasksResponse>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

import type { TasksAPIGetTenantTasksResponse } from "../tasks-api-get-tenant-tasks-response";

type TasksAPIGetTenantTasksPathParams = {
  leaseId: EnderId;
};

type TasksAPIGetTenantTasksSearchParams = {
  token?: string | undefined;
};

type TasksAPIGetTenantTasksPayload = TasksAPIGetTenantTasksPathParams &
  TasksAPIGetTenantTasksSearchParams;

function getTenantTasksUnsafeEffect(payload: TasksAPIGetTenantTasksPayload) {
  const { leaseId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, TasksAPIGetTenantTasksResponse>({
        body,
        decode: unsafeDecodeJsonResponse<TasksAPIGetTenantTasksResponse>({}),
        encode: encodeNoBody({ method: "GET" }),
        pathname: `/leases/${leaseId}/tasks`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getTenantTasks(
  payload: TasksAPIGetTenantTasksPayload,
  options?: { signal?: AbortSignal },
): Promise<TasksAPIGetTenantTasksResponse> {
  return F.pipe(
    payload,
    getTenantTasksUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getTenantTasks };
export type {
  TasksAPIGetTenantTasksPathParams,
  TasksAPIGetTenantTasksPayload,
  TasksAPIGetTenantTasksSearchParams,
};
