/**
 * HTTP Method: GET
 * Pathname: /admin/enderUsers
 * @function getEnderUsers
 * @memberof AdminAPI
 * @param {AdminAPIGetEnderUsersPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<AdminAPIGetEnderUsersResponse>}
 */
import { Effect, Function as F } from "effect";

import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

import type { AdminAPIGetEnderUsersResponse } from "../admin-api-get-ender-users-response";

type AdminAPIGetEnderUsersSearchParams = {
  token?: string | undefined;
};

type AdminAPIGetEnderUsersPayload = AdminAPIGetEnderUsersSearchParams;

function getEnderUsersUnsafeEffect(payload: AdminAPIGetEnderUsersPayload) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, AdminAPIGetEnderUsersResponse>({
        body,
        decode: unsafeDecodeJsonResponse<AdminAPIGetEnderUsersResponse>({}),
        encode: encodeNoBody({ method: "GET" }),
        pathname: "/admin/enderUsers",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getEnderUsers(
  payload: AdminAPIGetEnderUsersPayload,
  options?: { signal?: AbortSignal },
): Promise<AdminAPIGetEnderUsersResponse> {
  return F.pipe(
    payload,
    getEnderUsersUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getEnderUsers };
export type { AdminAPIGetEnderUsersPayload, AdminAPIGetEnderUsersSearchParams };
