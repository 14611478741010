import { Schema } from "@effect/schema";

import type { EnderId } from "./ender-id/ender-id";
import { EnderIdBrandSchema } from "./ender-id/ender-id";

type EnderModel = {
  id: EnderId;
};

const EnderModelSchema = Schema.Struct({
  id: EnderIdBrandSchema,
});

export { EnderModelSchema };
export type { EnderModel };
