/* eslint-disable  @typescript-eslint/consistent-type-definitions */
import type { Duration } from "date-fns";
import { isAfter, isBefore, isSameDay, startOfDay } from "date-fns";
import { add as _add } from "date-fns/add";
import { formatWithOptions } from "date-fns/fp";
import { enUS } from "date-fns/locale";
import { Effectable, Equal, Hash, Inspectable, Predicate, pipe } from "effect";
import { dual } from "effect/Function";

import type * as LocalDate from "../local-date";

/** @internal */
const TypeId: LocalDate.TypeId = Symbol.for(
  "ender/LocalDate",
) as LocalDate.TypeId;

const Formats = {
  /**
   * yyyy-MM-dd
   */
  JSON: "JSON",
  /**
   * MMM d, yyyy
   */
  SHORT_DATE: "SHORT_DATE",
  /**
   * MM/dd/yyyy
   */
  DEFAULT: "DEFAULT",
} as const;

/** @internal */
const isLocalDate = (u: unknown): u is LocalDate.LocalDate =>
  Predicate.hasProperty(u, TypeId);

const defaultFormat = formatWithOptions({ locale: enUS }, "MM/dd/yyyy");
const shortDateStringFormat = formatWithOptions(
  { locale: enUS },
  "MMM d, yyyy",
);
const jsonFormat = formatWithOptions({}, "yyyy-MM-dd");

/**
 * formats a LocalDate based on the provided Format.
 * Formats.SHORT_DATE - short string representation: MMM d, yyyy
 * Formats.JSON - kebab representation: YYYY-mm-dd
 * Formats.DEFAULT - slash separated values: mm/dd/YYYY
 */
const toFormatted: {
  (f: LocalDate.Format): (a: LocalDate.LocalDate) => string;
  (self: LocalDate.LocalDate, f: LocalDate.Format): string;
} = dual(
  2,
  (
    self: LocalDate.LocalDate,
    format: LocalDate.Format = Formats.DEFAULT,
  ): string => {
    switch (format) {
      case Formats.SHORT_DATE: {
        return shortDateStringFormat(self.value);
      }
      case Formats.JSON:
        return jsonFormat(self.value);

      default:
        return defaultFormat(self.value);
    }
  },
);

const Proto = {
  ...Effectable.EffectPrototype,
  [TypeId]: {
    _A: (_: never) => _,
  },
  _op: "LocalDate",
  _tag: "LocalDate",
  [Inspectable.NodeInspectSymbol](this: LocalDate.LocalDate) {
    return this.toString();
  },
  [Hash.symbol](this: LocalDate.LocalDate) {
    return Hash.cached(
      this,
      Hash.combine(Hash.hash(this._tag))(Hash.hash(this.value.getTime())),
    );
  },
  [Equal.symbol](this: LocalDate.LocalDate, that: unknown) {
    return (
      isLocalDate(that) &&
      that._tag === "LocalDate" &&
      isSameDay(this.value, that.value)
    );
  },
  equals(this: LocalDate.LocalDate, that: unknown) {
    return Equal.equals(this, that);
  },
  valueOf(this: LocalDate.LocalDate) {
    return this.value.getTime();
  },
  toJSON(this: LocalDate.LocalDate): LocalDate.Serialized {
    return toFormatted(this, Formats.JSON) as LocalDate.Serialized;
  },
  toString(this: LocalDate.LocalDate) {
    return `LocalDate(${this.toJSON()})`;
  },
};

/**
 * All of the instance properties which should disappear once all Money interactions
 * are done in a functional programming way
 */
const WrappedProtoProperties = {
  /**
   * @deprecated use LocalDate.Equivalence(self, other)
   * @param other
   */
  equals: function (this: LocalDate.LocalDate, other: LocalDate.LocalDate) {
    // eslint-disable-next-line no-use-before-define
    return isSameDay(this.value, other.value);
  },
  /**
   * @deprecated use Money.toFormatted(self, format)
   * @param other
   */
  toFormatted: function (
    this: LocalDate.LocalDate,
    format: LocalDate.Format = Formats.DEFAULT,
  ) {
    return toFormatted(this, format);
  },
  /**
   * @deprecated use Order.greaterThan(LocalDate$.Order)(self, other)
   * @param other
   */
  isAfter: function (
    this: LocalDate.LocalDate,
    other: LocalDate.LocalDate,
  ): boolean {
    return isAfter(this.value, other.value);
  },
  /**
   * @deprecated use Order.lessThan(LocalDate$.Order)(self, other)
   * @param other
   */
  isBefore: function (
    this: LocalDate.LocalDate,
    other: LocalDate.LocalDate,
  ): boolean {
    return isBefore(this.value, other.value);
  },
  /**
   * @deprecated use LocalDate$.Equivalence(self, other)
   * @param other
   */
  isEqual: function (
    this: LocalDate.LocalDate,
    other: LocalDate.LocalDate,
  ): boolean {
    return isSameDay(other.value, this.value);
  },
  /**
   * @deprecated use Order.greaterThanOrEqualTo(LocalDate$.Order)(self, other)
   * @param other
   */
  isAfterOrEqual: function (
    this: LocalDate.LocalDate,
    other: LocalDate.LocalDate,
  ): boolean {
    return this.isAfter(other) || this.isEqual(other);
  },
  /**
   * @deprecated use Order.lessThanOrEqual(LocalDate$.Order)(self, other)
   * @param other
   */
  isBeforeOrEqual: function (
    this: LocalDate.LocalDate,
    other: LocalDate.LocalDate,
  ): boolean {
    return this.isBefore(other) || this.isEqual(other);
  },
  /**
   * @deprecated use LocalDate$.add(self, duration);
   * @param duration
   */
  add: function (
    this: LocalDate.LocalDate,
    duration: Pick<Duration, "years" | "months" | "weeks" | "days">,
  ) {
    // eslint-disable-next-line no-use-before-define
    return pipe(this.value, (v) => _add(v, duration), makeFromDate);
  },
  /**
   * @deprecated use `toFormatted`, `toString`, or `toJSON`- transforming
   * to and from a Date is not a supported behavior on the FE
   */
  toDate: function (this: LocalDate.LocalDate): Date {
    return this.value;
  },
};

const makeFromDate = (dateValue: Date): LocalDate.LocalDate => {
  const self = Object.create(Object.assign(Proto, WrappedProtoProperties));
  Object.defineProperty(self, "value", {
    enumerable: true,
    value: startOfDay(dateValue),
    writable: true,
  });
  return self;
};

export { Formats, isLocalDate, makeFromDate, toFormatted };
