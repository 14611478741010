/**
 * Gets filtered units for a PM.
 * HTTP Method: POST
 * Pathname: /units/search
 * @function getUnits
 * @memberof UnitsAPI
 * @param {UnitsAPIGetUnitsPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<GetUnitsResponse[]>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { GetUnitsResponse } from "@ender/shared/generated/ender.api.core.response";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type UnitsAPIGetUnitsSearchParams = {
  token?: string | undefined;
};

type UnitsAPIGetUnitsBodyParams = {
  includeAddress?: boolean | undefined;
  /**
   * if true, will include lease details for each unit
   */
  includeLeaseDetails?: boolean | undefined;
  /**
   * limit for pagination
   */
  limit?: number | undefined;
  /**
   * return only units that are listed or not listed (true/false)
   */
  listed?: boolean | undefined;
  /**
   * offset for pagination
   */
  offset?: number | undefined;
  propertyIds: EnderId[];
};

type UnitsAPIGetUnitsPayload = UnitsAPIGetUnitsSearchParams &
  UnitsAPIGetUnitsBodyParams;

function getUnitsUnsafeEffect(payload: UnitsAPIGetUnitsPayload) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, GetUnitsResponse[]>({
        body,
        decode: unsafeDecodeJsonResponse<GetUnitsResponse[]>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/units/search",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getUnits(
  payload: UnitsAPIGetUnitsPayload,
  options?: { signal?: AbortSignal },
): Promise<GetUnitsResponse[]> {
  return F.pipe(
    payload,
    getUnitsUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getUnits };
export type {
  UnitsAPIGetUnitsBodyParams,
  UnitsAPIGetUnitsPayload,
  UnitsAPIGetUnitsSearchParams,
};
