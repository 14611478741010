import { Function as F, Option as O } from "effect";

import { LocalDate$ } from "@ender/shared/core";

import { useAuthActor } from "../context/auth-actor.context";

function useNeedsMfa() {
  const [snapshot] = useAuthActor();
  return F.pipe(
    snapshot.context.session,
    O.flatMap((s) => LocalDate$.parse(s.mfaExpiration)),
    O.filter((expiration) => expiration.isBefore(LocalDate$.today())),
    O.isNone,
  );
}

export { useNeedsMfa };
