/**
 * HTTP Method: GET
 * Pathname: /firms/{firmId}/stateConfiguration
 * @function getStateConfigurations
 * @memberof FirmsAPI
 * @param {FirmsAPIGetStateConfigurationsPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<FirmStateMapping[]>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { FirmStateMapping } from "@ender/shared/generated/ender.model.core";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type FirmsAPIGetStateConfigurationsPathParams = {
  firmId: EnderId;
};

type FirmsAPIGetStateConfigurationsSearchParams = {
  token?: string | undefined;
};

type FirmsAPIGetStateConfigurationsPayload =
  FirmsAPIGetStateConfigurationsPathParams &
    FirmsAPIGetStateConfigurationsSearchParams;

function getStateConfigurationsUnsafeEffect(
  payload: FirmsAPIGetStateConfigurationsPayload,
) {
  const { firmId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, FirmStateMapping[]>({
        body,
        decode: unsafeDecodeJsonResponse<FirmStateMapping[]>({}),
        encode: encodeNoBody({ method: "GET" }),
        pathname: `/firms/${firmId}/stateConfiguration`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getStateConfigurations(
  payload: FirmsAPIGetStateConfigurationsPayload,
  options?: { signal?: AbortSignal },
): Promise<FirmStateMapping[]> {
  return F.pipe(
    payload,
    getStateConfigurationsUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getStateConfigurations };
export type {
  FirmsAPIGetStateConfigurationsPathParams,
  FirmsAPIGetStateConfigurationsPayload,
  FirmsAPIGetStateConfigurationsSearchParams,
};
