import { adminSendSms } from "./admin-send-sms";
import { createPhoneLine } from "./create-phone-line";
import { createSqlReport } from "./create-sql-report";
import { createTestingPM } from "./create-testing-p-m";
import { deleteSqlReport } from "./delete-sql-report";
import { ensurePhoneLine } from "./ensure-phone-line";
import { executeAdminConsoleCommand } from "./execute-admin-console-command";
import { executeTestingAccountCommand } from "./execute-testing-account-command";
import { getAdminPhoneLine } from "./get-admin-phone-line";
import { getAdminPhoneLineMessages } from "./get-admin-phone-line-messages";
import { getAdminPhoneLines } from "./get-admin-phone-lines";
import { getAllSqlReports } from "./get-all-sql-reports";
import { getEnderSoftwareProviderId } from "./get-ender-software-provider-id";
import { getEnderUsers } from "./get-ender-users";
import { getPMCompanies } from "./get-p-m-companies";
import { getPMs } from "./get-p-ms";
import { getRedirectingNumber } from "./get-redirecting-number";
import { getSqlReport } from "./get-sql-report";
import { loadTransunionReports } from "./load-transunion-reports";
import { setContactPhone } from "./set-contact-phone";
import { setRedirectingNumber } from "./set-redirecting-number";
import { setSystemStatus } from "./set-system-status";
import { updateSqlReport } from "./update-sql-report";

const AdminAPI = {
  adminSendSms,
  createPhoneLine,
  createSqlReport,
  createTestingPM,
  deleteSqlReport,
  ensurePhoneLine,
  executeAdminConsoleCommand,
  executeTestingAccountCommand,
  getAdminPhoneLine,
  getAdminPhoneLineMessages,
  getAdminPhoneLines,
  getAllSqlReports,
  getEnderSoftwareProviderId,
  getEnderUsers,
  getPMCompanies,
  getPMs,
  getRedirectingNumber,
  getSqlReport,
  loadTransunionReports,
  setContactPhone,
  setRedirectingNumber,
  setSystemStatus,
  updateSqlReport,
};

export { AdminAPI };
