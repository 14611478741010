/**
 * HTTP Method: POST
 * Pathname: /approvalProcess/batchApprove
 * @function batchApprove
 * @memberof ApprovalsAPI
 * @param {ApprovalsAPIBatchApprovePayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { ModelType } from "@ender/shared/generated/com.ender.common.model";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type ApprovalsAPIBatchApproveSearchParams = {
  token?: string | undefined;
};

type ApprovalsAPIBatchApproveBodyParams = {
  comment?: string | undefined;
  modelIds: EnderId[];
  modelType: ModelType;
};

type ApprovalsAPIBatchApprovePayload = ApprovalsAPIBatchApproveSearchParams &
  ApprovalsAPIBatchApproveBodyParams;

function batchApproveUnsafeEffect(payload: ApprovalsAPIBatchApprovePayload) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/approvalProcess/batchApprove",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function batchApprove(
  payload: ApprovalsAPIBatchApprovePayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    batchApproveUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { batchApprove };
export type {
  ApprovalsAPIBatchApproveBodyParams,
  ApprovalsAPIBatchApprovePayload,
  ApprovalsAPIBatchApproveSearchParams,
};
