import { Schema } from "@effect/schema";
import { rand } from "@ngneat/falso";

import { castEnum } from "@ender/shared/utils/effect";

const ModelTypeValues = [
  "APPLICATION_GROUP",
  "APPLICATION",
  "BANK_ACCOUNT",
  "BANKING_BATCH",
  "CHECK",
  "CONSTRUCTION_INSPECTION_LINE_ITEM",
  "CONSTRUCTION_INSPECTION",
  "DEAL",
  "DWOLLA_TRANSFER",
  "FIRM",
  "GL_CATEGORY",
  "GL_JOURNAL_ENTRY",
  "GL_TX",
  "INSURANCE_POLICY",
  "INVOICE",
  "LEAD",
  "LEASE_RENEWAL_OFFER",
  "LEASE",
  "LISTING_TEMPLATE",
  "MARKET",
  "MONEY_TRANSFER",
  "MULTI_MODEL_CHAT",
  "OWNERSHIP_GROUP",
  "PAYMENT_PLAN",
  "PET",
  "PROPERTY_INSPECTION",
  "PROPERTY_MANAGER",
  "PROPERTY",
  "RECURRING_GL_JOURNAL_ENTRY",
  "RELEASE_NOTE",
  "SERVICE",
  "SIGNED_DOCUMENT",
  "SOFTWARE_PROVIDER",
  "TASK_COST",
  "TASK",
  "TASK2",
  "UNIT_AREA",
  "UNIT_ZONE",
  "UNIT",
  "USER",
  "VEHICLE",
  "VENDOR",
  "W9_INFO",
] as const;

const ModelTypeEffectSchema = Schema.Literal(...ModelTypeValues);

type ModelType = Schema.Schema.Type<typeof ModelTypeEffectSchema>;

const ModelTypeEnum = castEnum(ModelTypeEffectSchema);

function randomModelType(): ModelType {
  return rand(ModelTypeValues);
}

export {
  ModelTypeEffectSchema,
  ModelTypeEnum,
  ModelTypeValues,
  randomModelType,
};
export type { ModelType };
