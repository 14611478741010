/**
 * HTTP Method: GET
 * Pathname: /enums/{enumName}
 * @function getEnumValues
 * @memberof EnderAPI
 * @param {EnderAPIGetEnumValuesPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<JsonUtilsEnumResponse[]>}
 */
import { Effect, Function as F } from "effect";

import type { JsonUtilsEnumResponse } from "@ender/shared/generated/ender.misc";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type EnderAPIGetEnumValuesPathParams = {
  enumName: string;
};

type EnderAPIGetEnumValuesSearchParams = {
  token?: string | undefined;
};

type EnderAPIGetEnumValuesPayload = EnderAPIGetEnumValuesPathParams &
  EnderAPIGetEnumValuesSearchParams;

function getEnumValuesUnsafeEffect(payload: EnderAPIGetEnumValuesPayload) {
  const { enumName, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, JsonUtilsEnumResponse[]>({
        body,
        decode: unsafeDecodeJsonResponse<JsonUtilsEnumResponse[]>({}),
        encode: encodeNoBody({ method: "GET" }),
        pathname: `/enums/${enumName}`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getEnumValues(
  payload: EnderAPIGetEnumValuesPayload,
  options?: { signal?: AbortSignal },
): Promise<JsonUtilsEnumResponse[]> {
  return F.pipe(
    payload,
    getEnumValuesUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getEnumValues };
export type {
  EnderAPIGetEnumValuesPathParams,
  EnderAPIGetEnumValuesPayload,
  EnderAPIGetEnumValuesSearchParams,
};
