/**
 * HTTP Method: DELETE
 * Pathname: /firms/{firmId}
 * @function deleteFirm
 * @memberof FirmsAPI
 * @param {FirmsAPIDeleteFirmPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type FirmsAPIDeleteFirmPathParams = {
  firmId: EnderId;
};

type FirmsAPIDeleteFirmSearchParams = {
  token?: string | undefined;
};

type FirmsAPIDeleteFirmPayload = FirmsAPIDeleteFirmPathParams &
  FirmsAPIDeleteFirmSearchParams;

function deleteFirmUnsafeEffect(payload: FirmsAPIDeleteFirmPayload) {
  const { firmId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "DELETE" }),
        pathname: `/firms/${firmId}`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function deleteFirm(
  payload: FirmsAPIDeleteFirmPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    deleteFirmUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { deleteFirm };
export type {
  FirmsAPIDeleteFirmPathParams,
  FirmsAPIDeleteFirmPayload,
  FirmsAPIDeleteFirmSearchParams,
};
