/**
 * HTTP Method: POST
 * Pathname: /tasks/{taskId}/revokeJob
 * @function revokeJob
 * @memberof TasksAPI
 * @param {TasksAPIRevokeJobPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type TasksAPIRevokeJobPathParams = {
  taskId: EnderId;
};

type TasksAPIRevokeJobSearchParams = {
  token?: string | undefined;
};

type TasksAPIRevokeJobBodyParams = {
  reason: string;
  vendorId: EnderId;
};

type TasksAPIRevokeJobPayload = TasksAPIRevokeJobPathParams &
  TasksAPIRevokeJobSearchParams &
  TasksAPIRevokeJobBodyParams;

function revokeJobUnsafeEffect(payload: TasksAPIRevokeJobPayload) {
  const { taskId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/tasks/${taskId}/revokeJob`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function revokeJob(
  payload: TasksAPIRevokeJobPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    revokeJobUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { revokeJob };
export type {
  TasksAPIRevokeJobBodyParams,
  TasksAPIRevokeJobPathParams,
  TasksAPIRevokeJobPayload,
  TasksAPIRevokeJobSearchParams,
};
