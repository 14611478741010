/**
 * HTTP Method: POST
 * Pathname: /fixtures/createProperty
 * @function createProperty
 * @memberof FixturesAPI
 * @param {FixturesAPICreatePropertyPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<PropertySerializerDeepPropertyResponse>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { PropertySerializerDeepPropertyResponse } from "@ender/shared/generated/ender.arch.serializer.core";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type FixturesAPICreatePropertySearchParams = {
  token?: string | undefined;
};

type FixturesAPICreatePropertyBodyParams = {
  createMarket?: boolean | undefined;
  firmId: EnderId;
  marketId?: EnderId | undefined;
  name?: string | undefined;
};

type FixturesAPICreatePropertyPayload = FixturesAPICreatePropertySearchParams &
  FixturesAPICreatePropertyBodyParams;

function createPropertyUnsafeEffect(payload: FixturesAPICreatePropertyPayload) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, PropertySerializerDeepPropertyResponse>({
        body,
        decode:
          unsafeDecodeJsonResponse<PropertySerializerDeepPropertyResponse>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/fixtures/createProperty",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function createProperty(
  payload: FixturesAPICreatePropertyPayload,
  options?: { signal?: AbortSignal },
): Promise<PropertySerializerDeepPropertyResponse> {
  return F.pipe(
    payload,
    createPropertyUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { createProperty };
export type {
  FixturesAPICreatePropertyBodyParams,
  FixturesAPICreatePropertyPayload,
  FixturesAPICreatePropertySearchParams,
};
