/**
 * HTTP Method: POST
 * Pathname: /approvalProcess/steps
 * @function addApprovalStep
 * @memberof ApprovalsAPI
 * @param {ApprovalsAPIAddApprovalStepPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<ApprovalStep>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type {
  ApprovalProcessType,
  ApprovalStep,
  ApprovalStepApprovalPhase,
} from "@ender/shared/generated/ender.model.approvals";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type ApprovalsAPIAddApprovalStepSearchParams = {
  token?: string | undefined;
};

type ApprovalsAPIAddApprovalStepBodyParams = {
  approvalProcessId?: EnderId | undefined;
  approvalProcessType: ApprovalProcessType;
  isPaymentPending?: boolean | undefined;
  name: string;
  ordering?: number | undefined;
  phase?: ApprovalStepApprovalPhase | undefined;
};

type ApprovalsAPIAddApprovalStepPayload =
  ApprovalsAPIAddApprovalStepSearchParams &
    ApprovalsAPIAddApprovalStepBodyParams;

function addApprovalStepUnsafeEffect(
  payload: ApprovalsAPIAddApprovalStepPayload,
) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, ApprovalStep>({
        body,
        decode: unsafeDecodeJsonResponse<ApprovalStep>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/approvalProcess/steps",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function addApprovalStep(
  payload: ApprovalsAPIAddApprovalStepPayload,
  options?: { signal?: AbortSignal },
): Promise<ApprovalStep> {
  return F.pipe(
    payload,
    addApprovalStepUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { addApprovalStep };
export type {
  ApprovalsAPIAddApprovalStepBodyParams,
  ApprovalsAPIAddApprovalStepPayload,
  ApprovalsAPIAddApprovalStepSearchParams,
};
