/**
 * HTTP Method: POST
 * Pathname: /lob/webhook
 * @function handleLobEvent
 * @memberof LobAPI
 * @param {LobAPIHandleLobEventPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type LobAPIHandleLobEventSearchParams = {
  token?: string | undefined;
};

type LobAPIHandleLobEventPayload = LobAPIHandleLobEventSearchParams;

function handleLobEventUnsafeEffect(payload: LobAPIHandleLobEventPayload) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/lob/webhook",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function handleLobEvent(
  payload: LobAPIHandleLobEventPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    handleLobEventUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { handleLobEvent };
export type { LobAPIHandleLobEventPayload, LobAPIHandleLobEventSearchParams };
