/**
 * HTTP Method: GET
 * Pathname: /settings/{pmId}
 * @function getPMSettings
 * @memberof EnderAPI
 * @param {EnderAPIGetPMSettingsPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<GetPMResponse>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { GetPMResponse } from "@ender/shared/generated/ender.api.misc.response";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type EnderAPIGetPMSettingsPathParams = {
  pmId: EnderId;
};

type EnderAPIGetPMSettingsSearchParams = {
  token?: string | undefined;
};

type EnderAPIGetPMSettingsPayload = EnderAPIGetPMSettingsPathParams &
  EnderAPIGetPMSettingsSearchParams;

function getPMSettingsUnsafeEffect(payload: EnderAPIGetPMSettingsPayload) {
  const { pmId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, GetPMResponse>({
        body,
        decode: unsafeDecodeJsonResponse<GetPMResponse>({}),
        encode: encodeNoBody({ method: "GET" }),
        pathname: `/settings/${pmId}`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getPMSettings(
  payload: EnderAPIGetPMSettingsPayload,
  options?: { signal?: AbortSignal },
): Promise<GetPMResponse> {
  return F.pipe(
    payload,
    getPMSettingsUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getPMSettings };
export type {
  EnderAPIGetPMSettingsPathParams,
  EnderAPIGetPMSettingsPayload,
  EnderAPIGetPMSettingsSearchParams,
};
