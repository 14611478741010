/**
 * HTTP Method: POST
 * Pathname: /properties/{propertyId}/utilitiesPolicies
 * @function setUtilitiesPolicies
 * @memberof PropertiesAPI
 * @param {PropertiesAPISetUtilitiesPoliciesPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { UtilitiesPolicyRequest } from "@ender/shared/generated/ender.api.core.request";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type PropertiesAPISetUtilitiesPoliciesPathParams = {
  propertyId: EnderId;
};

type PropertiesAPISetUtilitiesPoliciesSearchParams = {
  token?: string | undefined;
};

type PropertiesAPISetUtilitiesPoliciesBodyParams = {
  policies: UtilitiesPolicyRequest[];
};

type PropertiesAPISetUtilitiesPoliciesPayload =
  PropertiesAPISetUtilitiesPoliciesPathParams &
    PropertiesAPISetUtilitiesPoliciesSearchParams &
    PropertiesAPISetUtilitiesPoliciesBodyParams;

function setUtilitiesPoliciesUnsafeEffect(
  payload: PropertiesAPISetUtilitiesPoliciesPayload,
) {
  const { propertyId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/properties/${propertyId}/utilitiesPolicies`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function setUtilitiesPolicies(
  payload: PropertiesAPISetUtilitiesPoliciesPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    setUtilitiesPoliciesUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { setUtilitiesPolicies };
export type {
  PropertiesAPISetUtilitiesPoliciesBodyParams,
  PropertiesAPISetUtilitiesPoliciesPathParams,
  PropertiesAPISetUtilitiesPoliciesPayload,
  PropertiesAPISetUtilitiesPoliciesSearchParams,
};
