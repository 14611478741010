/**
 * HTTP Method: POST
 * Pathname: /properties/searchv2
 * @function searchPropertiesv2
 * @memberof PropertiesAPI
 * @param {PropertiesAPISearchPropertiesv2Payload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<SearchPropertiesResponse>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type {
  PropertyFilters,
  SearchPropertiesRequestPropertySortKey,
} from "@ender/shared/generated/ender.api.core.request";
import type { SearchPropertiesResponse } from "@ender/shared/generated/ender.api.core.response";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type PropertiesAPISearchPropertiesv2SearchParams = {
  token?: string | undefined;
};

type PropertiesAPISearchPropertiesv2BodyParams = {
  ascending?: boolean | undefined;
  /**
   * If true, this will return a 'deep serialization' with lots of details
   */
  expanded?: boolean | undefined;
  filters?: PropertyFilters | undefined;
  includeAddresses?: boolean | undefined;
  includeAmountOwed?: boolean | undefined;
  includeUnitStatusMetrics?: boolean | undefined;
  includeUnits?: boolean | undefined;
  keyword?: string | undefined;
  limit?: number | undefined;
  /**
   * If true, this will return only 'id', 'firmId', and 'name'.
   */
  minimal?: boolean | undefined;
  offset?: number | undefined;
  ownershipGroupIds: EnderId[];
  showHidden?: boolean | undefined;
  showTerminated?: boolean | undefined;
  showUnpurchased?: boolean | undefined;
  showWithGLTxs?: boolean | undefined;
  sortKey?: SearchPropertiesRequestPropertySortKey | undefined;
};

type PropertiesAPISearchPropertiesv2Payload =
  PropertiesAPISearchPropertiesv2SearchParams &
    PropertiesAPISearchPropertiesv2BodyParams;

function searchPropertiesv2UnsafeEffect(
  payload: PropertiesAPISearchPropertiesv2Payload,
) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, SearchPropertiesResponse>({
        body,
        decode: unsafeDecodeJsonResponse<SearchPropertiesResponse>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/properties/searchv2",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function searchPropertiesv2(
  payload: PropertiesAPISearchPropertiesv2Payload,
  options?: { signal?: AbortSignal },
): Promise<SearchPropertiesResponse> {
  return F.pipe(
    payload,
    searchPropertiesv2UnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { searchPropertiesv2 };
export type {
  PropertiesAPISearchPropertiesv2BodyParams,
  PropertiesAPISearchPropertiesv2Payload,
  PropertiesAPISearchPropertiesv2SearchParams,
};
