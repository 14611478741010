/**
 * Note to devs migrating us from this API to the "v2" API:
 
 Observe that this API is using `propertiesService.getProperties(...) with the parameter "showTerminatedWithGLTxs"
 set to true. When you make the migration of FE to use the V2 API, this becomes a named API param by the same name
 "showTerminatedWithGLTxs". Thus any FE call-sites to this API should be updated to set this param to true when they
 switch to the new v2 API.
 * HTTP Method: POST
 * Pathname: /properties/search
 * @function searchProperties
 * @memberof PropertiesAPI
 * @param {PropertiesAPISearchPropertiesPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<PropertiesAPISearchPropertiesResponse[]>}
 * @deprecated
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

import type { PropertiesAPISearchPropertiesResponse } from "../properties-api-search-properties-response";

type PropertiesAPISearchPropertiesSearchParams = {
  token?: string | undefined;
};

type PropertiesAPISearchPropertiesBodyParams = {
  includeAddresses?: boolean | undefined;
  includeUnits?: boolean | undefined;
  keyword?: string | undefined;
  minimal?: boolean | undefined;
  ownershipGroupIds: EnderId[];
  showHidden?: boolean | undefined;
};

type PropertiesAPISearchPropertiesPayload =
  PropertiesAPISearchPropertiesSearchParams &
    PropertiesAPISearchPropertiesBodyParams;

function searchPropertiesUnsafeEffect(
  payload: PropertiesAPISearchPropertiesPayload,
) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, PropertiesAPISearchPropertiesResponse[]>({
        body,
        decode: unsafeDecodeJsonResponse<
          PropertiesAPISearchPropertiesResponse[]
        >({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/properties/search",
        searchParams: [["token", token]],
      }),
    ),
  );
}

/**
 * @deprecated
 */
function searchProperties(
  payload: PropertiesAPISearchPropertiesPayload,
  options?: { signal?: AbortSignal },
): Promise<PropertiesAPISearchPropertiesResponse[]> {
  return F.pipe(
    payload,
    searchPropertiesUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { searchProperties };
export type {
  PropertiesAPISearchPropertiesBodyParams,
  PropertiesAPISearchPropertiesPayload,
  PropertiesAPISearchPropertiesSearchParams,
};
