import { Schema } from "@effect/schema";
import { z } from "zod";

import { Percent$ } from "./percent";

/**
 * This file is for utilities that operate on / depend on Percent$.
 * None of these utilities should be accessed from upstream, i.e. within the Percent$ class-
 * if so, they will cause circular dependencies.
 */

/**
 * @deprecated this should only be used in generated schema, and will be removed when zod is replaced.
 * use PercentFormSchema
 */
const PercentSchema = z.string().transform((percent) => Percent$.of(percent));

const PercentFormSchema = Schema.instanceOf(Percent$);

export { PercentFormSchema, PercentSchema };
