"use client";

import { Schema } from "@effect/schema";
import { effectTsResolver } from "@hookform/resolvers/effect-ts";
import { useState } from "react";

import type { FormProps, UseFormReturn } from "@ender/form-system/base";
import { useForm } from "@ender/form-system/base";

// TODO This password schema is a temporary patch we will bring in https://github.com/zxcvbn-ts/zxcvbn for ENDER-22564
const PasswordSchema = Schema.String.pipe(
  Schema.trimmed({
    message: () => "Password cannot start or end with an empty space",
  }),
  Schema.nonEmptyString({ message: () => "Password is required" }),
  Schema.minLength(8, {
    message: () => "Password must be at least 8 characters long",
  }),
  Schema.pattern(/[!@#$%^&*]/, {
    message: () =>
      "Password must contain at least one special character !@#$%^&*",
  }),
);

const ChangePasswordFormSchema = Schema.Struct({
  password: PasswordSchema,
  password2: Schema.String.pipe(
    Schema.nonEmptyString({ message: () => "Password is required" }),
  ),
});
type ChangePasswordFormInput = Schema.Schema.Encoded<
  typeof ChangePasswordFormSchema
>;
type ChangePasswordFormOutput = Schema.Schema.Type<
  typeof ChangePasswordFormSchema
>;

type UseChangePasswordFormReturn = UseFormReturn<
  ChangePasswordFormInput,
  {},
  ChangePasswordFormOutput
>;
type ChangePasswordFormProps = FormProps<UseChangePasswordFormReturn>;

function defaultInitialValues(): ChangePasswordFormOutput {
  return {
    password: "",
    password2: "",
  };
}

type UseChangePasswordFormArgs = {
  initialValues?: () => ChangePasswordFormOutput;
};

function useChangePasswordForm(args?: UseChangePasswordFormArgs) {
  const { initialValues = defaultInitialValues } = args ?? {};
  const [defaultValues] = useState(initialValues);

  return useForm<ChangePasswordFormInput, {}, ChangePasswordFormOutput>({
    defaultValues: defaultValues,
    resolver: effectTsResolver(ChangePasswordFormSchema),
  });
}

export { ChangePasswordFormSchema, useChangePasswordForm };
export type {
  ChangePasswordFormInput,
  ChangePasswordFormOutput,
  ChangePasswordFormProps,
  UseChangePasswordFormArgs,
  UseChangePasswordFormReturn,
};
