/**
 * HTTP Method: POST
 * Pathname: /createOwnershipGroup
 * @function createOwnershipGroup
 * @memberof OwnershipGroupsAPI
 * @param {OwnershipGroupsAPICreateOwnershipGroupPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<CreateOwnershipGroupResponse>}
 */
import { Effect, Function as F } from "effect";

import type { CreateOwnershipGroupResponse } from "@ender/shared/generated/ender.api.misc.response";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type OwnershipGroupsAPICreateOwnershipGroupSearchParams = {
  token?: string | undefined;
};

type OwnershipGroupsAPICreateOwnershipGroupBodyParams = {
  name: string;
};

type OwnershipGroupsAPICreateOwnershipGroupPayload =
  OwnershipGroupsAPICreateOwnershipGroupSearchParams &
    OwnershipGroupsAPICreateOwnershipGroupBodyParams;

function createOwnershipGroupUnsafeEffect(
  payload: OwnershipGroupsAPICreateOwnershipGroupPayload,
) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, CreateOwnershipGroupResponse>({
        body,
        decode: unsafeDecodeJsonResponse<CreateOwnershipGroupResponse>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/createOwnershipGroup",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function createOwnershipGroup(
  payload: OwnershipGroupsAPICreateOwnershipGroupPayload,
  options?: { signal?: AbortSignal },
): Promise<CreateOwnershipGroupResponse> {
  return F.pipe(
    payload,
    createOwnershipGroupUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { createOwnershipGroup };
export type {
  OwnershipGroupsAPICreateOwnershipGroupBodyParams,
  OwnershipGroupsAPICreateOwnershipGroupPayload,
  OwnershipGroupsAPICreateOwnershipGroupSearchParams,
};
