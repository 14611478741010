/**
 * HTTP Method: POST
 * Pathname: /units/areas/{areaId}
 * @function updateUnitArea
 * @memberof UnitAreasAPI
 * @param {UnitAreasAPIUpdateUnitAreaPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<UnitArea>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { UnitArea } from "@ender/shared/generated/ender.model.core.unit.area";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type UnitAreasAPIUpdateUnitAreaPathParams = {
  areaId: EnderId;
};

type UnitAreasAPIUpdateUnitAreaSearchParams = {
  token?: string | undefined;
};

type UnitAreasAPIUpdateUnitAreaBodyParams = {
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  data?: any | undefined;
  description?: string | undefined;
};

type UnitAreasAPIUpdateUnitAreaPayload = UnitAreasAPIUpdateUnitAreaPathParams &
  UnitAreasAPIUpdateUnitAreaSearchParams &
  UnitAreasAPIUpdateUnitAreaBodyParams;

function updateUnitAreaUnsafeEffect(
  payload: UnitAreasAPIUpdateUnitAreaPayload,
) {
  const { areaId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, UnitArea>({
        body,
        decode: unsafeDecodeJsonResponse<UnitArea>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/units/areas/${areaId}`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function updateUnitArea(
  payload: UnitAreasAPIUpdateUnitAreaPayload,
  options?: { signal?: AbortSignal },
): Promise<UnitArea> {
  return F.pipe(
    payload,
    updateUnitAreaUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { updateUnitArea };
export type {
  UnitAreasAPIUpdateUnitAreaBodyParams,
  UnitAreasAPIUpdateUnitAreaPathParams,
  UnitAreasAPIUpdateUnitAreaPayload,
  UnitAreasAPIUpdateUnitAreaSearchParams,
};
