/**
 * HTTP Method: GET
 * Pathname: /properties
 * @function getProperties2
 * @memberof PropertiesAPI
 * @param {PropertiesAPIGetProperties2Payload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<PropertiesAPIGetProperties2Response>}
 * @deprecated
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

import type { PropertiesAPIGetProperties2Response } from "../properties-api-get-properties2-response";

type PropertiesAPIGetProperties2SearchParams = {
  token?: string | undefined;
  includeAmountOwed?: boolean | undefined;
  limit?: number | undefined;
  offset?: number | undefined;
  ownershipGroupIds: EnderId[];
};

type PropertiesAPIGetProperties2Payload =
  PropertiesAPIGetProperties2SearchParams;

function getProperties2UnsafeEffect(
  payload: PropertiesAPIGetProperties2Payload,
) {
  const {
    token,
    includeAmountOwed,
    limit,
    offset,
    ownershipGroupIds,
    ...body
  } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, PropertiesAPIGetProperties2Response>({
        body,
        decode: unsafeDecodeJsonResponse<PropertiesAPIGetProperties2Response>(
          {},
        ),
        encode: encodeNoBody({ method: "GET" }),
        pathname: "/properties",
        searchParams: [
          ["token", token],
          ["includeAmountOwed", includeAmountOwed],
          ["limit", limit],
          ["offset", offset],
          ["ownershipGroupIds", ownershipGroupIds],
        ],
      }),
    ),
  );
}

/**
 * @deprecated
 */
function getProperties2(
  payload: PropertiesAPIGetProperties2Payload,
  options?: { signal?: AbortSignal },
): Promise<PropertiesAPIGetProperties2Response> {
  return F.pipe(
    payload,
    getProperties2UnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getProperties2 };
export type {
  PropertiesAPIGetProperties2Payload,
  PropertiesAPIGetProperties2SearchParams,
};
