"use client";

import { useActor } from "@xstate/react";
import { Option as O } from "effect";
import type { PropsWithChildren } from "react";
import { createContext, useContext, useMemo, useState } from "react";
import type { ActorOptions } from "xstate";
import type { EventFromLogic, SnapshotFrom } from "xstate/dist/declarations/src/types";
import type { AuthActorLogic, AuthActorRef } from "../machine/auth.machine";
import { authMachine } from "../machine/auth.machine";
import type { Session } from "../machine/auth.types";
import { setAuthActor } from "../store/auth-actor.store";
type AuthSnapshot = SnapshotFrom<AuthActorRef>;
type AuthEvent = EventFromLogic<AuthActorLogic>;
type AuthSendEvent = (event: AuthEvent) => void;
type UseAuthActorReturn = [AuthSnapshot, AuthSendEvent, AuthActorRef];
type ContextState = O.Option<UseAuthActorReturn>;
const AuthActorContext = createContext<ContextState>(O.none());
type AuthActorProviderProps = {
  session?: Session | null | undefined;
  token?: string | null | undefined;
};
function AuthActorProvider(props: PropsWithChildren<AuthActorProviderProps>) {
  const {
    children,
    token,
    session
  } = props;
  const options: ActorOptions<AuthActorLogic> = useMemo(() => {
    return {
      input: {
        session: O.fromNullable(session),
        token: O.fromNullable(token)
      }
    };
  }, [session, token]);
  const [snapshot, send, actor] = useActor(authMachine,
  // @ts-expect-error DO NOT DELETE- your IDE may say this is not an error, but it will fail ts-check on our runner!
  options) as UseAuthActorReturn;
  const state: ContextState = useMemo(() => {
    // Since we are wrapping the result in an O.Option we want the O.Option to be stable
    return O.some([snapshot, send, actor]);
  }, [snapshot, send, actor]);
  // Keep the Zuzstand store in sync with this context
  setAuthActor(state);
  useState(() => {
    // Using `useState` ensures this is only run once
    actor.start();
  });
  return <AuthActorContext.Provider value={state} data-sentry-element="unknown" data-sentry-component="AuthActorProvider" data-sentry-source-file="auth-actor.context.tsx">
      {children}
    </AuthActorContext.Provider>;
}
function useAuthActor(): UseAuthActorReturn {
  return useContext(AuthActorContext).pipe(O.getOrThrow);
}
export { AuthActorContext, AuthActorProvider, useAuthActor };
export type { AuthEvent, AuthSendEvent, AuthSnapshot, UseAuthActorReturn };