/**
 * HTTP Method: POST
 * Pathname: /ownership
 * @function setOwnership
 * @memberof OwnershipAPI
 * @param {OwnershipAPISetOwnershipPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId, Percent, SerializesInto } from "@ender/shared/core";
import type { ModelType } from "@ender/shared/generated/com.ender.common.model";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type OwnershipAPISetOwnershipSearchParams = {
  token?: string | undefined;
};

type OwnershipAPISetOwnershipBodyParams = {
  fromId: EnderId;
  fromType: ModelType;
  ownershipPercent: SerializesInto<Percent>;
  toId: EnderId;
  toType: ModelType;
};

type OwnershipAPISetOwnershipPayload = OwnershipAPISetOwnershipSearchParams &
  OwnershipAPISetOwnershipBodyParams;

function setOwnershipUnsafeEffect(payload: OwnershipAPISetOwnershipPayload) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/ownership",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function setOwnership(
  payload: OwnershipAPISetOwnershipPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    setOwnershipUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { setOwnership };
export type {
  OwnershipAPISetOwnershipBodyParams,
  OwnershipAPISetOwnershipPayload,
  OwnershipAPISetOwnershipSearchParams,
};
