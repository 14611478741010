import { randNumber } from "@ngneat/falso";
import { Option as O, Predicate as P } from "effect";

import type { Serializable } from "../serializable";
import type { Percent } from "./percent.types";

class Percent$ implements Serializable {
  public static ZERO = new Percent$();

  public readonly value: number;

  public static random(): Percent$ {
    // eslint-disable-next-line no-use-before-define
    return randomPercent();
  }

  public static divide(numerator: number, denominator: number): Percent$ {
    return Percent$.of(numerator / denominator);
  }

  public static of(input?: string | number): Percent$ {
    return new Percent$(input);
  }

  public static parse(input?: string | number): O.Option<Percent$> {
    const percent = new Percent$(input);
    return isNaN(percent.value) ? O.none() : O.some(percent);
  }

  public constructor(input: string | number = "0%") {
    this.value = P.isString(input)
      ? Number(input.replace(/[^0-9.-]+/g, "")) / 100
      : input;
  }

  toJSON(): Percent {
    return this.toString() as Percent;
  }

  public toString(): string {
    return `${this.value * 100}%`;
  }

  public percentValue(fractionDigits = 2): number {
    return +(this.value * 100).toFixed(fractionDigits);
  }

  public toFixed(fractionDigits = 2): string {
    return `${this.percentValue(fractionDigits).toFixed(fractionDigits)}%`;
  }
}

/**
 * Generate a random Percent using @ngneat/falso randNumber().
 * @returns Randomly generated Percent.
 */
function randomPercent(): Percent$ {
  return Percent$.of(`${randNumber({ max: 100, min: 0 })}%`);
}

export { Percent$, randomPercent };
