import type { ObjectValues } from "@ender/shared/types/general";

// IMPORTANT: Keep Aligned with :root in apps/ender/root.css

const base = {
  GRAY_50: "#f9f8f8",
  GRAY_100: "#e9e9e9",
  GRAY_200: "#ccc",
  GRAY_300: "#b3b3b3",
  GRAY_400: "#999",
  GRAY_500: "#808080",
  GRAY_600: "#666",
  GRAY_700: "#4c4c4c",
  GRAY_800: "#333",
  GRAY_900: "#181818",
  GREEN_50: "#edfff2",
  GREEN_100: "#d4fade",
  GREEN_200: "#bbf6cb",
  GREEN_300: "#94debf",
  GREEN_400: "#6ac9a1",
  GREEN_500: "#3bac7d",
  GREEN_600: "#349e72",
  GREEN_700: "#22835b",
  GREEN_800: "#196445",
  GREEN_900: "#0f422d",
  PURPLE_50: "#f8f7ff",
  PURPLE_100: "#f3f0ff",
  PURPLE_200: "#e2dbff",
  PURPLE_300: "#d7cef8",
  PURPLE_400: "#7667c0",
  PURPLE_500: "#5843be",
  PURPLE_600: "#4227c1",
  PURPLE_700: "#2e179e",
  PURPLE_800: "#25137b",
  PURPLE_900: "#1a0a6b",
  RED_50: "#fff5f4",
  RED_100: "#fce3e1",
  RED_200: "#ffb5c1",
  RED_300: "#ff6982",
  RED_400: "#f64a57",
  RED_500: "#e6202f",
  RED_600: "#d91221",
  RED_700: "#c51421",
  RED_800: "#a7252f",
  RED_900: "#81121b",
  SLATE_50: "#f2f3ff",
  SLATE_100: "#e6e7f4",
  SLATE_200: "#d5d6e8",
  SLATE_300: "#c0c1da",
  SLATE_400: "#b7b8d2",
  SLATE_500: "#9b9cb9",
  SLATE_600: "#7e7f9b",
  SLATE_700: "#64657c",
  SLATE_800: "#3e3f55",
  SLATE_900: "#0e0e2c",
  YELLOW_50: "#fff8df",
  YELLOW_100: "#fef2c7",
  YELLOW_200: "#ffe8ac",
  YELLOW_300: "#ffdc98",
  YELLOW_400: "#ffcd6d",
  YELLOW_500: "#ffb21e",
  YELLOW_600: "#ea9f11",
  YELLOW_700: "#c3840b",
  YELLOW_800: "#a45d23",
  YELLOW_900: "#7c4415",
};

const EnderThemeColorEnum = {
  ...base,
  BLACK: "#000",
  DARK_BLUE: base.SLATE_900,
  GREEN: base.GREEN_500,
  MAGENTA: base.RED_300,
  PRIMARY: base.PURPLE_500,
  PRIMARY_50: base.PURPLE_50,
  PRIMARY_100: base.PURPLE_100,
  PRIMARY_200: base.PURPLE_200,
  PRIMARY_300: base.PURPLE_300,
  PRIMARY_400: base.PURPLE_400,
  PRIMARY_500: base.PURPLE_500,
  PRIMARY_600: base.PURPLE_600,
  PRIMARY_700: base.PURPLE_700,
  PRIMARY_800: base.PURPLE_800,
  PRIMARY_900: base.PURPLE_900,
  RED: base.RED_500,
  TEXT: base.SLATE_900,
  WHITE: "#fff",
  YELLOW: base.YELLOW_500,
} as const;

type EnderThemeColor = ObjectValues<typeof EnderThemeColorEnum>;

export { EnderThemeColorEnum };
export type { EnderThemeColor };
