import { deleteRole } from "./delete-role";
import { getFunctionalPermissions } from "./get-functional-permissions";
import { getPermissions } from "./get-permissions";
import { getPermissionsByModel } from "./get-permissions-by-model";
import { getRoles } from "./get-roles";
import { newRole } from "./new-role";
import { setUserRoles } from "./set-user-roles";
import { updateRole } from "./update-role";

const PermissionsAPI = {
  deleteRole,
  getFunctionalPermissions,
  getPermissions,
  getPermissionsByModel,
  getRoles,
  newRole,
  setUserRoles,
  updateRole,
};

export { PermissionsAPI };
