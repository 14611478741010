/**
 * HTTP Method: POST
 * Pathname: /vendors/{vendorId}/tags
 * @function addTag
 * @memberof VendorsAPI
 * @param {VendorsAPIAddTagPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type VendorsAPIAddTagPathParams = {
  vendorId: EnderId;
};

type VendorsAPIAddTagSearchParams = {
  token?: string | undefined;
};

type VendorsAPIAddTagBodyParams = {
  tag: string;
};

type VendorsAPIAddTagPayload = VendorsAPIAddTagPathParams &
  VendorsAPIAddTagSearchParams &
  VendorsAPIAddTagBodyParams;

function addTagUnsafeEffect(payload: VendorsAPIAddTagPayload) {
  const { vendorId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/vendors/${vendorId}/tags`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function addTag(
  payload: VendorsAPIAddTagPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    addTagUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { addTag };
export type {
  VendorsAPIAddTagBodyParams,
  VendorsAPIAddTagPathParams,
  VendorsAPIAddTagPayload,
  VendorsAPIAddTagSearchParams,
};
