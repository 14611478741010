import type { Effect, ManagedRuntime } from "effect";
import { Cause, Exit, Function as F, Option as O, Record as R } from "effect";
import { createStore } from "zustand/vanilla";

import type { RestService } from "@ender/shared/services/rest";
import { getDeferred } from "@ender/shared/utils/promise";
import { createBoundUseStoreWithSelectors } from "@ender/shared/utils/store";

if (
  globalThis.Headers &&
  globalThis.Headers.prototype &&
  !globalThis.Headers.prototype.getSetCookie
) {
  globalThis.Headers.prototype.getSetCookie = function getSetCookie(
    this: Headers,
  ): string[] {
    const setCookieHeaders: string[] = [];
    this.forEach((value, key) => {
      if (key.toLowerCase() === "set-cookie") {
        setCookieHeaders.push(value);
      }
    });
    return setCookieHeaders;
  };
}

type EffectRuntimeStoreState = {
  runtime: O.Option<ManagedRuntime.ManagedRuntime<RestService, never>>;
};

const effectRuntimeStore = createStore<EffectRuntimeStoreState>()(
  (): EffectRuntimeStoreState => {
    return {
      runtime: O.none(),
    };
  },
);
const useEffectRuntimeStore =
  createBoundUseStoreWithSelectors<EffectRuntimeStoreState>(effectRuntimeStore);

// Public API for the store
/*
 * This should only be done once per-env we want to use the runtime in.
 */
function setRuntime(
  runtime?: ManagedRuntime.ManagedRuntime<RestService, never>,
) {
  effectRuntimeStore.setState({
    ...effectRuntimeStore.getState(),
    runtime: O.fromNullable(runtime),
  });
}

function unwrapExit<Out, Err>(exit: Exit.Exit<Out, Err>): Out {
  return F.pipe(
    exit,
    Exit.getOrElse((cause: Cause.Cause<Err>) => {
      throw cause;
    }),
  );
}

function runPromise<Out, Err>(
  effect: Effect.Effect<Out, Err, RestService>,
): (options?: { signal?: AbortSignal }) => Promise<Out> {
  const runtime = F.pipe(
    effectRuntimeStore.getState(),
    R.get("runtime"),
    O.flatten,
    O.getOrThrow,
  );
  return (options) => {
    const { signal } = options ?? {};
    const deferred = getDeferred<Out>();
    const resolve = (arg: Out) => {
      if (signal && signal.aborted) {
        return;
      }
      deferred.resolve(arg);
    };
    const reject = (err: unknown) => {
      if (signal && signal.aborted) {
        return;
      }
      deferred.reject(err);
    };

    runtime
      .runPromiseExit(effect, options)
      .then(unwrapExit)
      .then(
        (result: Out) => {
          return resolve(result);
        },
        (maybeCause: unknown) => {
          if (Cause.isCause(maybeCause) && Cause.isFailType(maybeCause)) {
            return reject(maybeCause.error);
          }
          return reject(maybeCause);
        },
      );

    return deferred.promise;
  };
}

export { effectRuntimeStore, runPromise, setRuntime, useEffectRuntimeStore };
export type { EffectRuntimeStoreState };
