import dayjs from "dayjs";
import {
  Function as F,
  Option as O,
  Predicate as P,
  String as Str,
} from "effect";
import type {
  DoneActorEvent,
  ErrorActorEvent,
  PromiseActorLogic,
} from "xstate";
import { fromPromise } from "xstate";

import type { Instant } from "@ender/shared/core";
import type { AuthAPIGetSessionInfoPayload } from "@ender/shared/generated/ender.api.misc";
import type { EnderSessionResponse } from "@ender/shared/generated/ender.api.misc.response";

import type {
  AuthContext,
  AuthEvent,
  AuthEventMap,
  AuthEvents,
  LoginPayload,
  Session,
} from "./auth.types";
import { AuthEventEnum } from "./auth.types";

function toPayload<T extends AuthEvent>(expectedType: T) {
  function isExpectedEvent(ev: AuthEvents): ev is AuthEventMap[T] {
    return ev.type === expectedType;
  }

  return function fromEvent({
    event,
  }: {
    event: AuthEvents;
  }): AuthEventMap[T]["payload"] {
    if (!isExpectedEvent(event)) {
      throw new Error(
        `Invalid, expected "${expectedType}" event received "${event.type}".`,
      );
    }
    return event.payload;
  };
}

function toSession(session: O.Option<EnderSessionResponse>): O.Option<Session> {
  return O.map(
    session,
    ({ token, ...other }): Session => ({
      requiresMultiFactorAuth: F.pipe(
        token,
        O.fromNullable,
        O.map(Str.isEmpty),
        O.getOrElse(F.constTrue),
      ),
      ...other,
    }),
  );
}

const fromContext = {
  getSession({ context }: { context: AuthContext }): O.Option<Session> {
    return context.session;
  },
  toGetSessionInfoPayload({
    context,
  }: {
    context: AuthContext;
  }): AuthAPIGetSessionInfoPayload {
    return F.pipe(
      context.token,
      O.map((token) => ({ token })),
      O.getOrElse(() => ({})),
    );
  },
};
const fromEvent = {
  getLoginPayload: toPayload(AuthEventEnum.SET_LOGIN_PAYLOAD),
  toLoginPayload(args: {
    context: AuthContext;
    event: AuthEvents;
  }): LoginPayload {
    return F.pipe(
      args,
      toPayload(AuthEventEnum.LOGIN),
      (payload: LoginPayload) => ({
        initialLogin: F.pipe(args.context.session, O.isNone),
        ...payload,
      }),
    );
  },
  toSession(args: { event: AuthEvents }): O.Option<Session> {
    return F.pipe(args, toPayload(AuthEventEnum.SET_SESSION), toSession);
  },
};
const fromDoneEvent = {
  getSession({
    event,
  }: {
    event: DoneActorEvent<O.Option<Session>>;
  }): O.Option<Session> {
    return event.output;
  },
  toSession({
    event,
  }: {
    event: DoneActorEvent<EnderSessionResponse | undefined>;
  }): O.Option<Session> {
    return F.pipe(O.fromNullable(event.output), toSession);
  },
};
const fromErrorEvent = {
  toError({ event }: { event: ErrorActorEvent }): O.Option<unknown> {
    return O.some(event.error);
  },
};

function hasExpired(expiration: Instant | undefined): boolean {
  return (
    P.isNotNullable(expiration) && dayjs().isAfter(dayjs(expiration), "minute")
  );
}

function fromRestApi<Payload, Response>(
  restApi: (
    payload: Payload,
    options?: { signal?: AbortSignal },
  ) => Promise<Response>,
): PromiseActorLogic<Response, Payload> {
  return fromPromise<Response, Payload>(({ input: payload, signal }) =>
    restApi(payload, { signal }),
  );
}

export {
  fromContext,
  fromDoneEvent,
  fromErrorEvent,
  fromEvent,
  fromRestApi,
  hasExpired,
};
