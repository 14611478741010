/**
 * HTTP Method: GET
 * Pathname: /vendors/{vendorId}/users
 * @function getVendorUsers
 * @memberof VendorsAPI
 * @param {VendorsAPIGetVendorUsersPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<GetVendorUserResponse[]>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { GetVendorUserResponse } from "@ender/shared/generated/ender.api.misc.response";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type VendorsAPIGetVendorUsersPathParams = {
  vendorId: EnderId;
};

type VendorsAPIGetVendorUsersSearchParams = {
  token?: string | undefined;
};

type VendorsAPIGetVendorUsersPayload = VendorsAPIGetVendorUsersPathParams &
  VendorsAPIGetVendorUsersSearchParams;

function getVendorUsersUnsafeEffect(payload: VendorsAPIGetVendorUsersPayload) {
  const { vendorId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, GetVendorUserResponse[]>({
        body,
        decode: unsafeDecodeJsonResponse<GetVendorUserResponse[]>({}),
        encode: encodeNoBody({ method: "GET" }),
        pathname: `/vendors/${vendorId}/users`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getVendorUsers(
  payload: VendorsAPIGetVendorUsersPayload,
  options?: { signal?: AbortSignal },
): Promise<GetVendorUserResponse[]> {
  return F.pipe(
    payload,
    getVendorUsersUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getVendorUsers };
export type {
  VendorsAPIGetVendorUsersPathParams,
  VendorsAPIGetVendorUsersPayload,
  VendorsAPIGetVendorUsersSearchParams,
};
