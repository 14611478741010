/**
 * HTTP Method: POST
 * Pathname: /firms/{firmId}/users
 * @function getUsersForFirm
 * @memberof UsersAPI
 * @param {UsersAPIGetUsersForFirmPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<User[]>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { User } from "@ender/shared/generated/ender.model.core.user";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type UsersAPIGetUsersForFirmPathParams = {
  firmId: EnderId;
};

type UsersAPIGetUsersForFirmSearchParams = {
  token?: string | undefined;
};

type UsersAPIGetUsersForFirmPayload = UsersAPIGetUsersForFirmPathParams &
  UsersAPIGetUsersForFirmSearchParams;

function getUsersForFirmUnsafeEffect(payload: UsersAPIGetUsersForFirmPayload) {
  const { firmId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, User[]>({
        body,
        decode: unsafeDecodeJsonResponse<User[]>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/firms/${firmId}/users`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getUsersForFirm(
  payload: UsersAPIGetUsersForFirmPayload,
  options?: { signal?: AbortSignal },
): Promise<User[]> {
  return F.pipe(
    payload,
    getUsersForFirmUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getUsersForFirm };
export type {
  UsersAPIGetUsersForFirmPathParams,
  UsersAPIGetUsersForFirmPayload,
  UsersAPIGetUsersForFirmSearchParams,
};
