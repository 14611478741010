import { ParseResult, Schema as Sch } from "@effect/schema";
import { Function as F, Match } from "effect";

import { convertToNumber } from "@ender/shared/utils/string";

import { CENTS_PER_DOLLAR, isMoney, makeFromCents } from "./money";

/**
 * ## learning:
 * using a Schema Class, e.g.
 * ```ts
 * class MoneyClass extends Sch.Class<Money>("Money")({
 *   valueInCents: Sch.Number,
 * }) {}
 * ```
 * Sch.transformOrFail(MoneyClass, ...)
 *
 * instead of using this `declare` syntax,
 * while providing the correct typing, introduced an undesirable side-effect whereby the excess properties of the object
 * were stripped away- only those declared explicitly in the `MoneyClass` are passed along, which means that even fields
 * such as `toJSON` are lost. The workaround for this is _either_ to use an arbitrary type-guard `declare` as done here,
 *
 * or to adjust the parse function so that the `excess properties` are not removed from the resulting object.
 * ```ts
 * {
 *   onExcessProperty: "preserve",
 * }
 * ```
 */

const Money$ = Sch.declare(isMoney);

const Schema = Sch.Union(Sch.String, Sch.Number, Money$).pipe(
  Sch.transformOrFail(Money$, {
    decode: (v, _, ast) => {
      const centsVal = Match.value(v).pipe(
        // when a string is provided, assume it is provided in dollars. Multiply by CENTS_PER_DOLLAR to convert to cents
        Match.when(
          Match.string,
          F.flow(convertToNumber, (v) => v * CENTS_PER_DOLLAR),
        ),
        // when a number is provided, assume it is already provided in cents
        Match.when(Match.number, (v) => v),
        Match.when(isMoney, (v) => v.valueInCents),
        Match.orElse(() => NaN),
      );

      if (Number.isNaN(centsVal)) {
        return ParseResult.fail(
          new ParseResult.Transformation(
            ast,
            v,
            "Transformation",
            new ParseResult.Type(
              ast,
              v,
              "Provided value was not parseable to Money",
            ),
          ),
        );
      }
      return ParseResult.succeed(makeFromCents(Math.round(centsVal)));
    },
    encode: (money, _, ast) =>
      ParseResult.fail(
        new ParseResult.Forbidden(
          ast,
          money,
          "Encoding Money back to parsable input is forbidden",
        ),
      ),
  }),
);
export { Schema };
