/**
 * HTTP Method: POST
 * Pathname: /pms/{pmId}
 * @function updatePM
 * @memberof EnderAPI
 * @param {EnderAPIUpdatePMPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId, Money, SerializesInto } from "@ender/shared/core";
import type { UserUserTimezone } from "@ender/shared/generated/ender.model.core.user";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type EnderAPIUpdatePMPathParams = {
  pmId: EnderId;
};

type EnderAPIUpdatePMSearchParams = {
  token?: string | undefined;
};

type EnderAPIUpdatePMBodyParams = {
  applicationGroupFollowUpDays?: number | undefined;
  applicationTerms?: string | undefined;
  defaultMonthToMonthPremiumAmount?: SerializesInto<Money> | undefined;
  defaultTaskBudget?: SerializesInto<Money> | undefined;
  lateFee?: SerializesInto<Money> | undefined;
  leasingPhone?: string | undefined;
  maintenanceEmergencyPhone?: string | undefined;
  moveInNotificationOffsetHours?: number | undefined;
  moveOutNotificationOffsetHours?: number | undefined;
  noReplyEmail?: string | undefined;
  numDaysPaymentEnabledAfterMoveOut?: number | undefined;
  numGraceDays?: number | undefined;
  phone?: string | undefined;
  prospectFollowUpDays?: number | undefined;
  subdomain?: string | undefined;
  timezone?: UserUserTimezone | undefined;
  websiteUrl?: string | undefined;
};

type EnderAPIUpdatePMPayload = EnderAPIUpdatePMPathParams &
  EnderAPIUpdatePMSearchParams &
  EnderAPIUpdatePMBodyParams;

function updatePMUnsafeEffect(payload: EnderAPIUpdatePMPayload) {
  const { pmId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/pms/${pmId}`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function updatePM(
  payload: EnderAPIUpdatePMPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    updatePMUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { updatePM };
export type {
  EnderAPIUpdatePMBodyParams,
  EnderAPIUpdatePMPathParams,
  EnderAPIUpdatePMPayload,
  EnderAPIUpdatePMSearchParams,
};
