import { Schema } from "@effect/schema";
import { randAmount } from "@ngneat/falso";

import { Schema as MoneySchema } from "./internal/schema";
import type { Money } from "./money";
import { makeFromCents, of } from "./money";

/**
 * This file is for utilities that operate on / depend on LocalTime$.
 * None of these utilities should be accessed from upstream, i.e. within the LocalTime$ class-
 * if so, they will cause circular dependencies.
 */

const MoneyFormSchema = Schema.typeSchema(MoneySchema).annotations({
  arbitrary: () => (fc) =>
    fc.integer({ max: 10000_00, min: 50 }).map(makeFromCents),
});

/**
 * Generate a random Money using @ngneat/falso randAmount().
 * @returns Randomly generated Money.
 */
function randomMoney(): Money {
  return of(randAmount({ fraction: 2, symbol: "$" }));
}

export { MoneyFormSchema, randomMoney };
