/**
 * HTTP Method: POST
 * Pathname: /videos/{videoId}
 * @function updateVideo
 * @memberof VideosAPI
 * @param {VideosAPIUpdateVideoPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type VideosAPIUpdateVideoPathParams = {
  videoId: EnderId;
};

type VideosAPIUpdateVideoSearchParams = {
  token?: string | undefined;
};

type VideosAPIUpdateVideoBodyParams = {
  comment?: string | undefined;
};

type VideosAPIUpdateVideoPayload = VideosAPIUpdateVideoPathParams &
  VideosAPIUpdateVideoSearchParams &
  VideosAPIUpdateVideoBodyParams;

function updateVideoUnsafeEffect(payload: VideosAPIUpdateVideoPayload) {
  const { videoId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/videos/${videoId}`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function updateVideo(
  payload: VideosAPIUpdateVideoPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    updateVideoUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { updateVideo };
export type {
  VideosAPIUpdateVideoBodyParams,
  VideosAPIUpdateVideoPathParams,
  VideosAPIUpdateVideoPayload,
  VideosAPIUpdateVideoSearchParams,
};
