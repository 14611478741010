"use client";

import type { Option as O } from "effect";
import { useSearchParams } from "next/navigation";
import { useCallback } from "react";
import type { LoginPayload } from "../machine/auth.types";
import type { LoginFormOutput } from "./login-form.utils";
import { useLoginForm } from "./login-form.utils";
import { LoginFormView } from "./login-form.view";
import { getOrEmpty } from "./utils";
type LoginFormControllerProps = {
  errorMessage: O.Option<string>;
  loginPayload: O.Option<LoginPayload>;
  isFetching: boolean;
  onForgotPassword?: () => void;
  onLogin: (payload: LoginPayload) => void;
};
function LoginFormController(props: LoginFormControllerProps) {
  const {
    errorMessage,
    loginPayload,
    isFetching,
    onForgotPassword,
    onLogin
  } = props;
  const searchParams = useSearchParams();
  const form = useLoginForm({
    initialValues: () => ({
      email: getOrEmpty(loginPayload, payload => payload.email),
      password: getOrEmpty(loginPayload, payload => payload.password)
    })
  });
  const force2Fa = searchParams.has("force2Fa");
  const handleFormSubmit = useCallback((loginFormValues: LoginFormOutput) => {
    onLogin({
      force2Fa,
      ...loginFormValues
    });
  }, [force2Fa, onLogin]);
  return <LoginFormView errorMessage={errorMessage} form={form} isFetching={isFetching} onForgotPasswordClick={onForgotPassword} onFormSubmit={handleFormSubmit} data-sentry-element="LoginFormView" data-sentry-component="LoginFormController" data-sentry-source-file="login-form.controller.tsx" />;
}
export { LoginFormController };