/**
 * HTTP Method: POST
 * Pathname: /admin/sms/send
 * @function adminSendSms
 * @memberof AdminAPI
 * @param {AdminAPIAdminSendSmsPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type AdminAPIAdminSendSmsSearchParams = {
  token?: string | undefined;
};

type AdminAPIAdminSendSmsBodyParams = {
  body: string;
  from: string;
  to: string;
};

type AdminAPIAdminSendSmsPayload = AdminAPIAdminSendSmsSearchParams &
  AdminAPIAdminSendSmsBodyParams;

function adminSendSmsUnsafeEffect(payload: AdminAPIAdminSendSmsPayload) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/admin/sms/send",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function adminSendSms(
  payload: AdminAPIAdminSendSmsPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    adminSendSmsUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { adminSendSms };
export type {
  AdminAPIAdminSendSmsBodyParams,
  AdminAPIAdminSendSmsPayload,
  AdminAPIAdminSendSmsSearchParams,
};
