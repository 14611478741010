/**
 * HTTP Method: POST
 * Pathname: /tasks/{taskId}/rejectInvoice
 * @function rejectInvoice
 * @memberof TasksAPI
 * @param {TasksAPIRejectInvoicePayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type TasksAPIRejectInvoicePathParams = {
  taskId: EnderId;
};

type TasksAPIRejectInvoiceSearchParams = {
  token?: string | undefined;
};

type TasksAPIRejectInvoiceBodyParams = {
  reason: string;
};

type TasksAPIRejectInvoicePayload = TasksAPIRejectInvoicePathParams &
  TasksAPIRejectInvoiceSearchParams &
  TasksAPIRejectInvoiceBodyParams;

function rejectInvoiceUnsafeEffect(payload: TasksAPIRejectInvoicePayload) {
  const { taskId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/tasks/${taskId}/rejectInvoice`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function rejectInvoice(
  payload: TasksAPIRejectInvoicePayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    rejectInvoiceUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { rejectInvoice };
export type {
  TasksAPIRejectInvoiceBodyParams,
  TasksAPIRejectInvoicePathParams,
  TasksAPIRejectInvoicePayload,
  TasksAPIRejectInvoiceSearchParams,
};
