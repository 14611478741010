/**
 * HTTP Method: POST
 * Pathname: /createApprovalProcess
 * @function createApprovalProcess
 * @memberof ApprovalsAPI
 * @param {ApprovalsAPICreateApprovalProcessPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<ApprovalProcess>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId, Money, SerializesInto } from "@ender/shared/core";
import type {
  ApprovalProcess,
  ApprovalProcessType,
} from "@ender/shared/generated/ender.model.approvals";
import type { InvoicePayableType } from "@ender/shared/generated/ender.model.payments.invoice";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type ApprovalsAPICreateApprovalProcessSearchParams = {
  token?: string | undefined;
};

type ApprovalsAPICreateApprovalProcessBodyParams = {
  firmId?: EnderId | undefined;
  maxAmount?: SerializesInto<Money> | undefined;
  minAmount?: SerializesInto<Money> | undefined;
  ownershipGroupId?: EnderId | undefined;
  payableTypes: InvoicePayableType[];
  trailingPeriodNumDays?: number | undefined;
  trailingPeriodThreshold?: SerializesInto<Money> | undefined;
  type: ApprovalProcessType;
};

type ApprovalsAPICreateApprovalProcessPayload =
  ApprovalsAPICreateApprovalProcessSearchParams &
    ApprovalsAPICreateApprovalProcessBodyParams;

function createApprovalProcessUnsafeEffect(
  payload: ApprovalsAPICreateApprovalProcessPayload,
) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, ApprovalProcess>({
        body,
        decode: unsafeDecodeJsonResponse<ApprovalProcess>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/createApprovalProcess",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function createApprovalProcess(
  payload: ApprovalsAPICreateApprovalProcessPayload,
  options?: { signal?: AbortSignal },
): Promise<ApprovalProcess> {
  return F.pipe(
    payload,
    createApprovalProcessUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { createApprovalProcess };
export type {
  ApprovalsAPICreateApprovalProcessBodyParams,
  ApprovalsAPICreateApprovalProcessPayload,
  ApprovalsAPICreateApprovalProcessSearchParams,
};
