/**
 * HTTP Method: POST
 * Pathname: /units/{unitId}
 * @function updateUnit
 * @memberof UnitsAPI
 * @param {UnitsAPIUpdateUnitPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId, Money, SerializesInto } from "@ender/shared/core";
import type {
  UnitCoolingSystem,
  UnitHeatingSystem,
  UnitLaundryType,
  UnitParkingType,
} from "@ender/shared/generated/ender.model.core.unit";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type UnitsAPIUpdateUnitPathParams = {
  unitId: EnderId;
};

type UnitsAPIUpdateUnitSearchParams = {
  token?: string | undefined;
};

type UnitsAPIUpdateUnitBodyParams = {
  bedrooms?: number | undefined;
  coolingSystem?: UnitCoolingSystem | undefined;
  floorNum?: number | undefined;
  fullBaths?: number | undefined;
  halfBaths?: number | undefined;
  heatingSystem?: UnitHeatingSystem | undefined;
  heightFeet?: number | undefined;
  isAdverselyOccupied?: boolean | undefined;
  isHeldForSale?: boolean | undefined;
  isMarketingDown?: boolean | undefined;
  isRenovating?: boolean | undefined;
  isSold?: boolean | undefined;
  isSpecialDamageRenovation?: boolean | undefined;
  laundryType?: UnitLaundryType | undefined;
  lengthFeet?: number | undefined;
  marketRent?: SerializesInto<Money> | undefined;
  marketingCertificateAccepted?: boolean | undefined;
  name?: string | undefined;
  parkingType?: UnitParkingType | undefined;
  pendingMarketingCertificate?: boolean | undefined;
  rotation?: number | undefined;
  sqft?: number | undefined;
  storageGroup?: string | undefined;
  unitTypeId?: EnderId | undefined;
  videoUrl?: string | undefined;
  visualDisplayLeftFeet?: number | undefined;
  visualDisplayTopFeet?: number | undefined;
  widthFeet?: number | undefined;
};

type UnitsAPIUpdateUnitPayload = UnitsAPIUpdateUnitPathParams &
  UnitsAPIUpdateUnitSearchParams &
  UnitsAPIUpdateUnitBodyParams;

function updateUnitUnsafeEffect(payload: UnitsAPIUpdateUnitPayload) {
  const { unitId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/units/${unitId}`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function updateUnit(
  payload: UnitsAPIUpdateUnitPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    updateUnitUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { updateUnit };
export type {
  UnitsAPIUpdateUnitBodyParams,
  UnitsAPIUpdateUnitPathParams,
  UnitsAPIUpdateUnitPayload,
  UnitsAPIUpdateUnitSearchParams,
};
