/**
 * HTTP Method: GET
 * Pathname: /admin/sqlReports
 * @function getAllSqlReports
 * @memberof AdminAPI
 * @param {AdminAPIGetAllSqlReportsPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<SQLReport[]>}
 */
import { Effect, Function as F } from "effect";

import type { SQLReport } from "@ender/shared/generated/ender.model.reports";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type AdminAPIGetAllSqlReportsSearchParams = {
  token?: string | undefined;
};

type AdminAPIGetAllSqlReportsPayload = AdminAPIGetAllSqlReportsSearchParams;

function getAllSqlReportsUnsafeEffect(
  payload: AdminAPIGetAllSqlReportsPayload,
) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, SQLReport[]>({
        body,
        decode: unsafeDecodeJsonResponse<SQLReport[]>({}),
        encode: encodeNoBody({ method: "GET" }),
        pathname: "/admin/sqlReports",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getAllSqlReports(
  payload: AdminAPIGetAllSqlReportsPayload,
  options?: { signal?: AbortSignal },
): Promise<SQLReport[]> {
  return F.pipe(
    payload,
    getAllSqlReportsUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getAllSqlReports };
export type {
  AdminAPIGetAllSqlReportsPayload,
  AdminAPIGetAllSqlReportsSearchParams,
};
