"use client";

import { useMutation } from "@tanstack/react-query";
import { Function as F, Option as O } from "effect";
import { useCallback, useMemo } from "react";
import { AuthAPI } from "@ender/shared/generated/ender.api.misc";
import type { ForgotPasswordFormOutput } from "./forgot-password-form.utils";
import { useForgotPasswordForm } from "./forgot-password-form.utils";
import { ForgotPasswordFormView } from "./forgot-password-form.view";
type ForgotPasswordFormControllerProps = {
  onDone: () => void;
  onSignIn: () => void;
};
function ForgotPasswordFormController(props: ForgotPasswordFormControllerProps) {
  const {
    onDone,
    onSignIn
  } = props;
  const {
    mutateAsync: forgotPasswordAsync,
    error,
    isLoading
  } = useMutation({
    mutationFn: AuthAPI.changePassword,
    mutationKey: ["changePassword"]
  });
  const errorMessage: O.Option<string> = useMemo(() => {
    return F.pipe(error, O.fromNullable, O.map(err => `${err}`));
  }, [error]);
  const form = useForgotPasswordForm();
  const handleFormSubmit = useCallback(({
    email
  }: ForgotPasswordFormOutput) => {
    forgotPasswordAsync({
      email
    }).then(onDone);
  }, [forgotPasswordAsync, onDone]);
  return <ForgotPasswordFormView errorMessage={errorMessage} form={form} isFetching={isLoading} onFormSubmit={handleFormSubmit} onSignInClick={onSignIn} data-sentry-element="ForgotPasswordFormView" data-sentry-component="ForgotPasswordFormController" data-sentry-source-file="forgot-password-form.controller.tsx" />;
}
export { ForgotPasswordFormController };