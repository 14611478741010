import { Schema } from "@effect/schema";

const LocalDateBrandSchema = Schema.String.pipe(Schema.brand("LocalDate"));

/**
 * A string, formatted as YYYY-MM-DD
 */
type LocalDate = Schema.Schema.Type<typeof LocalDateBrandSchema>;

export { LocalDateBrandSchema };
export type { LocalDate };
