import { Schema } from "@effect/schema";

import type { ObjectValues } from "@ender/shared/types/general";

type NumberGrouping = {
  hundreds?: string;
  teens?: string;
  hyphenatedTens?: string;
  tens?: string | null;
  ones?: string;
  grouping: string | null;
};

const CURRENCY_FORMATS = {
  DEFAULT: "DEFAULT",
  DOLLARS: "DOLLARS",
  WORDS: "WORDS",
} as const;

type MoneyFormats = ObjectValues<typeof CURRENCY_FORMATS>;

const SignFormat = {
  minus: " MINUS",
  parenthesis: "PARENTHESIS",
} as const;
const SignFormatSchema = Schema.Enums(SignFormat);
type SignFormats = Schema.Schema.Type<typeof SignFormatSchema>;

export { CURRENCY_FORMATS, SignFormat, SignFormatSchema };
export type { MoneyFormats, NumberGrouping, SignFormats };
