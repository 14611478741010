export {
  EnvironmentEnum,
  environmentStore,
  initializeEnvironmentStore,
  setEnvironment,
  useEnvironmentStore,
} from "./lib/shared-stores-environment-store";
export type {
  Environment,
  EnvironmentStoreState,
} from "./lib/shared-stores-environment-store";
