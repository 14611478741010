import { Option as O } from "effect";
import type {
  EventFromLogic,
  SnapshotFrom,
} from "xstate/dist/declarations/src/types";
import { createStore } from "zustand/vanilla";

import type { AuthActorLogic, AuthActorRef } from "../machine/auth.machine";

type AuthSnapshot = SnapshotFrom<AuthActorRef>;
type AuthEvent = EventFromLogic<AuthActorLogic>;
type AuthSendEvent = (event: AuthEvent) => void;

type UseAuthActorReturn = [AuthSnapshot, AuthSendEvent, AuthActorRef];

type AuthActorStoreState = {
  actor: O.Option<UseAuthActorReturn>;
};
const authActorStore = createStore<AuthActorStoreState>()(
  (): AuthActorStoreState => ({
    actor: O.none(),
  }),
);

function setAuthActor(actor: O.Option<UseAuthActorReturn>) {
  authActorStore.setState({ actor });
}

function subscribeWithCurrentState(
  callback: (state: AuthActorStoreState) => void,
) {
  let ignore = false;
  let wrapper = (state: AuthActorStoreState) => {
    ignore = true;
    // This optimization ensures the wrapper is only ever called once
    wrapper = callback;
    callback(state);
  };

  // We need to preserve the semantics of the subscription so we delay the call to the function
  setTimeout(() => {
    if (ignore) {
      return;
    }
    wrapper(authActorStore.getState());
  });
  return authActorStore.subscribe((state: AuthActorStoreState) =>
    wrapper(state),
  );
}

export { authActorStore, setAuthActor, subscribeWithCurrentState };
export type { AuthActorStoreState };
