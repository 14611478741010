/**
 * HTTP Method: PATCH
 * Pathname: /vendors/{vendorId}/w9
 * @function updateW9
 * @memberof VendorsAPI
 * @param {VendorsAPIUpdateW9Payload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type VendorsAPIUpdateW9PathParams = {
  vendorId: EnderId;
};

type VendorsAPIUpdateW9SearchParams = {
  token?: string | undefined;
};

type VendorsAPIUpdateW9BodyParams = {
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  json: any;
};

type VendorsAPIUpdateW9Payload = VendorsAPIUpdateW9PathParams &
  VendorsAPIUpdateW9SearchParams &
  VendorsAPIUpdateW9BodyParams;

function updateW9UnsafeEffect(payload: VendorsAPIUpdateW9Payload) {
  const { vendorId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "PATCH" }),
        pathname: `/vendors/${vendorId}/w9`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function updateW9(
  payload: VendorsAPIUpdateW9Payload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    updateW9UnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { updateW9 };
export type {
  VendorsAPIUpdateW9BodyParams,
  VendorsAPIUpdateW9PathParams,
  VendorsAPIUpdateW9Payload,
  VendorsAPIUpdateW9SearchParams,
};
