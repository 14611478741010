/**
 * HTTP Method: DELETE
 * Pathname: /tasks/{taskId}/tags
 * @function removeTaskTag
 * @memberof TasksAPI
 * @param {TasksAPIRemoveTaskTagPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type TasksAPIRemoveTaskTagPathParams = {
  taskId: EnderId;
};

type TasksAPIRemoveTaskTagSearchParams = {
  token?: string | undefined;
};

type TasksAPIRemoveTaskTagBodyParams = {
  tag: string;
};

type TasksAPIRemoveTaskTagPayload = TasksAPIRemoveTaskTagPathParams &
  TasksAPIRemoveTaskTagSearchParams &
  TasksAPIRemoveTaskTagBodyParams;

function removeTaskTagUnsafeEffect(payload: TasksAPIRemoveTaskTagPayload) {
  const { taskId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "DELETE" }),
        pathname: `/tasks/${taskId}/tags`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function removeTaskTag(
  payload: TasksAPIRemoveTaskTagPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    removeTaskTagUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { removeTaskTag };
export type {
  TasksAPIRemoveTaskTagBodyParams,
  TasksAPIRemoveTaskTagPathParams,
  TasksAPIRemoveTaskTagPayload,
  TasksAPIRemoveTaskTagSearchParams,
};
