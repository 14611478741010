/**
 * HTTP Method: GET
 * Pathname: /model-explorer/firms/{firmId}
 * @function getFirm
 * @memberof ModelExplorerAPI
 * @param {ModelExplorerAPIGetFirmPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<ModelExplorerAPIGetFirmResponse>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

import type { ModelExplorerAPIGetFirmResponse } from "../model-explorer-api-get-firm-response";

type ModelExplorerAPIGetFirmPathParams = {
  firmId: EnderId;
};

type ModelExplorerAPIGetFirmSearchParams = {
  token?: string | undefined;
};

type ModelExplorerAPIGetFirmPayload = ModelExplorerAPIGetFirmPathParams &
  ModelExplorerAPIGetFirmSearchParams;

function getFirmUnsafeEffect(payload: ModelExplorerAPIGetFirmPayload) {
  const { firmId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, ModelExplorerAPIGetFirmResponse>({
        body,
        decode: unsafeDecodeJsonResponse<ModelExplorerAPIGetFirmResponse>({}),
        encode: encodeNoBody({ method: "GET" }),
        pathname: `/model-explorer/firms/${firmId}`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getFirm(
  payload: ModelExplorerAPIGetFirmPayload,
  options?: { signal?: AbortSignal },
): Promise<ModelExplorerAPIGetFirmResponse> {
  return F.pipe(
    payload,
    getFirmUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getFirm };
export type {
  ModelExplorerAPIGetFirmPathParams,
  ModelExplorerAPIGetFirmPayload,
  ModelExplorerAPIGetFirmSearchParams,
};
