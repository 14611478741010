import { useCallback, useMemo, useState } from "react";
type UseBooleanHandlers = {
  setTrue: () => void;
  setFalse: () => void;
  toggle: (val?: boolean) => void;
};
type UseBooleanReturn = readonly [boolean, UseBooleanHandlers];

/**
 * A custom React hook that provides a boolean value and functions to update it.
 *
 * @param {boolean} [startingValue=false] - The initial value of the boolean.
 * @returns {UseBooleanReturn} An array containing the boolean value and an object with functions to update it.
 */
function useBoolean(startingValue = false): UseBooleanReturn {
  const [value, setValue] = useState(startingValue);

  /**
   * Sets the boolean value to true.
   *
   * @type {function}
   */
  const setTrue = useCallback(() => setValue(true), []);

  /**
   * Sets the boolean value to false.
   *
   * @type {function}
   */
  const setFalse = useCallback(() => setValue(false), []);

  /**
   * Toggles the boolean value.
   *
   * @type {function}
   */
  const toggle = useCallback((inVal?: boolean) => setValue(v => inVal ?? !v), []);

  /**
   * An object containing the functions to update the boolean value.
   *
   * @type {object}
   * @property {function} setTrue - Sets the boolean value to true.
   * @property {function} setFalse - Sets the boolean value to false.
   * @property {function} toggle - Toggles the boolean value.
   */
  const handlers = useMemo<UseBooleanHandlers>(() => ({
    setFalse,
    setTrue,
    toggle
  }), [setTrue, setFalse, toggle]);

  /**
   * An array containing the boolean value and the object with functions to update it.
   *
   * @type {UseBooleanReturn}
   */
  return [value, handlers];
}
export { useBoolean };
export type { UseBooleanHandlers, UseBooleanReturn };