import { agreeToTerms } from "./agree-to-terms";
import { archive } from "./archive";
import { changePMSettings } from "./change-p-m-settings";
import { getAddressInformationForPM } from "./get-address-information-for-p-m";
import { getAuditTrailData } from "./get-audit-trail-data";
import { getAuditTrailModels } from "./get-audit-trail-models";
import { getChatInfo } from "./get-chat-info";
import { getEnumValues } from "./get-enum-values";
import { getInboxItems } from "./get-inbox-items";
import { getInboxUnreadThreadCount } from "./get-inbox-unread-thread-count";
import { getModelSettings } from "./get-model-settings";
import { getMyPM } from "./get-my-p-m";
import { getPM } from "./get-p-m";
import { getPMAddress } from "./get-p-m-address";
import { getPMIdForSubdomain } from "./get-p-m-id-for-subdomain";
import { getPMSettings } from "./get-p-m-settings";
import { getSystemStatus } from "./get-system-status";
import { getWhiteLabelConfig } from "./get-white-label-config";
import { updateModelSettings } from "./update-model-settings";
import { updatePM } from "./update-p-m";
import { updateRequireFullPaymentSettings } from "./update-require-full-payment-settings";
import { updateWhiteLabelConfig } from "./update-white-label-config";

const EnderAPI = {
  agreeToTerms,
  archive,
  changePMSettings,
  getAddressInformationForPM,
  getAuditTrailData,
  getAuditTrailModels,
  getChatInfo,
  getEnumValues,
  getInboxItems,
  getInboxUnreadThreadCount,
  getModelSettings,
  getMyPM,
  getPM,
  getPMAddress,
  getPMIdForSubdomain,
  getPMSettings,
  getSystemStatus,
  getWhiteLabelConfig,
  updateModelSettings,
  updatePM,
  updateRequireFullPaymentSettings,
  updateWhiteLabelConfig,
};

export { EnderAPI };
