import { capitalize } from "./shared-utils-string";

type FromDashCaseToCapitalSpaceCase<T extends string> =
  T extends `${infer First}-${infer Rest}`
    ? `${Capitalize<First>} ${FromDashCaseToCapitalSpaceCase<Rest>}`
    : Capitalize<T>;

function fromDashCaseToCapitalSpaceCase<T extends string>(
  str: T,
): FromDashCaseToCapitalSpaceCase<T> {
  return str
    .split("-")
    .map((word) => capitalize(word))
    .join(" ") as FromDashCaseToCapitalSpaceCase<T>;
}

export { fromDashCaseToCapitalSpaceCase };
export type { FromDashCaseToCapitalSpaceCase };
