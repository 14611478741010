/**
 * HTTP Method: POST
 * Pathname: /admin/sqlReports/{reportId}
 * @function updateSqlReport
 * @memberof AdminAPI
 * @param {AdminAPIUpdateSqlReportPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<SQLReport>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { SQLReport } from "@ender/shared/generated/ender.model.reports";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type AdminAPIUpdateSqlReportPathParams = {
  reportId: EnderId;
};

type AdminAPIUpdateSqlReportSearchParams = {
  token?: string | undefined;
};

type AdminAPIUpdateSqlReportBodyParams = {
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  columnOutputs: any[];
  description?: string | undefined;
  name: string;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  parameters: any[];
  sql: string;
};

type AdminAPIUpdateSqlReportPayload = AdminAPIUpdateSqlReportPathParams &
  AdminAPIUpdateSqlReportSearchParams &
  AdminAPIUpdateSqlReportBodyParams;

function updateSqlReportUnsafeEffect(payload: AdminAPIUpdateSqlReportPayload) {
  const { reportId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, SQLReport>({
        body,
        decode: unsafeDecodeJsonResponse<SQLReport>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/admin/sqlReports/${reportId}`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function updateSqlReport(
  payload: AdminAPIUpdateSqlReportPayload,
  options?: { signal?: AbortSignal },
): Promise<SQLReport> {
  return F.pipe(
    payload,
    updateSqlReportUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { updateSqlReport };
export type {
  AdminAPIUpdateSqlReportBodyParams,
  AdminAPIUpdateSqlReportPathParams,
  AdminAPIUpdateSqlReportPayload,
  AdminAPIUpdateSqlReportSearchParams,
};
