/**
 * HTTP Method: GET
 * Pathname: /amenityTypes
 * @function getAmenityTypes
 * @memberof PropertiesAPI
 * @param {PropertiesAPIGetAmenityTypesPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<AmenityAmenityType[]>}
 */
import { Effect, Function as F } from "effect";

import type { AmenityAmenityType } from "@ender/shared/generated/ender.model.core.property";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type PropertiesAPIGetAmenityTypesSearchParams = {
  token?: string | undefined;
};

type PropertiesAPIGetAmenityTypesPayload =
  PropertiesAPIGetAmenityTypesSearchParams;

function getAmenityTypesUnsafeEffect(
  payload: PropertiesAPIGetAmenityTypesPayload,
) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, AmenityAmenityType[]>({
        body,
        decode: unsafeDecodeJsonResponse<AmenityAmenityType[]>({}),
        encode: encodeNoBody({ method: "GET" }),
        pathname: "/amenityTypes",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getAmenityTypes(
  payload: PropertiesAPIGetAmenityTypesPayload,
  options?: { signal?: AbortSignal },
): Promise<AmenityAmenityType[]> {
  return F.pipe(
    payload,
    getAmenityTypesUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getAmenityTypes };
export type {
  PropertiesAPIGetAmenityTypesPayload,
  PropertiesAPIGetAmenityTypesSearchParams,
};
