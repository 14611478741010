import { Data, Function as F, HashMap, Option as O } from "effect";

type RestServerErrorValue = {
  message: string;
  status: number;
  statusText: string;
};

/**
 * The 5xx (Server Error) class of status code indicates that the server is aware that it has erred or is incapable of
 * performing the requested method.
 *
 * Except when responding to a HEAD request, the server SHOULD send a representation containing an explanation of the
 * error situation, and whether it is a temporary or permanent condition.
 *
 * A user agent SHOULD display any included representation to the user.
 */
class RestServerError extends Data.TaggedError<"RestServerError">(
  "RestServerError",
)<RestServerErrorValue> {
  override toString() {
    // Default toString used to display error messages to the User
    return this.message;
  }
}

function of(args: RestServerErrorValue) {
  return new RestServerError(args);
}

function InternalServerError(message: string) {
  return of({
    message,
    status: 500,
    statusText: "Internal Server Error",
  });
}

function NotImplemented(message: string) {
  return of({
    message,
    status: 501,
    statusText: "Not Implemented",
  });
}

function BadGateway(message: string) {
  return of({
    message,
    status: 502,
    statusText: "Bad Gateway",
  });
}

function ServiceUnavailable(message: string) {
  return of({
    message,
    status: 503,
    statusText: "Service Unavailable",
  });
}

function GatewayTimeout(message: string) {
  return of({
    message,
    status: 504,
    statusText: "Gateway Timeout",
  });
}

function HTTPVersionNotSupported(message: string) {
  return of({
    message,
    status: 505,
    statusText: "HTTP Version Not Supported",
  });
}

function VariantAlsoNegotiates(message: string) {
  return of({
    message,
    status: 506,
    statusText: "Variant Also Negotiates",
  });
}

function InsufficientStorage(message: string) {
  return of({
    message,
    status: 507,
    statusText: "Insufficient Storage",
  });
}

function LoopDetected(message: string) {
  return of({
    message,
    status: 508,
    statusText: "Loop Detected",
  });
}

function NotExtended(message: string) {
  return of({
    message,
    status: 510,
    statusText: "Not Extended",
  });
}

function NetworkAuthenticationRequired(message: string) {
  return of({
    message,
    status: 511,
    statusText: "Network Authentication Required",
  });
}

const codeToError = HashMap.fromIterable([
  [500, InternalServerError],
  [501, NotImplemented],
  [502, BadGateway],
  [503, ServiceUnavailable],
  [504, GatewayTimeout],
  [505, HTTPVersionNotSupported],
  [506, VariantAlsoNegotiates],
  [507, InsufficientStorage],
  [508, LoopDetected],
  [510, NotExtended],
  [511, NetworkAuthenticationRequired],
]);
function forStatus(status: number) {
  return F.pipe(
    codeToError,
    HashMap.get(status),
    O.getOrElse(
      () => (message: string) =>
        of({
          message,
          status: 599,
          statusText: `Unknown Server Error ${status}`,
        }),
    ),
  );
}

export {
  BadGateway,
  forStatus,
  GatewayTimeout,
  HTTPVersionNotSupported,
  InsufficientStorage,
  InternalServerError,
  LoopDetected,
  NetworkAuthenticationRequired,
  NotExtended,
  NotImplemented,
  of,
  RestServerError,
  ServiceUnavailable,
  VariantAlsoNegotiates,
};
