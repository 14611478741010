import { cva, cx } from "class-variance-authority";
import { Function as F } from "effect";
import type { MouseEvent, PropsWithChildren, ReactNode } from "react";
import { forwardRef } from "react";
import type { Colors } from "@ender/shared/utils/theming";
import { Color } from "@ender/shared/utils/theming";
import type { ButtonSizes, ButtonVariants } from "../../../button/src";
import { ButtonSize, ButtonVariant } from "../../../button/src";
import { LoadingSpinner } from "../../../loading-spinner/src";
import { Tooltip } from "../../../tooltip/src";
import { isEmptyReactNode } from "../../../utils";
type ActionIconProps = {
  color?: Extract<Colors, "red" | "green" | "primary" | "slate">;
  disabled?: boolean;
  disabledTooltip?: ReactNode;
  label?: string;
  loading?: boolean;
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
  size?: ButtonSizes;
  tooltip?: ReactNode;
  type?: "button" | "submit" | "reset";
  variant?: ButtonVariants;
};
const ActionIconVariantGenerator = cva(["border border-transparent", "leading-normal font-semibold", "rounded-full"], {
  compoundVariants: [{
    className: ["bg-wildcard-700", "hover:bg-wildcard-800 active:bg-wildcard-900"],
    color: Color.slate,
    variant: ButtonVariant.filled
  }, {
    className: ["border-wildcard-700 text-wildcard-700"],
    color: Color.slate,
    variant: ButtonVariant.outlined
  }, {
    className: ["text-wildcard-700", "hover:text-wildcard-800 active:text-wildcard-900"],
    color: Color.slate,
    variant: ButtonVariant.transparent
  }],
  defaultVariants: {
    color: Color.primary,
    size: ButtonSize.md,
    variant: ButtonVariant.outlined
  },
  variants: {
    color: {
      [Color.green]: "wildcard-green",
      [Color.primary]: "wildcard-primary",
      [Color.red]: "wildcard-red",
      [Color.slate]: "wildcard-slate"
    },
    size: {
      [ButtonSize.lg]: "p-[calc(0.625rem-1px)] text-xl",
      [ButtonSize.md]: "p-1.5 text-lg",
      [ButtonSize.sm]: "p-[calc(0.25rem-1px)] text-base",
      [ButtonSize.xl]: "p-3 text-2xl"
    },
    variant: {
      [ButtonVariant.filled]: ["bg-wildcard-500 text-white", "hover:bg-wildcard-600 active:bg-wildcard-700", "disabled:text-white disabled:bg-gray-200"],
      [ButtonVariant.outlined]: ["bg-white border-wildcard-500 text-wildcard-500", "hover:bg-wildcard-50 active:bg-wildcard-100", "disabled:text-white disabled:bg-gray-200 disabled:border-transparent"],
      [ButtonVariant.transparent]: ["bg-transparent text-wildcard-500", "hover:text-wildcard-600 hover:underline active:text-wildcard-700", "disabled:text-gray-200 disabled:no-underline"]
    }
  }
});
const ActionIcon = forwardRef<HTMLButtonElement, PropsWithChildren<ActionIconProps>>(function ActionIcon(props, ref) {
  const {
    children,
    disabled = false,
    disabledTooltip,
    label,
    loading = false,
    onClick = F.constVoid,
    tooltip = label,
    type = "button",
    ...other
  } = props;
  const tooltipLabel = disabled ? disabledTooltip : tooltip;
  return <Tooltip label={tooltipLabel} disabled={isEmptyReactNode(tooltipLabel)}>
      <button disabled={disabled || loading} onClick={onClick} ref={ref} aria-label={label} type={type} {...other} className={cx(ActionIconVariantGenerator(props),
    //@ts-expect-error className is not an exposed prop, but is used when this is a child via Radix `asChild`
    other.className)}>
        {loading ? <LoadingSpinner /> : children}
      </button>
    </Tooltip>;
});
export { ActionIcon };
export type { ActionIconProps };