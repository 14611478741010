/**
 * HTTP Method: POST
 * Pathname: /pms/{pmId}/users
 * @function getUsersForPM
 * @memberof UsersAPI
 * @param {UsersAPIGetUsersForPMPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<User[]>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { User } from "@ender/shared/generated/ender.model.core.user";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type UsersAPIGetUsersForPMPathParams = {
  pmId: EnderId;
};

type UsersAPIGetUsersForPMSearchParams = {
  token?: string | undefined;
};

type UsersAPIGetUsersForPMBodyParams = {
  keyword?: string | undefined;
};

type UsersAPIGetUsersForPMPayload = UsersAPIGetUsersForPMPathParams &
  UsersAPIGetUsersForPMSearchParams &
  UsersAPIGetUsersForPMBodyParams;

function getUsersForPMUnsafeEffect(payload: UsersAPIGetUsersForPMPayload) {
  const { pmId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, User[]>({
        body,
        decode: unsafeDecodeJsonResponse<User[]>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/pms/${pmId}/users`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getUsersForPM(
  payload: UsersAPIGetUsersForPMPayload,
  options?: { signal?: AbortSignal },
): Promise<User[]> {
  return F.pipe(
    payload,
    getUsersForPMUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getUsersForPM };
export type {
  UsersAPIGetUsersForPMBodyParams,
  UsersAPIGetUsersForPMPathParams,
  UsersAPIGetUsersForPMPayload,
  UsersAPIGetUsersForPMSearchParams,
};
