import type { E164Number } from "libphonenumber-js";
import type { Metadata } from "react-phone-number-input";
import {
  formatPhoneNumber as _formatPhoneNumber,
  isPossiblePhoneNumber as _isPossiblePhoneNumber,
} from "react-phone-number-input/input-core";

// https://gitlab.com/catamphetamine/react-phone-number-input#customizing-metadata
// Ender custom US metadata saves us >100kb
// Created with:
// `pnpm run generate-libphonenumber-metadata`
// Copy and paste the contents of `metadata.custom.json` below
const ReactPhoneInputMetadata: Metadata & { version: number } = {
  countries: {
    US: [
      "1",
      "011",
      "[2-9]\\d{9}|3\\d{6}",
      [10],
      [
        ["(\\d{3})(\\d{4})", "$1-$2", ["310"], 0, 1],
        ["(\\d{3})(\\d{3})(\\d{4})", "($1) $2-$3", ["[2-9]"], 0, 1, "$1-$2-$3"],
      ],
      "1",
      0,
      0,
      0,
      0,
      0,
      [
        [
          "505(?:[2-57-9]\\d\\d|6(?:[0-35-9]\\d|4[46]))\\d{4}|(?:2(?:0[1-35-9]|1[02-9]|2[03-589]|3[149]|4[08]|5[1-46]|6[0279]|7[0269]|8[13])|3(?:0[1-57-9]|1[02-9]|2[01356]|3[0-24679]|4[167]|5[0-2]|6[014]|8[056])|4(?:0[124-9]|1[02-579]|2[3-5]|3[0245]|4[023578]|58|6[349]|7[0589]|8[04])|5(?:0[1-47-9]|1[0235-8]|20|3[0149]|4[01]|5[19]|6[1-47]|7[0-5]|8[0256])|6(?:0[1-35-9]|1[024-9]|2[03689]|[34][016]|5[01679]|6[0-279]|78|8[0-29])|7(?:0[1-46-8]|1[2-9]|2[04-7]|3[1247]|4[037]|5[47]|6[02359]|7[0-59]|8[156])|8(?:0[1-68]|1[02-8]|2[068]|3[0-2589]|4[03578]|5[046-9]|6[02-5]|7[028])|9(?:0[1346-9]|1[02-9]|2[0589]|3[0146-8]|4[01357-9]|5[12469]|7[0-389]|8[04-69]))[2-9]\\d{6}",
        ],
        [""],
        ["8(?:00|33|44|55|66|77|88)[2-9]\\d{6}"],
        ["900[2-9]\\d{6}"],
        [
          "52(?:3(?:[2-46-9][02-9]\\d|5(?:[02-46-9]\\d|5[0-46-9]))|4(?:[2-478][02-9]\\d|5(?:[034]\\d|2[024-9]|5[0-46-9])|6(?:0[1-9]|[2-9]\\d)|9(?:[05-9]\\d|2[0-5]|49)))\\d{4}|52[34][2-9]1[02-9]\\d{4}|5(?:00|2[125-7]|33|44|66|77|88)[2-9]\\d{6}",
        ],
      ],
    ],
  },
  country_calling_codes: { 1: ["US"] },
  version: 4, // version is not part of Metadata
};

/**
 * Formats a phone number in E.164 format.
 * @param {string} value - The phone number to format.
 * @returns {string} - The formatted phone number.
 */
function formatPhoneNumber(value: string): string {
  if (!value.startsWith("+1")) {
    console.warn("Cannot format a non-E.164 phone number", value);
    return value;
  }

  return _formatPhoneNumber(value as E164Number, ReactPhoneInputMetadata);
}

/**
 * Checks if a phone number is a possible valid phone number in E.164 format.
 * @param {string} value - The phone number to check.
 * @returns {boolean} - `true` if the phone number is a possible valid phone number, `false` otherwise.
 */
function isPossiblePhoneNumber(value: string) {
  if (!value.startsWith("+1")) {
    console.warn("Cannot validate a non-E.164 phone number", value);
    return true;
  }

  return _isPossiblePhoneNumber(value, ReactPhoneInputMetadata);
}

export { formatPhoneNumber, isPossiblePhoneNumber, ReactPhoneInputMetadata };
