/**
 * HTTP Method: POST
 * Pathname: /cloudwatchAlarmTriggered
 * @function alarmTriggered
 * @memberof CloudWatchAlarmAPI
 * @param {CloudWatchAlarmAPIAlarmTriggeredPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type CloudWatchAlarmAPIAlarmTriggeredSearchParams = {
  token?: string | undefined;
};

type CloudWatchAlarmAPIAlarmTriggeredBodyParams = {
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  body: any;
};

type CloudWatchAlarmAPIAlarmTriggeredPayload =
  CloudWatchAlarmAPIAlarmTriggeredSearchParams &
    CloudWatchAlarmAPIAlarmTriggeredBodyParams;

function alarmTriggeredUnsafeEffect(
  payload: CloudWatchAlarmAPIAlarmTriggeredPayload,
) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/cloudwatchAlarmTriggered",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function alarmTriggered(
  payload: CloudWatchAlarmAPIAlarmTriggeredPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    alarmTriggeredUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { alarmTriggered };
export type {
  CloudWatchAlarmAPIAlarmTriggeredBodyParams,
  CloudWatchAlarmAPIAlarmTriggeredPayload,
  CloudWatchAlarmAPIAlarmTriggeredSearchParams,
};
