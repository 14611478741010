import { randSentence } from "@ngneat/falso";

import type { EnderId, Percent } from "@ender/shared/core";
import { randomEnderId, randomPercent } from "@ender/shared/core";
import type { ModelType } from "@ender/shared/generated/com.ender.common.model";
import { randomModelType } from "@ender/shared/generated/com.ender.common.model";

type OwnershipAPIOwnershipResponseNode = {
  children: OwnershipAPIOwnershipResponseNode[];
  id: EnderId;
  name: string;
  netOwnership?: Percent | undefined;
  ownershipPercent: Percent;
  type: ModelType;
};

function randomOwnershipAPIOwnershipResponseNode(
  params: Partial<OwnershipAPIOwnershipResponseNode> = {},
): OwnershipAPIOwnershipResponseNode {
  const {
    children = [],
    id = randomEnderId(),
    name = randSentence(),
    netOwnership = undefined,
    ownershipPercent = randomPercent().toJSON(),
    type = randomModelType(),
  } = params;

  return {
    children,
    id,
    name,
    netOwnership,
    ownershipPercent,
    type,
  };
}

export { randomOwnershipAPIOwnershipResponseNode };
export type { OwnershipAPIOwnershipResponseNode };
