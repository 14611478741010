import type { DefaultValues } from "react-hook-form";
import { useForm as useFormRhf } from "react-hook-form";

import type { Undefined } from "@ender/shared/constants/general";

import type { FieldValues, UseFormProps, UseFormReturn } from "../types";

function useForm<
  TFieldValues extends FieldValues = FieldValues,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TContext = any,
  TTransformedValues extends FieldValues | Undefined = Undefined,
>(
  props: UseFormProps<TFieldValues, TContext>,
): UseFormReturn<TFieldValues, TContext, TTransformedValues> {
  return useFormRhf({
    ...props,
    defaultValues: props.defaultValues as DefaultValues<TFieldValues>,
  });
}

export { useForm };
