/**
 * HTTP Method: GET
 * Pathname: /permissions/roles
 * @function getRoles
 * @memberof PermissionsAPI
 * @param {PermissionsAPIGetRolesPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<GetRolesResponse[]>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { GetRolesResponse } from "@ender/shared/generated/ender.api.misc.response";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type PermissionsAPIGetRolesSearchParams = {
  token?: string | undefined;
  vendorId?: EnderId | undefined;
};

type PermissionsAPIGetRolesPayload = PermissionsAPIGetRolesSearchParams;

function getRolesUnsafeEffect(payload: PermissionsAPIGetRolesPayload) {
  const { token, vendorId, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, GetRolesResponse[]>({
        body,
        decode: unsafeDecodeJsonResponse<GetRolesResponse[]>({}),
        encode: encodeNoBody({ method: "GET" }),
        pathname: "/permissions/roles",
        searchParams: [
          ["token", token],
          ["vendorId", vendorId],
        ],
      }),
    ),
  );
}

function getRoles(
  payload: PermissionsAPIGetRolesPayload,
  options?: { signal?: AbortSignal },
): Promise<GetRolesResponse[]> {
  return F.pipe(
    payload,
    getRolesUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getRoles };
export type {
  PermissionsAPIGetRolesPayload,
  PermissionsAPIGetRolesSearchParams,
};
