import { Schema } from "@effect/schema";

import { Money$ } from "@ender/shared/core";

/**
 * @deprecated use MoneyFormSchema from `@ender/shared/core`
 */
const MoneyEffectSchema = Schema.typeSchema(Money$.Schema).annotations({
  arbitrary: () => (fc) =>
    fc.integer({ max: 10000_00, min: 50 }).map(Money$.makeFromCents),
});

export { MoneyEffectSchema };
