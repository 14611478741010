/**
 * HTTP Method: POST
 * Pathname: /tasks
 * @function createTask
 * @memberof TasksAPI
 * @param {TasksAPICreateTaskPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<Task>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId, LocalDate, SerializesInto } from "@ender/shared/core";
import type { AddTaskTagRequest } from "@ender/shared/generated/ender.api.misc.request";
import type {
  Task,
  TaskTaskPriority,
  TaskTaskType,
} from "@ender/shared/generated/ender.model.task";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type TasksAPICreateTaskSearchParams = {
  token?: string | undefined;
};

type TasksAPICreateTaskBodyParams = {
  addTagRequests?: AddTaskTagRequest[] | undefined;
  description: string;
  internalAssigneeId?: EnderId | undefined;
  parentTaskId?: EnderId | undefined;
  permissionToEnter?: boolean | undefined;
  priority?: TaskTaskPriority | undefined;
  propertyId?: EnderId | undefined;
  targetDate?: SerializesInto<LocalDate> | undefined;
  type?: TaskTaskType | undefined;
  unitId?: EnderId | undefined;
  vendorId?: EnderId | undefined;
};

type TasksAPICreateTaskPayload = TasksAPICreateTaskSearchParams &
  TasksAPICreateTaskBodyParams;

function createTaskUnsafeEffect(payload: TasksAPICreateTaskPayload) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, Task>({
        body,
        decode: unsafeDecodeJsonResponse<Task>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/tasks",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function createTask(
  payload: TasksAPICreateTaskPayload,
  options?: { signal?: AbortSignal },
): Promise<Task> {
  return F.pipe(
    payload,
    createTaskUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { createTask };
export type {
  TasksAPICreateTaskBodyParams,
  TasksAPICreateTaskPayload,
  TasksAPICreateTaskSearchParams,
};
