import { deletePhoto } from "./delete-photo";
import { getPhotosForUnit } from "./get-photos-for-unit";
import { makePrimaryPhoto } from "./make-primary-photo";
import { updatePhoto } from "./update-photo";
import { updatePhotoOrdering } from "./update-photo-ordering";

const PhotosAPI = {
  deletePhoto,
  getPhotosForUnit,
  makePrimaryPhoto,
  updatePhoto,
  updatePhotoOrdering,
};

export { PhotosAPI };
