/**
 * HTTP Method: GET
 * Pathname: /units/{unitId}/amenities
 * @function getUnitAmenities
 * @memberof UnitsAPI
 * @param {UnitsAPIGetUnitAmenitiesPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<AmenityAmenityType[]>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { AmenityAmenityType } from "@ender/shared/generated/ender.model.core.property";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type UnitsAPIGetUnitAmenitiesPathParams = {
  unitId: EnderId;
};

type UnitsAPIGetUnitAmenitiesSearchParams = {
  token?: string | undefined;
};

type UnitsAPIGetUnitAmenitiesPayload = UnitsAPIGetUnitAmenitiesPathParams &
  UnitsAPIGetUnitAmenitiesSearchParams;

function getUnitAmenitiesUnsafeEffect(
  payload: UnitsAPIGetUnitAmenitiesPayload,
) {
  const { unitId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, AmenityAmenityType[]>({
        body,
        decode: unsafeDecodeJsonResponse<AmenityAmenityType[]>({}),
        encode: encodeNoBody({ method: "GET" }),
        pathname: `/units/${unitId}/amenities`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getUnitAmenities(
  payload: UnitsAPIGetUnitAmenitiesPayload,
  options?: { signal?: AbortSignal },
): Promise<AmenityAmenityType[]> {
  return F.pipe(
    payload,
    getUnitAmenitiesUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getUnitAmenities };
export type {
  UnitsAPIGetUnitAmenitiesPathParams,
  UnitsAPIGetUnitAmenitiesPayload,
  UnitsAPIGetUnitAmenitiesSearchParams,
};
