import type { NotificationProps } from "@mantine/notifications";
import { hideNotification, showNotification, updateNotification } from "@mantine/notifications";
import { Function as F } from "effect";
import type { ReactNode } from "react";
import { EnderThemeColorEnum } from "@ender/shared/constants/mantine";
import { randomEnderId } from "@ender/shared/core";
type ShowLoadingNotificationParams = Omit<NotificationProps, "id" | "autoClose" | "color" | "disallowClose" | "icon" | "loading"> & {
  autoClose?: number | boolean;
};
// Tuple of [id, clearLoadingNotification]
type ShowLoadingNotificationResult = [string, () => void];

// We want to ensure that there is only ever a single Loading Notification shown at a time but still allow for multiple
// calls to showLoadingNotification to be made.
// To do this, we keep track of the number of calls to showLoadingNotification and assign an id to each call.
// The id can later be used to clear a specific notification.
const loadingQueue: {
  id: string;
  params: ShowLoadingNotificationParams;
}[] = [];
const autoCloseMills = 3_600_000; // 1 hour
let isLoading = false;
const loadingNotificationId = "__ENDER_LOADING_NOTIFICATION__";
function clearQueue() {
  isLoading = false;
  loadingQueue.forEach(({
    id,
    params
  }) => {
    const {
      onClose = F.constVoid
    } = params;
    onClose({
      id,
      ...params
    });
  });
  loadingQueue.splice(0, loadingQueue.length);
}
function clearLoadingNotification(id: string) {
  const index = loadingQueue.findIndex(item => item.id === id);
  const {
    params
  } = loadingQueue[index];
  if (index !== -1) {
    loadingQueue.splice(index, 1);
  }
  const {
    onClose = F.constVoid
  } = params;
  onClose({
    id,
    ...params
  });
  if (loadingQueue.length === 0) {
    hideNotification(loadingNotificationId);
  }
}
function showLoadingNotification({
  autoClose = autoCloseMills,
  ...params
}: ShowLoadingNotificationParams): ShowLoadingNotificationResult {
  const id = randomEnderId();
  loadingQueue.push({
    id,
    params
  });
  let message: ReactNode = `Currently waiting for ${loadingQueue.length} requests to finish loading`;
  let onOpen: (props: NotificationProps) => void = F.constVoid;
  let rest: Omit<ShowLoadingNotificationParams, "message" | "onClose" | "onOpen" | "title"> = {};
  let title: ReactNode = "Loading";
  if (loadingQueue.length === 1) {
    ({
      message,
      onOpen = F.constVoid,
      title = "Loading",
      ...rest
    } = params);
  }
  if (isLoading) {
    updateNotification({
      id: loadingNotificationId,
      title,
      message,
      ...rest
    });
  } else {
    isLoading = true;
    showNotification({
      autoClose,
      color: EnderThemeColorEnum.PURPLE_500,
      disallowClose: true,
      id: loadingNotificationId,
      loading: true,
      message,
      onClose: clearQueue,
      title,
      ...rest
    });
  }
  onOpen({
    id,
    ...params
  });
  return [id, () => clearLoadingNotification(id)];
}
function clearAllLoadingNotifications() {
  clearQueue();
  hideNotification(loadingNotificationId);
}
export { clearAllLoadingNotifications, clearLoadingNotification, showLoadingNotification };
export type { ShowLoadingNotificationParams, ShowLoadingNotificationResult };