import { addApprovalStep } from "./add-approval-step";
import { addApprover } from "./add-approver";
import { approve } from "./approve";
import { archiveStep } from "./archive-step";
import { batchApprove } from "./batch-approve";
import { batchReject } from "./batch-reject";
import { createApprovalProcess } from "./create-approval-process";
import { deleteApprovalProcess } from "./delete-approval-process";
import { getApprovalPipeline } from "./get-approval-pipeline";
import { getDealPipeline } from "./get-deal-pipeline";
import { getDefaultApprovalProcess } from "./get-default-approval-process";
import { reinstate } from "./reinstate";
import { reject } from "./reject";
import { removeApprover } from "./remove-approver";
import { searchApprovalProcesses } from "./search-approval-processes";
import { updateApprovalProcess } from "./update-approval-process";
import { updateStepName } from "./update-step-name";

const ApprovalsAPI = {
  addApprovalStep,
  addApprover,
  approve,
  archiveStep,
  batchApprove,
  batchReject,
  createApprovalProcess,
  deleteApprovalProcess,
  getApprovalPipeline,
  getDealPipeline,
  getDefaultApprovalProcess,
  reinstate,
  reject,
  removeApprover,
  searchApprovalProcesses,
  updateApprovalProcess,
  updateStepName,
};

export { ApprovalsAPI };
