/**
 * HTTP Method: POST
 * Pathname: /permissions/roles/{roleId}
 * @function updateRole
 * @memberof PermissionsAPI
 * @param {PermissionsAPIUpdateRolePayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { FunctionalPermission } from "@ender/shared/generated/ender.model.permissions";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type PermissionsAPIUpdateRolePathParams = {
  roleId: EnderId;
};

type PermissionsAPIUpdateRoleSearchParams = {
  token?: string | undefined;
};

type PermissionsAPIUpdateRoleBodyParams = {
  name: string;
  rolePermissions: Partial<Record<FunctionalPermission, boolean>>;
};

type PermissionsAPIUpdateRolePayload = PermissionsAPIUpdateRolePathParams &
  PermissionsAPIUpdateRoleSearchParams &
  PermissionsAPIUpdateRoleBodyParams;

function updateRoleUnsafeEffect(payload: PermissionsAPIUpdateRolePayload) {
  const { roleId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/permissions/roles/${roleId}`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function updateRole(
  payload: PermissionsAPIUpdateRolePayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    updateRoleUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { updateRole };
export type {
  PermissionsAPIUpdateRoleBodyParams,
  PermissionsAPIUpdateRolePathParams,
  PermissionsAPIUpdateRolePayload,
  PermissionsAPIUpdateRoleSearchParams,
};
