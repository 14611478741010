/**
 * HTTP Method: GET
 * Pathname: /admin/phoneLines/{phoneLineId}
 * @function getAdminPhoneLine
 * @memberof AdminAPI
 * @param {AdminAPIGetAdminPhoneLinePayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<AdminAPIGetAdminPhoneLineResponse>}
 */
import { Effect, Function as F } from "effect";

import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

import type { AdminAPIGetAdminPhoneLineResponse } from "../admin-api-get-admin-phone-line-response";

type AdminAPIGetAdminPhoneLinePathParams = {
  phoneLineId: string;
};

type AdminAPIGetAdminPhoneLineSearchParams = {
  token?: string | undefined;
};

type AdminAPIGetAdminPhoneLinePayload = AdminAPIGetAdminPhoneLinePathParams &
  AdminAPIGetAdminPhoneLineSearchParams;

function getAdminPhoneLineUnsafeEffect(
  payload: AdminAPIGetAdminPhoneLinePayload,
) {
  const { phoneLineId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, AdminAPIGetAdminPhoneLineResponse>({
        body,
        decode: unsafeDecodeJsonResponse<AdminAPIGetAdminPhoneLineResponse>({}),
        encode: encodeNoBody({ method: "GET" }),
        pathname: `/admin/phoneLines/${phoneLineId}`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getAdminPhoneLine(
  payload: AdminAPIGetAdminPhoneLinePayload,
  options?: { signal?: AbortSignal },
): Promise<AdminAPIGetAdminPhoneLineResponse> {
  return F.pipe(
    payload,
    getAdminPhoneLineUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getAdminPhoneLine };
export type {
  AdminAPIGetAdminPhoneLinePathParams,
  AdminAPIGetAdminPhoneLinePayload,
  AdminAPIGetAdminPhoneLineSearchParams,
};
