/**
 * HTTP Method: GET
 * Pathname: /admin/sms/redirectingNumber
 * @function getRedirectingNumber
 * @memberof AdminAPI
 * @param {AdminAPIGetRedirectingNumberPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<AdminAPIGetRedirectingNumberResponse>}
 */
import { Effect, Function as F } from "effect";

import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

import type { AdminAPIGetRedirectingNumberResponse } from "../admin-api-get-redirecting-number-response";

type AdminAPIGetRedirectingNumberSearchParams = {
  token?: string | undefined;
};

type AdminAPIGetRedirectingNumberPayload =
  AdminAPIGetRedirectingNumberSearchParams;

function getRedirectingNumberUnsafeEffect(
  payload: AdminAPIGetRedirectingNumberPayload,
) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, AdminAPIGetRedirectingNumberResponse>({
        body,
        decode: unsafeDecodeJsonResponse<AdminAPIGetRedirectingNumberResponse>(
          {},
        ),
        encode: encodeNoBody({ method: "GET" }),
        pathname: "/admin/sms/redirectingNumber",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getRedirectingNumber(
  payload: AdminAPIGetRedirectingNumberPayload,
  options?: { signal?: AbortSignal },
): Promise<AdminAPIGetRedirectingNumberResponse> {
  return F.pipe(
    payload,
    getRedirectingNumberUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getRedirectingNumber };
export type {
  AdminAPIGetRedirectingNumberPayload,
  AdminAPIGetRedirectingNumberSearchParams,
};
