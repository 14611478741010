import { addFirmToOwnershipGroup } from "./add-firm-to-ownership-group";
import { addFundToOwnershipGroup } from "./add-fund-to-ownership-group";
import { createOwnershipGroup } from "./create-ownership-group";
import { deleteOwnershipGroup } from "./delete-ownership-group";
import { getOwnershipGroupAssociations } from "./get-ownership-group-associations";
import { getOwnershipGroups } from "./get-ownership-groups";
import { removeFirmFromOwnershipGroup } from "./remove-firm-from-ownership-group";
import { removeFundFromOwnershipGroup } from "./remove-fund-from-ownership-group";
import { renameOwnershipGroup } from "./rename-ownership-group";

const OwnershipGroupsAPI = {
  addFirmToOwnershipGroup,
  addFundToOwnershipGroup,
  createOwnershipGroup,
  deleteOwnershipGroup,
  getOwnershipGroupAssociations,
  getOwnershipGroups,
  removeFirmFromOwnershipGroup,
  removeFundFromOwnershipGroup,
  renameOwnershipGroup,
};

export { OwnershipGroupsAPI };
