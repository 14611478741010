"use client";

import { Option as O } from "effect";
import { useRouter } from "next/navigation";
import { NULL } from "@ender/shared/constants/general";
import { LoadingSpinner } from "@ender/shared/ds/loading-spinner";
import { Stack } from "@ender/shared/ds/stack";
import type { Session } from "../machine/auth.types";
import { AuthTagEnum } from "../machine/auth.types";
import { useAuthActor } from "./auth-actor.context";
type SessionProviderProps = {
  children: (user: Session) => JSX.Element;
};
function SessionProvider(props: SessionProviderProps) {
  const {
    children
  } = props;
  const router = useRouter();
  const [authSnapshot] = useAuthActor();
  const {
    context: {
      session
    }
  } = authSnapshot;
  if (authSnapshot.hasTag(AuthTagEnum.INITIALIZING)) {
    return <Stack fullHeight fullWidth align="center" justify="center">
        <LoadingSpinner />
      </Stack>;
  }
  if (O.isNone(session)) {
    router.replace("/login");
    return NULL;
  }
  return children(O.getOrThrow(session));
}
export { SessionProvider };