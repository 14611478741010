/**
 * HTTP Method: POST
 * Pathname: /featureFlag
 * @function updateFeatureFlag
 * @memberof FeatureFlagAPI
 * @param {FeatureFlagAPIUpdateFeatureFlagPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { FeatureFlagFlagKey } from "@ender/shared/generated/ender.model.misc";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type FeatureFlagAPIUpdateFeatureFlagSearchParams = {
  token?: string | undefined;
};

type FeatureFlagAPIUpdateFeatureFlagBodyParams = {
  enabled: boolean;
  flagKey: FeatureFlagFlagKey;
};

type FeatureFlagAPIUpdateFeatureFlagPayload =
  FeatureFlagAPIUpdateFeatureFlagSearchParams &
    FeatureFlagAPIUpdateFeatureFlagBodyParams;

function updateFeatureFlagUnsafeEffect(
  payload: FeatureFlagAPIUpdateFeatureFlagPayload,
) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/featureFlag",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function updateFeatureFlag(
  payload: FeatureFlagAPIUpdateFeatureFlagPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    updateFeatureFlagUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { updateFeatureFlag };
export type {
  FeatureFlagAPIUpdateFeatureFlagBodyParams,
  FeatureFlagAPIUpdateFeatureFlagPayload,
  FeatureFlagAPIUpdateFeatureFlagSearchParams,
};
