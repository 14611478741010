import { Data, Function as F, HashMap, Option as O } from "effect";

type RestClientErrorValue = {
  message: string;
  status: number;
  statusText: string;
};

/**
 * The 4xx (Client Error) class of status code indicates that the client seems to have erred.
 *
 * Except when responding to a HEAD request, the server SHOULD send a representation containing an explanation of the
 * error situation, and whether it is a temporary or permanent condition.
 *
 * A user agent SHOULD display any included representation to the user.
 */
class RestClientError extends Data.TaggedError<"RestClientError">(
  "RestClientError",
)<RestClientErrorValue> {
  override toString() {
    // Default toString used to display error messages to the User
    return this.message;
  }
}

function of(args: RestClientErrorValue) {
  return new RestClientError(args);
}

function BadRequest(message: string) {
  return of({
    message,
    status: 400,
    statusText: "Bad Request",
  });
}

function Unauthorized(message: string) {
  return of({
    message,
    status: 401,
    statusText: "Unauthorized",
  });
}

function PaymentRequired(message: string) {
  return of({
    message,
    status: 402,
    statusText: "Payment Required",
  });
}

function Forbidden(message: string) {
  return of({
    message,
    status: 403,
    statusText: "Forbidden",
  });
}

function NotFound(message: string) {
  return of({
    message,
    status: 404,
    statusText: "Not Found",
  });
}

function MethodNotAllowed(message: string) {
  return of({
    message,
    status: 405,
    statusText: "Method Not Allowed",
  });
}

function NotAcceptable(message: string) {
  return of({
    message,
    status: 406,
    statusText: "Not Acceptable",
  });
}

function ProxyAuthenticationRequired(message: string) {
  return of({
    message,
    status: 407,
    statusText: "Proxy Authentication Required",
  });
}

function RequestTimeout(message: string) {
  return of({
    message,
    status: 408,
    statusText: "Request Timeout",
  });
}

function Conflict(message: string) {
  return of({
    message,
    status: 409,
    statusText: "Conflict",
  });
}

function Gone(message: string) {
  return of({
    message,
    status: 410,
    statusText: "Gone",
  });
}

function LengthRequired(message: string) {
  return of({
    message,
    status: 411,
    statusText: "Length Required",
  });
}

function PreconditionFailed(message: string) {
  return of({
    message,
    status: 412,
    statusText: "Precondition Failed",
  });
}

function PayloadTooLarge(message: string) {
  return of({
    message,
    status: 413,
    statusText: "Payload Too Large",
  });
}

function URITooLong(message: string) {
  return of({
    message,
    status: 414,
    statusText: "URI Too Long",
  });
}

function UnsupportedMediaType(message: string) {
  return of({
    message,
    status: 415,
    statusText: "Unsupported Media Type",
  });
}

function RangeNotSatisfiable(message: string) {
  return of({
    message,
    status: 416,
    statusText: "Range Not Satisfiable",
  });
}

function ExpectationFailed(message: string) {
  return of({
    message,
    status: 417,
    statusText: "Expectation Failed",
  });
}

function ImATeapot(message: string) {
  return of({
    message,
    status: 418,
    statusText: "I'm a teapot",
  });
}

function MisdirectedRequest(message: string) {
  return of({
    message,
    status: 421,
    statusText: "Misdirected Request",
  });
}

function UnprocessableEntity(message: string) {
  return of({
    message,
    status: 422,
    statusText: "Unprocessable Entity",
  });
}

function Locked(message: string) {
  return of({
    message,
    status: 423,
    statusText: "Locked",
  });
}

function FailedDependency(message: string) {
  return of({
    message,
    status: 424,
    statusText: "Failed Dependency",
  });
}

function TooEarly(message: string) {
  return of({
    message,
    status: 425,
    statusText: "Too Early",
  });
}

function UpgradeRequired(message: string) {
  return of({
    message,
    status: 426,
    statusText: "Upgrade Required",
  });
}

function PreconditionRequired(message: string) {
  return of({
    message,
    status: 428,
    statusText: "Precondition Required",
  });
}

function TooManyRequests(message: string) {
  return of({
    message,
    status: 429,
    statusText: "Too Many Requests",
  });
}

function RequestHeaderFieldsTooLarge(message: string) {
  return of({
    message,
    status: 431,
    statusText: "Request Header Fields Too Large",
  });
}

function UnavailableForLegalReasons(message: string) {
  return of({
    message,
    status: 451,
    statusText: "Unavailable For Legal Reasons",
  });
}

const codeToError = HashMap.fromIterable([
  [400, BadRequest],
  [401, Unauthorized],
  [402, PaymentRequired],
  [403, Forbidden],
  [404, NotFound],
  [405, MethodNotAllowed],
  [406, NotAcceptable],
  [407, ProxyAuthenticationRequired],
  [408, RequestTimeout],
  [409, Conflict],
  [410, Gone],
  [411, LengthRequired],
  [412, PreconditionFailed],
  [413, PayloadTooLarge],
  [414, URITooLong],
  [415, UnsupportedMediaType],
  [416, RangeNotSatisfiable],
  [417, ExpectationFailed],
  [418, ImATeapot],
  [421, MisdirectedRequest],
  [422, UnprocessableEntity],
  [423, Locked],
  [424, FailedDependency],
  [425, TooEarly],
  [426, UpgradeRequired],
  [428, PreconditionRequired],
  [429, TooManyRequests],
  [431, RequestHeaderFieldsTooLarge],
  [451, UnavailableForLegalReasons],
]);

function forStatus(status: number) {
  return F.pipe(
    codeToError,
    HashMap.get(status),
    O.getOrElse(
      () => (message: string) =>
        of({
          message,
          status: 499,
          statusText: `Unknown Client Error ${status}`,
        }),
    ),
  );
}

export {
  BadRequest,
  Conflict,
  ExpectationFailed,
  FailedDependency,
  Forbidden,
  forStatus,
  Gone,
  ImATeapot,
  LengthRequired,
  Locked,
  MethodNotAllowed,
  MisdirectedRequest,
  NotAcceptable,
  NotFound,
  of,
  PayloadTooLarge,
  PaymentRequired,
  PreconditionFailed,
  PreconditionRequired,
  ProxyAuthenticationRequired,
  RangeNotSatisfiable,
  RequestHeaderFieldsTooLarge,
  RequestTimeout,
  RestClientError,
  TooEarly,
  TooManyRequests,
  Unauthorized,
  UnavailableForLegalReasons,
  UnprocessableEntity,
  UnsupportedMediaType,
  UpgradeRequired,
  URITooLong,
};
