/**
 * HTTP Method: GET
 * Pathname: /pmForSubdomain
 * @function getPMIdForSubdomain
 * @memberof EnderAPI
 * @param {EnderAPIGetPMIdForSubdomainPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<GetPMForSubdomainResponse>}
 */
import { Effect, Function as F } from "effect";

import type { GetPMForSubdomainResponse } from "@ender/shared/generated/ender.api.misc.response";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type EnderAPIGetPMIdForSubdomainSearchParams = {
  token?: string | undefined;
  subdomain: string;
};

type EnderAPIGetPMIdForSubdomainPayload =
  EnderAPIGetPMIdForSubdomainSearchParams;

function getPMIdForSubdomainUnsafeEffect(
  payload: EnderAPIGetPMIdForSubdomainPayload,
) {
  const { token, subdomain, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, GetPMForSubdomainResponse>({
        body,
        decode: unsafeDecodeJsonResponse<GetPMForSubdomainResponse>({}),
        encode: encodeNoBody({ method: "GET" }),
        pathname: "/pmForSubdomain",
        searchParams: [
          ["token", token],
          ["subdomain", subdomain],
        ],
      }),
    ),
  );
}

function getPMIdForSubdomain(
  payload: EnderAPIGetPMIdForSubdomainPayload,
  options?: { signal?: AbortSignal },
): Promise<GetPMForSubdomainResponse> {
  return F.pipe(
    payload,
    getPMIdForSubdomainUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getPMIdForSubdomain };
export type {
  EnderAPIGetPMIdForSubdomainPayload,
  EnderAPIGetPMIdForSubdomainSearchParams,
};
