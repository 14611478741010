/**
 * HTTP Method: POST
 * Pathname: /tasks/search/count
 * @function searchTaskCounts
 * @memberof TasksAPI
 * @param {TasksAPISearchTaskCountsPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<Partial<Record<string, number>>>}
 */
import { Effect, Function as F } from "effect";

import type { TaskCountSearchRequestTaskCountSearchRequestFilterBy } from "@ender/shared/generated/ender.api.misc.request";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type TasksAPISearchTaskCountsSearchParams = {
  token?: string | undefined;
};

type TasksAPISearchTaskCountsBodyParams = {
  filterBy: TaskCountSearchRequestTaskCountSearchRequestFilterBy;
};

type TasksAPISearchTaskCountsPayload = TasksAPISearchTaskCountsSearchParams &
  TasksAPISearchTaskCountsBodyParams;

function searchTaskCountsUnsafeEffect(
  payload: TasksAPISearchTaskCountsPayload,
) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, Partial<Record<string, number>>>({
        body,
        decode: unsafeDecodeJsonResponse<Partial<Record<string, number>>>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/tasks/search/count",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function searchTaskCounts(
  payload: TasksAPISearchTaskCountsPayload,
  options?: { signal?: AbortSignal },
): Promise<Partial<Record<string, number>>> {
  return F.pipe(
    payload,
    searchTaskCountsUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { searchTaskCounts };
export type {
  TasksAPISearchTaskCountsBodyParams,
  TasksAPISearchTaskCountsPayload,
  TasksAPISearchTaskCountsSearchParams,
};
