import type { ParseResult } from "@effect/schema";
import { Schema as Sch } from "@effect/schema";
import type {
  Brand,
  Either as E,
  Equivalence as Eq,
  Order as Od,
} from "effect";
import { Number as N, Option as O, flow } from "effect";
import { dual } from "effect/Function";

import * as money from "./internal/money";
import { Schema } from "./internal/schema";

type Serialized = Brand.Branded<string, "Money">;

type Money = {
  readonly _tag: "Money";
  readonly valueInCents: number;
  toJSON(): Serialized;
  /**
   * @deprecated use Money$.subtract(self, other)
   */
  subtract(value: Money): Money;
  /**
   * @deprecated use Money$.divide(self, value)
   */
  divideBy(value: number): Money;
  /**
   * @deprecated use Money$.negate(self)
   */
  negate(): Money;
  /**
   * @deprecated use Money$.negateWhen(self, condition)
   */
  negateWhen(condition: boolean): Money;
  /**
   * @deprecated use Money$.abs(self)
   */
  abs(): Money;
  /**
   * @deprecated use Money$.toFormatted(self, format)
   */
  // eslint-disable-next-line no-use-before-define
  toFormatted(format?: Format): string;
};

const Formats = {
  /**
   * 123
   */
  DOLLARS: "DOLLARS",
  /**
   * One hundred and twenty three
   */
  WORDS: "WORDS",
  /**
   *
   */
  DEFAULT: "DEFAULT",
} as const;
type Format = (typeof Formats)[keyof typeof Formats];

/**
 * @category Symbols
 */
const TypeId: unique symbol = Symbol.for("ender/Money");

/**
 * @category Symbols
 */
// eslint-disable-next-line @typescript-eslint/no-redeclare,import/group-exports
export type TypeId = typeof TypeId;

/**
 * Creates an instance of Money representing zero value.
 * This is useful as a default or initial value in calculations.
 *
 * @example
 * ```ts
 * import { Money$ } from "@ender/shared/core"
 *
 * // A Money object containing zero dollars and zero cents
 * //
 * //      ┌─── Money$.Money
 * //      ▼
 * const value = Money$.zero()
 *
 * assert.deepStrictEqual(value, Money$.makeFromCents(0));
 * ```
 *
 * Because it is a function, it can be used as a `LazyArg` for Effect piping.
 *
 * @category Constructors
 */
const zero = () => money.makeFromCents(0);

/**
 * Checks if the provided object is an instance of `Money`.
 *
 * This guard function evaluates whether the given value conforms to the `Money` type.
 * It is particularly useful in scenarios involving type narrowing, such as ensuring
 * that an input value is a valid `Money` object before performing operations on it.
 *
 * @example
 * ```ts
 * import { Money$ } from "@ender/shared/core";
 *
 * const value = Money$.makeFromCents(100);
 *
 *  assert.deepStrictEqual(Money$.isMoney(value), true);
 * ```
 *
 * @category Guards
 */
const isMoney = money.isMoney;

/**
 * Creates an instance of Money representing the provided `centsVal`.
 *
 * @example
 * ```ts
 * import { Money$ } from "@ender/shared/core"
 *
 * //
 * //      ┌─── Money$.Money
 * //      ▼
 * const value = Money$.makeFromCents(123_00)
 *
 * assert.deepStrictEqual(value.valueInCents, 12300);
 * ```
 *
 * @category Constructors
 */
const makeFromCents = money.makeFromCents;

const fromDollars = (v: number) => money.makeFromCents(v * 100);

/**
 * Converts a `Money` instance into its equivalent dollar representation.
 *
 * This method simplifies a `Money` object into a numeric representation in dollars,
 * making it suitable for further calculations where a floating-point
 * dollar value is required. Keep in mind that significant figures may not be preserved during
 * this conversion, making it unsuitable for most display purposes
 *
 * @example
 * ```ts
 * import { Money$ } from "@ender/shared/core";
 *
 * const value = Money$.makeFromCents(12345);
 *
 * assert.deepStrictEqual(Money$.toDollars(value), 123.45);
 * ```
 *
 * @category Conversions
 */
const toDollars = money.toDollars;

/**
 * Converts a `Money` instance into a formatted string representation.
 *
 * This method allows for the customization of the output format, making it ideal
 * for displaying monetary amounts in different contexts, such as
 * user interfaces, reports, or logs.
 *
 * Allowed formats are
 * - "DOLLARS", for truncated dollars-only output
 * - "WORDS", for textual representation of the number
 * - "DEFAULT", for dollars-and-cents output
 *
 * @example
 * ```ts
 * import { Money$ } from "@ender/shared/core";
 *
 * const value = Money$.makeFromCents(12345);
 *
 * // Formatting as dollars
 * assert.deepStrictEqual(Money$.toFormatted(value, "DOLLARS"), "$123");
 *
 * // Formatting as words (e.g., "one hundred twenty-three dollars and forty-five cents")
 * assert.deepStrictEqual(Money$.toFormatted(value, "WORDS"), "one hundred twenty-three dollars and forty-five cents");
 *
 * // Using the default format
 * assert.deepStrictEqual(Money$.toFormatted(value, "DEFAULT"), "$123.45");
 * ```
 *
 * @category Conversions
 */
const toFormatted = money.toFormatted;

/**
 * Adds two `Money` instances together.
 *
 * This function allows you to combine two monetary values into a single `Money` instance.
 *
 * @example
 * ```ts
 * import { Money$ } from "@ender/shared/core";
 *
 * const value1 = Money$.makeFromCents(100);
 * const value2 = Money$.makeFromCents(200);
 *
 * const result = Money$.add(value1, value2);
 *
 * assert.deepStrictEqual(result.valueInCents, 300);
 * ```
 *
 * @category Mapping
 */
const add: {
  (n: Money): (a: Money) => Money;
  (self: Money, n: Money): Money;
} = dual(
  2,
  (a: Money, n: Money): Money =>
    money.makeFromCents(a.valueInCents + n.valueInCents),
);

/**
 * Subtracts one `Money` instance from another.
 *
 * This function allows you to deduct one monetary value from another,
 * resulting in a new `Money` instance.
 *
 * @example
 * ```ts
 * import { Money$ } from "@ender/shared/core";
 *
 * const value1 = Money$.makeFromCents(300);
 * const value2 = Money$.makeFromCents(100);
 *
 * const result = Money$.subtract(value1, value2);
 *
 * assert.deepStrictEqual(result.valueInCents, 200);
 * ```
 *
 * @category Mapping
 */
const subtract: {
  (n: Money): (a: Money) => Money;
  (self: Money, n: Money): Money;
} = dual(
  2,
  (a: Money, n: Money): Money =>
    money.makeFromCents(a.valueInCents - n.valueInCents),
);

/**
 * Computes the total of an array of `Money` instances.
 *
 * This function takes an array of `Money` objects and returns their accumulated sum as a single `Money` instance.
 * It's particularly useful for aggregating monetary values, such as when calculating the total cost of items,
 * balances, or transactions.
 *
 * @example
 * ```ts
 * import { Money$ } from "@ender/shared/core";
 *
 * const values = [
 *   Money$.makeFromCents(100),
 *   Money$.makeFromCents(200),
 *   Money$.makeFromCents(300),
 * ];
 *
 * assert.deepStrictEqual(Money$.total(values).valueInCents, 600);
 * ```
 *
 * @category Folding
 */
const total = (n: Money[]) => n.reduce(add, zero());

/**
 * Returns the absolute value of the provided `Money` instance.
 *
 * This method ensures that the monetary value is converted to its non-negative equivalent.
 * It can be useful in scenarios where negative values need to be avoided, such as displaying
 * values or performing calculations that require positive amounts.
 *
 * @example
 * ```ts
 * import { Money$ } from "@ender/shared/core";
 *
 * const negativeValue = Money$.makeFromCents(-500);
 * const positiveValue = Money$.makeFromCents(500);
 *
 * assert.deepStrictEqual(Money$.abs(negativeValue).valueInCents, 500);
 * assert.deepStrictEqual(Money$.abs(positiveValue).valueInCents, 500);
 * ```
 *
 * @category Mapping
 */
const abs: (n: Money) => Money = (n: Money) =>
  money.makeFromCents(Math.abs(n.valueInCents));

/**
 * Multiplies the value of the provided `Money` instance by a specified factor.
 *
 * This method allows performing scaling operations on monetary values.
 *
 * @example
 * ```ts
 * import { Money$ } from "@ender/shared/core";
 *
 * const value = Money$.makeFromCents(200);
 *
 * assert.deepStrictEqual(Money$.multiply(value, 2).valueInCents, 400);
 * assert.deepStrictEqual(Money$.multiply(value, 3).valueInCents, 600);
 * ```
 *
 * @category Mapping
 */
const multiply: {
  (n: number): (a: Money) => Money;
  (self: Money, n: number): Money;
} = dual(
  2,
  (a: Money, n: number): Money => money.makeFromCents(a.valueInCents * n),
);

/**
 * Divides the value of the provided `Money` instance by a specified factor.
 *
 * This function allows performing scaling operations on monetary values.
 * Since divide-by-zero will cause invalid behavior, this function returns an Option<Money>
 *   to account for that scenario.
 *
 * @example
 * ```ts
 * import { Money$ } from "@ender/shared/core";
 *
 * const value = Money$.makeFromCents(200);
 *
 * assert.deepStrictEqual(Money$.divide(value, 2).valueInCents, O.some(Money$.makeFromCents(100)));
 * assert.deepStrictEqual(Money$.divide(value, 0).valueInCents, O.none());
 * assert.deepStrictEqual(Money$.multiply(value, 4).valueInCents, O.some(Money$.makeFromCents(50)));
 * ```
 *
 * @category Mapping
 */
const divide: {
  (n: number): (a: Money) => O.Option<Money>;
  (self: Money, n: number): O.Option<Money>;
} = dual(
  2,
  (self: Money, that: number): O.Option<Money> =>
    that === 0
      ? O.none<Money>()
      : O.some(money.makeFromCents(self.valueInCents / that)),
);

/**
 * Conditionally negates the value of a `Money` instance based on a predicate function.
 * The predicate function is provided with the operand Money$.Money instance.
 *
 * This can be used in functional programming scenarios where the negate operation
 * needs to be applied conditionally, such as reversing the sign based on specific
 * criteria.
 *
 * @example
 * ```ts
 * import { Money$ } from "@ender/shared/core";
 *
 * const positive = Money$.makeFromCents(200);
 * const negative = Money$.makeFromCents(-300);
 *
 * // A pipe execution that negates only if the value is positive
 * const negationFn = Money$.negateWhen(
 *   Money$.isPositive // Predicate function checking positivity
 * );
 *
 * assert.deepStrictEqual(negationFn(positive).valueInCents, -200);
 * assert.deepStrictEqual(negationFn(negative).valueInCents, -300);
 * ```
 *
 * @example
 * ```ts
 * import { Money$ } from "@ender/shared/core";
 * import { pipe } from "effect";
 *
 * const value = Money$.makeFromCents(500);
 *
 * assert.deepStrictEqual(Money$.negateWhen(value, Money$.isPositive).valueInCents, -500);
 * ```
 *
 * @category Mapping
 */
const negateWhen: {
  (f: (a: Money) => boolean): (self: Money) => Money;
  (self: Money, f: (a: Money) => boolean): Money;
} = dual(
  2,
  (a: Money, f: (a: Money) => boolean): Money =>
    f(a)
      ? money.makeFromCents(-a.valueInCents)
      : money.makeFromCents(a.valueInCents),
);

/**
 * Negates the value of the provided `Money` instance, effectively flipping its sign.
 *
 * This can be useful for applying negative operations to financial values, such as converting a
 * positive amount into a debit or reversing the charge of a negative amount.
 *
 * @example
 * ```ts
 * import { Money$ } from "@ender/shared/core";
 *
 * const positive = Money$.makeFromCents(500);
 * const negative = Money$.makeFromCents(-500);
 *
 * assert.deepStrictEqual(Money$.negate(positive).valueInCents, -500);
 * assert.deepStrictEqual(Money$.negate(negative).valueInCents, 500);
 * ```
 *
 * @category Mapping
 */
const negate: (n: Money) => Money = (n: Money) =>
  money.makeFromCents(-n.valueInCents);

/**
 * Checks whether the provided `Money` instance is zero, based on its internal value in cents.
 *
 * @example
 * ```ts
 * import { Money$ } from "@ender/shared/core";
 *
 * const zero = Money$.zero();
 * const nonZero = Money$.makeFromCents(20);
 *
 * assert.deepStrictEqual(Money$.isZero(zero), true);
 * assert.deepStrictEqual(Money$.isZero(nonZero), false);
 * ```
 *
 * @category Guards
 */
const isZero: (n: Money) => boolean = (n: Money) => n.valueInCents === 0;

/**
 * Checks whether the provided `Money` instance is greater than zero, based on its internal value in cents.
 *
 * @example
 * ```ts
 * import { Money$ } from "@ender/shared/core";
 *
 * const value1 = Money$.makeFromCents(0);
 * const value2 = Money$.makeFromCents(20_00);
 * const value3 = Money$.makeFromCents(-120_00);
 *
 * assert.deepStrictEqual(Money$.isPositive(value1), false);
 * assert.deepStrictEqual(Money$.isPositive(value2), true);
 * assert.deepStrictEqual(Money$.isPositive(value3), false);
 * ```
 *
 * @category Guards
 */
const isPositive: (n: Money) => boolean = (n: Money) => n.valueInCents > 0;

/**
 * Checks whether the provided `Money` instance is less than zero, based on its internal value in cents.
 *
 * @example
 * ```ts
 * import { Money$ } from "@ender/shared/core";
 *
 * const value1 = Money$.makeFromCents(0);
 * const value2 = Money$.makeFromCents(20_00);
 * const value3 = Money$.makeFromCents(-120_00);
 *
 * assert.deepStrictEqual(Money$.isNegative(value1), false);
 * assert.deepStrictEqual(Money$.isNegative(value2), false);
 * assert.deepStrictEqual(Money$.isNegative(value3), true);
 * ```
 *
 * @category Guards
 */
const isNegative: (n: Money) => boolean = (n: Money) => n.valueInCents < 0;

/**
 * Equivalence is used to determine if two `Money` objects are equivalent based on their internal value.
 *
 * This can be particularly useful in scenarios where checks for equality, filtering in lists, or other equality-based operations
 * are required.
 *
 * @example
 * ```ts
 * import { Money$ } from '@ender/shared/core';
 * import { Array } from 'effect';
 *
 * const money1 = Money$.makeFromCents(100);
 * const money2 = Money$.makeFromCents(100);
 * const money3 = Money$.makeFromCents(50);
 *
 * // Check equality between two Money instances
 * assert.deepStrictEqual(Money$.Equivalence(money1, money2), true);
 * assert.deepStrictEqual(Money$.Equivalence(money1, money3), false);
 *
 * // Example with Array.containsWith
 * const moneyArray = [Money$.makeFromCents(200), Money$.makeFromCents(100), Money$.makeFromCents(50)];
 * const contains100 = Array.containsWith(Money$.Equivalence)(moneyArray, Money$.makeFromCents(100));
 *
 * assert.deepStrictEqual(contains100, true);
 *
 * const contains300 = Array.containsWith(Money$.Equivalence)(moneyArray, Money$.makeFromCents(300));
 * assert.deepStrictEqual(contains300, false);
 * ```
 *
 * @category instances
 */
const Equivalence: Eq.Equivalence<Money> = (self, that) => {
  return self.valueInCents === that.valueInCents;
};

/**
 * Used for Effect ordering checks, within arrays, options, etc.
 *
 * @example
 * ```ts
 * import { Order as MoneyOrder, fromDollars } from './money';
 * import { Order } from 'effect';
 *
 * const money1 = fromDollars(50);
 * const money2 = fromDollars(100);
 *
 * const isLessOrEqual = Order.lessThanOrEqual(MoneyOrder);
 * const isGreater = Order.greaterThan(MoneyOrder);
 *
 * assert.deepStrictEqual(isLessOrEqual(money1, money2), true);
 * assert.deepStrictEqual(isGreater(money1, money2), false);
 * assert.deepStrictEqual(isGreater(money2, money1), true);
 *```
 *
 * Example of using `Money$.Order` to sort an array of allocation objects by their `amount`.
 * @example
 * ```ts
 * import { Money$, Order as MoneyOrder } from './money';
 * import { Array } from 'effect';
 *
 * const allocations = [
 *   { id: 1, amount: Money$.makeFromCents(300) },
 *   { id: 2, amount: Money$.makeFromCents(100) },
 *   { id: 3, amount: Money$.makeFromCents(200) },
 * ];
 *
 * const mapSortFn = Array.sortWith((a) => a.amount, MoneyOrder);
 *
 * assert.deepStrictEqual(mapSortFn(sortedAllocations), [
 *   { id: 2, amount: Money$.makeFromCents(100) },
 *   { id: 3, amount: Money$.makeFromCents(200) },
 *   { id: 1, amount: Money$.makeFromCents(300) },
 * ]);
 * ```
 *
 * @category instances
 */
const Order: Od.Order<Money> = (self, that) => {
  return N.sign(self.valueInCents - that.valueInCents);
};

/**
 * Parses the provided input into a `Money` instance, returning an Option containing the result
 *
 * This function supports parsing values of type `number`, `string`, `Money$.Money`, `null`, or `undefined`.
 * - If the input is a string, it is assumed to be in dollars.
 * - If the input is a number, it is treated as an already defined value in cents.
 * - If the input is a `Money` object, it is returned
 * - If the input is `null` or `undefined` or invalid, it returns `None`.
 *
 * @example
 * ```ts
 * import { Option as O } from "effect";
 * import { Money$ } from "@ender/shared/core";
 *
 * assert.deepStrictEqual(Money$.parse("$10.50"), O.some(Money.makeFromCents(1050)));
 * assert.deepStrictEqual(Money$.parse(250), O.some(Money.makeFromCents(250)));
 * assert.deepStrictEqual(Money$.parse(Money$.makeFromCents(790)), O.some(Money.makeFromCents(790)));
 * assert.deepStrictEqual(Money$.parse("invalid"), O.none());
 * assert.deepStrictEqual(Money$.parse(null), O.none());
 * ```
 *
 * @category constructors
 */
const parse: (
  v: number | string | Money | null | undefined,
) => O.Option<Money> = Sch.decodeUnknownOption(Schema);

/**
 * Parses the provided input into a `Money` instance, returning an Either.Right containing the result,
 * or an Either.Left containing the parsing error
 *
 * This function supports parsing values of type `number`, `string`, `Money$.Money`, `null`, or `undefined`.
 * - If the input is a string, it is assumed to be in dollars.
 * - If the input is a number, it is treated as an already defined value in cents.
 * - If the input is a `Money` object, it is returned
 * - If the input is `null` or `undefined` or invalid, it returns `None`.
 *
 * @example
 * ```ts
 * import { Either as E } from "effect";
 * import { Money$ } from "@ender/shared/core";
 *
 * assert.deepStrictEqual(Money$.safeParse("$10.50"), E.right(Money.makeFromCents(1050)));
 * assert.deepStrictEqual(Money$.safeParse(250), E.right(Money.makeFromCents(250)));
 * assert.deepStrictEqual(Money$.safeParse(Money$.makeFromCents(790)), E.right(Money.makeFromCents(790)));
 * assert.deepStrictEqual(Money$.safeParse("invalid"), E.left(...));
 * assert.deepStrictEqual(Money$.safeParse(null), E.left(...));
 * ```
 *
 * @category constructors
 */
const safeParse: (
  v: number | string | Money | null | undefined,
) => E.Either<Money, ParseResult.ParseError> = Sch.decodeUnknownEither(Schema);

/**
 * Parses the provided input into a `Money` instance, returning a valid `Money` object.
 *
 * This function supports parsing values of type `number`, `string`, `Money$,` `null`, or `undefined`.
 * - If the input is a string, it is assumed to be in dollars.
 * - If the input is a number, it is treated as an already defined value in cents.
 * - If the input is a `Money` object, it is returned.
 * - If the input is `null`, `undefined`, or invalid, it defaults to `Money$.zero`.
 *
 * @example
 * ```ts
 * import { Money$ } from "@ender/shared/core";
 *
 * const result = Money$.of("$10.50");
 * assert.deepStrictEqual(result, Money$.makeFromCents(1050));
 *
 * const result2 = Money$.of(250);
 * assert.deepStrictEqual(result2, Money$.makeFromCents(250));
 *
 * const result3 = Money$.of(Money$.makeFromCents(790));
 * assert.deepStrictEqual(result3, Money$.makeFromCents(790));
 *
 * const result4 = Money$.of("invalid");
 * assert.deepStrictEqual(result4, Money$.zero);
 *
 * const result5 = Money$.of(null);
 * assert.deepStrictEqual(result5, Money$.zero);
 * ```
 *
 * @category constructors
 */
const of: (v: number | string | Money | null | undefined) => Money = flow(
  parse,
  O.getOrElse(zero),
);

/**
 * @deprecated use `Money$.parse`
 */
const ofOrNull: (
  v: number | string | Money | null | undefined,
) => Money | null = flow(parse, O.getOrNull);

export {
  Equivalence,
  Formats,
  Order,
  Schema,
  TypeId,
  abs,
  add,
  divide,
  fromDollars,
  isMoney,
  isNegative,
  isPositive,
  isZero,
  makeFromCents,
  multiply,
  negate,
  negateWhen,
  of,
  ofOrNull,
  parse,
  safeParse,
  subtract,
  total as sum,
  toDollars,
  toFormatted,
  zero,
};

// eslint-disable-next-line import/group-exports
export type { Format, Money, Serialized };
