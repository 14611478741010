/**
 * HTTP Method: POST
 * Pathname: /properties/{propertyId}/owners
 * @function setOwners
 * @memberof OwnershipAPI
 * @param {OwnershipAPISetOwnersPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId, Percent, SerializesInto } from "@ender/shared/core";
import type { Party } from "@ender/shared/generated/ender.model.payments";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type OwnershipAPISetOwnersPathParams = {
  propertyId: EnderId;
};

type OwnershipAPISetOwnersSearchParams = {
  token?: string | undefined;
};

type OwnershipAPISetOwnersBodyParams = {
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  owners?: any[] | undefined;
  ownershipPercent: SerializesInto<Percent>;
  partyId: EnderId;
  partyType: Party;
};

type OwnershipAPISetOwnersPayload = OwnershipAPISetOwnersPathParams &
  OwnershipAPISetOwnersSearchParams &
  OwnershipAPISetOwnersBodyParams;

function setOwnersUnsafeEffect(payload: OwnershipAPISetOwnersPayload) {
  const { propertyId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/properties/${propertyId}/owners`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function setOwners(
  payload: OwnershipAPISetOwnersPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    setOwnersUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { setOwners };
export type {
  OwnershipAPISetOwnersBodyParams,
  OwnershipAPISetOwnersPathParams,
  OwnershipAPISetOwnersPayload,
  OwnershipAPISetOwnersSearchParams,
};
