/**
 * HTTP Method: GET
 * Pathname: /vendors/tags
 * @function getExistingVendorTags
 * @memberof VendorsAPI
 * @param {VendorsAPIGetExistingVendorTagsPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<GetExistingVendorTagsResponse>}
 */
import { Effect, Function as F } from "effect";

import type { GetExistingVendorTagsResponse } from "@ender/shared/generated/ender.api.misc.response";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type VendorsAPIGetExistingVendorTagsSearchParams = {
  token?: string | undefined;
};

type VendorsAPIGetExistingVendorTagsPayload =
  VendorsAPIGetExistingVendorTagsSearchParams;

function getExistingVendorTagsUnsafeEffect(
  payload: VendorsAPIGetExistingVendorTagsPayload,
) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, GetExistingVendorTagsResponse>({
        body,
        decode: unsafeDecodeJsonResponse<GetExistingVendorTagsResponse>({}),
        encode: encodeNoBody({ method: "GET" }),
        pathname: "/vendors/tags",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getExistingVendorTags(
  payload: VendorsAPIGetExistingVendorTagsPayload,
  options?: { signal?: AbortSignal },
): Promise<GetExistingVendorTagsResponse> {
  return F.pipe(
    payload,
    getExistingVendorTagsUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getExistingVendorTags };
export type {
  VendorsAPIGetExistingVendorTagsPayload,
  VendorsAPIGetExistingVendorTagsSearchParams,
};
