/**
 * HTTP Method: POST
 * Pathname: /leases/{leaseId}/tasks
 * @function createTenantTask
 * @memberof TasksAPI
 * @param {TasksAPICreateTenantTaskPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<Task>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type {
  Task,
  TaskTaskPriority,
} from "@ender/shared/generated/ender.model.task";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type TasksAPICreateTenantTaskPathParams = {
  leaseId: EnderId;
};

type TasksAPICreateTenantTaskSearchParams = {
  token?: string | undefined;
};

type TasksAPICreateTenantTaskBodyParams = {
  description: string;
  permissionToEnter?: boolean | undefined;
  priority?: TaskTaskPriority | undefined;
};

type TasksAPICreateTenantTaskPayload = TasksAPICreateTenantTaskPathParams &
  TasksAPICreateTenantTaskSearchParams &
  TasksAPICreateTenantTaskBodyParams;

function createTenantTaskUnsafeEffect(
  payload: TasksAPICreateTenantTaskPayload,
) {
  const { leaseId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, Task>({
        body,
        decode: unsafeDecodeJsonResponse<Task>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/leases/${leaseId}/tasks`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function createTenantTask(
  payload: TasksAPICreateTenantTaskPayload,
  options?: { signal?: AbortSignal },
): Promise<Task> {
  return F.pipe(
    payload,
    createTenantTaskUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { createTenantTask };
export type {
  TasksAPICreateTenantTaskBodyParams,
  TasksAPICreateTenantTaskPathParams,
  TasksAPICreateTenantTaskPayload,
  TasksAPICreateTenantTaskSearchParams,
};
