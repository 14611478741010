/**
 * HTTP Method: GET
 * Pathname: /vendors/{vendorId}
 * @function getVendor
 * @memberof VendorsAPI
 * @param {VendorsAPIGetVendorPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<GetVendorResponse>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { GetVendorResponse } from "@ender/shared/generated/ender.api.core.response";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type VendorsAPIGetVendorPathParams = {
  vendorId: EnderId;
};

type VendorsAPIGetVendorSearchParams = {
  token?: string | undefined;
};

type VendorsAPIGetVendorPayload = VendorsAPIGetVendorPathParams &
  VendorsAPIGetVendorSearchParams;

function getVendorUnsafeEffect(payload: VendorsAPIGetVendorPayload) {
  const { vendorId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, GetVendorResponse>({
        body,
        decode: unsafeDecodeJsonResponse<GetVendorResponse>({}),
        encode: encodeNoBody({ method: "GET" }),
        pathname: `/vendors/${vendorId}`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getVendor(
  payload: VendorsAPIGetVendorPayload,
  options?: { signal?: AbortSignal },
): Promise<GetVendorResponse> {
  return F.pipe(
    payload,
    getVendorUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getVendor };
export type {
  VendorsAPIGetVendorPathParams,
  VendorsAPIGetVendorPayload,
  VendorsAPIGetVendorSearchParams,
};
