/**
 * HTTP Method: POST
 * Pathname: /tasks/{taskId}/close
 * @function closeTask
 * @memberof TasksAPI
 * @param {TasksAPICloseTaskPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { TaskCloseReason } from "@ender/shared/generated/ender.model.task";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type TasksAPICloseTaskPathParams = {
  taskId: EnderId;
};

type TasksAPICloseTaskSearchParams = {
  token?: string | undefined;
};

type TasksAPICloseTaskBodyParams = {
  closeReason?: TaskCloseReason | undefined;
  closeReasonDescription?: string | undefined;
  isTaskV1?: boolean | undefined;
};

type TasksAPICloseTaskPayload = TasksAPICloseTaskPathParams &
  TasksAPICloseTaskSearchParams &
  TasksAPICloseTaskBodyParams;

function closeTaskUnsafeEffect(payload: TasksAPICloseTaskPayload) {
  const { taskId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/tasks/${taskId}/close`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function closeTask(
  payload: TasksAPICloseTaskPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    closeTaskUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { closeTask };
export type {
  TasksAPICloseTaskBodyParams,
  TasksAPICloseTaskPathParams,
  TasksAPICloseTaskPayload,
  TasksAPICloseTaskSearchParams,
};
