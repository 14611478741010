/**
 * HTTP Method: GET
 * Pathname: /properties/{propertyId}
 * @function getProperty
 * @memberof PropertiesAPI
 * @param {PropertiesAPIGetPropertyPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<PropertySerializerDeepPropertyResponse>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { PropertySerializerDeepPropertyResponse } from "@ender/shared/generated/ender.arch.serializer.core";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type PropertiesAPIGetPropertyPathParams = {
  propertyId: EnderId;
};

type PropertiesAPIGetPropertySearchParams = {
  token?: string | undefined;
};

type PropertiesAPIGetPropertyPayload = PropertiesAPIGetPropertyPathParams &
  PropertiesAPIGetPropertySearchParams;

function getPropertyUnsafeEffect(payload: PropertiesAPIGetPropertyPayload) {
  const { propertyId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, PropertySerializerDeepPropertyResponse>({
        body,
        decode:
          unsafeDecodeJsonResponse<PropertySerializerDeepPropertyResponse>({}),
        encode: encodeNoBody({ method: "GET" }),
        pathname: `/properties/${propertyId}`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getProperty(
  payload: PropertiesAPIGetPropertyPayload,
  options?: { signal?: AbortSignal },
): Promise<PropertySerializerDeepPropertyResponse> {
  return F.pipe(
    payload,
    getPropertyUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getProperty };
export type {
  PropertiesAPIGetPropertyPathParams,
  PropertiesAPIGetPropertyPayload,
  PropertiesAPIGetPropertySearchParams,
};
