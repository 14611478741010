/**
 * HTTP Method: GET
 * Pathname: /properties/{propertyId}/units
 * @function getUnitsForProperty
 * @memberof PropertiesAPI
 * @param {PropertiesAPIGetUnitsForPropertyPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<GetUnitsForPropertyResponse[]>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { GetUnitsForPropertyResponse } from "@ender/shared/generated/ender.api.core.response";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type PropertiesAPIGetUnitsForPropertyPathParams = {
  propertyId: EnderId;
};

type PropertiesAPIGetUnitsForPropertySearchParams = {
  token?: string | undefined;
  includeLeaseDetails?: boolean | undefined;
};

type PropertiesAPIGetUnitsForPropertyPayload =
  PropertiesAPIGetUnitsForPropertyPathParams &
    PropertiesAPIGetUnitsForPropertySearchParams;

function getUnitsForPropertyUnsafeEffect(
  payload: PropertiesAPIGetUnitsForPropertyPayload,
) {
  const { propertyId, token, includeLeaseDetails, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, GetUnitsForPropertyResponse[]>({
        body,
        decode: unsafeDecodeJsonResponse<GetUnitsForPropertyResponse[]>({}),
        encode: encodeNoBody({ method: "GET" }),
        pathname: `/properties/${propertyId}/units`,
        searchParams: [
          ["token", token],
          ["includeLeaseDetails", includeLeaseDetails],
        ],
      }),
    ),
  );
}

function getUnitsForProperty(
  payload: PropertiesAPIGetUnitsForPropertyPayload,
  options?: { signal?: AbortSignal },
): Promise<GetUnitsForPropertyResponse[]> {
  return F.pipe(
    payload,
    getUnitsForPropertyUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getUnitsForProperty };
export type {
  PropertiesAPIGetUnitsForPropertyPathParams,
  PropertiesAPIGetUnitsForPropertyPayload,
  PropertiesAPIGetUnitsForPropertySearchParams,
};
