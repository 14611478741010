"use client";

import { Array as A, Function as F, Option as O } from "effect";
import { useCallback, useMemo, useState } from "react";
import type { RadioGroupData } from "@ender/shared/ds/radio-group";
import type { TwilioClientVerificationCodeChannel } from "@ender/shared/generated/com.ender.common.arch.client";
import { TwilioClientVerificationCodeChannelEnum } from "@ender/shared/generated/com.ender.common.arch.client";
import { MultiFactorChannelSelectFormView } from "./multi-factor-channel-select-form.view";
type ChannelRadioItem = RadioGroupData<TwilioClientVerificationCodeChannel>;
type ChannelKeys = "email" | "sms";
type ChannelMap = { [K in ChannelKeys]: O.Option<ChannelRadioItem> };
const channelMap: ChannelMap = {
  email: O.some({
    label: "Email",
    value: TwilioClientVerificationCodeChannelEnum.EMAIL
  }),
  sms: O.some({
    label: "SMS",
    value: TwilioClientVerificationCodeChannelEnum.SMS
  })
};
type MultiFactorChannelSelectFormViewProps = {
  errorMessage: O.Option<string>;
  hasEmail: boolean;
  hasPhone: boolean;
  initialChannel: O.Option<TwilioClientVerificationCodeChannel>;
  isFetching: boolean;
  onSendCode: (channel: TwilioClientVerificationCodeChannel) => void;
};
function MultiFactorChannelSelectFormController(props: MultiFactorChannelSelectFormViewProps) {
  const {
    errorMessage,
    hasEmail,
    hasPhone,
    initialChannel,
    isFetching,
    onSendCode
  } = props;
  const availableChannels: ChannelRadioItem[] = useMemo(() => {
    return F.pipe([F.pipe(channelMap.email, O.filter(() => hasEmail)), F.pipe(channelMap.sms, O.filter(() => hasPhone))], A.filter(O.isSome), O.all, O.getOrElse(F.constant([])));
  }, [hasPhone, hasEmail]);
  const [channel, setChannel] = useState(initialChannel);
  const handleCancelClick = useCallback(() => {
    if (O.isNone(initialChannel)) {
      return;
    }
    onSendCode(O.getOrThrow(initialChannel));
  }, [onSendCode, initialChannel]);
  const handleSendCodeClick = useCallback(() => {
    if (O.isNone(channel)) {
      return;
    }
    onSendCode(O.getOrThrow(channel));
  }, [onSendCode, channel]);
  return <MultiFactorChannelSelectFormView availableChannels={availableChannels} channel={channel} errorMessage={errorMessage} isCancelDisabled={O.isNone(initialChannel)} isFetching={isFetching} isSendCodeDisabled={O.isNone(channel)} onCancelClick={handleCancelClick} onChannelChange={setChannel} onSendCodeClick={handleSendCodeClick} data-sentry-element="MultiFactorChannelSelectFormView" data-sentry-component="MultiFactorChannelSelectFormController" data-sentry-source-file="multi-factor-channel-select-form.controller.tsx" />;
}
export { MultiFactorChannelSelectFormController };