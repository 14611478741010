/**
 * HTTP Method: POST
 * Pathname: /properties/getPropertyCodeIdMap
 * @function getPropertyCodeIdMap
 * @memberof PropertiesAPI
 * @param {PropertiesAPIGetPropertyCodeIdMapPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<Partial<Record<string, Property>>>}
 */
import { Effect, Function as F } from "effect";

import type { Property } from "@ender/shared/generated/ender.model.core.property";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type PropertiesAPIGetPropertyCodeIdMapSearchParams = {
  token?: string | undefined;
};

type PropertiesAPIGetPropertyCodeIdMapBodyParams = {
  friendlyIds: string[];
};

type PropertiesAPIGetPropertyCodeIdMapPayload =
  PropertiesAPIGetPropertyCodeIdMapSearchParams &
    PropertiesAPIGetPropertyCodeIdMapBodyParams;

function getPropertyCodeIdMapUnsafeEffect(
  payload: PropertiesAPIGetPropertyCodeIdMapPayload,
) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, Partial<Record<string, Property>>>({
        body,
        decode: unsafeDecodeJsonResponse<Partial<Record<string, Property>>>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/properties/getPropertyCodeIdMap",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getPropertyCodeIdMap(
  payload: PropertiesAPIGetPropertyCodeIdMapPayload,
  options?: { signal?: AbortSignal },
): Promise<Partial<Record<string, Property>>> {
  return F.pipe(
    payload,
    getPropertyCodeIdMapUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getPropertyCodeIdMap };
export type {
  PropertiesAPIGetPropertyCodeIdMapBodyParams,
  PropertiesAPIGetPropertyCodeIdMapPayload,
  PropertiesAPIGetPropertyCodeIdMapSearchParams,
};
