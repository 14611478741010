/**
 * HTTP Method: GET
 * Pathname: /tasks/{taskId}/vendorHistory
 * @function getVendorHistory
 * @memberof TasksAPI
 * @param {TasksAPIGetVendorHistoryPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<TasksAPIGetVendorHistoryResponse[]>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

import type { TasksAPIGetVendorHistoryResponse } from "../tasks-api-get-vendor-history-response";

type TasksAPIGetVendorHistoryPathParams = {
  taskId: EnderId;
};

type TasksAPIGetVendorHistorySearchParams = {
  token?: string | undefined;
};

type TasksAPIGetVendorHistoryPayload = TasksAPIGetVendorHistoryPathParams &
  TasksAPIGetVendorHistorySearchParams;

function getVendorHistoryUnsafeEffect(
  payload: TasksAPIGetVendorHistoryPayload,
) {
  const { taskId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, TasksAPIGetVendorHistoryResponse[]>({
        body,
        decode: unsafeDecodeJsonResponse<TasksAPIGetVendorHistoryResponse[]>(
          {},
        ),
        encode: encodeNoBody({ method: "GET" }),
        pathname: `/tasks/${taskId}/vendorHistory`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getVendorHistory(
  payload: TasksAPIGetVendorHistoryPayload,
  options?: { signal?: AbortSignal },
): Promise<TasksAPIGetVendorHistoryResponse[]> {
  return F.pipe(
    payload,
    getVendorHistoryUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getVendorHistory };
export type {
  TasksAPIGetVendorHistoryPathParams,
  TasksAPIGetVendorHistoryPayload,
  TasksAPIGetVendorHistorySearchParams,
};
