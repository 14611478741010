/**
 * HTTP Method: POST
 * Pathname: /updateApprovalProcess
 * @function updateApprovalProcess
 * @memberof ApprovalsAPI
 * @param {ApprovalsAPIUpdateApprovalProcessPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<ApprovalProcess>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId, Money, SerializesInto } from "@ender/shared/core";
import type { ApprovalProcess } from "@ender/shared/generated/ender.model.approvals";
import type { InvoicePayableType } from "@ender/shared/generated/ender.model.payments.invoice";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type ApprovalsAPIUpdateApprovalProcessSearchParams = {
  token?: string | undefined;
};

type ApprovalsAPIUpdateApprovalProcessBodyParams = {
  approvalProcessId: EnderId;
  maxAmount?: SerializesInto<Money> | undefined;
  minAmount?: SerializesInto<Money> | undefined;
  payableTypes: InvoicePayableType[];
  trailingPeriodNumDays?: number | undefined;
  trailingPeriodThreshold?: SerializesInto<Money> | undefined;
};

type ApprovalsAPIUpdateApprovalProcessPayload =
  ApprovalsAPIUpdateApprovalProcessSearchParams &
    ApprovalsAPIUpdateApprovalProcessBodyParams;

function updateApprovalProcessUnsafeEffect(
  payload: ApprovalsAPIUpdateApprovalProcessPayload,
) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, ApprovalProcess>({
        body,
        decode: unsafeDecodeJsonResponse<ApprovalProcess>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/updateApprovalProcess",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function updateApprovalProcess(
  payload: ApprovalsAPIUpdateApprovalProcessPayload,
  options?: { signal?: AbortSignal },
): Promise<ApprovalProcess> {
  return F.pipe(
    payload,
    updateApprovalProcessUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { updateApprovalProcess };
export type {
  ApprovalsAPIUpdateApprovalProcessBodyParams,
  ApprovalsAPIUpdateApprovalProcessPayload,
  ApprovalsAPIUpdateApprovalProcessSearchParams,
};
