/**
 * HTTP Method: POST
 * Pathname: /users/{userId}/nylasAccount
 * @function connectNylasAccount
 * @memberof NylasAPI
 * @param {NylasAPIConnectNylasAccountPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type NylasAPIConnectNylasAccountPathParams = {
  userId: EnderId;
};

type NylasAPIConnectNylasAccountSearchParams = {
  token?: string | undefined;
};

type NylasAPIConnectNylasAccountBodyParams = {
  code: string;
};

type NylasAPIConnectNylasAccountPayload =
  NylasAPIConnectNylasAccountPathParams &
    NylasAPIConnectNylasAccountSearchParams &
    NylasAPIConnectNylasAccountBodyParams;

function connectNylasAccountUnsafeEffect(
  payload: NylasAPIConnectNylasAccountPayload,
) {
  const { userId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/users/${userId}/nylasAccount`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function connectNylasAccount(
  payload: NylasAPIConnectNylasAccountPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    connectNylasAccountUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { connectNylasAccount };
export type {
  NylasAPIConnectNylasAccountBodyParams,
  NylasAPIConnectNylasAccountPathParams,
  NylasAPIConnectNylasAccountPayload,
  NylasAPIConnectNylasAccountSearchParams,
};
