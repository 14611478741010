/**
 * HTTP Method: POST
 * Pathname: /deleteCounty
 * @function deleteCounty
 * @memberof CountiesAPI
 * @param {CountiesAPIDeleteCountyPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type CountiesAPIDeleteCountySearchParams = {
  token?: string | undefined;
};

type CountiesAPIDeleteCountyBodyParams = {
  countyId: EnderId;
};

type CountiesAPIDeleteCountyPayload = CountiesAPIDeleteCountySearchParams &
  CountiesAPIDeleteCountyBodyParams;

function deleteCountyUnsafeEffect(payload: CountiesAPIDeleteCountyPayload) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/deleteCounty",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function deleteCounty(
  payload: CountiesAPIDeleteCountyPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    deleteCountyUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { deleteCounty };
export type {
  CountiesAPIDeleteCountyBodyParams,
  CountiesAPIDeleteCountyPayload,
  CountiesAPIDeleteCountySearchParams,
};
