/**
 * HTTP Method: POST
 * Pathname: /units/zones/{zoneId}
 * @function updateUnitZone
 * @memberof UnitZonesAPI
 * @param {UnitZonesAPIUpdateUnitZonePayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<UnitZone>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { UnitZone } from "@ender/shared/generated/ender.model.core.unit.zone";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type UnitZonesAPIUpdateUnitZonePathParams = {
  zoneId: EnderId;
};

type UnitZonesAPIUpdateUnitZoneSearchParams = {
  token?: string | undefined;
};

type UnitZonesAPIUpdateUnitZoneBodyParams = {
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  data?: any | undefined;
  description?: string | undefined;
  unitAreaId?: EnderId | undefined;
};

type UnitZonesAPIUpdateUnitZonePayload = UnitZonesAPIUpdateUnitZonePathParams &
  UnitZonesAPIUpdateUnitZoneSearchParams &
  UnitZonesAPIUpdateUnitZoneBodyParams;

function updateUnitZoneUnsafeEffect(
  payload: UnitZonesAPIUpdateUnitZonePayload,
) {
  const { zoneId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, UnitZone>({
        body,
        decode: unsafeDecodeJsonResponse<UnitZone>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/units/zones/${zoneId}`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function updateUnitZone(
  payload: UnitZonesAPIUpdateUnitZonePayload,
  options?: { signal?: AbortSignal },
): Promise<UnitZone> {
  return F.pipe(
    payload,
    updateUnitZoneUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { updateUnitZone };
export type {
  UnitZonesAPIUpdateUnitZoneBodyParams,
  UnitZonesAPIUpdateUnitZonePathParams,
  UnitZonesAPIUpdateUnitZonePayload,
  UnitZonesAPIUpdateUnitZoneSearchParams,
};
