// We use `void 0` because `undefined` is not a keyword or a reserved word it's a property on the Global Object/Environment.
// Don't believe me try -> Object.prototype.hasOwnProperty.call(globalThis, "undefined");
// This means some weirdo could do this -> const undefined = "I'm a weirdo";
// Using `void 0` here makes Werner happy and using the UNDEFINED import everywhere else makes Alessandro happy...
// While making the code safe from weirdos and the imports traceable with NX
const UNDEFINED = void 0;
const NULL = null;

// in case the world ends and `void 0` is not recognized as equal to `undefined` by the tsc
// this is a way to use the type of UNDEFINED as an explicit type
type Undefined = typeof UNDEFINED;
type Null = typeof NULL;

export { NULL, UNDEFINED };
export type { Null, Undefined };
