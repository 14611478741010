/**
 * HTTP Method: POST
 * Pathname: /approvals/pipeline
 * @function getApprovalPipeline
 * @memberof ApprovalsAPI
 * @param {ApprovalsAPIGetApprovalPipelinePayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<GetApprovalPipelineResponse[]>}
 */
import { Effect, Function as F } from "effect";

import type { GetApprovalPipelineResponse } from "@ender/shared/generated/ender.api.misc.response";
import type { ApprovalProcessType } from "@ender/shared/generated/ender.model.approvals";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type ApprovalsAPIGetApprovalPipelineSearchParams = {
  token?: string | undefined;
};

type ApprovalsAPIGetApprovalPipelineBodyParams = {
  approvalProcessType: ApprovalProcessType;
};

type ApprovalsAPIGetApprovalPipelinePayload =
  ApprovalsAPIGetApprovalPipelineSearchParams &
    ApprovalsAPIGetApprovalPipelineBodyParams;

function getApprovalPipelineUnsafeEffect(
  payload: ApprovalsAPIGetApprovalPipelinePayload,
) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, GetApprovalPipelineResponse[]>({
        body,
        decode: unsafeDecodeJsonResponse<GetApprovalPipelineResponse[]>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/approvals/pipeline",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getApprovalPipeline(
  payload: ApprovalsAPIGetApprovalPipelinePayload,
  options?: { signal?: AbortSignal },
): Promise<GetApprovalPipelineResponse[]> {
  return F.pipe(
    payload,
    getApprovalPipelineUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getApprovalPipeline };
export type {
  ApprovalsAPIGetApprovalPipelineBodyParams,
  ApprovalsAPIGetApprovalPipelinePayload,
  ApprovalsAPIGetApprovalPipelineSearchParams,
};
