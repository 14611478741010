/**
 * HTTP Method: POST
 * Pathname: /getCounties
 * @function getCounties
 * @memberof CountiesAPI
 * @param {CountiesAPIGetCountiesPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<GetCountiesResponse>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { GetCountiesResponse } from "@ender/shared/generated/ender.api.misc.response";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type CountiesAPIGetCountiesSearchParams = {
  token?: string | undefined;
};

type CountiesAPIGetCountiesBodyParams = {
  limit?: number | undefined;
  offsetId?: EnderId | undefined;
};

type CountiesAPIGetCountiesPayload = CountiesAPIGetCountiesSearchParams &
  CountiesAPIGetCountiesBodyParams;

function getCountiesUnsafeEffect(payload: CountiesAPIGetCountiesPayload) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, GetCountiesResponse>({
        body,
        decode: unsafeDecodeJsonResponse<GetCountiesResponse>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/getCounties",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getCounties(
  payload: CountiesAPIGetCountiesPayload,
  options?: { signal?: AbortSignal },
): Promise<GetCountiesResponse> {
  return F.pipe(
    payload,
    getCountiesUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getCounties };
export type {
  CountiesAPIGetCountiesBodyParams,
  CountiesAPIGetCountiesPayload,
  CountiesAPIGetCountiesSearchParams,
};
