/**
 * HTTP Method: POST
 * Pathname: /properties/{propertyId}/tptExcluded
 * @function setTptExcluded
 * @memberof PropertiesAPI
 * @param {PropertiesAPISetTptExcludedPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type PropertiesAPISetTptExcludedPathParams = {
  propertyId: EnderId;
};

type PropertiesAPISetTptExcludedSearchParams = {
  token?: string | undefined;
};

type PropertiesAPISetTptExcludedBodyParams = {
  isTptExcluded: boolean;
};

type PropertiesAPISetTptExcludedPayload =
  PropertiesAPISetTptExcludedPathParams &
    PropertiesAPISetTptExcludedSearchParams &
    PropertiesAPISetTptExcludedBodyParams;

function setTptExcludedUnsafeEffect(
  payload: PropertiesAPISetTptExcludedPayload,
) {
  const { propertyId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/properties/${propertyId}/tptExcluded`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function setTptExcluded(
  payload: PropertiesAPISetTptExcludedPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    setTptExcludedUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { setTptExcluded };
export type {
  PropertiesAPISetTptExcludedBodyParams,
  PropertiesAPISetTptExcludedPathParams,
  PropertiesAPISetTptExcludedPayload,
  PropertiesAPISetTptExcludedSearchParams,
};
