/* eslint-disable  @typescript-eslint/consistent-type-definitions */
import { Effectable, Equal, Hash, Inspectable, Predicate } from "effect";
import { dual } from "effect/Function";
import { pipeArguments } from "effect/Pipeable";

import { pluralize } from "@ender/shared/utils/string";

import type * as Money from "../money";
import {
  convertToWords,
  dollarsCentsFormatter,
  dollarsOnlyFormatter,
} from "./utils";

/** @internal */
const TypeId: Money.TypeId = Symbol.for("ender/Money") as Money.TypeId;

const FORMATS = {
  DEFAULT: "DEFAULT",
  DOLLARS: "DOLLARS",
  WORDS: "WORDS",
} as const;

/** @internal */
const CENTS_PER_DOLLAR = 100;

/** @internal */
const isMoney = (u: unknown): u is Money.Money =>
  Predicate.hasProperty(u, TypeId);

/** @internal */
function dollarsCentsFormat(val: number): string {
  return dollarsCentsFormatter.format(val);
}

/** @internal */
function dollarsOnlyFormat(val: number) {
  return dollarsOnlyFormatter.format(val);
}

const toDollars: (self: Money.Money) => number = (self: Money.Money): number =>
  self.valueInCents / CENTS_PER_DOLLAR;

/** @internal */
function wordsFormat(val: number): string {
  const [dollarValue, centsValue] = dollarsCentsFormat(val)
    .replace(/\$/g, "")
    .split(".");
  const dollarValueText = convertToWords(dollarValue);
  const dollarLabel = pluralize("dollar", Number(dollarValue));
  const returnText = `${dollarValueText} and ${centsValue}/100 ${dollarLabel}`;

  return returnText.toUpperCase();
}

/**
 * formats a Money based on the provided Format.
 * FORMATS.DOLLARS - Dollars-only format. truncated- `$120`
 * FORMATS.WORDS - words representation of the number- `one hundred and twenty`
 * FORMATS.DEFAULT - dollars-and-cents format- `$120.00`
 */
const toFormatted: {
  (f: Money.Format): (a: Money.Money) => string;
  (self: Money.Money, f: Money.Format): string;
} = dual(
  2,
  (self: Money.Money, format: Money.Format = FORMATS.DEFAULT): string => {
    switch (format) {
      case FORMATS.DOLLARS: {
        return dollarsOnlyFormat(toDollars(self));
      }
      case FORMATS.WORDS:
        return wordsFormat(toDollars(self));

      default:
        return dollarsCentsFormat(toDollars(self));
    }
  },
);

const Proto = {
  ...Effectable.EffectPrototype,
  [TypeId]: { _A: (_: never) => _ },
  _op: "Money",
  _tag: "Money",
  pipe() {
    return pipeArguments(this, arguments);
  },
  [Inspectable.NodeInspectSymbol](this: Money.Money) {
    return this.toString();
  },
  [Hash.symbol](this: Money.Money) {
    return Hash.cached(
      this,
      Hash.combine(Hash.hash(this._tag))(Hash.hash(this.valueInCents)),
    );
  },
  [Equal.symbol](this: Money.Money, that: unknown) {
    return (
      isMoney(that) &&
      that._tag === "Money" &&
      this.valueInCents === that.valueInCents
    );
  },
  toJSON(this: Money.Money): Money.Serialized {
    return toFormatted(this, FORMATS.DEFAULT) as Money.Serialized;
  },
  toString(this: Money.Money) {
    return `Money(${this.toJSON()})`;
  },
  valueOf(this: Money.Money): number {
    return this.valueInCents;
  },
};

/**
 * All of the instance properties which should disappear once all Money interactions
 * are done in a functional programming way
 */
const WrappedProtoProperties = {
  /**
   * @deprecated use Money.subtract(self, other)
   * @param other
   */
  subtract: function (this: Money.Money, other: Money.Money) {
    // eslint-disable-next-line no-use-before-define
    return makeFromCents(this.valueInCents - other.valueInCents);
  },
  /**
   * @deprecated use Money.divide(self, other)
   * @param other
   */
  divideBy: function (this: Money.Money, by: number) {
    // eslint-disable-next-line no-use-before-define
    return makeFromCents(this.valueInCents / by);
  },
  /**
   * @deprecated use Money.negateWhen(self, ()=>other)
   * @param other
   */
  negateWhen: function (this: Money.Money, shouldNegate: boolean) {
    // eslint-disable-next-line no-use-before-define
    return makeFromCents(shouldNegate ? -this.valueInCents : this.valueInCents);
  },
  /**
   * @deprecated use Money.abs(self)
   * @param other
   */
  abs: function (this: Money.Money) {
    // eslint-disable-next-line no-use-before-define
    return makeFromCents(Math.abs(this.valueInCents));
  },
  /**
   * @deprecated use Money.negate(self)
   * @param other
   */
  negate: function (this: Money.Money): Money.Money {
    // eslint-disable-next-line no-use-before-define
    return makeFromCents(this.valueInCents * -1);
  },
  /**
   * @deprecated use Money.toFormatted(self, format)
   * @param other
   */
  toFormatted: function (
    this: Money.Money,
    format: Money.Format = FORMATS.DEFAULT,
  ) {
    return toFormatted(this, format);
  },
};

const makeFromCents = (valueInCents: number): Money.Money => {
  const self = Object.create(Proto);
  Object.defineProperty(self, "valueInCents", {
    enumerable: false,
    value: valueInCents,
    writable: true,
  });
  Object.assign(self, WrappedProtoProperties);
  return self;
};

export { CENTS_PER_DOLLAR, isMoney, makeFromCents, toDollars, toFormatted };
