/**
 * HTTP Method: POST
 * Pathname: /permissions/roles
 * @function newRole
 * @memberof PermissionsAPI
 * @param {PermissionsAPINewRolePayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { FunctionalPermission } from "@ender/shared/generated/ender.model.permissions";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type PermissionsAPINewRoleSearchParams = {
  token?: string | undefined;
};

type PermissionsAPINewRoleBodyParams = {
  name: string;
  rolePermissions: Partial<Record<FunctionalPermission, boolean>>;
  vendorId?: EnderId | undefined;
};

type PermissionsAPINewRolePayload = PermissionsAPINewRoleSearchParams &
  PermissionsAPINewRoleBodyParams;

function newRoleUnsafeEffect(payload: PermissionsAPINewRolePayload) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/permissions/roles",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function newRole(
  payload: PermissionsAPINewRolePayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    newRoleUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { newRole };
export type {
  PermissionsAPINewRoleBodyParams,
  PermissionsAPINewRolePayload,
  PermissionsAPINewRoleSearchParams,
};
