/**
 * HTTP Method: POST
 * Pathname: /services
 * @function createService
 * @memberof TasksAPI
 * @param {TasksAPICreateServicePayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<RecurringService>}
 */
import { Effect, Function as F } from "effect";

import type {
  EnderId,
  LocalDate,
  Money,
  SerializesInto,
} from "@ender/shared/core";
import type {
  RecurringService,
  RecurringServiceScheduleFrequency,
  RecurringServiceScheduleType,
} from "@ender/shared/generated/ender.model.core.property";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type TasksAPICreateServiceSearchParams = {
  token?: string | undefined;
};

type TasksAPICreateServiceBodyParams = {
  amount: SerializesInto<Money>;
  endDateInclusive?: SerializesInto<LocalDate> | undefined;
  glCategoryId: EnderId;
  name: string;
  propertyId?: EnderId | undefined;
  scheduleFrequency: RecurringServiceScheduleFrequency;
  scheduleType: RecurringServiceScheduleType;
  startDate: SerializesInto<LocalDate>;
  unitId?: EnderId | undefined;
  vendorId: EnderId;
};

type TasksAPICreateServicePayload = TasksAPICreateServiceSearchParams &
  TasksAPICreateServiceBodyParams;

function createServiceUnsafeEffect(payload: TasksAPICreateServicePayload) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, RecurringService>({
        body,
        decode: unsafeDecodeJsonResponse<RecurringService>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/services",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function createService(
  payload: TasksAPICreateServicePayload,
  options?: { signal?: AbortSignal },
): Promise<RecurringService> {
  return F.pipe(
    payload,
    createServiceUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { createService };
export type {
  TasksAPICreateServiceBodyParams,
  TasksAPICreateServicePayload,
  TasksAPICreateServiceSearchParams,
};
