"use client";

import { Option as O } from "effect";
import { Form } from "@ender/form-system/base";
import { Button, ButtonVariant } from "@ender/shared/ds/button";
import { H1 } from "@ender/shared/ds/heading";
import { Stack } from "@ender/shared/ds/stack";
import { Text, TextColor } from "@ender/shared/ds/text";
import { FormTextInput } from "@ender/shared/ds/text-input";
import { Soc2Hidden } from "@ender/shared/ui/soc2-hidden";
import type { ForgotPasswordFormProps } from "./forgot-password-form.utils";
type ForgotPasswordFormViewProps = {
  errorMessage: O.Option<string>;
  form: ForgotPasswordFormProps["form"];
  isFetching: boolean;
  onFormSubmit: ForgotPasswordFormProps["onSubmit"];
  onSignInClick: () => void;
};
function ForgotPasswordFormView(props: ForgotPasswordFormViewProps) {
  const {
    errorMessage,
    form,
    isFetching,
    onFormSubmit,
    onSignInClick
  } = props;
  return <>
      <H1 data-sentry-element="H1" data-sentry-source-file="forgot-password-form.view.tsx">Forgot Password</H1>
      <Form form={form} onSubmit={onFormSubmit} data-sentry-element="Form" data-sentry-source-file="forgot-password-form.view.tsx">
        <Stack data-sentry-element="Stack" data-sentry-source-file="forgot-password-form.view.tsx">
          <Soc2Hidden data-sentry-element="Soc2Hidden" data-sentry-source-file="forgot-password-form.view.tsx">
            <FormTextInput name="email" form={form} label="Email" disabled={isFetching} data-sentry-element="FormTextInput" data-sentry-source-file="forgot-password-form.view.tsx" />
          </Soc2Hidden>
          {O.isSome(errorMessage) && <Text color={TextColor["red-500"]}>
              {O.getOrThrow(errorMessage)}
            </Text>}
          <Button type="submit" loading={isFetching} disabled={isFetching} data-sentry-element="Button" data-sentry-source-file="forgot-password-form.view.tsx">
            Send Password Reset Email
          </Button>
          <Button variant={ButtonVariant.transparent} loading={isFetching} onClick={onSignInClick} data-sentry-element="Button" data-sentry-source-file="forgot-password-form.view.tsx">
            Have an account? Sign In
          </Button>
        </Stack>
      </Form>
    </>;
}
export { ForgotPasswordFormView };