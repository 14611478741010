/**
 * HTTP Method: GET
 * Pathname: /tasks/{taskId}/invoice
 * @function getInvoice
 * @memberof TasksAPI
 * @param {TasksAPIGetInvoicePayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<GetTaskInvoiceResponse>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { GetTaskInvoiceResponse } from "@ender/shared/generated/ender.api.misc.response";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type TasksAPIGetInvoicePathParams = {
  taskId: EnderId;
};

type TasksAPIGetInvoiceSearchParams = {
  token?: string | undefined;
};

type TasksAPIGetInvoicePayload = TasksAPIGetInvoicePathParams &
  TasksAPIGetInvoiceSearchParams;

function getInvoiceUnsafeEffect(payload: TasksAPIGetInvoicePayload) {
  const { taskId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, GetTaskInvoiceResponse>({
        body,
        decode: unsafeDecodeJsonResponse<GetTaskInvoiceResponse>({}),
        encode: encodeNoBody({ method: "GET" }),
        pathname: `/tasks/${taskId}/invoice`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getInvoice(
  payload: TasksAPIGetInvoicePayload,
  options?: { signal?: AbortSignal },
): Promise<GetTaskInvoiceResponse> {
  return F.pipe(
    payload,
    getInvoiceUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getInvoice };
export type {
  TasksAPIGetInvoicePathParams,
  TasksAPIGetInvoicePayload,
  TasksAPIGetInvoiceSearchParams,
};
