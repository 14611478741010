import { addIssueAttachment } from "./add-issue-attachment";
import { createIssueForPM } from "./create-issue-for-p-m";
import { getIssuesForPM } from "./get-issues-for-p-m";
import { removeIssueAttachment } from "./remove-issue-attachment";
import { updateIssue } from "./update-issue";

const JiraAPI = {
  addIssueAttachment,
  createIssueForPM,
  getIssuesForPM,
  removeIssueAttachment,
  updateIssue,
};

export { JiraAPI };
