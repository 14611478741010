/**
 * HTTP Method: GET
 * Pathname: /properties/tptExcluded
 * @function getTptExcludedProperties
 * @memberof PropertiesAPI
 * @param {PropertiesAPIGetTptExcludedPropertiesPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<EnderId[]>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type PropertiesAPIGetTptExcludedPropertiesSearchParams = {
  token?: string | undefined;
};

type PropertiesAPIGetTptExcludedPropertiesPayload =
  PropertiesAPIGetTptExcludedPropertiesSearchParams;

function getTptExcludedPropertiesUnsafeEffect(
  payload: PropertiesAPIGetTptExcludedPropertiesPayload,
) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, EnderId[]>({
        body,
        decode: unsafeDecodeJsonResponse<EnderId[]>({}),
        encode: encodeNoBody({ method: "GET" }),
        pathname: "/properties/tptExcluded",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getTptExcludedProperties(
  payload: PropertiesAPIGetTptExcludedPropertiesPayload,
  options?: { signal?: AbortSignal },
): Promise<EnderId[]> {
  return F.pipe(
    payload,
    getTptExcludedPropertiesUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getTptExcludedProperties };
export type {
  PropertiesAPIGetTptExcludedPropertiesPayload,
  PropertiesAPIGetTptExcludedPropertiesSearchParams,
};
