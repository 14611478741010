/**
 * HTTP Method: DELETE
 * Pathname: /units/areas/{areaId}
 * @function deleteUnitArea
 * @memberof UnitAreasAPI
 * @param {UnitAreasAPIDeleteUnitAreaPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<UnitArea>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { UnitArea } from "@ender/shared/generated/ender.model.core.unit.area";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type UnitAreasAPIDeleteUnitAreaPathParams = {
  areaId: EnderId;
};

type UnitAreasAPIDeleteUnitAreaSearchParams = {
  token?: string | undefined;
};

type UnitAreasAPIDeleteUnitAreaPayload = UnitAreasAPIDeleteUnitAreaPathParams &
  UnitAreasAPIDeleteUnitAreaSearchParams;

function deleteUnitAreaUnsafeEffect(
  payload: UnitAreasAPIDeleteUnitAreaPayload,
) {
  const { areaId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, UnitArea>({
        body,
        decode: unsafeDecodeJsonResponse<UnitArea>({}),
        encode: encodeJsonBody({ method: "DELETE" }),
        pathname: `/units/areas/${areaId}`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function deleteUnitArea(
  payload: UnitAreasAPIDeleteUnitAreaPayload,
  options?: { signal?: AbortSignal },
): Promise<UnitArea> {
  return F.pipe(
    payload,
    deleteUnitAreaUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { deleteUnitArea };
export type {
  UnitAreasAPIDeleteUnitAreaPathParams,
  UnitAreasAPIDeleteUnitAreaPayload,
  UnitAreasAPIDeleteUnitAreaSearchParams,
};
