import { Record as R } from "effect";
import type { ReactElement, ReactNode } from "react";
import { useMemo } from "react";
import type { Path } from "react-hook-form";
import { cast } from "@ender/shared/types/cast";
import { FormSectionContext } from "../contexts/form-section-context";
import type { FormInput, UseFormReturn } from "../types";
type Expand<T> = T extends infer O ? { [K in keyof O]: O[K] } : never;
type FormSectionFields<Paths, Name extends string> = { [Path in Paths extends `${Name}.${infer R}` ? R extends `${infer Next}.${string}` ? Next : R : never]: Extract<Paths, `${Name}.${Path}`> };
type FormSectionProps<
// eslint-disable-next-line @typescript-eslint/no-explicit-any
Form extends UseFormReturn<any, any, any>, Name extends Path<FormInput<Form>>> = {
  name: Name;
  form: Form;
  children: (props: {
    section: Expand<FormSectionFields<Path<FormInput<Form>>, Name>>;
  }) => ReactNode;
};
function FormSection<
// eslint-disable-next-line @typescript-eslint/no-explicit-any
Form extends UseFormReturn<any, any, any>, Name extends Path<FormInput<Form>>>({
  name,
  children: _children,
  form
}: FormSectionProps<Form, Name>): ReactElement {
  const {
    getValues
  } = form;
  const section = useMemo(() => {
    const entries = Object.entries(getValues(name)) as [Extract<Path<FormInput<Form>>, Name>, unknown][];
    const mappedPaths = entries.map(([key, _value]) => [key, `${name}.${key}`] as const);
    return R.fromEntries(mappedPaths);
  }, [name, getValues]);
  const children = useMemo(() => _children({
    section: cast(section)
  }), [_children, section]);
  return <FormSectionContext.Provider value={{}} data-sentry-element="unknown" data-sentry-component="FormSection" data-sentry-source-file="form-section.tsx">
      {children}
    </FormSectionContext.Provider>;
}
export { FormSection };
export type { FormSectionProps };