type ResolveFn<T> = (value: T | PromiseLike<T>) => void;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type RejectFn = (reason?: any) => void;

/**
 * @deprecated Use Promise.withResolvers() instead.
 * See: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Promise/withResolvers
 */
const getDeferred = <T>(): {
  resolve: ResolveFn<T>;
  reject: RejectFn;
  promise: Promise<T>;
} => {
  let settled = false;
  let resolve: ResolveFn<T>;
  let reject: RejectFn;
  const promise = new Promise<T>((_resolve, _reject) => {
    resolve = _resolve;
    reject = _reject;
  }).finally(() => {
    settled = true;
  });

  return {
    promise,
    reject: (...args) => {
      if (settled) {
        return;
      }

      reject(...args);
    },
    resolve: (...args) => {
      if (settled) {
        return;
      }

      resolve(...args);
    },
  };
};

export { getDeferred };
