/**
 * This route is for vanilla, non-sensitive unit information. User can be any kind of user who is connected to the
 unit somehow.
 * HTTP Method: GET
 * Pathname: /units/{unitId}
 * @function getUnitDetails
 * @memberof UnitsAPI
 * @param {UnitsAPIGetUnitDetailsPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<UnitSerializerUnitResponse>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { UnitSerializerUnitResponse } from "@ender/shared/generated/ender.arch.serializer.core";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type UnitsAPIGetUnitDetailsPathParams = {
  unitId: EnderId;
};

type UnitsAPIGetUnitDetailsSearchParams = {
  token?: string | undefined;
};

type UnitsAPIGetUnitDetailsPayload = UnitsAPIGetUnitDetailsPathParams &
  UnitsAPIGetUnitDetailsSearchParams;

function getUnitDetailsUnsafeEffect(payload: UnitsAPIGetUnitDetailsPayload) {
  const { unitId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, UnitSerializerUnitResponse>({
        body,
        decode: unsafeDecodeJsonResponse<UnitSerializerUnitResponse>({}),
        encode: encodeNoBody({ method: "GET" }),
        pathname: `/units/${unitId}`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getUnitDetails(
  payload: UnitsAPIGetUnitDetailsPayload,
  options?: { signal?: AbortSignal },
): Promise<UnitSerializerUnitResponse> {
  return F.pipe(
    payload,
    getUnitDetailsUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getUnitDetails };
export type {
  UnitsAPIGetUnitDetailsPathParams,
  UnitsAPIGetUnitDetailsPayload,
  UnitsAPIGetUnitDetailsSearchParams,
};
