/**
 * HTTP Method: GET
 * Pathname: /featureFlag/{flagKey}/value
 * @function getFeatureFlagValue
 * @memberof FeatureFlagAPI
 * @param {FeatureFlagAPIGetFeatureFlagValuePayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<GetFeatureFlagValueResponse>}
 */
import { Effect, Function as F } from "effect";

import type { GetFeatureFlagValueResponse } from "@ender/shared/generated/ender.api.misc.response";
import type { FeatureFlagFlagKey } from "@ender/shared/generated/ender.model.misc";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type FeatureFlagAPIGetFeatureFlagValuePathParams = {
  flagKey: FeatureFlagFlagKey;
};

type FeatureFlagAPIGetFeatureFlagValueSearchParams = {
  token?: string | undefined;
};

type FeatureFlagAPIGetFeatureFlagValuePayload =
  FeatureFlagAPIGetFeatureFlagValuePathParams &
    FeatureFlagAPIGetFeatureFlagValueSearchParams;

function getFeatureFlagValueUnsafeEffect(
  payload: FeatureFlagAPIGetFeatureFlagValuePayload,
) {
  const { flagKey, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, GetFeatureFlagValueResponse>({
        body,
        decode: unsafeDecodeJsonResponse<GetFeatureFlagValueResponse>({}),
        encode: encodeNoBody({ method: "GET" }),
        pathname: `/featureFlag/${flagKey}/value`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getFeatureFlagValue(
  payload: FeatureFlagAPIGetFeatureFlagValuePayload,
  options?: { signal?: AbortSignal },
): Promise<GetFeatureFlagValueResponse> {
  return F.pipe(
    payload,
    getFeatureFlagValueUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getFeatureFlagValue };
export type {
  FeatureFlagAPIGetFeatureFlagValuePathParams,
  FeatureFlagAPIGetFeatureFlagValuePayload,
  FeatureFlagAPIGetFeatureFlagValueSearchParams,
};
