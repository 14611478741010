/**
 * HTTP Method: POST
 * Pathname: /model-explorer/money-transfers/search
 * @function searchMoneyTransfers
 * @memberof ModelExplorerAPI
 * @param {ModelExplorerAPISearchMoneyTransfersPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<ModelExplorerAPISearchMoneyTransfersResponse>}
 */
import { Effect, Function as F } from "effect";

import type { LocalDate, SerializesInto } from "@ender/shared/core";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

import type { ModelExplorerAPISearchMoneyTransfersResponse } from "../model-explorer-api-search-money-transfers-response";

type ModelExplorerAPISearchMoneyTransfersSearchParams = {
  token?: string | undefined;
};

type ModelExplorerAPISearchMoneyTransfersBodyParams = {
  inclusiveEndDate: SerializesInto<LocalDate>;
  startDate: SerializesInto<LocalDate>;
};

type ModelExplorerAPISearchMoneyTransfersPayload =
  ModelExplorerAPISearchMoneyTransfersSearchParams &
    ModelExplorerAPISearchMoneyTransfersBodyParams;

function searchMoneyTransfersUnsafeEffect(
  payload: ModelExplorerAPISearchMoneyTransfersPayload,
) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, ModelExplorerAPISearchMoneyTransfersResponse>({
        body,
        decode:
          unsafeDecodeJsonResponse<ModelExplorerAPISearchMoneyTransfersResponse>(
            {},
          ),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/model-explorer/money-transfers/search",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function searchMoneyTransfers(
  payload: ModelExplorerAPISearchMoneyTransfersPayload,
  options?: { signal?: AbortSignal },
): Promise<ModelExplorerAPISearchMoneyTransfersResponse> {
  return F.pipe(
    payload,
    searchMoneyTransfersUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { searchMoneyTransfers };
export type {
  ModelExplorerAPISearchMoneyTransfersBodyParams,
  ModelExplorerAPISearchMoneyTransfersPayload,
  ModelExplorerAPISearchMoneyTransfersSearchParams,
};
