"use client";

import type { Option as O } from "effect";
import { Modal } from "@ender/shared/ds/modal";
import { LoginFormController } from "../form/login-form.controller";
import type { LoginPayload } from "../machine/auth.types";
type LoginModalViewProps = {
  errorMessage: O.Option<string>;
  isFetching: boolean;
  loginPayload: O.Option<LoginPayload>;
  onForgotPassword: () => void;
  onLogin: (payload: LoginPayload) => void;
  opened: boolean;
};
function LoginModalView(props: LoginModalViewProps) {
  const {
    errorMessage,
    isFetching,
    loginPayload,
    onForgotPassword,
    onLogin,
    opened
  } = props;
  return <Modal title="Login" opened={opened} data-sentry-element="Modal" data-sentry-component="LoginModalView" data-sentry-source-file="login-modal.view.tsx">
      <LoginFormController errorMessage={errorMessage} isFetching={isFetching} loginPayload={loginPayload} onForgotPassword={onForgotPassword} onLogin={onLogin} data-sentry-element="LoginFormController" data-sentry-source-file="login-modal.view.tsx" />
    </Modal>;
}
export { LoginModalView };