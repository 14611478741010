/**
 * HTTP Method: POST
 * Pathname: /tasks/vendors/search
 * @function searchForAssignableVendors
 * @memberof TasksAPI
 * @param {TasksAPISearchForAssignableVendorsPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<SearchForAssignableVendorsResponse[]>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { SearchForAssignableVendorsResponse } from "@ender/shared/generated/ender.api.misc.response";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type TasksAPISearchForAssignableVendorsSearchParams = {
  token?: string | undefined;
};

type TasksAPISearchForAssignableVendorsBodyParams = {
  excludeIds?: EnderId[] | undefined;
  keyword: string;
  marketId?: EnderId | undefined;
};

type TasksAPISearchForAssignableVendorsPayload =
  TasksAPISearchForAssignableVendorsSearchParams &
    TasksAPISearchForAssignableVendorsBodyParams;

function searchForAssignableVendorsUnsafeEffect(
  payload: TasksAPISearchForAssignableVendorsPayload,
) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, SearchForAssignableVendorsResponse[]>({
        body,
        decode: unsafeDecodeJsonResponse<SearchForAssignableVendorsResponse[]>(
          {},
        ),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/tasks/vendors/search",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function searchForAssignableVendors(
  payload: TasksAPISearchForAssignableVendorsPayload,
  options?: { signal?: AbortSignal },
): Promise<SearchForAssignableVendorsResponse[]> {
  return F.pipe(
    payload,
    searchForAssignableVendorsUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { searchForAssignableVendors };
export type {
  TasksAPISearchForAssignableVendorsBodyParams,
  TasksAPISearchForAssignableVendorsPayload,
  TasksAPISearchForAssignableVendorsSearchParams,
};
