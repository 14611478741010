/**
 * HTTP Method: POST
 * Pathname: /units/{unitId}/amenities
 * @function setUnitAmenities
 * @memberof UnitsAPI
 * @param {UnitsAPISetUnitAmenitiesPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { AmenityAmenityType } from "@ender/shared/generated/ender.model.core.property";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type UnitsAPISetUnitAmenitiesPathParams = {
  unitId: EnderId;
};

type UnitsAPISetUnitAmenitiesSearchParams = {
  token?: string | undefined;
};

type UnitsAPISetUnitAmenitiesBodyParams = {
  amenities: AmenityAmenityType[];
};

type UnitsAPISetUnitAmenitiesPayload = UnitsAPISetUnitAmenitiesPathParams &
  UnitsAPISetUnitAmenitiesSearchParams &
  UnitsAPISetUnitAmenitiesBodyParams;

function setUnitAmenitiesUnsafeEffect(
  payload: UnitsAPISetUnitAmenitiesPayload,
) {
  const { unitId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/units/${unitId}/amenities`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function setUnitAmenities(
  payload: UnitsAPISetUnitAmenitiesPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    setUnitAmenitiesUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { setUnitAmenities };
export type {
  UnitsAPISetUnitAmenitiesBodyParams,
  UnitsAPISetUnitAmenitiesPathParams,
  UnitsAPISetUnitAmenitiesPayload,
  UnitsAPISetUnitAmenitiesSearchParams,
};
