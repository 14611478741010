/**
 * Submits the two factor verification code.
 * HTTP Method: POST
 * Pathname: /checkVerificationCode
 * @function checkVerificationCode
 * @memberof AuthAPI
 * @param {AuthAPICheckVerificationCodePayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<EnderSessionResponse>}
 */
import { Effect, Function as F } from "effect";

import type { TwilioClientVerificationCodeChannel } from "@ender/shared/generated/com.ender.common.arch.client";
import type { EnderSessionResponse } from "@ender/shared/generated/ender.api.misc.response";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type AuthAPICheckVerificationCodeSearchParams = {
  token?: string | undefined;
};

type AuthAPICheckVerificationCodeBodyParams = {
  /**
   * Whether this code was sent via SMS or EMAIL
   */
  channel: TwilioClientVerificationCodeChannel;
  /**
   * The two-factor code
   */
  code: string;
};

type AuthAPICheckVerificationCodePayload =
  AuthAPICheckVerificationCodeSearchParams &
    AuthAPICheckVerificationCodeBodyParams;

function checkVerificationCodeUnsafeEffect(
  payload: AuthAPICheckVerificationCodePayload,
) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, EnderSessionResponse>({
        body,
        decode: unsafeDecodeJsonResponse<EnderSessionResponse>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/checkVerificationCode",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function checkVerificationCode(
  payload: AuthAPICheckVerificationCodePayload,
  options?: { signal?: AbortSignal },
): Promise<EnderSessionResponse> {
  return F.pipe(
    payload,
    checkVerificationCodeUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { checkVerificationCode };
export type {
  AuthAPICheckVerificationCodeBodyParams,
  AuthAPICheckVerificationCodePayload,
  AuthAPICheckVerificationCodeSearchParams,
};
