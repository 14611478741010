/**
 * HTTP Method: POST
 * Pathname: /playwright/startTest
 * @function startTest
 * @memberof FixturesAPI
 * @param {FixturesAPIStartTestPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<string>}
 */
import { Effect, Function as F } from "effect";

import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type FixturesAPIStartTestSearchParams = {
  token?: string | undefined;
};

type FixturesAPIStartTestBodyParams = {
  testName: string;
};

type FixturesAPIStartTestPayload = FixturesAPIStartTestSearchParams &
  FixturesAPIStartTestBodyParams;

function startTestUnsafeEffect(payload: FixturesAPIStartTestPayload) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, string>({
        body,
        decode: unsafeDecodeJsonResponse<string>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/playwright/startTest",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function startTest(
  payload: FixturesAPIStartTestPayload,
  options?: { signal?: AbortSignal },
): Promise<string> {
  return F.pipe(
    payload,
    startTestUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { startTest };
export type {
  FixturesAPIStartTestBodyParams,
  FixturesAPIStartTestPayload,
  FixturesAPIStartTestSearchParams,
};
