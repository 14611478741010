/**
 * HTTP Method: GET
 * Pathname: /admin/pms
 * @function getPMs
 * @memberof AdminAPI
 * @param {AdminAPIGetPMsPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<AdminAPIGetPMsResponse>}
 */
import { Effect, Function as F } from "effect";

import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

import type { AdminAPIGetPMsResponse } from "../admin-api-get-p-ms-response";

type AdminAPIGetPMsSearchParams = {
  token?: string | undefined;
};

type AdminAPIGetPMsPayload = AdminAPIGetPMsSearchParams;

function getPMsUnsafeEffect(payload: AdminAPIGetPMsPayload) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, AdminAPIGetPMsResponse>({
        body,
        decode: unsafeDecodeJsonResponse<AdminAPIGetPMsResponse>({}),
        encode: encodeNoBody({ method: "GET" }),
        pathname: "/admin/pms",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getPMs(
  payload: AdminAPIGetPMsPayload,
  options?: { signal?: AbortSignal },
): Promise<AdminAPIGetPMsResponse> {
  return F.pipe(
    payload,
    getPMsUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getPMs };
export type { AdminAPIGetPMsPayload, AdminAPIGetPMsSearchParams };
