import { Effect, Layer } from "effect";

import type { ClientConfigServiceShape } from "./client-config-service";
import { ClientConfigService } from "./client-config-service";

type MakeClientConfigServiceArgs = {
  getConfig: () => Effect.Effect<ClientConfigServiceShape>;
};

function makeClientConfigService(args: MakeClientConfigServiceArgs) {
  const { getConfig } = args;

  return Layer.effect(
    ClientConfigService,
    Effect.gen(function* () {
      return yield* getConfig();
    }),
  );
}

export { makeClientConfigService };
