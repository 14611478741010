/**
 * HTTP Method: POST
 * Pathname: /emergencyContacts/{contactId}
 * @function updateEmergencyContact
 * @memberof UsersAPI
 * @param {UsersAPIUpdateEmergencyContactPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<EmergencyContact>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { EmergencyContact } from "@ender/shared/generated/ender.model.leasing";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type UsersAPIUpdateEmergencyContactPathParams = {
  contactId: EnderId;
};

type UsersAPIUpdateEmergencyContactSearchParams = {
  token?: string | undefined;
};

type UsersAPIUpdateEmergencyContactBodyParams = {
  email?: string | undefined;
  firstName: string;
  lastName: string;
  phone?: string | undefined;
};

type UsersAPIUpdateEmergencyContactPayload =
  UsersAPIUpdateEmergencyContactPathParams &
    UsersAPIUpdateEmergencyContactSearchParams &
    UsersAPIUpdateEmergencyContactBodyParams;

function updateEmergencyContactUnsafeEffect(
  payload: UsersAPIUpdateEmergencyContactPayload,
) {
  const { contactId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, EmergencyContact>({
        body,
        decode: unsafeDecodeJsonResponse<EmergencyContact>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/emergencyContacts/${contactId}`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function updateEmergencyContact(
  payload: UsersAPIUpdateEmergencyContactPayload,
  options?: { signal?: AbortSignal },
): Promise<EmergencyContact> {
  return F.pipe(
    payload,
    updateEmergencyContactUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { updateEmergencyContact };
export type {
  UsersAPIUpdateEmergencyContactBodyParams,
  UsersAPIUpdateEmergencyContactPathParams,
  UsersAPIUpdateEmergencyContactPayload,
  UsersAPIUpdateEmergencyContactSearchParams,
};
