/**
 * Sends a password reset email
 * HTTP Method: POST
 * Pathname: /changePassword
 * @function changePassword
 * @memberof AuthAPI
 * @param {AuthAPIChangePasswordPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type AuthAPIChangePasswordSearchParams = {
  token?: string | undefined;
};

type AuthAPIChangePasswordBodyParams = {
  /**
 * If there is no currently logged in User, this email address will be used to lookup the User whose
              password we are resetting.
 */
  email: string;
};

type AuthAPIChangePasswordPayload = AuthAPIChangePasswordSearchParams &
  AuthAPIChangePasswordBodyParams;

function changePasswordUnsafeEffect(payload: AuthAPIChangePasswordPayload) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/changePassword",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function changePassword(
  payload: AuthAPIChangePasswordPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    changePasswordUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { changePassword };
export type {
  AuthAPIChangePasswordBodyParams,
  AuthAPIChangePasswordPayload,
  AuthAPIChangePasswordSearchParams,
};
