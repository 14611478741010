/**
 * HTTP Method: GET
 * Pathname: /tasks/{taskId}/costEstimateHistory
 * @function getCostEstimateHistory
 * @memberof TasksAPI
 * @param {TasksAPIGetCostEstimateHistoryPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<TasksAPIGetCostEstimateHistoryResponse[]>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

import type { TasksAPIGetCostEstimateHistoryResponse } from "../tasks-api-get-cost-estimate-history-response";

type TasksAPIGetCostEstimateHistoryPathParams = {
  taskId: EnderId;
};

type TasksAPIGetCostEstimateHistorySearchParams = {
  token?: string | undefined;
};

type TasksAPIGetCostEstimateHistoryPayload =
  TasksAPIGetCostEstimateHistoryPathParams &
    TasksAPIGetCostEstimateHistorySearchParams;

function getCostEstimateHistoryUnsafeEffect(
  payload: TasksAPIGetCostEstimateHistoryPayload,
) {
  const { taskId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, TasksAPIGetCostEstimateHistoryResponse[]>({
        body,
        decode: unsafeDecodeJsonResponse<
          TasksAPIGetCostEstimateHistoryResponse[]
        >({}),
        encode: encodeNoBody({ method: "GET" }),
        pathname: `/tasks/${taskId}/costEstimateHistory`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getCostEstimateHistory(
  payload: TasksAPIGetCostEstimateHistoryPayload,
  options?: { signal?: AbortSignal },
): Promise<TasksAPIGetCostEstimateHistoryResponse[]> {
  return F.pipe(
    payload,
    getCostEstimateHistoryUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getCostEstimateHistory };
export type {
  TasksAPIGetCostEstimateHistoryPathParams,
  TasksAPIGetCostEstimateHistoryPayload,
  TasksAPIGetCostEstimateHistorySearchParams,
};
