"use client";

import { Schema } from "@effect/schema";
import { effectTsResolver } from "@hookform/resolvers/effect-ts";
import { useState } from "react";

import type { FormProps, UseFormReturn } from "@ender/form-system/base";
import { useForm } from "@ender/form-system/base";

const ForgotPasswordFormSchema = Schema.Struct({
  email: Schema.String.pipe(
    Schema.nonEmptyString({ message: () => "Email is required" }),
  ),
});
type ForgotPasswordFormInput = Schema.Schema.Encoded<
  typeof ForgotPasswordFormSchema
>;
type ForgotPasswordFormOutput = Schema.Schema.Type<
  typeof ForgotPasswordFormSchema
>;

type UseForgotPasswordFormReturn = UseFormReturn<
  ForgotPasswordFormInput,
  {},
  ForgotPasswordFormOutput
>;
type ForgotPasswordFormProps = FormProps<UseForgotPasswordFormReturn>;

function defaultInitialValues(): ForgotPasswordFormOutput {
  return {
    email: "",
  };
}

type UseForgotPasswordFormArgs = {
  initialValues?: () => ForgotPasswordFormOutput;
};

function useForgotPasswordForm(args?: UseForgotPasswordFormArgs) {
  const { initialValues = defaultInitialValues } = args ?? {};
  const [defaultValues] = useState(initialValues);

  return useForm<ForgotPasswordFormInput, {}, ForgotPasswordFormOutput>({
    defaultValues: defaultValues,
    resolver: effectTsResolver(ForgotPasswordFormSchema),
  });
}

export { ForgotPasswordFormSchema, useForgotPasswordForm };
export type {
  ForgotPasswordFormInput,
  ForgotPasswordFormOutput,
  ForgotPasswordFormProps,
  UseForgotPasswordFormArgs,
  UseForgotPasswordFormReturn,
};
