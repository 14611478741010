/**
 * HTTP Method: POST
 * Pathname: /tasks/{taskId}/chargebacks
 * @function createChargebackRequest
 * @memberof TasksAPI
 * @param {TasksAPICreateChargebackRequestPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<Task>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId, Money, SerializesInto } from "@ender/shared/core";
import type { Task } from "@ender/shared/generated/ender.model.task";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type TasksAPICreateChargebackRequestPathParams = {
  taskId: EnderId;
};

type TasksAPICreateChargebackRequestSearchParams = {
  token?: string | undefined;
};

type TasksAPICreateChargebackRequestBodyParams = {
  chargebackAmount?: SerializesInto<Money> | undefined;
  description?: string | undefined;
};

type TasksAPICreateChargebackRequestPayload =
  TasksAPICreateChargebackRequestPathParams &
    TasksAPICreateChargebackRequestSearchParams &
    TasksAPICreateChargebackRequestBodyParams;

function createChargebackRequestUnsafeEffect(
  payload: TasksAPICreateChargebackRequestPayload,
) {
  const { taskId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, Task>({
        body,
        decode: unsafeDecodeJsonResponse<Task>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/tasks/${taskId}/chargebacks`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function createChargebackRequest(
  payload: TasksAPICreateChargebackRequestPayload,
  options?: { signal?: AbortSignal },
): Promise<Task> {
  return F.pipe(
    payload,
    createChargebackRequestUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { createChargebackRequest };
export type {
  TasksAPICreateChargebackRequestBodyParams,
  TasksAPICreateChargebackRequestPathParams,
  TasksAPICreateChargebackRequestPayload,
  TasksAPICreateChargebackRequestSearchParams,
};
