/**
 * HTTP Method: POST
 * Pathname: /approvalProcess/steps/{stepId}/archive
 * @function archiveStep
 * @memberof ApprovalsAPI
 * @param {ApprovalsAPIArchiveStepPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type ApprovalsAPIArchiveStepPathParams = {
  stepId: EnderId;
};

type ApprovalsAPIArchiveStepSearchParams = {
  token?: string | undefined;
};

type ApprovalsAPIArchiveStepPayload = ApprovalsAPIArchiveStepPathParams &
  ApprovalsAPIArchiveStepSearchParams;

function archiveStepUnsafeEffect(payload: ApprovalsAPIArchiveStepPayload) {
  const { stepId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/approvalProcess/steps/${stepId}/archive`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function archiveStep(
  payload: ApprovalsAPIArchiveStepPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    archiveStepUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { archiveStep };
export type {
  ApprovalsAPIArchiveStepPathParams,
  ApprovalsAPIArchiveStepPayload,
  ApprovalsAPIArchiveStepSearchParams,
};
