import { HttpClientResponse } from "@effect/platform";
import type { AST, Schema } from "@effect/schema";
import { Effect, Function as F } from "effect";

import { cast } from "@ender/shared/types/cast";

import type { DecodeResponse, RestError } from "./rest-types";
import { mapResponseError } from "./rest-utils";

type UnsafeDecodeJsonResponseOptions = {};
function unsafeDecodeJsonResponse<Response>(
  _options?: UnsafeDecodeJsonResponseOptions,
): DecodeResponse<Response> {
  return (response: HttpClientResponse.HttpClientResponse) => {
    return F.pipe(
      response.json,
      Effect.map((parsed: unknown) => {
        return cast<Response>(parsed);
      }),
      Effect.catchTags({
        ResponseError: mapResponseError,
      }),
    );
  };
}

type DecodeJsonResponseOptions<Response> = {
  scheme: Schema.Schema<Response, string>;
  parseOptions?: AST.ParseOptions;
};
function decodeJsonResponse<Response>(
  options: DecodeJsonResponseOptions<Response>,
): DecodeResponse<Response> {
  const { scheme, parseOptions } = options;
  const parse = HttpClientResponse.schemaBodyJson(scheme, parseOptions);
  return (
    response: HttpClientResponse.HttpClientResponse,
  ): Effect.Effect<Response, RestError> =>
    F.pipe(
      parse(response),
      Effect.catchTags({
        ResponseError: mapResponseError,
      }),
    );
}

export { decodeJsonResponse, unsafeDecodeJsonResponse };
