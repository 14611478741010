const GROUPING_TEXT = ["", "thousand", "million", "billion"];
const ONES_TEXT = [
  "zero",
  "one",
  "two",
  "three",
  "four",
  "five",
  "six",
  "seven",
  "eight",
  "nine",
] as const;
const TENS_TEXT = [
  "",
  "ten",
  "twenty",
  "thirty",
  "forty",
  "fifty",
  "sixty",
  "seventy",
  "eighty",
  "ninety",
] as const;
const TEENS_TEXT = [
  "",
  "eleven",
  "twelve",
  "thirteen",
  "fourteen",
  "fifteen",
  "sixteen",
  "seventeen",
  "eighteen",
  "nineteen",
] as const;

/**
 * @const centsPerDollar
 * @description To be used as the smallest divisible unit of a money
 */
const CENTS_PER_DOLLAR = 100;

export { CENTS_PER_DOLLAR, GROUPING_TEXT, ONES_TEXT, TEENS_TEXT, TENS_TEXT };
