/**
 * Requests a 2-factor authentication code be sent to the invoker.
 * HTTP Method: POST
 * Pathname: /sendVerificationCode
 * @function sendVerificationCode
 * @memberof AuthAPI
 * @param {AuthAPISendVerificationCodePayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<EnderSessionResponse>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { TwilioClientVerificationCodeChannel } from "@ender/shared/generated/com.ender.common.arch.client";
import type { EnderSessionResponse } from "@ender/shared/generated/ender.api.misc.response";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type AuthAPISendVerificationCodeSearchParams = {
  token?: string | undefined;
};

type AuthAPISendVerificationCodeBodyParams = {
  /**
   * Whether to send the code by SMS or EMAIL
   */
  channel: TwilioClientVerificationCodeChannel;
  /**
   * In the case where the User is not logged in, this is the id of the User to send the code to.
   */
  userId?: EnderId | undefined;
};

type AuthAPISendVerificationCodePayload =
  AuthAPISendVerificationCodeSearchParams &
    AuthAPISendVerificationCodeBodyParams;

function sendVerificationCodeUnsafeEffect(
  payload: AuthAPISendVerificationCodePayload,
) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, EnderSessionResponse>({
        body,
        decode: unsafeDecodeJsonResponse<EnderSessionResponse>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/sendVerificationCode",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function sendVerificationCode(
  payload: AuthAPISendVerificationCodePayload,
  options?: { signal?: AbortSignal },
): Promise<EnderSessionResponse> {
  return F.pipe(
    payload,
    sendVerificationCodeUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { sendVerificationCode };
export type {
  AuthAPISendVerificationCodeBodyParams,
  AuthAPISendVerificationCodePayload,
  AuthAPISendVerificationCodeSearchParams,
};
