"use client";

import type { PropsWithChildren } from "react";
import { Align, Justify, Spacing } from "@ender/shared/ds/flex";
import { Stack } from "@ender/shared/ds/stack";
type MultiFactorAuthenticationFormProps = {};
function MultiFactorAuthenticationFormView(props: PropsWithChildren<MultiFactorAuthenticationFormProps>) {
  const {
    children
  } = props;
  return <Stack align={Align.center} justify={Justify.center} spacing={Spacing.sm} data-sentry-element="Stack" data-sentry-component="MultiFactorAuthenticationFormView" data-sentry-source-file="multi-factor-authentication-form.view.tsx">
      {children}
    </Stack>;
}
export { MultiFactorAuthenticationFormView };