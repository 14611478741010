/**
 * HTTP Method: POST
 * Pathname: /properties/getFixedExpenses
 * @function getFixedExpenses
 * @memberof PropertiesAPI
 * @param {PropertiesAPIGetFixedExpensesPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<PropertyYearExpensesResponse[]>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId, LocalDate, SerializesInto } from "@ender/shared/core";
import type { PropertyYearExpensesResponse } from "@ender/shared/generated/ender.api.reports.response";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type PropertiesAPIGetFixedExpensesSearchParams = {
  token?: string | undefined;
};

type PropertiesAPIGetFixedExpensesBodyParams = {
  propertyIds: EnderId[];
  year?: SerializesInto<LocalDate> | undefined;
};

type PropertiesAPIGetFixedExpensesPayload =
  PropertiesAPIGetFixedExpensesSearchParams &
    PropertiesAPIGetFixedExpensesBodyParams;

function getFixedExpensesUnsafeEffect(
  payload: PropertiesAPIGetFixedExpensesPayload,
) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, PropertyYearExpensesResponse[]>({
        body,
        decode: unsafeDecodeJsonResponse<PropertyYearExpensesResponse[]>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/properties/getFixedExpenses",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getFixedExpenses(
  payload: PropertiesAPIGetFixedExpensesPayload,
  options?: { signal?: AbortSignal },
): Promise<PropertyYearExpensesResponse[]> {
  return F.pipe(
    payload,
    getFixedExpensesUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getFixedExpenses };
export type {
  PropertiesAPIGetFixedExpensesBodyParams,
  PropertiesAPIGetFixedExpensesPayload,
  PropertiesAPIGetFixedExpensesSearchParams,
};
