import { String as S } from "effect";

import { cast } from "@ender/shared/types/cast";
import type { ObjectKeys } from "@ender/shared/types/general";

/**
 * @function convertToNumber
 * @param {string} numString The string to be converted to a number
 * @return {number} The converted number, or NaN if the conversion fails
 */
function convertToNumber(numString: string): number {
  try {
    return parseFloat(numString.toString().replace(/[^0-9-.]/gu, ""));
  } catch {
    return NaN;
  }
}

// The BE uses VARCHAR[191] to store String & Enum types in MySQL. see: EZDB Table::MAX_STRING_SIZE & lines L371-L372
const DEFAULT_MAX_CHARACTER_LENGTH = 191;

function abbreviate(
  str: string,
  maxCharacterLength: number = DEFAULT_MAX_CHARACTER_LENGTH,
): string {
  if (str.length > maxCharacterLength) {
    return `${str.slice(0, maxCharacterLength - 3)}...`;
  }

  return str;
}

/**
 * Capitalizes the first letter of each word in a string.
 *
 * @param {string} [str=""] - The string to capitalize.
 * @returns {string} The capitalized string.
 */
function capitalize(str = "") {
  const words = str.split(/\s|_/);
  const capitalizedWords = words.map((word) => {
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  });
  return capitalizedWords.join(" ");
}

/**
 * Converts a string in kebab-case to UpperCamelCase.
 */
function convertKebabCaseToUpperCamelCase(str: string): string {
  return str
    .split("-")
    .map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join("");
}

/**
 * converts all space-separated strings into `camelCase` strings
 * @param str
 */
function convertToCamelCase(str: string): string {
  return str
    .replace(/(?<!\p{L})\p{L}|\s+/gu, (m) => (+m === 0 ? "" : m.toUpperCase()))
    .replace(/^./, (m) => m?.toLowerCase());
}

/**
 * Converts a string to kebab-case.
 */
function convertToKebabCase(str: string): string {
  return str
    .replace(/([A-Z])/g, " $1")
    .trim()
    .replace(/[ _-]+/g, "-")
    .toLowerCase();
}

/**
 * Converts a string to a valid CSS class name.
 * @param {string} str - The string to convert.
 * @returns {string} - A valid CSS class name.
 */
function convertToClassName(str: string) {
  return str
    .trim()
    .toLowerCase()
    .replace(/^[0-9]+/, "")
    .replace(/[^a-z-_0-9]+/g, "")
    .replace(/[_]+/g, "-")
    .replace(/-$/, "");
}

const PLURAL_LIBRARY = {
  applicant: "applicants",
  application: "applications",
  area: "areas",
  assignee: "assignees",
  attachment: "attachments",
  author: "authors",
  bath: "baths",
  bed: "beds",
  category: "categories",
  charge: "charges",
  "charge type": "charge types",
  check: "checks",
  column: "columns",
  contact: "contacts",
  day: "days",
  document: "documents",
  dollar: "dollars",
  fee: "fees",
  filter: "filters",
  group: "groups",
  home: "homes",
  hour: "hours",
  invoice: "invoices",
  "invoice type": "invoice types",
  is: "are",
  item: "items",
  "lease status": "lease statuses",
  "line item": "line items",
  market: "markets",
  message: "messages",
  month: "months",
  occupant: "occupants",
  page: "pages",
  permission: "permissions",
  pet: "pets",
  photo: "photos",
  property: "properties",
  prospect: "prospects",
  recipient: "recipients",
  result: "results",
  second: "seconds",
  state: "states",
  status: "statuses",
  step: "steps",
  tenant: "tenants",
  transaction: "transactions",
  type: "types",
  unit: "units",
  user: "users",
  vendor: "vendors",
  zone: "zones",
} as const;
// Using this type enables TS to give us an error a word is not in the PLURAL_LIBRARY
type PluralizableWord<T extends string> =
  Lowercase<T> extends ObjectKeys<typeof PLURAL_LIBRARY> ? T : never;

// Type Guard to ensure that the word is a valid key in the PLURAL_LIBRARY
function isPluralizableWord(
  word: string,
): word is ObjectKeys<typeof PLURAL_LIBRARY> {
  return word in PLURAL_LIBRARY;
}

/**
 * @function pluralize
 * @description Provide a singular or plural version of a word
 * @param {string} word The word to modify
 * @param {number=} count The number used to modify the word
 * @returns {string}
 */
function pluralize<T extends string>(word: PluralizableWord<T>, count = 2) {
  const lowerWord = word.toLowerCase();
  if (!isPluralizableWord(lowerWord)) {
    throw new Error(`${word} needs to be defined within the plural library.`);
  }

  const pluralWord = PLURAL_LIBRARY[lowerWord];

  // obtain the correct form of the word
  const responseWord = Math.abs(count) === 1 ? word : pluralWord;

  // ensure proper capitalization
  return word === word.toLowerCase() ? responseWord : capitalize(responseWord);
}

const titleCaseLowerCaseWords: { [key: string]: string } = {
  a: "a",
  an: "an",
  and: "and",
  as: "as",
  at: "at",
  but: "but",
  by: "by",
  for: "for",
  if: "if",
  in: "in",
  nor: "nor",
  of: "of",
  off: "off",
  on: "on",
  per: "per",
  so: "so",
  the: "the",
  to: "to",
  up: "up",
  via: "via",
  yet: "yet",
} as const;

function isMiddleElement(index: number, items: unknown[]) {
  return index !== 0 && index !== items.length - 1;
}

function isTitleCaseLowerCaseException(word: string) {
  return !!titleCaseLowerCaseWords[word.toLowerCase()];
}

/**
 * Converts a string in snake_case to title case.
 * @param {string} str - The string to convert.
 * @returns {string} - The converted string in title case.
 */
function convertSnakeCaseToTitleCase(str: string): string {
  if (str.length === 0) {
    return "";
  }

  return str
    .split("_")
    .map((word, i, words) => {
      if (isMiddleElement(i, words) && isTitleCaseLowerCaseException(word)) {
        return word.toLowerCase();
      }

      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    })
    .join(" ");
}

/**
 * Converts a string in title case to snake_case.
 * @param {string} str - The string to convert.
 * @returns {string} - The converted string in snake_case.
 */
function convertTitleCaseToSnakeCase<T extends string>(str: string): T {
  return cast<T>(str.toUpperCase().split(" ").join("_"));
}

function genTestId(base: string, id?: string) {
  return id ? `${base}-${id}` : base;
}

function splitNumberAndLetterParts(str: string): string[] | null {
  const numberOrLetterRegex = /[a-z]+|[0-9]+/gi;
  return str.match(numberOrLetterRegex);
}

type ReplacePathParams = { url: string; path?: string; depth?: number };

/**
 * Replaces the end of a url with the given path.
 */
function replacePath(params: ReplacePathParams): string {
  const { url, path = "", depth = 1 } = params;
  const paths = url.split("/");
  const count = Math.max(0, Math.min(depth, paths.length));

  if (S.isEmpty(path)) {
    paths.splice(paths.length - count, count);
  } else {
    paths.splice(paths.length - count, count, path);
  }

  return paths.join("/");
}

function convertToURLSlug(str: string) {
  return (
    str
      .trim()
      .toLowerCase()
      // Remove characters that are not alphanumeric or valid URL characters (hyphen, underscore)
      .replace(/[^a-z0-9-_]/g, "-")
      // Replace multiple consecutive hyphens with a single one
      .replace(/-+/g, "-")
      // Remove leading numbers if necessary (optional based on URL design preferences)
      .replace(/^[0-9]+/, "")
      // Remove leading and trailing hyphens
      .replace(/^-+|-+$/g, "")
  );
}

export {
  abbreviate,
  capitalize,
  convertKebabCaseToUpperCamelCase,
  convertSnakeCaseToTitleCase,
  convertTitleCaseToSnakeCase,
  convertToCamelCase,
  convertToClassName,
  convertToKebabCase,
  convertToNumber,
  convertToURLSlug,
  genTestId,
  pluralize,
  replacePath,
  splitNumberAndLetterParts,
};
