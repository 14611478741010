/**
 * HTTP Method: POST
 * Pathname: /admin/sqlReports
 * @function createSqlReport
 * @memberof AdminAPI
 * @param {AdminAPICreateSqlReportPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<SQLReport>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { SQLReport } from "@ender/shared/generated/ender.model.reports";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type AdminAPICreateSqlReportSearchParams = {
  token?: string | undefined;
};

type AdminAPICreateSqlReportBodyParams = {
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  columnOutputs: any[];
  description?: string | undefined;
  name: string;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  parameters: any[];
  pmId?: EnderId | undefined;
  sql: string;
};

type AdminAPICreateSqlReportPayload = AdminAPICreateSqlReportSearchParams &
  AdminAPICreateSqlReportBodyParams;

function createSqlReportUnsafeEffect(payload: AdminAPICreateSqlReportPayload) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, SQLReport>({
        body,
        decode: unsafeDecodeJsonResponse<SQLReport>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/admin/sqlReports",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function createSqlReport(
  payload: AdminAPICreateSqlReportPayload,
  options?: { signal?: AbortSignal },
): Promise<SQLReport> {
  return F.pipe(
    payload,
    createSqlReportUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { createSqlReport };
export type {
  AdminAPICreateSqlReportBodyParams,
  AdminAPICreateSqlReportPayload,
  AdminAPICreateSqlReportSearchParams,
};
