/**
 * HTTP Method: POST
 * Pathname: /inbox
 * @function getInboxItems
 * @memberof EnderAPI
 * @param {EnderAPIGetInboxItemsPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<GetInboxItemsResponse>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId, LocalDate, SerializesInto } from "@ender/shared/core";
import type {
  GetInboxItemsResponse,
  GetInboxItemsResponseParticipantType,
} from "@ender/shared/generated/ender.model.inbox";
import type { ApplicationGroupApplicationStatus } from "@ender/shared/generated/ender.model.leasing";
import type { InboxItemInboxParty } from "@ender/shared/generated/ender.service.comm";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type EnderAPIGetInboxItemsSearchParams = {
  token?: string | undefined;
};

type EnderAPIGetInboxItemsBodyParams = {
  applicationStatuses: ApplicationGroupApplicationStatus[];
  fromDate?: SerializesInto<LocalDate> | undefined;
  inboxParties: InboxItemInboxParty[];
  limit?: number | undefined;
  marketIds: EnderId[];
  offset?: number | undefined;
  participantSearchText: string;
  participantTypes: GetInboxItemsResponseParticipantType[];
  propertyIds: EnderId[];
  showOnlyNeedsFollowUp?: boolean | undefined;
  sortByDateDesc?: boolean | undefined;
};

type EnderAPIGetInboxItemsPayload = EnderAPIGetInboxItemsSearchParams &
  EnderAPIGetInboxItemsBodyParams;

function getInboxItemsUnsafeEffect(payload: EnderAPIGetInboxItemsPayload) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, GetInboxItemsResponse>({
        body,
        decode: unsafeDecodeJsonResponse<GetInboxItemsResponse>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/inbox",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getInboxItems(
  payload: EnderAPIGetInboxItemsPayload,
  options?: { signal?: AbortSignal },
): Promise<GetInboxItemsResponse> {
  return F.pipe(
    payload,
    getInboxItemsUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getInboxItems };
export type {
  EnderAPIGetInboxItemsBodyParams,
  EnderAPIGetInboxItemsPayload,
  EnderAPIGetInboxItemsSearchParams,
};
