"use client";

import { Option as O } from "effect";
import type { EnderId } from "@ender/shared/core";
import { Button, ButtonVariant } from "@ender/shared/ds/button";
import { H2 } from "@ender/shared/ds/heading";
import { Stack } from "@ender/shared/ds/stack";
import { Text, TextColor } from "@ender/shared/ds/text";
import type { EnderSessionResponseVendorResponse } from "@ender/shared/generated/ender.api.misc.response";
type VendorItem = Pick<EnderSessionResponseVendorResponse, "id" | "name">;
type VendorSelectFormViewProps = {
  availableVendors: VendorItem[];
  errorMessage: O.Option<string>;
  isFetching: boolean;
  onCancelClick?: () => void;
  onVendorSelect: (id: EnderId) => void;
};
function VendorSelectFormView(props: VendorSelectFormViewProps) {
  const {
    availableVendors,
    errorMessage,
    isFetching,
    onCancelClick,
    onVendorSelect
  } = props;
  return <Stack data-sentry-element="Stack" data-sentry-component="VendorSelectFormView" data-sentry-source-file="vendor-select-form.view.tsx">
      <H2 data-sentry-element="H2" data-sentry-source-file="vendor-select-form.view.tsx">Select Vendor</H2>
      {availableVendors.map(({
      name,
      id
    }) => <Button variant={ButtonVariant.transparent} key={id} onClick={() => onVendorSelect(id)} loading={isFetching} disabled={isFetching}>
          {name}
        </Button>)}
      {O.isSome(errorMessage) && <Text color={TextColor["red-500"]}>{O.getOrThrow(errorMessage)}</Text>}
      {onCancelClick && <Button variant={ButtonVariant.transparent} onClick={onCancelClick} loading={isFetching}>
          Cancel
        </Button>}
    </Stack>;
}
export { VendorSelectFormView };
export type { VendorItem };