import { addEmergencyContact } from "./add-emergency-contact";
import { getCurrentUser } from "./get-current-user";
import { getCurrentUserVendors } from "./get-current-user-vendors";
import { getEmergencyContacts } from "./get-emergency-contacts";
import { getFilteredUser } from "./get-filtered-user";
import { getFirmsForUser } from "./get-firms-for-user";
import { getPortalAccessInfo } from "./get-portal-access-info";
import { getUser } from "./get-user";
import { getUsers } from "./get-users";
import { getUsersForFirm } from "./get-users-for-firm";
import { getUsersForPM } from "./get-users-for-p-m";
import { newUser } from "./new-user";
import { onboardInvestor } from "./onboard-investor";
import { onboardTenant } from "./onboard-tenant";
import { onboardVendor } from "./onboard-vendor";
import { removeEmergencyContact } from "./remove-emergency-contact";
import { saveProfile } from "./save-profile";
import { sendOnboardingEmail } from "./send-onboarding-email";
import { updateEmergencyContact } from "./update-emergency-contact";
import { updateUser } from "./update-user";
import { verifyUser } from "./verify-user";

const UsersAPI = {
  addEmergencyContact,
  getCurrentUser,
  getCurrentUserVendors,
  getEmergencyContacts,
  getFilteredUser,
  getFirmsForUser,
  getPortalAccessInfo,
  getUser,
  getUsers,
  getUsersForFirm,
  getUsersForPM,
  newUser,
  onboardInvestor,
  onboardTenant,
  onboardVendor,
  removeEmergencyContact,
  saveProfile,
  sendOnboardingEmail,
  updateEmergencyContact,
  updateUser,
  verifyUser,
};

export { UsersAPI };
