import type { Path } from "react-hook-form";
import type { FormInput, MakeFormPropsFromInputProps, UseFormReturn } from "@ender/form-system/base";
import { FormField } from "@ender/form-system/base";
import type { PhoneInputProps } from "./shared-ds-phone-input";
import { PhoneInput } from "./shared-ds-phone-input";
type FormPhoneInputProps<
// eslint-disable-next-line @typescript-eslint/no-explicit-any
Form extends UseFormReturn<any, any, any>, Name extends Path<FormInput<Form>>> = MakeFormPropsFromInputProps<PhoneInputProps, Form, Name>;
function FormPhoneInput<
// eslint-disable-next-line @typescript-eslint/no-explicit-any
Form extends UseFormReturn<any, any, any>, Name extends Path<FormInput<Form>>>(props: FormPhoneInputProps<Form, Name>) {
  const {
    form,
    name,
    ...rest
  } = props;
  return <FormField name={name} form={form} data-sentry-element="FormField" data-sentry-component="FormPhoneInput" data-sentry-source-file="form-phone-input.tsx">
      {({
      field,
      error
    }) => {
      return <PhoneInput {...rest} {...field} error={error} />;
    }}
    </FormField>;
}
export { FormPhoneInput };
export type { FormPhoneInputProps };