/**
 * HTTP Method: POST
 * Pathname: /services/search
 * @function searchServices
 * @memberof TasksAPI
 * @param {TasksAPISearchServicesPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<TasksAPISearchServicesResponse[]>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

import type { TasksAPISearchServicesResponse } from "../tasks-api-search-services-response";

type TasksAPISearchServicesSearchParams = {
  token?: string | undefined;
};

type TasksAPISearchServicesBodyParams = {
  propertyId?: EnderId | undefined;
  unitId?: EnderId | undefined;
  vendorId?: EnderId | undefined;
};

type TasksAPISearchServicesPayload = TasksAPISearchServicesSearchParams &
  TasksAPISearchServicesBodyParams;

function searchServicesUnsafeEffect(payload: TasksAPISearchServicesPayload) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, TasksAPISearchServicesResponse[]>({
        body,
        decode: unsafeDecodeJsonResponse<TasksAPISearchServicesResponse[]>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/services/search",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function searchServices(
  payload: TasksAPISearchServicesPayload,
  options?: { signal?: AbortSignal },
): Promise<TasksAPISearchServicesResponse[]> {
  return F.pipe(
    payload,
    searchServicesUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { searchServices };
export type {
  TasksAPISearchServicesBodyParams,
  TasksAPISearchServicesPayload,
  TasksAPISearchServicesSearchParams,
};
