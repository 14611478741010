import { createUnitZone } from "./create-unit-zone";
import { deleteUnitZone } from "./delete-unit-zone";
import { getUnitZones } from "./get-unit-zones";
import { updateUnitZone } from "./update-unit-zone";

const UnitZonesAPI = {
  createUnitZone,
  deleteUnitZone,
  getUnitZones,
  updateUnitZone,
};

export { UnitZonesAPI };
