/**
 * HTTP Method: GET
 * Pathname: /model-explorer/bank-accounts/{bankAccountId}
 * @function getBankAccount
 * @memberof ModelExplorerAPI
 * @param {ModelExplorerAPIGetBankAccountPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<ModelExplorerAPIGetBankAccountResponse>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

import type { ModelExplorerAPIGetBankAccountResponse } from "../model-explorer-api-get-bank-account-response";

type ModelExplorerAPIGetBankAccountPathParams = {
  bankAccountId: EnderId;
};

type ModelExplorerAPIGetBankAccountSearchParams = {
  token?: string | undefined;
};

type ModelExplorerAPIGetBankAccountPayload =
  ModelExplorerAPIGetBankAccountPathParams &
    ModelExplorerAPIGetBankAccountSearchParams;

function getBankAccountUnsafeEffect(
  payload: ModelExplorerAPIGetBankAccountPayload,
) {
  const { bankAccountId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, ModelExplorerAPIGetBankAccountResponse>({
        body,
        decode:
          unsafeDecodeJsonResponse<ModelExplorerAPIGetBankAccountResponse>({}),
        encode: encodeNoBody({ method: "GET" }),
        pathname: `/model-explorer/bank-accounts/${bankAccountId}`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getBankAccount(
  payload: ModelExplorerAPIGetBankAccountPayload,
  options?: { signal?: AbortSignal },
): Promise<ModelExplorerAPIGetBankAccountResponse> {
  return F.pipe(
    payload,
    getBankAccountUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getBankAccount };
export type {
  ModelExplorerAPIGetBankAccountPathParams,
  ModelExplorerAPIGetBankAccountPayload,
  ModelExplorerAPIGetBankAccountSearchParams,
};
