/**
 * HTTP Method: POST
 * Pathname: /admin/phoneLines/{phoneLineId}/messages
 * @function getAdminPhoneLineMessages
 * @memberof AdminAPI
 * @param {AdminAPIGetAdminPhoneLineMessagesPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<AdminAPIGetAdminPhoneLineMessagesResponse>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

import type { AdminAPIGetAdminPhoneLineMessagesResponse } from "../admin-api-get-admin-phone-line-messages-response";

type AdminAPIGetAdminPhoneLineMessagesPathParams = {
  phoneLineId: EnderId;
};

type AdminAPIGetAdminPhoneLineMessagesSearchParams = {
  token?: string | undefined;
};

type AdminAPIGetAdminPhoneLineMessagesPayload =
  AdminAPIGetAdminPhoneLineMessagesPathParams &
    AdminAPIGetAdminPhoneLineMessagesSearchParams;

function getAdminPhoneLineMessagesUnsafeEffect(
  payload: AdminAPIGetAdminPhoneLineMessagesPayload,
) {
  const { phoneLineId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, AdminAPIGetAdminPhoneLineMessagesResponse>({
        body,
        decode:
          unsafeDecodeJsonResponse<AdminAPIGetAdminPhoneLineMessagesResponse>(
            {},
          ),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/admin/phoneLines/${phoneLineId}/messages`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getAdminPhoneLineMessages(
  payload: AdminAPIGetAdminPhoneLineMessagesPayload,
  options?: { signal?: AbortSignal },
): Promise<AdminAPIGetAdminPhoneLineMessagesResponse> {
  return F.pipe(
    payload,
    getAdminPhoneLineMessagesUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getAdminPhoneLineMessages };
export type {
  AdminAPIGetAdminPhoneLineMessagesPathParams,
  AdminAPIGetAdminPhoneLineMessagesPayload,
  AdminAPIGetAdminPhoneLineMessagesSearchParams,
};
