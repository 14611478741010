import { Data } from "effect";
import type { ReactNode } from "react";
import type {
  FieldPathByValue,
  Path,
  FieldPath as RhfFieldPath,
  FieldValues as RhfFieldValues,
  UseControllerProps as RhfUseControllerProps,
  UseControllerReturn as RhfUseControllerReturn,
  UseFormHandleSubmit as RhfUseFormHandleSubmit,
  UseFormProps as RhfUseFormProps,
  UseFormReturn as RhfUseFormReturn,
} from "react-hook-form";

import type { Undefined } from "@ender/shared/constants/general";

class NotInFormContextError extends Data.TaggedError("NotInFormContext") {
  static readonly message = "Not in form context";
  override readonly message = NotInFormContextError.message;
  static of = () => new NotInFormContextError();
}

type FormValuesCustomFields = {
  // Add fields that should always be present in the form values here
  // they are combined partially so they can be required
  // and using them meaningfully will require further refactoring
  // changing these will require changing useFormContext
};
type FieldValues = RhfFieldValues & Partial<FormValuesCustomFields>;

type UseFormCustomReturn = {
  // Add custom return values here
  // changing these will require changing useForm
};

type UseFormReturn<
  TFieldValues extends FieldValues,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TContext = any,
  TransformedValues extends FieldValues | Undefined = Undefined,
> = RhfUseFormReturn<TFieldValues, TContext, TransformedValues> &
  UseFormCustomReturn;

/**
 * extractor types from UseFormReturn
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type FormInput<T extends UseFormReturn<any, any, any>> =
  T extends UseFormReturn<infer Input, infer _Context, infer _Output>
    ? Input
    : never;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type FormOutput<T extends UseFormReturn<any, any, any>> =
  T extends UseFormReturn<infer _Input, infer _Context, infer Output>
    ? Output
    : never;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type FormContext<T extends UseFormReturn<any, any, any>> =
  T extends UseFormReturn<infer _Input, infer Context, infer _Output>
    ? Context
    : never;

type FieldPath<TFieldValues extends FieldValues> = RhfFieldPath<TFieldValues>;

type UseControllerProps<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
> = RhfUseControllerProps<TFieldValues, TName>;

type UseControllerReturn<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
> = RhfUseControllerReturn<TFieldValues, TName>;

type UseFormCustomProps<TFieldValues extends FieldValues> = {
  // Add custom options here
  // they are combined partially so they can be required
  // changing these will require changing useForm usages
  defaultValues: TFieldValues;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type UseFormProps<TFieldValues extends FieldValues, TContext = any> = Omit<
  RhfUseFormProps<TFieldValues, TContext>,
  "defaultValues"
> &
  UseFormCustomProps<TFieldValues>;

type FormProviderChild<
  TFieldValues extends FieldValues,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TContext = any,
  TTransformedValues extends FieldValues | Undefined = Undefined,
> = (
  form: UseFormReturn<TFieldValues, TContext, TTransformedValues>,
) => ReactNode;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type UseFormHandleSubmit<T extends UseFormReturn<any, any, any>> =
  RhfUseFormHandleSubmit<FormInput<T>, FormOutput<T>>;

type FormFieldProps<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  T extends { value: any },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Form extends UseFormReturn<any, any, any>,
  Name extends Path<FormInput<Form>>,
> = {
  name: Extract<Name, FieldPathByValue<FormInput<Form>, T["value"]>>;
  form: Form;
};

type OmitNewAndImproved<T, K> = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [P in keyof T as Exclude<P, K & keyof any>]: T[P];
};

// make a type util to omit input props
type MakeFormPropsFromInputProps<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  T extends { name?: any; value: any; onChange: any },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Form extends UseFormReturn<any, any, any>,
  Name extends Path<FormInput<Form>>,
> = OmitNewAndImproved<T, "name" | "onChange" | "value"> &
  FormFieldProps<T, Form, Name>;

/**
 * This can potentially be built in to the Section and List types-
 * as long as you provide a valid subset of fields, you can get a subset of the form
 * if the path points to that valid subset
 */
type FormSubSectionReference<
  T extends RhfFieldValues,
  F extends RhfFieldValues,
> = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  form: UseFormReturn<T>;
  name: FieldPathByValue<T, F>;
};

export type {
  FieldPath,
  FieldValues,
  FormContext,
  FormInput,
  FormOutput,
  FormProviderChild,
  FormSubSectionReference,
  FormValuesCustomFields,
  MakeFormPropsFromInputProps,
  UseControllerProps,
  UseControllerReturn,
  UseFormCustomProps,
  UseFormHandleSubmit,
  UseFormProps,
  UseFormReturn,
};

export { NotInFormContextError };
