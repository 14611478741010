/**
 * HTTP Method: POST
 * Pathname: /getOwnershipGroups
 * @function getOwnershipGroups
 * @memberof OwnershipGroupsAPI
 * @param {OwnershipGroupsAPIGetOwnershipGroupsPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<GetOwnershipGroupsResponse>}
 */
import { Effect, Function as F } from "effect";

import type { GetOwnershipGroupsResponse } from "@ender/shared/generated/ender.api.misc.response";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type OwnershipGroupsAPIGetOwnershipGroupsSearchParams = {
  token?: string | undefined;
};

type OwnershipGroupsAPIGetOwnershipGroupsPayload =
  OwnershipGroupsAPIGetOwnershipGroupsSearchParams;

function getOwnershipGroupsUnsafeEffect(
  payload: OwnershipGroupsAPIGetOwnershipGroupsPayload,
) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, GetOwnershipGroupsResponse>({
        body,
        decode: unsafeDecodeJsonResponse<GetOwnershipGroupsResponse>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/getOwnershipGroups",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getOwnershipGroups(
  payload: OwnershipGroupsAPIGetOwnershipGroupsPayload,
  options?: { signal?: AbortSignal },
): Promise<GetOwnershipGroupsResponse> {
  return F.pipe(
    payload,
    getOwnershipGroupsUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getOwnershipGroups };
export type {
  OwnershipGroupsAPIGetOwnershipGroupsPayload,
  OwnershipGroupsAPIGetOwnershipGroupsSearchParams,
};
