/**
 * HTTP Method: POST
 * Pathname: /fixtures/createFirmBankAccount
 * @function createFirmBankAccount
 * @memberof FixturesAPI
 * @param {FixturesAPICreateFirmBankAccountPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<BankAccount>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { BankAccount } from "@ender/shared/generated/ender.model.payments";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type FixturesAPICreateFirmBankAccountSearchParams = {
  token?: string | undefined;
};

type FixturesAPICreateFirmBankAccountBodyParams = {
  cashCategoryId: EnderId;
  firmId: EnderId;
  isDefaultOperating: boolean;
  isDwollaVerified: boolean;
  name?: string | undefined;
};

type FixturesAPICreateFirmBankAccountPayload =
  FixturesAPICreateFirmBankAccountSearchParams &
    FixturesAPICreateFirmBankAccountBodyParams;

function createFirmBankAccountUnsafeEffect(
  payload: FixturesAPICreateFirmBankAccountPayload,
) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, BankAccount>({
        body,
        decode: unsafeDecodeJsonResponse<BankAccount>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/fixtures/createFirmBankAccount",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function createFirmBankAccount(
  payload: FixturesAPICreateFirmBankAccountPayload,
  options?: { signal?: AbortSignal },
): Promise<BankAccount> {
  return F.pipe(
    payload,
    createFirmBankAccountUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { createFirmBankAccount };
export type {
  FixturesAPICreateFirmBankAccountBodyParams,
  FixturesAPICreateFirmBankAccountPayload,
  FixturesAPICreateFirmBankAccountSearchParams,
};
