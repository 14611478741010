import { getFeatureFlag } from "./get-feature-flag";
import { getFeatureFlagValue } from "./get-feature-flag-value";
import { getFeatureFlagValues } from "./get-feature-flag-values";
import { updateFeatureFlag } from "./update-feature-flag";

const FeatureFlagAPI = {
  getFeatureFlag,
  getFeatureFlagValue,
  getFeatureFlagValues,
  updateFeatureFlag,
};

export { FeatureFlagAPI };
