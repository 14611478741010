/**
 * HTTP Method: POST
 * Pathname: /tasks/{taskId}/acceptInvoice
 * @function acceptInvoice
 * @memberof TasksAPI
 * @param {TasksAPIAcceptInvoicePayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type TasksAPIAcceptInvoicePathParams = {
  taskId: EnderId;
};

type TasksAPIAcceptInvoiceSearchParams = {
  token?: string | undefined;
};

type TasksAPIAcceptInvoicePayload = TasksAPIAcceptInvoicePathParams &
  TasksAPIAcceptInvoiceSearchParams;

function acceptInvoiceUnsafeEffect(payload: TasksAPIAcceptInvoicePayload) {
  const { taskId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/tasks/${taskId}/acceptInvoice`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function acceptInvoice(
  payload: TasksAPIAcceptInvoicePayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    acceptInvoiceUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { acceptInvoice };
export type {
  TasksAPIAcceptInvoicePathParams,
  TasksAPIAcceptInvoicePayload,
  TasksAPIAcceptInvoiceSearchParams,
};
