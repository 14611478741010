/**
 * HTTP Method: POST
 * Pathname: /addFundToOwnershipGroup
 * @function addFundToOwnershipGroup
 * @memberof OwnershipGroupsAPI
 * @param {OwnershipGroupsAPIAddFundToOwnershipGroupPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type OwnershipGroupsAPIAddFundToOwnershipGroupSearchParams = {
  token?: string | undefined;
};

type OwnershipGroupsAPIAddFundToOwnershipGroupBodyParams = {
  fundId: EnderId;
  ownershipGroupId: EnderId;
};

type OwnershipGroupsAPIAddFundToOwnershipGroupPayload =
  OwnershipGroupsAPIAddFundToOwnershipGroupSearchParams &
    OwnershipGroupsAPIAddFundToOwnershipGroupBodyParams;

function addFundToOwnershipGroupUnsafeEffect(
  payload: OwnershipGroupsAPIAddFundToOwnershipGroupPayload,
) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/addFundToOwnershipGroup",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function addFundToOwnershipGroup(
  payload: OwnershipGroupsAPIAddFundToOwnershipGroupPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    addFundToOwnershipGroupUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { addFundToOwnershipGroup };
export type {
  OwnershipGroupsAPIAddFundToOwnershipGroupBodyParams,
  OwnershipGroupsAPIAddFundToOwnershipGroupPayload,
  OwnershipGroupsAPIAddFundToOwnershipGroupSearchParams,
};
