/**
 * HTTP Method: POST
 * Pathname: /firms/search
 * @function searchFirms
 * @memberof FirmsAPI
 * @param {FirmsAPISearchFirmsPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<Firm[]>}
 */
import { Effect, Function as F } from "effect";

import type {
  Firm,
  FirmFirmType,
} from "@ender/shared/generated/ender.model.core";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type FirmsAPISearchFirmsSearchParams = {
  token?: string | undefined;
};

type FirmsAPISearchFirmsBodyParams = {
  directlyOwnsProperties?: boolean | undefined;
  firmType?: FirmFirmType | undefined;
};

type FirmsAPISearchFirmsPayload = FirmsAPISearchFirmsSearchParams &
  FirmsAPISearchFirmsBodyParams;

function searchFirmsUnsafeEffect(payload: FirmsAPISearchFirmsPayload) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, Firm[]>({
        body,
        decode: unsafeDecodeJsonResponse<Firm[]>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/firms/search",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function searchFirms(
  payload: FirmsAPISearchFirmsPayload,
  options?: { signal?: AbortSignal },
): Promise<Firm[]> {
  return F.pipe(
    payload,
    searchFirmsUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { searchFirms };
export type {
  FirmsAPISearchFirmsBodyParams,
  FirmsAPISearchFirmsPayload,
  FirmsAPISearchFirmsSearchParams,
};
