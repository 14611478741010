/**
 * HTTP Method: POST
 * Pathname: /vendors/{vendorId}/w9
 * @function submitW9
 * @memberof VendorsAPI
 * @param {VendorsAPISubmitW9Payload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type VendorsAPISubmitW9PathParams = {
  vendorId: EnderId;
};

type VendorsAPISubmitW9SearchParams = {
  token?: string | undefined;
};

type VendorsAPISubmitW9BodyParams = {
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  json: any;
};

type VendorsAPISubmitW9Payload = VendorsAPISubmitW9PathParams &
  VendorsAPISubmitW9SearchParams &
  VendorsAPISubmitW9BodyParams;

function submitW9UnsafeEffect(payload: VendorsAPISubmitW9Payload) {
  const { vendorId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/vendors/${vendorId}/w9`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function submitW9(
  payload: VendorsAPISubmitW9Payload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    submitW9UnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { submitW9 };
export type {
  VendorsAPISubmitW9BodyParams,
  VendorsAPISubmitW9PathParams,
  VendorsAPISubmitW9Payload,
  VendorsAPISubmitW9SearchParams,
};
