/**
 * HTTP Method: POST
 * Pathname: /getOwnershipGroupAssociations
 * @function getOwnershipGroupAssociations
 * @memberof OwnershipGroupsAPI
 * @param {OwnershipGroupsAPIGetOwnershipGroupAssociationsPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<GetOwnershipGroupAssociationsResponse>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { GetOwnershipGroupAssociationsResponse } from "@ender/shared/generated/ender.api.misc.response";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type OwnershipGroupsAPIGetOwnershipGroupAssociationsSearchParams = {
  token?: string | undefined;
};

type OwnershipGroupsAPIGetOwnershipGroupAssociationsBodyParams = {
  ownershipGroupId: EnderId;
};

type OwnershipGroupsAPIGetOwnershipGroupAssociationsPayload =
  OwnershipGroupsAPIGetOwnershipGroupAssociationsSearchParams &
    OwnershipGroupsAPIGetOwnershipGroupAssociationsBodyParams;

function getOwnershipGroupAssociationsUnsafeEffect(
  payload: OwnershipGroupsAPIGetOwnershipGroupAssociationsPayload,
) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, GetOwnershipGroupAssociationsResponse>({
        body,
        decode: unsafeDecodeJsonResponse<GetOwnershipGroupAssociationsResponse>(
          {},
        ),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/getOwnershipGroupAssociations",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getOwnershipGroupAssociations(
  payload: OwnershipGroupsAPIGetOwnershipGroupAssociationsPayload,
  options?: { signal?: AbortSignal },
): Promise<GetOwnershipGroupAssociationsResponse> {
  return F.pipe(
    payload,
    getOwnershipGroupAssociationsUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getOwnershipGroupAssociations };
export type {
  OwnershipGroupsAPIGetOwnershipGroupAssociationsBodyParams,
  OwnershipGroupsAPIGetOwnershipGroupAssociationsPayload,
  OwnershipGroupsAPIGetOwnershipGroupAssociationsSearchParams,
};
