/**
 * HTTP Method: POST
 * Pathname: /properties/{propertyId}/unitTypes/{unitTypeId}
 * @function updateUnitType
 * @memberof PropertiesAPI
 * @param {PropertiesAPIUpdateUnitTypePayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId, Money, SerializesInto } from "@ender/shared/core";
import type { AmenityAmenityType } from "@ender/shared/generated/ender.model.core.property";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type PropertiesAPIUpdateUnitTypePathParams = {
  propertyId: EnderId;
  unitTypeId: EnderId;
};

type PropertiesAPIUpdateUnitTypeSearchParams = {
  token?: string | undefined;
};

type PropertiesAPIUpdateUnitTypeBodyParams = {
  amenities?: AmenityAmenityType[] | undefined;
  applyUpdatesToUnits?: boolean | undefined;
  beds?: number | undefined;
  fullBaths?: number | undefined;
  halfBaths?: number | undefined;
  lengthFeet?: number | undefined;
  marketRentPrice?: SerializesInto<Money> | undefined;
  name?: string | undefined;
  sqft?: number | undefined;
  widthFeet?: number | undefined;
};

type PropertiesAPIUpdateUnitTypePayload =
  PropertiesAPIUpdateUnitTypePathParams &
    PropertiesAPIUpdateUnitTypeSearchParams &
    PropertiesAPIUpdateUnitTypeBodyParams;

function updateUnitTypeUnsafeEffect(
  payload: PropertiesAPIUpdateUnitTypePayload,
) {
  const { propertyId, unitTypeId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/properties/${propertyId}/unitTypes/${unitTypeId}`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function updateUnitType(
  payload: PropertiesAPIUpdateUnitTypePayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    updateUnitTypeUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { updateUnitType };
export type {
  PropertiesAPIUpdateUnitTypeBodyParams,
  PropertiesAPIUpdateUnitTypePathParams,
  PropertiesAPIUpdateUnitTypePayload,
  PropertiesAPIUpdateUnitTypeSearchParams,
};
