/**
 * HTTP Method: POST
 * Pathname: /approvalProcess/reject
 * @function reject
 * @memberof ApprovalsAPI
 * @param {ApprovalsAPIRejectPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId, LocalDate, SerializesInto } from "@ender/shared/core";
import type { ModelType } from "@ender/shared/generated/com.ender.common.model";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type ApprovalsAPIRejectSearchParams = {
  token?: string | undefined;
};

type ApprovalsAPIRejectBodyParams = {
  comment?: string | undefined;
  modelId: EnderId;
  modelType: ModelType;
  periodDate?: SerializesInto<LocalDate> | undefined;
  rejectedToStepId?: EnderId | undefined;
  transactionDate?: SerializesInto<LocalDate> | undefined;
};

type ApprovalsAPIRejectPayload = ApprovalsAPIRejectSearchParams &
  ApprovalsAPIRejectBodyParams;

function rejectUnsafeEffect(payload: ApprovalsAPIRejectPayload) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/approvalProcess/reject",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function reject(
  payload: ApprovalsAPIRejectPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    rejectUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { reject };
export type {
  ApprovalsAPIRejectBodyParams,
  ApprovalsAPIRejectPayload,
  ApprovalsAPIRejectSearchParams,
};
