/**
 * HTTP Method: POST
 * Pathname: /units/{unitId}/areas
 * @function createUnitArea
 * @memberof UnitAreasAPI
 * @param {UnitAreasAPICreateUnitAreaPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<UnitArea>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type {
  UnitArea,
  UnitAreaUnitAreaType,
} from "@ender/shared/generated/ender.model.core.unit.area";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type UnitAreasAPICreateUnitAreaPathParams = {
  unitId: EnderId;
};

type UnitAreasAPICreateUnitAreaSearchParams = {
  token?: string | undefined;
};

type UnitAreasAPICreateUnitAreaBodyParams = {
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  data?: any | undefined;
  description?: string | undefined;
  type: UnitAreaUnitAreaType;
};

type UnitAreasAPICreateUnitAreaPayload = UnitAreasAPICreateUnitAreaPathParams &
  UnitAreasAPICreateUnitAreaSearchParams &
  UnitAreasAPICreateUnitAreaBodyParams;

function createUnitAreaUnsafeEffect(
  payload: UnitAreasAPICreateUnitAreaPayload,
) {
  const { unitId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, UnitArea>({
        body,
        decode: unsafeDecodeJsonResponse<UnitArea>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/units/${unitId}/areas`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function createUnitArea(
  payload: UnitAreasAPICreateUnitAreaPayload,
  options?: { signal?: AbortSignal },
): Promise<UnitArea> {
  return F.pipe(
    payload,
    createUnitAreaUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { createUnitArea };
export type {
  UnitAreasAPICreateUnitAreaBodyParams,
  UnitAreasAPICreateUnitAreaPathParams,
  UnitAreasAPICreateUnitAreaPayload,
  UnitAreasAPICreateUnitAreaSearchParams,
};
