/**
 * Returns the logged-in user's session information.

 In the returned data: <br> 
 "readOnly" indicates that the session can only read, not write data. <br> 
 "userId" is the id of the logged in User <br> 
 "originalUserId" is the id of the user who is masquerading as the logged in User. <br>
 * HTTP Method: GET
 * Pathname: /getSessionInfo
 * @function getSessionInfo
 * @memberof AuthAPI
 * @param {AuthAPIGetSessionInfoPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<EnderSessionResponse>}
 */
import { Effect, Function as F } from "effect";

import type { EnderSessionResponse } from "@ender/shared/generated/ender.api.misc.response";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type AuthAPIGetSessionInfoSearchParams = {
  token?: string | undefined;
};

type AuthAPIGetSessionInfoPayload = AuthAPIGetSessionInfoSearchParams;

function getSessionInfoUnsafeEffect(payload: AuthAPIGetSessionInfoPayload) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, EnderSessionResponse>({
        body,
        decode: unsafeDecodeJsonResponse<EnderSessionResponse>({}),
        encode: encodeNoBody({ method: "GET" }),
        pathname: "/getSessionInfo",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getSessionInfo(
  payload: AuthAPIGetSessionInfoPayload,
  options?: { signal?: AbortSignal },
): Promise<EnderSessionResponse> {
  return F.pipe(
    payload,
    getSessionInfoUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getSessionInfo };
export type { AuthAPIGetSessionInfoPayload, AuthAPIGetSessionInfoSearchParams };
