/**
 * HTTP Method: GET
 * Pathname: /units/{unitId}/appliances
 * @function getUnitAppliances
 * @memberof UnitsAPI
 * @param {UnitsAPIGetUnitAppliancesPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<Appliance[]>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { Appliance } from "@ender/shared/generated/ender.model.core.unit";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type UnitsAPIGetUnitAppliancesPathParams = {
  unitId: EnderId;
};

type UnitsAPIGetUnitAppliancesSearchParams = {
  token?: string | undefined;
};

type UnitsAPIGetUnitAppliancesPayload = UnitsAPIGetUnitAppliancesPathParams &
  UnitsAPIGetUnitAppliancesSearchParams;

function getUnitAppliancesUnsafeEffect(
  payload: UnitsAPIGetUnitAppliancesPayload,
) {
  const { unitId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, Appliance[]>({
        body,
        decode: unsafeDecodeJsonResponse<Appliance[]>({}),
        encode: encodeNoBody({ method: "GET" }),
        pathname: `/units/${unitId}/appliances`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getUnitAppliances(
  payload: UnitsAPIGetUnitAppliancesPayload,
  options?: { signal?: AbortSignal },
): Promise<Appliance[]> {
  return F.pipe(
    payload,
    getUnitAppliancesUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getUnitAppliances };
export type {
  UnitsAPIGetUnitAppliancesPathParams,
  UnitsAPIGetUnitAppliancesPayload,
  UnitsAPIGetUnitAppliancesSearchParams,
};
