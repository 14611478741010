/**
 * HTTP Method: GET
 * Pathname: /tasks/{taskId}/downloadPhotosZIP
 * @function downloadTaskPhotosZIP
 * @memberof TasksAPI
 * @param {TasksAPIDownloadTaskPhotosZIPPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type TasksAPIDownloadTaskPhotosZIPPathParams = {
  taskId: EnderId;
};

type TasksAPIDownloadTaskPhotosZIPSearchParams = {
  token?: string | undefined;
  taskName?: string | undefined;
};

type TasksAPIDownloadTaskPhotosZIPPayload =
  TasksAPIDownloadTaskPhotosZIPPathParams &
    TasksAPIDownloadTaskPhotosZIPSearchParams;

function downloadTaskPhotosZIPUnsafeEffect(
  payload: TasksAPIDownloadTaskPhotosZIPPayload,
) {
  const { taskId, token, taskName, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeNoBody({ method: "GET" }),
        pathname: `/tasks/${taskId}/downloadPhotosZIP`,
        searchParams: [
          ["token", token],
          ["taskName", taskName],
        ],
      }),
    ),
  );
}

function downloadTaskPhotosZIP(
  payload: TasksAPIDownloadTaskPhotosZIPPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    downloadTaskPhotosZIPUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { downloadTaskPhotosZIP };
export type {
  TasksAPIDownloadTaskPhotosZIPPathParams,
  TasksAPIDownloadTaskPhotosZIPPayload,
  TasksAPIDownloadTaskPhotosZIPSearchParams,
};
