/**
 * HTTP Method: POST
 * Pathname: /login/changeActiveVendor
 * @function changeActiveVendor
 * @memberof AuthAPI
 * @param {AuthAPIChangeActiveVendorPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<EnderSessionResponse>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { EnderSessionResponse } from "@ender/shared/generated/ender.api.misc.response";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type AuthAPIChangeActiveVendorSearchParams = {
  token?: string | undefined;
};

type AuthAPIChangeActiveVendorBodyParams = {
  vendorId: EnderId;
};

type AuthAPIChangeActiveVendorPayload = AuthAPIChangeActiveVendorSearchParams &
  AuthAPIChangeActiveVendorBodyParams;

function changeActiveVendorUnsafeEffect(
  payload: AuthAPIChangeActiveVendorPayload,
) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, EnderSessionResponse>({
        body,
        decode: unsafeDecodeJsonResponse<EnderSessionResponse>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/login/changeActiveVendor",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function changeActiveVendor(
  payload: AuthAPIChangeActiveVendorPayload,
  options?: { signal?: AbortSignal },
): Promise<EnderSessionResponse> {
  return F.pipe(
    payload,
    changeActiveVendorUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { changeActiveVendor };
export type {
  AuthAPIChangeActiveVendorBodyParams,
  AuthAPIChangeActiveVendorPayload,
  AuthAPIChangeActiveVendorSearchParams,
};
