/**
 * HTTP Method: GET
 * Pathname: /users/{targetId}/ownershipTree
 * @function getOwnershipTree
 * @memberof OwnershipAPI
 * @param {OwnershipAPIGetOwnershipTreePayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<OwnershipAPIOwnershipResponseNode>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

import type { OwnershipAPIOwnershipResponseNode } from "../ownership-api-ownership-response-node";

type OwnershipAPIGetOwnershipTreePathParams = {
  targetId: EnderId;
};

type OwnershipAPIGetOwnershipTreeSearchParams = {
  token?: string | undefined;
};

type OwnershipAPIGetOwnershipTreePayload =
  OwnershipAPIGetOwnershipTreePathParams &
    OwnershipAPIGetOwnershipTreeSearchParams;

function getOwnershipTreeUnsafeEffect(
  payload: OwnershipAPIGetOwnershipTreePayload,
) {
  const { targetId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, OwnershipAPIOwnershipResponseNode>({
        body,
        decode: unsafeDecodeJsonResponse<OwnershipAPIOwnershipResponseNode>({}),
        encode: encodeNoBody({ method: "GET" }),
        pathname: `/users/${targetId}/ownershipTree`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getOwnershipTree(
  payload: OwnershipAPIGetOwnershipTreePayload,
  options?: { signal?: AbortSignal },
): Promise<OwnershipAPIOwnershipResponseNode> {
  return F.pipe(
    payload,
    getOwnershipTreeUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getOwnershipTree };
export type {
  OwnershipAPIGetOwnershipTreePathParams,
  OwnershipAPIGetOwnershipTreePayload,
  OwnershipAPIGetOwnershipTreeSearchParams,
};
