/**
 * HTTP Method: POST
 * Pathname: /firms/{firmId}/settings
 * @function updateFirmSettings
 * @memberof FirmsAPI
 * @param {FirmsAPIUpdateFirmSettingsPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { ModelSettingsSettingKey } from "@ender/shared/generated/ender.model.misc";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type FirmsAPIUpdateFirmSettingsPathParams = {
  firmId: EnderId;
};

type FirmsAPIUpdateFirmSettingsSearchParams = {
  token?: string | undefined;
};

type FirmsAPIUpdateFirmSettingsBodyParams = {
  settingKey: ModelSettingsSettingKey;
  value: string;
};

type FirmsAPIUpdateFirmSettingsPayload = FirmsAPIUpdateFirmSettingsPathParams &
  FirmsAPIUpdateFirmSettingsSearchParams &
  FirmsAPIUpdateFirmSettingsBodyParams;

function updateFirmSettingsUnsafeEffect(
  payload: FirmsAPIUpdateFirmSettingsPayload,
) {
  const { firmId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/firms/${firmId}/settings`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function updateFirmSettings(
  payload: FirmsAPIUpdateFirmSettingsPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    updateFirmSettingsUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { updateFirmSettings };
export type {
  FirmsAPIUpdateFirmSettingsBodyParams,
  FirmsAPIUpdateFirmSettingsPathParams,
  FirmsAPIUpdateFirmSettingsPayload,
  FirmsAPIUpdateFirmSettingsSearchParams,
};
