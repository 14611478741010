/**
 * HTTP Method: POST
 * Pathname: /fixtures/createTextTemplate
 * @function createTextTemplate
 * @memberof FixturesAPI
 * @param {FixturesAPICreateTextTemplatePayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<TextTemplate>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type {
  TextTemplate,
  TextTemplateTemplateType,
} from "@ender/shared/generated/ender.model.leasing";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type FixturesAPICreateTextTemplateSearchParams = {
  token?: string | undefined;
};

type FixturesAPICreateTextTemplateBodyParams = {
  body?: string | undefined;
  name?: string | undefined;
  pmId: EnderId;
  subject?: string | undefined;
  type: TextTemplateTemplateType;
};

type FixturesAPICreateTextTemplatePayload =
  FixturesAPICreateTextTemplateSearchParams &
    FixturesAPICreateTextTemplateBodyParams;

function createTextTemplateUnsafeEffect(
  payload: FixturesAPICreateTextTemplatePayload,
) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, TextTemplate>({
        body,
        decode: unsafeDecodeJsonResponse<TextTemplate>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/fixtures/createTextTemplate",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function createTextTemplate(
  payload: FixturesAPICreateTextTemplatePayload,
  options?: { signal?: AbortSignal },
): Promise<TextTemplate> {
  return F.pipe(
    payload,
    createTextTemplateUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { createTextTemplate };
export type {
  FixturesAPICreateTextTemplateBodyParams,
  FixturesAPICreateTextTemplatePayload,
  FixturesAPICreateTextTemplateSearchParams,
};
