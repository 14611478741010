/**
 * HTTP Method: POST
 * Pathname: /model-explorer/messages/search
 * @function searchMessages
 * @memberof ModelExplorerAPI
 * @param {ModelExplorerAPISearchMessagesPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<ModelExplorerAPISearchMessagesResponse>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

import type { ModelExplorerAPISearchMessagesResponse } from "../model-explorer-api-search-messages-response";

type ModelExplorerAPISearchMessagesSearchParams = {
  token?: string | undefined;
};

type ModelExplorerAPISearchMessagesBodyParams = {
  modelId: EnderId;
};

type ModelExplorerAPISearchMessagesPayload =
  ModelExplorerAPISearchMessagesSearchParams &
    ModelExplorerAPISearchMessagesBodyParams;

function searchMessagesUnsafeEffect(
  payload: ModelExplorerAPISearchMessagesPayload,
) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, ModelExplorerAPISearchMessagesResponse>({
        body,
        decode:
          unsafeDecodeJsonResponse<ModelExplorerAPISearchMessagesResponse>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/model-explorer/messages/search",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function searchMessages(
  payload: ModelExplorerAPISearchMessagesPayload,
  options?: { signal?: AbortSignal },
): Promise<ModelExplorerAPISearchMessagesResponse> {
  return F.pipe(
    payload,
    searchMessagesUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { searchMessages };
export type {
  ModelExplorerAPISearchMessagesBodyParams,
  ModelExplorerAPISearchMessagesPayload,
  ModelExplorerAPISearchMessagesSearchParams,
};
