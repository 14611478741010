"use client";

import { Option as O } from "effect";
import { Form } from "@ender/form-system/base";
import { Button, ButtonVariant } from "@ender/shared/ds/button";
import { H1 } from "@ender/shared/ds/heading";
import { Stack } from "@ender/shared/ds/stack";
import { Text, TextColor } from "@ender/shared/ds/text";
import { Soc2Hidden } from "@ender/shared/ui/soc2-hidden";
import type { ChangePasswordFormProps } from "./change-password-form.utils";
import { FormPasswordInput } from "./form-password-input";
type ChangePasswordFromViewProps = {
  errorMessage: O.Option<string>;
  form: ChangePasswordFormProps["form"];
  isFetching: boolean;
  onCancelClick: () => void;
  onFormSubmit: ChangePasswordFormProps["onSubmit"];
  userDisplayName: O.Option<string>;
};
function ChangePasswordFormView(props: ChangePasswordFromViewProps) {
  const {
    errorMessage,
    form,
    isFetching,
    onCancelClick,
    onFormSubmit,
    userDisplayName
  } = props;
  return <>
      <H1 data-sentry-element="H1" data-sentry-source-file="change-password-form.view.tsx">Change Password</H1>
      <Text data-sentry-element="Text" data-sentry-source-file="change-password-form.view.tsx">
        {O.getOrElse(userDisplayName, () => "Guest user")}, please choose a new
        password
      </Text>
      <Form form={form} onSubmit={onFormSubmit} data-sentry-element="Form" data-sentry-source-file="change-password-form.view.tsx">
        <Stack data-sentry-element="Stack" data-sentry-source-file="change-password-form.view.tsx">
          <Soc2Hidden data-sentry-element="Soc2Hidden" data-sentry-source-file="change-password-form.view.tsx">
            <FormPasswordInput name="password" form={form} label="Choose a password" disabled={isFetching} data-sentry-element="FormPasswordInput" data-sentry-source-file="change-password-form.view.tsx" />
          </Soc2Hidden>
          <Soc2Hidden data-sentry-element="Soc2Hidden" data-sentry-source-file="change-password-form.view.tsx">
            <FormPasswordInput name="password2" form={form} label="Retype password" disabled={isFetching} data-sentry-element="FormPasswordInput" data-sentry-source-file="change-password-form.view.tsx" />
          </Soc2Hidden>
          {O.isSome(errorMessage) && <Text color={TextColor["red-500"]}>
              {O.getOrThrow(errorMessage)}
            </Text>}
          <Button type="submit" loading={isFetching} disabled={isFetching} data-sentry-element="Button" data-sentry-source-file="change-password-form.view.tsx">
            Submit
          </Button>
          <Button variant={ButtonVariant.transparent} loading={isFetching} onClick={onCancelClick} data-sentry-element="Button" data-sentry-source-file="change-password-form.view.tsx">
            Cancel
          </Button>
        </Stack>
      </Form>
    </>;
}
export { ChangePasswordFormView };