/**
 * HTTP Method: DELETE
 * Pathname: /approvalProcess/{approvalProcessId}
 * @function deleteApprovalProcess
 * @memberof ApprovalsAPI
 * @param {ApprovalsAPIDeleteApprovalProcessPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type ApprovalsAPIDeleteApprovalProcessPathParams = {
  approvalProcessId: EnderId;
};

type ApprovalsAPIDeleteApprovalProcessSearchParams = {
  token?: string | undefined;
};

type ApprovalsAPIDeleteApprovalProcessPayload =
  ApprovalsAPIDeleteApprovalProcessPathParams &
    ApprovalsAPIDeleteApprovalProcessSearchParams;

function deleteApprovalProcessUnsafeEffect(
  payload: ApprovalsAPIDeleteApprovalProcessPayload,
) {
  const { approvalProcessId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "DELETE" }),
        pathname: `/approvalProcess/${approvalProcessId}`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function deleteApprovalProcess(
  payload: ApprovalsAPIDeleteApprovalProcessPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    deleteApprovalProcessUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { deleteApprovalProcess };
export type {
  ApprovalsAPIDeleteApprovalProcessPathParams,
  ApprovalsAPIDeleteApprovalProcessPayload,
  ApprovalsAPIDeleteApprovalProcessSearchParams,
};
