/**
 * HTTP Method: DELETE
 * Pathname: /issues/attachments/{attachmentId}
 * @function removeIssueAttachment
 * @memberof JiraAPI
 * @param {JiraAPIRemoveIssueAttachmentPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type JiraAPIRemoveIssueAttachmentPathParams = {
  attachmentId: string;
};

type JiraAPIRemoveIssueAttachmentSearchParams = {
  token?: string | undefined;
};

type JiraAPIRemoveIssueAttachmentPayload =
  JiraAPIRemoveIssueAttachmentPathParams &
    JiraAPIRemoveIssueAttachmentSearchParams;

function removeIssueAttachmentUnsafeEffect(
  payload: JiraAPIRemoveIssueAttachmentPayload,
) {
  const { attachmentId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "DELETE" }),
        pathname: `/issues/attachments/${attachmentId}`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function removeIssueAttachment(
  payload: JiraAPIRemoveIssueAttachmentPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    removeIssueAttachmentUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { removeIssueAttachment };
export type {
  JiraAPIRemoveIssueAttachmentPathParams,
  JiraAPIRemoveIssueAttachmentPayload,
  JiraAPIRemoveIssueAttachmentSearchParams,
};
