/**
 * HTTP Method: GET
 * Pathname: /admin/pmCompanies
 * @function getPMCompanies
 * @memberof AdminAPI
 * @param {AdminAPIGetPMCompaniesPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<GetPMCompaniesResponse[]>}
 */
import { Effect, Function as F } from "effect";

import type { GetPMCompaniesResponse } from "@ender/shared/generated/ender.api.misc.response";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type AdminAPIGetPMCompaniesSearchParams = {
  token?: string | undefined;
};

type AdminAPIGetPMCompaniesPayload = AdminAPIGetPMCompaniesSearchParams;

function getPMCompaniesUnsafeEffect(payload: AdminAPIGetPMCompaniesPayload) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, GetPMCompaniesResponse[]>({
        body,
        decode: unsafeDecodeJsonResponse<GetPMCompaniesResponse[]>({}),
        encode: encodeNoBody({ method: "GET" }),
        pathname: "/admin/pmCompanies",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getPMCompanies(
  payload: AdminAPIGetPMCompaniesPayload,
  options?: { signal?: AbortSignal },
): Promise<GetPMCompaniesResponse[]> {
  return F.pipe(
    payload,
    getPMCompaniesUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getPMCompanies };
export type {
  AdminAPIGetPMCompaniesPayload,
  AdminAPIGetPMCompaniesSearchParams,
};
