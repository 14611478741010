/**
 * HTTP Method: GET
 * Pathname: /inbox/unreadThreadCount
 * @function getInboxUnreadThreadCount
 * @memberof EnderAPI
 * @param {EnderAPIGetInboxUnreadThreadCountPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<GetInboxUnreadThreadCountResponse>}
 */
import { Effect, Function as F } from "effect";

import type { LocalDate } from "@ender/shared/core";
import type { GetInboxUnreadThreadCountResponse } from "@ender/shared/generated/ender.api.misc.response";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type EnderAPIGetInboxUnreadThreadCountSearchParams = {
  token?: string | undefined;
  fromDate?: LocalDate | undefined;
};

type EnderAPIGetInboxUnreadThreadCountPayload =
  EnderAPIGetInboxUnreadThreadCountSearchParams;

function getInboxUnreadThreadCountUnsafeEffect(
  payload: EnderAPIGetInboxUnreadThreadCountPayload,
) {
  const { token, fromDate, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, GetInboxUnreadThreadCountResponse>({
        body,
        decode: unsafeDecodeJsonResponse<GetInboxUnreadThreadCountResponse>({}),
        encode: encodeNoBody({ method: "GET" }),
        pathname: "/inbox/unreadThreadCount",
        searchParams: [
          ["token", token],
          ["fromDate", fromDate],
        ],
      }),
    ),
  );
}

function getInboxUnreadThreadCount(
  payload: EnderAPIGetInboxUnreadThreadCountPayload,
  options?: { signal?: AbortSignal },
): Promise<GetInboxUnreadThreadCountResponse> {
  return F.pipe(
    payload,
    getInboxUnreadThreadCountUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getInboxUnreadThreadCount };
export type {
  EnderAPIGetInboxUnreadThreadCountPayload,
  EnderAPIGetInboxUnreadThreadCountSearchParams,
};
