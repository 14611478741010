import { acceptInvoice } from "./accept-invoice";
import { acceptJob } from "./accept-job";
import { addTaskTag } from "./add-task-tag";
import { closeTask } from "./close-task";
import { confirmTask } from "./confirm-task";
import { createChargebackRequest } from "./create-chargeback-request";
import { createInvoice } from "./create-invoice";
import { createService } from "./create-service";
import { createTask } from "./create-task";
import { createTenantTask } from "./create-tenant-task";
import { downloadTaskPhotosZIP } from "./download-task-photos-z-i-p";
import { getCostEstimateHistory } from "./get-cost-estimate-history";
import { getEventHistory } from "./get-event-history";
import { getExistingTaskTags } from "./get-existing-task-tags";
import { getInvoice } from "./get-invoice";
import { getMostRelevantContacts } from "./get-most-relevant-contacts";
import { getPMUserJobCapacities } from "./get-p-m-user-job-capacities";
import { getService } from "./get-service";
import { getTaskAvailability } from "./get-task-availability";
import { getTaskDetails } from "./get-task-details";
import { getTasksChat } from "./get-tasks-chat";
import { getTasksStatusCount } from "./get-tasks-status-count";
import { getTenantTasks } from "./get-tenant-tasks";
import { getVendorHistory } from "./get-vendor-history";
import { onTaskCompleted } from "./on-task-completed";
import { onTaskConfirmedByPM } from "./on-task-confirmed-by-p-m";
import { onTaskUnfinished } from "./on-task-unfinished";
import { onWorkStarted } from "./on-work-started";
import { rejectInvoice } from "./reject-invoice";
import { rejectJob } from "./reject-job";
import { removeTaskTag } from "./remove-task-tag";
import { reopenTask } from "./reopen-task";
import { revokeJob } from "./revoke-job";
import { searchForAssignableUsers } from "./search-for-assignable-users";
import { searchForAssignableVendorUsers } from "./search-for-assignable-vendor-users";
import { searchForAssignableVendors } from "./search-for-assignable-vendors";
import { searchServices } from "./search-services";
import { searchTaskCounts } from "./search-task-counts";
import { searchTasks } from "./search-tasks";
import { sendPDFPrintedMessage } from "./send-p-d-f-printed-message";
import { setPMUserJobCapacity } from "./set-p-m-user-job-capacity";
import { updateInvoice } from "./update-invoice";
import { updateService } from "./update-service";
import { updateTask } from "./update-task";
import { updateTaskAvailability } from "./update-task-availability";

const TasksAPI = {
  acceptInvoice,
  acceptJob,
  addTaskTag,
  closeTask,
  confirmTask,
  createChargebackRequest,
  createInvoice,
  createService,
  createTask,
  createTenantTask,
  downloadTaskPhotosZIP,
  getCostEstimateHistory,
  getEventHistory,
  getExistingTaskTags,
  getInvoice,
  getMostRelevantContacts,
  getPMUserJobCapacities,
  getService,
  getTaskAvailability,
  getTaskDetails,
  getTasksChat,
  getTasksStatusCount,
  getTenantTasks,
  getVendorHistory,
  onTaskCompleted,
  onTaskConfirmedByPM,
  onTaskUnfinished,
  onWorkStarted,
  rejectInvoice,
  rejectJob,
  removeTaskTag,
  reopenTask,
  revokeJob,
  searchForAssignableUsers,
  searchForAssignableVendorUsers,
  searchForAssignableVendors,
  searchServices,
  searchTaskCounts,
  searchTasks,
  sendPDFPrintedMessage,
  setPMUserJobCapacity,
  updateInvoice,
  updateService,
  updateTask,
  updateTaskAvailability,
};

export { TasksAPI };
