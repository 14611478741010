/**
 * HTTP Method: POST
 * Pathname: /units/{unitId}/appliances
 * @function createAppliance
 * @memberof UnitsAPI
 * @param {UnitsAPICreateAppliancePayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<EnderId>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { ApplianceApplianceType } from "@ender/shared/generated/ender.model.core.unit";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type UnitsAPICreateAppliancePathParams = {
  unitId: EnderId;
};

type UnitsAPICreateApplianceSearchParams = {
  token?: string | undefined;
};

type UnitsAPICreateApplianceBodyParams = {
  brand?: string | undefined;
  model?: string | undefined;
  serialCode?: string | undefined;
  type: ApplianceApplianceType;
};

type UnitsAPICreateAppliancePayload = UnitsAPICreateAppliancePathParams &
  UnitsAPICreateApplianceSearchParams &
  UnitsAPICreateApplianceBodyParams;

function createApplianceUnsafeEffect(payload: UnitsAPICreateAppliancePayload) {
  const { unitId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, EnderId>({
        body,
        decode: unsafeDecodeJsonResponse<EnderId>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/units/${unitId}/appliances`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function createAppliance(
  payload: UnitsAPICreateAppliancePayload,
  options?: { signal?: AbortSignal },
): Promise<EnderId> {
  return F.pipe(
    payload,
    createApplianceUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { createAppliance };
export type {
  UnitsAPICreateApplianceBodyParams,
  UnitsAPICreateAppliancePathParams,
  UnitsAPICreateAppliancePayload,
  UnitsAPICreateApplianceSearchParams,
};
