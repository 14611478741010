/**
 * HTTP Method: POST
 * Pathname: /vendors
 * @function addVendor
 * @memberof VendorsAPI
 * @param {VendorsAPIAddVendorPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<Vendor>}
 */
import { Effect, Function as F } from "effect";

import type { Vendor } from "@ender/shared/generated/ender.model.core.vendor";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type VendorsAPIAddVendorSearchParams = {
  token?: string | undefined;
};

type VendorsAPIAddVendorBodyParams = {
  companyName: string;
  contactTags?: string[] | undefined;
  email: string;
  phone: string;
};

type VendorsAPIAddVendorPayload = VendorsAPIAddVendorSearchParams &
  VendorsAPIAddVendorBodyParams;

function addVendorUnsafeEffect(payload: VendorsAPIAddVendorPayload) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, Vendor>({
        body,
        decode: unsafeDecodeJsonResponse<Vendor>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/vendors",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function addVendor(
  payload: VendorsAPIAddVendorPayload,
  options?: { signal?: AbortSignal },
): Promise<Vendor> {
  return F.pipe(
    payload,
    addVendorUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { addVendor };
export type {
  VendorsAPIAddVendorBodyParams,
  VendorsAPIAddVendorPayload,
  VendorsAPIAddVendorSearchParams,
};
