"use client";

import { Function as F, Option as O, String as Str } from "effect";
import { useMemo } from "react";
import { useAuthActor } from "../context/auth-actor.context";
import type { LoginPayload } from "../machine/auth.types";
import { AuthTagEnum } from "../machine/auth.types";
import { LoginModalView } from "./login-modal.view";
type LoginModalControllerProps = {
  onForgotPassword: () => void;
  onLogin: (payload: LoginPayload) => void;
  opened: boolean;
};
function LoginModalController(props: LoginModalControllerProps) {
  const {
    onForgotPassword,
    onLogin,
    opened
  } = props;
  const [authSnapshot] = useAuthActor();
  const {
    context: {
      error,
      loginPayload
    }
  } = authSnapshot;
  const isFetching = authSnapshot.hasTag(AuthTagEnum.LOADING);
  const errorMessage = useMemo(() => {
    return F.pipe(error, O.map((e: unknown) => `${e}`), O.filter(Str.isNonEmpty));
  }, [error]);
  return <LoginModalView errorMessage={errorMessage} isFetching={isFetching} loginPayload={loginPayload} onForgotPassword={onForgotPassword} onLogin={onLogin} opened={opened} data-sentry-element="LoginModalView" data-sentry-component="LoginModalController" data-sentry-source-file="login-modal.controller.tsx" />;
}
export { LoginModalController };