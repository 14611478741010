/**
 * HTTP Method: POST
 * Pathname: /updateCounty
 * @function updateCounty
 * @memberof CountiesAPI
 * @param {CountiesAPIUpdateCountyPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId, Percent, SerializesInto } from "@ender/shared/core";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type CountiesAPIUpdateCountySearchParams = {
  token?: string | undefined;
};

type CountiesAPIUpdateCountyBodyParams = {
  countyId: EnderId;
  name?: string | undefined;
  tptTaxRate?: SerializesInto<Percent> | undefined;
  zipcodes?: string[] | undefined;
};

type CountiesAPIUpdateCountyPayload = CountiesAPIUpdateCountySearchParams &
  CountiesAPIUpdateCountyBodyParams;

function updateCountyUnsafeEffect(payload: CountiesAPIUpdateCountyPayload) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/updateCounty",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function updateCounty(
  payload: CountiesAPIUpdateCountyPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    updateCountyUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { updateCounty };
export type {
  CountiesAPIUpdateCountyBodyParams,
  CountiesAPIUpdateCountyPayload,
  CountiesAPIUpdateCountySearchParams,
};
