/**
 * HTTP Method: DELETE
 * Pathname: /photos/{photoId}
 * @function deletePhoto
 * @memberof PhotosAPI
 * @param {PhotosAPIDeletePhotoPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type PhotosAPIDeletePhotoPathParams = {
  photoId: EnderId;
};

type PhotosAPIDeletePhotoSearchParams = {
  token?: string | undefined;
};

type PhotosAPIDeletePhotoPayload = PhotosAPIDeletePhotoPathParams &
  PhotosAPIDeletePhotoSearchParams;

function deletePhotoUnsafeEffect(payload: PhotosAPIDeletePhotoPayload) {
  const { photoId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "DELETE" }),
        pathname: `/photos/${photoId}`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function deletePhoto(
  payload: PhotosAPIDeletePhotoPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    deletePhotoUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { deletePhoto };
export type {
  PhotosAPIDeletePhotoPathParams,
  PhotosAPIDeletePhotoPayload,
  PhotosAPIDeletePhotoSearchParams,
};
