import type { Cookies } from "@effect/platform";
import { Context } from "effect";

type ClientConfigServiceShape = {
  baseApiUrl: () => URL;
  apiCookies: () => Cookies.Cookies;
};

class ClientConfigService extends Context.Tag("ClientConfigService")<
  // eslint-disable-next-line no-use-before-define
  ClientConfigService,
  ClientConfigServiceShape
>() {}

export { ClientConfigService };
export type { ClientConfigServiceShape };
