/**
 * HTTP Method: GET
 * Pathname: /firms/{firmId}/owners
 * @function getOwnersForFirm
 * @memberof PropertiesAPI
 * @param {PropertiesAPIGetOwnersForFirmPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<OwnershipServiceLegalEntityNode>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { OwnershipServiceLegalEntityNode } from "@ender/shared/generated/ender.service.misc";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type PropertiesAPIGetOwnersForFirmPathParams = {
  firmId: EnderId;
};

type PropertiesAPIGetOwnersForFirmSearchParams = {
  token?: string | undefined;
};

type PropertiesAPIGetOwnersForFirmPayload =
  PropertiesAPIGetOwnersForFirmPathParams &
    PropertiesAPIGetOwnersForFirmSearchParams;

function getOwnersForFirmUnsafeEffect(
  payload: PropertiesAPIGetOwnersForFirmPayload,
) {
  const { firmId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, OwnershipServiceLegalEntityNode>({
        body,
        decode: unsafeDecodeJsonResponse<OwnershipServiceLegalEntityNode>({}),
        encode: encodeNoBody({ method: "GET" }),
        pathname: `/firms/${firmId}/owners`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getOwnersForFirm(
  payload: PropertiesAPIGetOwnersForFirmPayload,
  options?: { signal?: AbortSignal },
): Promise<OwnershipServiceLegalEntityNode> {
  return F.pipe(
    payload,
    getOwnersForFirmUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getOwnersForFirm };
export type {
  PropertiesAPIGetOwnersForFirmPathParams,
  PropertiesAPIGetOwnersForFirmPayload,
  PropertiesAPIGetOwnersForFirmSearchParams,
};
