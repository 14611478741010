/**
 * HTTP Method: POST
 * Pathname: /properties/{propertyId}/unitTypes
 * @function createUnitType
 * @memberof PropertiesAPI
 * @param {PropertiesAPICreateUnitTypePayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<CreateUnitTypeResponse>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId, Money, SerializesInto } from "@ender/shared/core";
import type { CreateUnitTypeResponse } from "@ender/shared/generated/ender.api.core.response";
import type { AmenityAmenityType } from "@ender/shared/generated/ender.model.core.property";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type PropertiesAPICreateUnitTypePathParams = {
  propertyId: EnderId;
};

type PropertiesAPICreateUnitTypeSearchParams = {
  token?: string | undefined;
};

type PropertiesAPICreateUnitTypeBodyParams = {
  amenities: AmenityAmenityType[];
  beds?: number | undefined;
  fullBaths?: number | undefined;
  halfBaths?: number | undefined;
  heightFeet?: number | undefined;
  lengthFeet?: number | undefined;
  marketRentPrice?: SerializesInto<Money> | undefined;
  name: string;
  sqft?: number | undefined;
  widthFeet?: number | undefined;
};

type PropertiesAPICreateUnitTypePayload =
  PropertiesAPICreateUnitTypePathParams &
    PropertiesAPICreateUnitTypeSearchParams &
    PropertiesAPICreateUnitTypeBodyParams;

function createUnitTypeUnsafeEffect(
  payload: PropertiesAPICreateUnitTypePayload,
) {
  const { propertyId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, CreateUnitTypeResponse>({
        body,
        decode: unsafeDecodeJsonResponse<CreateUnitTypeResponse>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/properties/${propertyId}/unitTypes`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function createUnitType(
  payload: PropertiesAPICreateUnitTypePayload,
  options?: { signal?: AbortSignal },
): Promise<CreateUnitTypeResponse> {
  return F.pipe(
    payload,
    createUnitTypeUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { createUnitType };
export type {
  PropertiesAPICreateUnitTypeBodyParams,
  PropertiesAPICreateUnitTypePathParams,
  PropertiesAPICreateUnitTypePayload,
  PropertiesAPICreateUnitTypeSearchParams,
};
