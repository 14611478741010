/**
 * HTTP Method: POST
 * Pathname: /fixtures/createPMUser
 * @function createPMUser
 * @memberof FixturesAPI
 * @param {FixturesAPICreatePMUserPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<User>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { User } from "@ender/shared/generated/ender.model.core.user";
import type { FunctionalPermission } from "@ender/shared/generated/ender.model.permissions";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type FixturesAPICreatePMUserSearchParams = {
  token?: string | undefined;
};

type FixturesAPICreatePMUserBodyParams = {
  acceptTerms?: boolean | undefined;
  password?: string | undefined;
  permissions: FunctionalPermission[];
  pmCompanyId: EnderId;
};

type FixturesAPICreatePMUserPayload = FixturesAPICreatePMUserSearchParams &
  FixturesAPICreatePMUserBodyParams;

function createPMUserUnsafeEffect(payload: FixturesAPICreatePMUserPayload) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, User>({
        body,
        decode: unsafeDecodeJsonResponse<User>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/fixtures/createPMUser",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function createPMUser(
  payload: FixturesAPICreatePMUserPayload,
  options?: { signal?: AbortSignal },
): Promise<User> {
  return F.pipe(
    payload,
    createPMUserUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { createPMUser };
export type {
  FixturesAPICreatePMUserBodyParams,
  FixturesAPICreatePMUserPayload,
  FixturesAPICreatePMUserSearchParams,
};
