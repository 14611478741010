/**
 * HTTP Method: POST
 * Pathname: /tasks/capacity/user/{userId}
 * @function setPMUserJobCapacity
 * @memberof TasksAPI
 * @param {TasksAPISetPMUserJobCapacityPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type TasksAPISetPMUserJobCapacityPathParams = {
  userId: EnderId;
};

type TasksAPISetPMUserJobCapacitySearchParams = {
  token?: string | undefined;
};

type TasksAPISetPMUserJobCapacityBodyParams = {
  jobCapacity: number;
};

type TasksAPISetPMUserJobCapacityPayload =
  TasksAPISetPMUserJobCapacityPathParams &
    TasksAPISetPMUserJobCapacitySearchParams &
    TasksAPISetPMUserJobCapacityBodyParams;

function setPMUserJobCapacityUnsafeEffect(
  payload: TasksAPISetPMUserJobCapacityPayload,
) {
  const { userId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/tasks/capacity/user/${userId}`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function setPMUserJobCapacity(
  payload: TasksAPISetPMUserJobCapacityPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    setPMUserJobCapacityUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { setPMUserJobCapacity };
export type {
  TasksAPISetPMUserJobCapacityBodyParams,
  TasksAPISetPMUserJobCapacityPathParams,
  TasksAPISetPMUserJobCapacityPayload,
  TasksAPISetPMUserJobCapacitySearchParams,
};
