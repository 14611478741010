/**
 * HTTP Method: GET
 * Pathname: /tasks/availability/{taskId}
 * @function getTaskAvailability
 * @memberof TasksAPI
 * @param {TasksAPIGetTaskAvailabilityPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<GetTaskAvailabilityResponse>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { GetTaskAvailabilityResponse } from "@ender/shared/generated/ender.api.misc.response";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type TasksAPIGetTaskAvailabilityPathParams = {
  taskId: EnderId;
};

type TasksAPIGetTaskAvailabilitySearchParams = {
  token?: string | undefined;
};

type TasksAPIGetTaskAvailabilityPayload =
  TasksAPIGetTaskAvailabilityPathParams &
    TasksAPIGetTaskAvailabilitySearchParams;

function getTaskAvailabilityUnsafeEffect(
  payload: TasksAPIGetTaskAvailabilityPayload,
) {
  const { taskId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, GetTaskAvailabilityResponse>({
        body,
        decode: unsafeDecodeJsonResponse<GetTaskAvailabilityResponse>({}),
        encode: encodeNoBody({ method: "GET" }),
        pathname: `/tasks/availability/${taskId}`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getTaskAvailability(
  payload: TasksAPIGetTaskAvailabilityPayload,
  options?: { signal?: AbortSignal },
): Promise<GetTaskAvailabilityResponse> {
  return F.pipe(
    payload,
    getTaskAvailabilityUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getTaskAvailability };
export type {
  TasksAPIGetTaskAvailabilityPathParams,
  TasksAPIGetTaskAvailabilityPayload,
  TasksAPIGetTaskAvailabilitySearchParams,
};
