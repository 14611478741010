/**
 * HTTP Method: GET
 * Pathname: /units/areas/{areaId}
 * @function getUnitArea
 * @memberof UnitAreasAPI
 * @param {UnitAreasAPIGetUnitAreaPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<UnitArea>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { UnitArea } from "@ender/shared/generated/ender.model.core.unit.area";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type UnitAreasAPIGetUnitAreaPathParams = {
  areaId: EnderId;
};

type UnitAreasAPIGetUnitAreaSearchParams = {
  token?: string | undefined;
};

type UnitAreasAPIGetUnitAreaPayload = UnitAreasAPIGetUnitAreaPathParams &
  UnitAreasAPIGetUnitAreaSearchParams;

function getUnitAreaUnsafeEffect(payload: UnitAreasAPIGetUnitAreaPayload) {
  const { areaId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, UnitArea>({
        body,
        decode: unsafeDecodeJsonResponse<UnitArea>({}),
        encode: encodeNoBody({ method: "GET" }),
        pathname: `/units/areas/${areaId}`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getUnitArea(
  payload: UnitAreasAPIGetUnitAreaPayload,
  options?: { signal?: AbortSignal },
): Promise<UnitArea> {
  return F.pipe(
    payload,
    getUnitAreaUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getUnitArea };
export type {
  UnitAreasAPIGetUnitAreaPathParams,
  UnitAreasAPIGetUnitAreaPayload,
  UnitAreasAPIGetUnitAreaSearchParams,
};
