/**
 * HTTP Method: DELETE
 * Pathname: /vendors/{vendorId}/tags
 * @function deleteTag
 * @memberof VendorsAPI
 * @param {VendorsAPIDeleteTagPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type VendorsAPIDeleteTagPathParams = {
  vendorId: EnderId;
};

type VendorsAPIDeleteTagSearchParams = {
  token?: string | undefined;
};

type VendorsAPIDeleteTagBodyParams = {
  tag: string;
};

type VendorsAPIDeleteTagPayload = VendorsAPIDeleteTagPathParams &
  VendorsAPIDeleteTagSearchParams &
  VendorsAPIDeleteTagBodyParams;

function deleteTagUnsafeEffect(payload: VendorsAPIDeleteTagPayload) {
  const { vendorId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "DELETE" }),
        pathname: `/vendors/${vendorId}/tags`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function deleteTag(
  payload: VendorsAPIDeleteTagPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    deleteTagUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { deleteTag };
export type {
  VendorsAPIDeleteTagBodyParams,
  VendorsAPIDeleteTagPathParams,
  VendorsAPIDeleteTagPayload,
  VendorsAPIDeleteTagSearchParams,
};
