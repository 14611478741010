/**
 * HTTP Method: POST
 * Pathname: /fixtures/createVendor
 * @function createVendor
 * @memberof FixturesAPI
 * @param {FixturesAPICreateVendorPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<Vendor>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { Vendor } from "@ender/shared/generated/ender.model.core.vendor";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type FixturesAPICreateVendorSearchParams = {
  token?: string | undefined;
};

type FixturesAPICreateVendorBodyParams = {
  name?: string | undefined;
  pmCompanyId: EnderId;
};

type FixturesAPICreateVendorPayload = FixturesAPICreateVendorSearchParams &
  FixturesAPICreateVendorBodyParams;

function createVendorUnsafeEffect(payload: FixturesAPICreateVendorPayload) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, Vendor>({
        body,
        decode: unsafeDecodeJsonResponse<Vendor>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/fixtures/createVendor",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function createVendor(
  payload: FixturesAPICreateVendorPayload,
  options?: { signal?: AbortSignal },
): Promise<Vendor> {
  return F.pipe(
    payload,
    createVendorUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { createVendor };
export type {
  FixturesAPICreateVendorBodyParams,
  FixturesAPICreateVendorPayload,
  FixturesAPICreateVendorSearchParams,
};
