/**
 * HTTP Method: POST
 * Pathname: /tasks/search
 * @function searchTasks
 * @memberof TasksAPI
 * @param {TasksAPISearchTasksPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<TaskSearchResponse>}
 */
import { Effect, Function as F } from "effect";

import type {
  TaskSearchRequestTaskSearchRequestFilterBy,
  TaskSearchRequestTaskSearchRequestSortBy,
} from "@ender/shared/generated/ender.api.misc.request";
import type { TaskSearchResponse } from "@ender/shared/generated/ender.api.misc.response";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type TasksAPISearchTasksSearchParams = {
  token?: string | undefined;
};

type TasksAPISearchTasksBodyParams = {
  filterBy?: TaskSearchRequestTaskSearchRequestFilterBy | undefined;
  limit?: number | undefined;
  offset?: number | undefined;
  sortBy: TaskSearchRequestTaskSearchRequestSortBy[];
};

type TasksAPISearchTasksPayload = TasksAPISearchTasksSearchParams &
  TasksAPISearchTasksBodyParams;

function searchTasksUnsafeEffect(payload: TasksAPISearchTasksPayload) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, TaskSearchResponse>({
        body,
        decode: unsafeDecodeJsonResponse<TaskSearchResponse>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/tasks/search",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function searchTasks(
  payload: TasksAPISearchTasksPayload,
  options?: { signal?: AbortSignal },
): Promise<TaskSearchResponse> {
  return F.pipe(
    payload,
    searchTasksUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { searchTasks };
export type {
  TasksAPISearchTasksBodyParams,
  TasksAPISearchTasksPayload,
  TasksAPISearchTasksSearchParams,
};
