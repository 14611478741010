import { ParseResult, Schema as Sch } from "@effect/schema";
import { parse } from "date-fns";
import { isValid } from "date-fns/fp";
import { Array as A, Function as F, Match, Option as O, pipe } from "effect";

import { isLocalDate, makeFromDate } from "./local-date";

const INVALID_DATE = new Date(NaN);

/**
 * ## learning:
 * using a Schema Class, e.g.
 * ```ts
 * class MoneyClass extends Sch.Class<Money>("Money")({
 *   valueInCents: Sch.Number,
 * }) {}
 * ```
 * Sch.transformOrFail(MoneyClass, ...)
 *
 * instead of using this `declare` syntax,
 * while providing the correct typing, introduced an undesirable side-effect whereby the excess properties of the object
 * were stripped away- only those declared explicitly in the `MoneyClass` are passed along, which means that even fields
 * such as `toJSON` are lost. The workaround for this is _either_ to use an arbitrary type-guard `declare` as done here,
 *
 * or to adjust the parse function so that the `excess properties` are not removed from the resulting object.
 * ```ts
 * {
 *   onExcessProperty: "preserve",
 * }
 * ```
 */

const LocalDate$ = Sch.declare(isLocalDate);

const Schema = Sch.Union(Sch.String, Sch.instanceOf(Date), LocalDate$).pipe(
  Sch.transformOrFail(LocalDate$, {
    decode: (v, _, ast) => {
      const dateVal = Match.value(v).pipe(
        // when a string is provided, assume it is provided in dollars. Multiply by CENTS_PER_DOLLAR to convert to cents
        Match.when(Match.string, (v) =>
          pipe(
            ["MM/dd/yyyy", "yyyy-MM-dd"],
            A.map((formatString) => parse(v, formatString, new Date())),
            A.findFirst((date) => !isNaN(date.getTime())),
            O.getOrElse(F.constant(INVALID_DATE)),
          ),
        ),
        Match.when(Match.date, (v) => v),
        Match.when(isLocalDate, (v) => v.value),
        Match.orElse(F.constant(INVALID_DATE)),
      );

      if (isValid(dateVal)) {
        return ParseResult.succeed(makeFromDate(dateVal));
      }
      return ParseResult.fail(
        new ParseResult.Transformation(
          ast,
          v,
          "Transformation",
          new ParseResult.Type(
            ast,
            v,
            "Provided value was not parseable to Money",
          ),
        ),
      );
    },
    encode: (localDate, _, ast) =>
      ParseResult.fail(
        new ParseResult.Forbidden(
          ast,
          localDate,
          "Encoding LocalDate back to parsable input is forbidden",
        ),
      ),
  }),
);
export { Schema };
