/**
 * HTTP Method: POST
 * Pathname: /firms
 * @function newFirm
 * @memberof FirmsAPI
 * @param {FirmsAPINewFirmPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<Firm>}
 */
import { Effect, Function as F } from "effect";

import type {
  Firm,
  FirmFirmType,
} from "@ender/shared/generated/ender.model.core";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type FirmsAPINewFirmSearchParams = {
  token?: string | undefined;
};

type FirmsAPINewFirmBodyParams = {
  name: string;
  type?: FirmFirmType | undefined;
};

type FirmsAPINewFirmPayload = FirmsAPINewFirmSearchParams &
  FirmsAPINewFirmBodyParams;

function newFirmUnsafeEffect(payload: FirmsAPINewFirmPayload) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, Firm>({
        body,
        decode: unsafeDecodeJsonResponse<Firm>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/firms",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function newFirm(
  payload: FirmsAPINewFirmPayload,
  options?: { signal?: AbortSignal },
): Promise<Firm> {
  return F.pipe(
    payload,
    newFirmUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { newFirm };
export type {
  FirmsAPINewFirmBodyParams,
  FirmsAPINewFirmPayload,
  FirmsAPINewFirmSearchParams,
};
