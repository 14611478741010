/**
 * HTTP Method: GET
 * Pathname: /users/{userId}/portalAccessInfo
 * @function getPortalAccessInfo
 * @memberof UsersAPI
 * @param {UsersAPIGetPortalAccessInfoPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<GetPortalAccessInfoResponse>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { GetPortalAccessInfoResponse } from "@ender/shared/generated/ender.api.core.response";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type UsersAPIGetPortalAccessInfoPathParams = {
  userId: EnderId;
};

type UsersAPIGetPortalAccessInfoSearchParams = {
  token?: string | undefined;
};

type UsersAPIGetPortalAccessInfoPayload =
  UsersAPIGetPortalAccessInfoPathParams &
    UsersAPIGetPortalAccessInfoSearchParams;

function getPortalAccessInfoUnsafeEffect(
  payload: UsersAPIGetPortalAccessInfoPayload,
) {
  const { userId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, GetPortalAccessInfoResponse>({
        body,
        decode: unsafeDecodeJsonResponse<GetPortalAccessInfoResponse>({}),
        encode: encodeNoBody({ method: "GET" }),
        pathname: `/users/${userId}/portalAccessInfo`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getPortalAccessInfo(
  payload: UsersAPIGetPortalAccessInfoPayload,
  options?: { signal?: AbortSignal },
): Promise<GetPortalAccessInfoResponse> {
  return F.pipe(
    payload,
    getPortalAccessInfoUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getPortalAccessInfo };
export type {
  UsersAPIGetPortalAccessInfoPathParams,
  UsersAPIGetPortalAccessInfoPayload,
  UsersAPIGetPortalAccessInfoSearchParams,
};
