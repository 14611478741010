/**
 * HTTP Method: POST
 * Pathname: /fixtures/createUnit
 * @function createUnit
 * @memberof FixturesAPI
 * @param {FixturesAPICreateUnitPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<Unit>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { Unit } from "@ender/shared/generated/ender.model.core.unit";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type FixturesAPICreateUnitSearchParams = {
  token?: string | undefined;
};

type FixturesAPICreateUnitBodyParams = {
  propertyId: EnderId;
};

type FixturesAPICreateUnitPayload = FixturesAPICreateUnitSearchParams &
  FixturesAPICreateUnitBodyParams;

function createUnitUnsafeEffect(payload: FixturesAPICreateUnitPayload) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, Unit>({
        body,
        decode: unsafeDecodeJsonResponse<Unit>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/fixtures/createUnit",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function createUnit(
  payload: FixturesAPICreateUnitPayload,
  options?: { signal?: AbortSignal },
): Promise<Unit> {
  return F.pipe(
    payload,
    createUnitUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { createUnit };
export type {
  FixturesAPICreateUnitBodyParams,
  FixturesAPICreateUnitPayload,
  FixturesAPICreateUnitSearchParams,
};
