/**
 * Sets the password for the current User.
 * HTTP Method: POST
 * Pathname: /setPassword
 * @function setPassword
 * @memberof AuthAPI
 * @param {AuthAPISetPasswordPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<EnderSessionResponse>}
 */
import { Effect, Function as F } from "effect";

import type { EnderSessionResponse } from "@ender/shared/generated/ender.api.misc.response";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type AuthAPISetPasswordSearchParams = {
  token?: string | undefined;
};

type AuthAPISetPasswordBodyParams = {
  /**
   * The password to set
   */
  password: string;
  /**
   * Re-iteration of the password. We confirm that the passwords match.
   */
  password2: string;
};

type AuthAPISetPasswordPayload = AuthAPISetPasswordSearchParams &
  AuthAPISetPasswordBodyParams;

function setPasswordUnsafeEffect(payload: AuthAPISetPasswordPayload) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, EnderSessionResponse>({
        body,
        decode: unsafeDecodeJsonResponse<EnderSessionResponse>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/setPassword",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function setPassword(
  payload: AuthAPISetPasswordPayload,
  options?: { signal?: AbortSignal },
): Promise<EnderSessionResponse> {
  return F.pipe(
    payload,
    setPasswordUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { setPassword };
export type {
  AuthAPISetPasswordBodyParams,
  AuthAPISetPasswordPayload,
  AuthAPISetPasswordSearchParams,
};
