/**
 * HTTP Method: POST
 * Pathname: /admin/phoneLines
 * @function createPhoneLine
 * @memberof AdminAPI
 * @param {AdminAPICreatePhoneLinePayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type AdminAPICreatePhoneLineSearchParams = {
  token?: string | undefined;
};

type AdminAPICreatePhoneLineBodyParams = {
  friendlyName: string;
};

type AdminAPICreatePhoneLinePayload = AdminAPICreatePhoneLineSearchParams &
  AdminAPICreatePhoneLineBodyParams;

function createPhoneLineUnsafeEffect(payload: AdminAPICreatePhoneLinePayload) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/admin/phoneLines",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function createPhoneLine(
  payload: AdminAPICreatePhoneLinePayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    createPhoneLineUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { createPhoneLine };
export type {
  AdminAPICreatePhoneLineBodyParams,
  AdminAPICreatePhoneLinePayload,
  AdminAPICreatePhoneLineSearchParams,
};
