/**
 * HTTP Method: POST
 * Pathname: /tasks/{taskId}/updateInvoice
 * @function updateInvoice
 * @memberof TasksAPI
 * @param {TasksAPIUpdateInvoicePayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type {
  EnderId,
  LocalDate,
  Money,
  SerializesInto,
} from "@ender/shared/core";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type TasksAPIUpdateInvoicePathParams = {
  taskId: EnderId;
};

type TasksAPIUpdateInvoiceSearchParams = {
  token?: string | undefined;
};

type TasksAPIUpdateInvoiceBodyParams = {
  amount?: SerializesInto<Money> | undefined;
  description?: string | undefined;
  detailedDescription?: string | undefined;
  dueDate?: SerializesInto<LocalDate> | undefined;
  externalInvoiceDate?: SerializesInto<LocalDate> | undefined;
  externalInvoiceId?: string | undefined;
  poNumbers: number[];
};

type TasksAPIUpdateInvoicePayload = TasksAPIUpdateInvoicePathParams &
  TasksAPIUpdateInvoiceSearchParams &
  TasksAPIUpdateInvoiceBodyParams;

function updateInvoiceUnsafeEffect(payload: TasksAPIUpdateInvoicePayload) {
  const { taskId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/tasks/${taskId}/updateInvoice`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function updateInvoice(
  payload: TasksAPIUpdateInvoicePayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    updateInvoiceUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { updateInvoice };
export type {
  TasksAPIUpdateInvoiceBodyParams,
  TasksAPIUpdateInvoicePathParams,
  TasksAPIUpdateInvoicePayload,
  TasksAPIUpdateInvoiceSearchParams,
};
