import { HttpBody, HttpClientRequest as Request } from "@effect/platform";
import {
  Array as A,
  Either as E,
  Function as F,
  Option as O,
  String as Str,
} from "effect";

import type {
  EncodeBody,
  EncodeBodyOptions,
  EncodeNoBody,
  EncodeNoBodyOptions,
} from "./rest-types";

const $internal = {
  encodeSearchEntry([key, value]: [string, unknown]): O.Option<
    [string, string]
  > {
    return F.pipe(
      value,
      O.fromNullable,
      O.map((v) => {
        if (Str.isString(v)) {
          return v;
        }
        return JSON.stringify(v);
      }),
      O.map((v) => [key, v]),
    );
  },
  encodeSearchParams(
    searchParams: [string, unknown][],
  ): O.Option<URLSearchParams> {
    return F.pipe(
      searchParams,
      A.map($internal.encodeSearchEntry),
      A.filter(O.isSome),
      O.all,
      O.filter(A.isNonEmptyArray),
      O.map((entries: [string, string][]) => new URLSearchParams(entries)),
    );
  },
  makeUrl(options: {
    baseUrl: URL;
    pathname: string;
    searchParams: [string, unknown][];
  }): URL {
    const { baseUrl, pathname, searchParams } = options;
    return F.pipe(
      [
        `${pathname.replace(/^\//, "./")}`,
        F.pipe(
          searchParams,
          $internal.encodeSearchParams,
          O.map((sp) => `?${sp.toString()}`),
          O.getOrElse(F.constant("")),
        ),
      ],
      A.join(""),
      (p) => new URL(p, baseUrl),
    );
  },
};

type EncodeJsonBodyArgs = {
  accept?: string;
  method: "POST" | "PUT" | "DELETE" | "PATCH";
};

function encodeJsonBody<Body extends { [key: string]: unknown }>(
  args: EncodeJsonBodyArgs,
): EncodeBody<Body> {
  const { method, accept = "application/json" } = args ?? {};
  const requestFor = Request.make(method);

  return (options: EncodeBodyOptions<Body>) => {
    const { body } = options;
    const url = $internal.makeUrl(options);
    return E.right(
      requestFor(url, { accept, body: HttpBody.unsafeJson(body) }),
    );
  };
}

type EncodeNoBodyArgs = {
  accept?: string;
  method: "GET";
};

function encodeNoBody(args: EncodeNoBodyArgs): EncodeNoBody {
  const { method, accept = "application/json" } = args ?? {};
  const requestFor = Request.make(method);

  return (options: EncodeNoBodyOptions) => {
    const url = $internal.makeUrl(options);
    return E.right(requestFor(url, { accept }));
  };
}

export { $internal, encodeJsonBody, encodeNoBody };
