/**
 * HTTP Method: POST
 * Pathname: /tasks/{taskId}/createInvoice
 * @function createInvoice
 * @memberof TasksAPI
 * @param {TasksAPICreateInvoicePayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<CreateTaskInvoiceResponse>}
 */
import { Effect, Function as F } from "effect";

import type {
  EnderId,
  LocalDate,
  Money,
  SerializesInto,
} from "@ender/shared/core";
import type { CreateTaskInvoiceResponse } from "@ender/shared/generated/ender.api.misc.response";
import type { Party } from "@ender/shared/generated/ender.model.payments";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type TasksAPICreateInvoicePathParams = {
  taskId: EnderId;
};

type TasksAPICreateInvoiceSearchParams = {
  token?: string | undefined;
};

type TasksAPICreateInvoiceBodyParams = {
  amount: SerializesInto<Money>;
  description: string;
  detailedDescription: string;
  dueDate?: SerializesInto<LocalDate> | undefined;
  externalInvoiceDate?: SerializesInto<LocalDate> | undefined;
  externalInvoiceId: string;
  ledgerDate?: SerializesInto<LocalDate> | undefined;
  payeeId: EnderId;
  payeeParty: Party;
  poNumbers: number[];
};

type TasksAPICreateInvoicePayload = TasksAPICreateInvoicePathParams &
  TasksAPICreateInvoiceSearchParams &
  TasksAPICreateInvoiceBodyParams;

function createInvoiceUnsafeEffect(payload: TasksAPICreateInvoicePayload) {
  const { taskId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, CreateTaskInvoiceResponse>({
        body,
        decode: unsafeDecodeJsonResponse<CreateTaskInvoiceResponse>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/tasks/${taskId}/createInvoice`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function createInvoice(
  payload: TasksAPICreateInvoicePayload,
  options?: { signal?: AbortSignal },
): Promise<CreateTaskInvoiceResponse> {
  return F.pipe(
    payload,
    createInvoiceUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { createInvoice };
export type {
  TasksAPICreateInvoiceBodyParams,
  TasksAPICreateInvoicePathParams,
  TasksAPICreateInvoicePayload,
  TasksAPICreateInvoiceSearchParams,
};
