/**
 * HTTP Method: POST
 * Pathname: /admin/testingAccountCommand
 * @function executeTestingAccountCommand
 * @memberof AdminAPI
 * @param {AdminAPIExecuteTestingAccountCommandPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<string[]>}
 */
import { Effect, Function as F } from "effect";

import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type AdminAPIExecuteTestingAccountCommandSearchParams = {
  token?: string | undefined;
};

type AdminAPIExecuteTestingAccountCommandBodyParams = {
  commandLines: string[];
};

type AdminAPIExecuteTestingAccountCommandPayload =
  AdminAPIExecuteTestingAccountCommandSearchParams &
    AdminAPIExecuteTestingAccountCommandBodyParams;

function executeTestingAccountCommandUnsafeEffect(
  payload: AdminAPIExecuteTestingAccountCommandPayload,
) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, string[]>({
        body,
        decode: unsafeDecodeJsonResponse<string[]>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/admin/testingAccountCommand",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function executeTestingAccountCommand(
  payload: AdminAPIExecuteTestingAccountCommandPayload,
  options?: { signal?: AbortSignal },
): Promise<string[]> {
  return F.pipe(
    payload,
    executeTestingAccountCommandUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { executeTestingAccountCommand };
export type {
  AdminAPIExecuteTestingAccountCommandBodyParams,
  AdminAPIExecuteTestingAccountCommandPayload,
  AdminAPIExecuteTestingAccountCommandSearchParams,
};
