/**
 * HTTP Method: GET
 * Pathname: /permissions/functional
 * @function getFunctionalPermissions
 * @memberof PermissionsAPI
 * @param {PermissionsAPIGetFunctionalPermissionsPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<FunctionalPermission[]>}
 */
import { Effect, Function as F } from "effect";

import type { FunctionalPermission } from "@ender/shared/generated/ender.model.permissions";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type PermissionsAPIGetFunctionalPermissionsSearchParams = {
  token?: string | undefined;
  showAllPermissions?: boolean | undefined;
};

type PermissionsAPIGetFunctionalPermissionsPayload =
  PermissionsAPIGetFunctionalPermissionsSearchParams;

function getFunctionalPermissionsUnsafeEffect(
  payload: PermissionsAPIGetFunctionalPermissionsPayload,
) {
  const { token, showAllPermissions, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, FunctionalPermission[]>({
        body,
        decode: unsafeDecodeJsonResponse<FunctionalPermission[]>({}),
        encode: encodeNoBody({ method: "GET" }),
        pathname: "/permissions/functional",
        searchParams: [
          ["token", token],
          ["showAllPermissions", showAllPermissions],
        ],
      }),
    ),
  );
}

function getFunctionalPermissions(
  payload: PermissionsAPIGetFunctionalPermissionsPayload,
  options?: { signal?: AbortSignal },
): Promise<FunctionalPermission[]> {
  return F.pipe(
    payload,
    getFunctionalPermissionsUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getFunctionalPermissions };
export type {
  PermissionsAPIGetFunctionalPermissionsPayload,
  PermissionsAPIGetFunctionalPermissionsSearchParams,
};
