/**
 * HTTP Method: POST
 * Pathname: /tasks/availability/{taskId}
 * @function updateTaskAvailability
 * @memberof TasksAPI
 * @param {TasksAPIUpdateTaskAvailabilityPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<GetTaskAvailabilityResponse>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { TaskAvailabilityUpdateRequestTenantAvailability } from "@ender/shared/generated/ender.api.misc.request";
import type { GetTaskAvailabilityResponse } from "@ender/shared/generated/ender.api.misc.response";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type TasksAPIUpdateTaskAvailabilityPathParams = {
  taskId: EnderId;
};

type TasksAPIUpdateTaskAvailabilitySearchParams = {
  token?: string | undefined;
};

type TasksAPIUpdateTaskAvailabilityBodyParams = {
  tenantAvailabilities: TaskAvailabilityUpdateRequestTenantAvailability[];
};

type TasksAPIUpdateTaskAvailabilityPayload =
  TasksAPIUpdateTaskAvailabilityPathParams &
    TasksAPIUpdateTaskAvailabilitySearchParams &
    TasksAPIUpdateTaskAvailabilityBodyParams;

function updateTaskAvailabilityUnsafeEffect(
  payload: TasksAPIUpdateTaskAvailabilityPayload,
) {
  const { taskId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, GetTaskAvailabilityResponse>({
        body,
        decode: unsafeDecodeJsonResponse<GetTaskAvailabilityResponse>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/tasks/availability/${taskId}`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function updateTaskAvailability(
  payload: TasksAPIUpdateTaskAvailabilityPayload,
  options?: { signal?: AbortSignal },
): Promise<GetTaskAvailabilityResponse> {
  return F.pipe(
    payload,
    updateTaskAvailabilityUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { updateTaskAvailability };
export type {
  TasksAPIUpdateTaskAvailabilityBodyParams,
  TasksAPIUpdateTaskAvailabilityPathParams,
  TasksAPIUpdateTaskAvailabilityPayload,
  TasksAPIUpdateTaskAvailabilitySearchParams,
};
