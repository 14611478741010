import { addTag } from "./add-tag";
import { addVendor } from "./add-vendor";
import { approvePendingVendor } from "./approve-pending-vendor";
import { associateVendorWithMarket } from "./associate-vendor-with-market";
import { deleteTag } from "./delete-tag";
import { dissociateVendorFromMarket } from "./dissociate-vendor-from-market";
import { getExistingVendorTags } from "./get-existing-vendor-tags";
import { getVendor } from "./get-vendor";
import { getVendorLedger } from "./get-vendor-ledger";
import { getVendorMarkets } from "./get-vendor-markets";
import { getVendorUsers } from "./get-vendor-users";
import { getVendors } from "./get-vendors";
import { rejectPendingVendor } from "./reject-pending-vendor";
import { setVendorMarketCompliance } from "./set-vendor-market-compliance";
import { submitW9 } from "./submit-w9";
import { syncVendorWithRmis } from "./sync-vendor-with-rmis";
import { updateVendor } from "./update-vendor";
import { updateVendorAddress } from "./update-vendor-address";
import { updateW9 } from "./update-w9";
import { uploadVendors } from "./upload-vendors";

const VendorsAPI = {
  addTag,
  addVendor,
  approvePendingVendor,
  associateVendorWithMarket,
  deleteTag,
  dissociateVendorFromMarket,
  getExistingVendorTags,
  getVendor,
  getVendorLedger,
  getVendorMarkets,
  getVendorUsers,
  getVendors,
  rejectPendingVendor,
  setVendorMarketCompliance,
  submitW9,
  syncVendorWithRmis,
  updateVendor,
  updateVendorAddress,
  updateW9,
  uploadVendors,
};

export { VendorsAPI };
