import { auth0Login } from "./auth0-login";
import { changeActiveVendor } from "./change-active-vendor";
import { changePassword } from "./change-password";
import { checkVerificationCode } from "./check-verification-code";
import { getSessionInfo } from "./get-session-info";
import { login } from "./login";
import { loginAs } from "./login-as";
import { logout } from "./logout";
import { sendVerificationCode } from "./send-verification-code";
import { sendVerificationEmail } from "./send-verification-email";
import { setPassword } from "./set-password";
import { tokenToUser } from "./token-to-user";

const AuthAPI = {
  auth0Login,
  changeActiveVendor,
  changePassword,
  checkVerificationCode,
  getSessionInfo,
  login,
  loginAs,
  logout,
  sendVerificationCode,
  sendVerificationEmail,
  setPassword,
  tokenToUser,
};

export { AuthAPI };
