/**
 * HTTP Method: POST
 * Pathname: /users/{userId}/setRoles
 * @function setUserRoles
 * @memberof PermissionsAPI
 * @param {PermissionsAPISetUserRolesPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type PermissionsAPISetUserRolesPathParams = {
  userId: EnderId;
};

type PermissionsAPISetUserRolesSearchParams = {
  token?: string | undefined;
};

type PermissionsAPISetUserRolesBodyParams = {
  roleIds: EnderId[];
};

type PermissionsAPISetUserRolesPayload = PermissionsAPISetUserRolesPathParams &
  PermissionsAPISetUserRolesSearchParams &
  PermissionsAPISetUserRolesBodyParams;

function setUserRolesUnsafeEffect(payload: PermissionsAPISetUserRolesPayload) {
  const { userId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/users/${userId}/setRoles`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function setUserRoles(
  payload: PermissionsAPISetUserRolesPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    setUserRolesUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { setUserRoles };
export type {
  PermissionsAPISetUserRolesBodyParams,
  PermissionsAPISetUserRolesPathParams,
  PermissionsAPISetUserRolesPayload,
  PermissionsAPISetUserRolesSearchParams,
};
