/**
 * HTTP Method: DELETE
 * Pathname: /emergencyContacts/{contactId}
 * @function removeEmergencyContact
 * @memberof UsersAPI
 * @param {UsersAPIRemoveEmergencyContactPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type UsersAPIRemoveEmergencyContactPathParams = {
  contactId: EnderId;
};

type UsersAPIRemoveEmergencyContactSearchParams = {
  token?: string | undefined;
};

type UsersAPIRemoveEmergencyContactPayload =
  UsersAPIRemoveEmergencyContactPathParams &
    UsersAPIRemoveEmergencyContactSearchParams;

function removeEmergencyContactUnsafeEffect(
  payload: UsersAPIRemoveEmergencyContactPayload,
) {
  const { contactId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "DELETE" }),
        pathname: `/emergencyContacts/${contactId}`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function removeEmergencyContact(
  payload: UsersAPIRemoveEmergencyContactPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    removeEmergencyContactUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { removeEmergencyContact };
export type {
  UsersAPIRemoveEmergencyContactPathParams,
  UsersAPIRemoveEmergencyContactPayload,
  UsersAPIRemoveEmergencyContactSearchParams,
};
