export {
  abbreviate,
  capitalize,
  convertKebabCaseToUpperCamelCase,
  convertSnakeCaseToTitleCase,
  convertTitleCaseToSnakeCase,
  convertToCamelCase,
  convertToClassName,
  convertToKebabCase,
  convertToNumber,
  convertToURLSlug,
  genTestId,
  pluralize,
  replacePath,
  splitNumberAndLetterParts,
} from "./lib/shared-utils-string";

export { fromDashCaseToCapitalSpaceCase } from "./lib/from-dash-case-to-capital-space-case";
export type { FromDashCaseToCapitalSpaceCase } from "./lib/from-dash-case-to-capital-space-case";

export type { FromDashCaseToScreamingSnakeCase } from "./lib/from-dash-case-to-screaming-snake-case";

export { fromScreamingSnakeCaseToDashCase } from "./lib/from-screaming-snake-case-to-dash-case";
export type { FromScreamingSnakeCaseToDashCase } from "./lib/from-screaming-snake-case-to-dash-case";

export { fromScreamingSnakeCaseToSpaceCase } from "./lib/from-screaming-snake-case-to-space-case";
export type { FromScreamingSnakeCaseToSpaceCase } from "./lib/from-screaming-snake-case-to-space-case";

export type { FromSpaceCaseToScreamingSnakeCase } from "./lib/from-space-case-to-screaming-snake-case";

export type { FromSpaceCaseToSnakeCase } from "./lib/from-space-case-to-snake-case";

export { createScreamingSnakeCaseToDashCaseRecord } from "./lib/screaming-snake-case-to-dash-case-record";
export type { ScreamingSnakeCaseToDashCaseRecord } from "./lib/screaming-snake-case-to-dash-case-record";
