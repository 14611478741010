/**
 * HTTP Method: POST
 * Pathname: /pms/{pmId}/requireFullPaymentSettings
 * @function updateRequireFullPaymentSettings
 * @memberof EnderAPI
 * @param {EnderAPIUpdateRequireFullPaymentSettingsPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId, Money, SerializesInto } from "@ender/shared/core";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type EnderAPIUpdateRequireFullPaymentSettingsPathParams = {
  pmId: EnderId;
};

type EnderAPIUpdateRequireFullPaymentSettingsSearchParams = {
  token?: string | undefined;
};

type EnderAPIUpdateRequireFullPaymentSettingsBodyParams = {
  adjustment?: SerializesInto<Money> | undefined;
  gracePeriod?: number | undefined;
  on: boolean;
};

type EnderAPIUpdateRequireFullPaymentSettingsPayload =
  EnderAPIUpdateRequireFullPaymentSettingsPathParams &
    EnderAPIUpdateRequireFullPaymentSettingsSearchParams &
    EnderAPIUpdateRequireFullPaymentSettingsBodyParams;

function updateRequireFullPaymentSettingsUnsafeEffect(
  payload: EnderAPIUpdateRequireFullPaymentSettingsPayload,
) {
  const { pmId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/pms/${pmId}/requireFullPaymentSettings`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function updateRequireFullPaymentSettings(
  payload: EnderAPIUpdateRequireFullPaymentSettingsPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    updateRequireFullPaymentSettingsUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { updateRequireFullPaymentSettings };
export type {
  EnderAPIUpdateRequireFullPaymentSettingsBodyParams,
  EnderAPIUpdateRequireFullPaymentSettingsPathParams,
  EnderAPIUpdateRequireFullPaymentSettingsPayload,
  EnderAPIUpdateRequireFullPaymentSettingsSearchParams,
};
