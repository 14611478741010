/**
 * Allows a User (with the right permissions) to masquerade as another User.
 * HTTP Method: POST
 * Pathname: /loginAs
 * @function loginAs
 * @memberof AuthAPI
 * @param {AuthAPILoginAsPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<EnderSessionResponse>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { EnderSessionResponse } from "@ender/shared/generated/ender.api.misc.response";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type AuthAPILoginAsSearchParams = {
  token?: string | undefined;
};

type AuthAPILoginAsBodyParams = {
  /**
 * Defaults to True. If you set this to false, the system will not remember the original User
                   (useful for certain kinds of testing)
 */
  masquerade?: boolean | undefined;
  /**
   * Whether a session should be created in "readOnly" mode. "readOnly" sessions can't make changes.
   */
  readOnly?: boolean | undefined;
  /**
   * The User who we are logging in as.
   */
  userId: EnderId;
  /**
   * If the user is a vendor employee which vendor are they are logging for.
   */
  vendorId?: EnderId | undefined;
};

type AuthAPILoginAsPayload = AuthAPILoginAsSearchParams &
  AuthAPILoginAsBodyParams;

function loginAsUnsafeEffect(payload: AuthAPILoginAsPayload) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, EnderSessionResponse>({
        body,
        decode: unsafeDecodeJsonResponse<EnderSessionResponse>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/loginAs",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function loginAs(
  payload: AuthAPILoginAsPayload,
  options?: { signal?: AbortSignal },
): Promise<EnderSessionResponse> {
  return F.pipe(
    payload,
    loginAsUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { loginAs };
export type {
  AuthAPILoginAsBodyParams,
  AuthAPILoginAsPayload,
  AuthAPILoginAsSearchParams,
};
