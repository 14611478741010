import type { ReactElement } from "react";
import type { Path } from "react-hook-form";
import { useController } from "../hooks/use-controller";
import type { FormInput, UseControllerReturn, UseFormReturn } from "../types";
type FormFieldProps<
// eslint-disable-next-line @typescript-eslint/no-explicit-any
Form extends UseFormReturn<any, any, any>, Name extends Path<FormInput<Form>>> = {
  name: Name;
  children: (props: UseControllerReturn<FormInput<Form>, Name> & {
    error: string;
  }) => ReactElement;
  form: Form;
};
function FormField<
// eslint-disable-next-line @typescript-eslint/no-explicit-any
Form extends UseFormReturn<any, any, any>, Name extends Path<FormInput<Form>>>({
  children,
  name,
  form
}: FormFieldProps<Form, Name>) {
  const {
    control
  } = form;
  const methods = useController<FormInput<Form>, Name>({
    control,
    name
  });
  const error = methods.fieldState.error?.message ?? "";
  return children({
    ...methods,
    error
  });
}
export { FormField };
export type { FormFieldProps };