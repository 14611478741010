import { createChartOfAccounts } from "./create-chart-of-accounts";
import { createDeal } from "./create-deal";
import { createFirmBankAccount } from "./create-firm-bank-account";
import { createMoveInConfigRow } from "./create-move-in-config-row";
import { createPM } from "./create-p-m";
import { createPMUser } from "./create-p-m-user";
import { createProperty } from "./create-property";
import { createTenantBankAccount } from "./create-tenant-bank-account";
import { createTenantUser } from "./create-tenant-user";
import { createTextTemplate } from "./create-text-template";
import { createUnit } from "./create-unit";
import { createVendor } from "./create-vendor";
import { endTest } from "./end-test";
import { startTest } from "./start-test";

const FixturesAPI = {
  createChartOfAccounts,
  createDeal,
  createFirmBankAccount,
  createMoveInConfigRow,
  createPM,
  createPMUser,
  createProperty,
  createTenantBankAccount,
  createTenantUser,
  createTextTemplate,
  createUnit,
  createVendor,
  endTest,
  startTest,
};

export { FixturesAPI };
