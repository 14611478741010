import "@date-fns/utc";
import type { ParseResult } from "@effect/schema";
import { Schema as Sch } from "@effect/schema";
import type { Duration } from "date-fns";
import {
  add as _add,
  endOfMonth as _endOfMonth,
  endOfYear as _endOfYear,
  startOfMonth as _startOfMonth,
  startOfYear as _startOfYear,
  getDate,
  getMonth,
  getYear,
} from "date-fns";
import { isEqual } from "date-fns/fp";
import type { Brand, Either as E, Equivalence as Eq } from "effect";
import {
  Number as N,
  Option as O,
  Order as Od,
  Predicate as P,
  flow,
  pipe,
} from "effect";
import { dual } from "effect/Function";

import * as localDate from "./internal/local-date";
import { Schema } from "./internal/schema";

type Serialized = Brand.Branded<string, "LocalDate">;

type LocalDate = {
  readonly _tag: "LocalDate";
  readonly value: Date;
  toJSON(): Serialized;
  /**
   * @deprecated use LocalDate$.subtract(self, other)
   */
  subtract(value: LocalDate): LocalDate;
  /**
   * @deprecated use LocalDate$.divide(self, value)
   */
  divideBy(value: number): LocalDate;
  /**
   * @deprecated use LocalDate$.Order(self, other)
   * or use Effect Order.lessThan(LocalDate$.Order), Order.greaterThanOrEqual(LocalDate$.Order), etc
   */
  compareTo(other: LocalDate): -1 | 0 | 1;
  /**
   * @deprecated use LocalDate$.Equivalence(self, other)
   */
  equals(other: LocalDate): boolean;
  /**
   * @deprecated use LocalDate$.toFormatted(self, format)
   */
  // eslint-disable-next-line no-use-before-define
  toFormatted(format?: Format): string;

  /**
   * @deprecated use Order.greaterThan(LocalDate$.Order)(self, other)
   */
  isAfter(other: LocalDate): boolean;
  /**
   * @deprecated use LocalDate$.Equivalence(self, other)
   */
  isEqual(other: LocalDate): boolean;
  /**
   * @deprecated use Order.lessThan(LocalDate$.Order)(self, other)
   */
  isBefore(other: LocalDate): boolean;
  /**
   * @deprecated use Order.greaterThanOrEqual(LocalDate$.Order)(self, other)
   */
  isAfterOrEqual(other: LocalDate): boolean;
  /**
   * @deprecated use Order.lessThanOrEqual(LocalDate$.Order)(self, other)
   */
  isBeforeOrEqual(other: LocalDate): boolean;
  /**
   * @deprecated use LocalDate$.add(self, duration);
   * @param duration
   */
  add(
    duration: Pick<Duration, "years" | "months" | "weeks" | "days">,
  ): LocalDate;
  /**
   * @deprecated use `toFormatted`, `toString`, or `toJSON`- transforming
   * to and from a Date is not a supported behavior on the FE
   */
  toDate(): Date;
};

const Formats = {
  /**
   * MM/dd/yyyy
   *
   * Standard US date format with month first, day second, and full year.
   * Example: 05/15/2023
   */
  DEFAULT: "DEFAULT",
  /**
   * yyyy-MM-dd
   *
   * ISO 8601 date format used for JSON serialization and API communication.
   * Example: 2023-05-15
   */
  JSON: "JSON",
  /**
   * MMM d, yyyy
   *
   * Human-readable date format with abbreviated month name.
   * Example: May 15, 2023
   */
  SHORT_DATE: "SHORT_DATE",
} as const;

type Format = (typeof Formats)[keyof typeof Formats];

/**
 * Unique symbol identifier for the LocalDate type.
 *
 * This symbol is used internally to identify LocalDate instances and ensure type safety.
 *
 * @category Symbols
 */
const TypeId: unique symbol = Symbol.for("ender/LocalDate");

/**
 * Type definition for the LocalDate TypeId symbol.
 *
 * @category Symbols
 */
// eslint-disable-next-line @typescript-eslint/no-redeclare,import/group-exports
export type TypeId = typeof TypeId;

/**
 * Adds the specified durations to the `LocalDate`
 *
 * This function allows you to create a new LocalDate representing the desired time in the future or past.
 *
 * @example
 * ```ts
 * import { LocalDate$ } from "@ender/shared/core";
 *
 * const value1 = LocalDate$.of("1985-10-21");
 *
 * const result = LocalDate$.add(value1, { years: 30 });
 *
 * assert.deepStrictEqual(result.toJSON(), "2015-10-21");
 * ```
 *
 * @category Mapping
 */
const add: {
  (
    duration: Pick<Duration, "years" | "months" | "weeks" | "days">,
  ): (a: LocalDate) => LocalDate;
  (
    self: LocalDate,
    duration: Pick<Duration, "years" | "months" | "weeks" | "days">,
  ): LocalDate;
} = dual(
  2,
  (
    a: LocalDate,
    duration: Pick<Duration, "years" | "months" | "weeks" | "days">,
  ) => pipe(a.value, (v) => _add(v, duration), localDate.makeFromDate),
);

/**
 * Creates an instance of LocalDate representing the provided JS Date object.
 *
 * @example
 * ```ts
 * import { LocalDate$ } from "@ender/shared/core"
 *
 * //
 * //      ┌─── LocalDate$.LocalDate
 * //      ▼
 * const value = LocalDate$.makeFromDate(new Date())
 *
 * ```
 *
 * @category Constructors
 */
const makeFromDate = localDate.makeFromDate;

/**
 * Converts a `LocalDate` instance into a formatted string representation.
 *
 * This method allows for the customization of the output format, making it ideal
 * for displaying date amounts in different contexts, such as
 * user interfaces, reports, or logs.
 *
 * Allowed formats are
 * - "SHORT_", for truncated dollars-only output
 * - "WORDS", for textual representation of the number
 * - "DEFAULT", for dollars-and-cents output
 *
 * @example
 * ```ts
 * import { LocalDate$ } from "@ender/shared/core";
 *
 * const value = LocalDate$.of("1985-10-21");
 *
 * // Formatting as short date
 * assert.deepStrictEqual(LocalDate$.toFormatted(value, "SHORT_DATE"), "Oct 21, 1985");
 *
 * // Formatting as JSON
 * assert.deepStrictEqual(LocalDate$.toFormatted(value, "JSON"), "1985-10-21");
 *
 * // Using the default format
 * assert.deepStrictEqual(LocalDate$.toFormatted(value, "DEFAULT"), "10/21/1985");
 * ```
 *
 * @category Conversions
 */
const toFormatted = localDate.toFormatted;

/**
 * Returns a new `LocalDate` representing the first day of the year for the provided date.
 *
 * This function is useful for operations that need to work with the beginning of a year,
 * such as calculating year-to-date ranges or setting date boundaries.
 *
 * @example
 * ```ts
 * import { LocalDate$ } from "@ender/shared/core";
 *
 * const date = LocalDate$.of("2023-05-15");
 * const firstDayOfYear = LocalDate$.startOfYear(date);
 *
 * assert.deepStrictEqual(firstDayOfYear.toJSON(), "2023-01-01");
 * ```
 *
 * @param self The LocalDate to get the start of year from
 * @category Mapping
 */
const startOfYear = (self: LocalDate): LocalDate =>
  pipe(self.value, _startOfYear, localDate.makeFromDate);

/**
 * Returns a new `LocalDate` representing the last day of the year for the provided date.
 *
 * This function is useful for operations that need to work with the end of a year,
 * such as calculating year-to-date ranges or setting date boundaries.
 *
 * @example
 * ```ts
 * import { LocalDate$ } from "@ender/shared/core";
 *
 * const date = LocalDate$.of("2023-05-15");
 * const lastDayOfYear = LocalDate$.endOfYear(date);
 *
 * assert.deepStrictEqual(lastDayOfYear.toJSON(), "2023-12-31");
 * ```
 *
 * @param self The LocalDate to get the end of year from
 * @category Mapping
 */
const endOfYear = (self: LocalDate): LocalDate =>
  pipe(self.value, _endOfYear, localDate.makeFromDate);

/**
 * Returns a new `LocalDate` representing the first day of the month for the provided date.
 *
 * This function is useful for operations that need to work with the beginning of a month,
 * such as calculating month-to-date ranges or setting date boundaries.
 *
 * @example
 * ```ts
 * import { LocalDate$ } from "@ender/shared/core";
 *
 * const date = LocalDate$.of("2023-05-15");
 * const firstDayOfMonth = LocalDate$.startOfMonth(date);
 *
 * assert.deepStrictEqual(firstDayOfMonth.toJSON(), "2023-05-01");
 * ```
 *
 * @param self The LocalDate to get the start of month from
 * @category Mapping
 */
const startOfMonth = (self: LocalDate): LocalDate =>
  pipe(self.value, _startOfMonth, localDate.makeFromDate);

/**
 * Returns a new `LocalDate` representing the last day of the month for the provided date.
 *
 * This function is useful for operations that need to work with the end of a month,
 * such as calculating month-to-date ranges or setting date boundaries.
 *
 * @example
 * ```ts
 * import { LocalDate$ } from "@ender/shared/core";
 *
 * const date = LocalDate$.of("2023-05-15");
 * const lastDayOfMonth = LocalDate$.endOfMonth(date);
 *
 * assert.deepStrictEqual(lastDayOfMonth.toJSON(), "2023-05-31");
 * ```
 *
 * @param self The LocalDate to get the end of month from
 * @category Mapping
 */
const endOfMonth = (self: LocalDate): LocalDate =>
  pipe(self.value, _endOfMonth, localDate.makeFromDate);

/**
 * Extracts the day of the month from a `LocalDate` instance.
 *
 * This function returns the day component (1-31) of the given date, which is useful
 * for date calculations, formatting, or when you need to work with specific days.
 *
 * @example
 * ```ts
 * import { LocalDate$ } from "@ender/shared/core";
 *
 * const date = LocalDate$.of("2023-05-15");
 * const dayOfMonth = LocalDate$.date(date);
 *
 * assert.deepStrictEqual(dayOfMonth, 15);
 * ```
 *
 * @param self The LocalDate to extract the day from
 * @category conversions
 */
const date: (self: LocalDate) => number = (self: LocalDate) =>
  getDate(self.value);

/**
 * Gets the month (1-indexed) from a `LocalDate` instance.
 *
 * This function returns the month component (1-12) of the given date, where:
 * - 1 represents January
 * - 2 represents February
 * - ...and so on up to 12 for December
 *
 * This is useful for date calculations, formatting, or when you need to work with specific months.
 * Note that unlike JavaScript's native Date object (which uses 0-indexed months),
 * this function returns a 1-indexed month for better readability.
 *
 * @example
 * ```ts
 * import { LocalDate$ } from "@ender/shared/core";
 *
 * const date = LocalDate$.of("2023-05-15");
 * const monthValue = LocalDate$.month(date);
 *
 * assert.deepStrictEqual(monthValue, 5); // May is the 5th month
 * ```
 *
 * @param self The LocalDate to extract the month from
 * @category conversions
 */
const month: (self: LocalDate) => number = (self: LocalDate) =>
  getMonth(self.value) + 1;

/**
 * Extracts the year from a `LocalDate` instance.
 *
 * This function returns the full year component (e.g., 2023) of the given date, which is useful
 * for date calculations, formatting, or when you need to work with specific years.
 *
 * @example
 * ```ts
 * import { LocalDate$ } from "@ender/shared/core";
 *
 * const date = LocalDate$.of("2023-05-15");
 * const yearValue = LocalDate$.year(date);
 *
 * assert.deepStrictEqual(yearValue, 2023);
 * ```
 *
 * @param self The LocalDate to extract the year from
 * @category conversions
 */
const year: (self: LocalDate) => number = (self: LocalDate) =>
  getYear(self.value);

/**
 * Checks whether a `LocalDate` instance contains the specified month, day, or year.
 *
 * This function allows you to verify if a date matches specific components without
 * having to extract and compare each component individually. You can check for any
 * combination of day, month, and year.
 *
 * @example
 * ```ts
 * import { LocalDate$ } from "@ender/shared/core";
 *
 * const date = LocalDate$.of("2023-05-15");
 *
 * // Check if the date is in May
 * const isInMay = LocalDate$.contains(date, { month: 5 });
 * assert.deepStrictEqual(isInMay, true);
 *
 * // Check if the date is the 15th of any month
 * const isDay15 = LocalDate$.contains(date, { day: 15 });
 * assert.deepStrictEqual(isDay15, true);
 *
 * // Check if the date is May 15th, 2023
 * const isMay15_2023 = LocalDate$.contains(date, { month: 5, day: 15, year: 2023 });
 * assert.deepStrictEqual(isMay15_2023, true);
 *
 * // Check if the date is in 2024 (it's not)
 * const isIn2024 = LocalDate$.contains(date, { year: 2024 });
 * assert.deepStrictEqual(isIn2024, false);
 * ```
 *
 * @category Guards
 */
const contains: {
  (options: {
    month?: number;
    year?: number;
    day?: number;
  }): (self: LocalDate) => boolean;
  (
    self: LocalDate,
    options: { month?: number; year?: number; day?: number },
  ): boolean;
} = dual(
  2,
  (
    self: LocalDate,
    options: { month?: number; year?: number; day?: number },
  ): boolean =>
    (P.isNullable(options.day) || date(self) === options.day) &&
    (P.isNullable(options.month) || month(self) === options.month) &&
    (P.isNullable(options.year) || year(self) === options.year),
);

/**
 * Creates a `LocalDate` instance representing the current date.
 *
 * This function is useful when you need to work with the current date in your application,
 * such as for date comparisons, setting default values, or displaying the current date.
 *
 * @example
 * ```ts
 * import { LocalDate$ } from "@ender/shared/core";
 *
 * // Get a LocalDate representing today
 * const today = LocalDate$.today();
 *
 * // Use today's date as a reference point
 * const isPastDate = LocalDate$.Order(someDate, today) < 0;
 * ```
 *
 * Because it is a function, it can be used as a `LazyArg` for Effect piping.
 *
 * @category constructors
 */
const today = () => localDate.makeFromDate(new Date());

/**
 * Equivalence is used to determine if two `LocalDate` objects are equivalent based on their internal value.
 *
 * This can be particularly useful in scenarios where checks for equality, filtering in lists, or other equality-based operations
 * are required.
 *
 * @example
 * ```ts
 * import { LocalDate$ } from '@ender/shared/core';
 * import { Array } from 'effect';
 *
 * const date1 = LocalDate$.of("1990-01-24");
 * const date2 = LocalDate$.of("1990-01-24");
 * const date3 = LocalDate$.of("1992-01-13");
 *
 * // Check equality between two LocalDate instances
 * assert.deepStrictEqual(LocalDate$.Equivalence(date1, date2), true);
 * assert.deepStrictEqual(LocalDate$.Equivalence(date1, date3), false);
 *
 * // Example with Array.containsWith
 * const dateArray = [LocalDate$.of("1989-01-24"), LocalDate$.of("1990-01-24"), LocalDate$.of("1992-01-13")];
 * const contains124 = Array.containsWith(LocalDate$.Equivalence)(dateArray, LocalDate$.of("1990-01-24"));
 *
 * assert.deepStrictEqual(contains124, true);
 *
 * const containsBackToTheFutureDay = Array.containsWith(LocalDate$.Equivalence)(dateArray, LocalDate$.of("1985-10-21"));
 * assert.deepStrictEqual(containsBackToTheFutureDay, false);
 * ```
 *
 * @category instances
 */
const Equivalence: Eq.Equivalence<LocalDate> = (self, that) => {
  return isEqual(self.value, that.value);
};

/**
 * Used for Effect ordering checks, within arrays, options, etc.
 *
 * @example
 * ```ts
 * import { Order as DateOrder, of } from './local-date';
 * import { Order } from 'effect';
 *
 * const date1 = of("1985-10-21");
 * const date2 = of("2015-10-21");
 *
 * const isLessOrEqual = Order.lessThanOrEqual(DateOrder);
 * const isGreater = Order.greaterThan(DateOrder);
 *
 * assert.deepStrictEqual(isLessOrEqual(date1, date2), true);
 * assert.deepStrictEqual(isGreater(date1, date2), false);
 * assert.deepStrictEqual(isGreater(date2, date1), true);
 *```
 *
 * Example of using `LocalDate$.Order` to sort an array of period objects by their `endDate`
 * @example
 * ```ts
 * import { LocalDate$, Order as DateOrder } from './local-date';
 * import { Array } from 'effect';
 *
 * const allocations = [
 *   { id: 1, endDate: LocalDate$.of("1985-10-21") },
 *   { id: 2, endDate: LocalDate$.of("2015-10-21") },
 *   { id: 3, endDate: LocalDate$.of("1885-10-21") },
 * ];
 *
 * const mapSortFn = Array.sortWith((a) => a.endDate, DateOrder);
 *
 * assert.deepStrictEqual(mapSortFn(sortedAllocations), [
 *   { id: 3, endDate: LocalDate$.of("1885-10-21") },
 *   { id: 1, endDate: LocalDate$.of("1985-10-21") },
 *   { id: 2, endDate: LocalDate$.of("2015-10-21") },
 * ]);
 * ```
 *
 * @category instances
 */
const Order: Od.Order<LocalDate> = (self, that) => {
  return N.sign(self.value.getTime() - that.value.getTime());
};

/**
 * Clamps the value of the provided `LocalDate` instance so that it is within the provided bounds.
 *
 * Omitting a `minimum` or `maximum` bound will cause the value to be unconstrained in that direction.
 *
 * @example
 * ```ts
 * import { LocalDate$ } from "@ender/shared/core";
 *
 * const value = LocalDate$.of("1985-10-21");
 *
 * assert.deepStrictEqual(LocalDate$.clamp(value, { minimum: LocalDate$.of("2000-01-01") }).toJSON(), "2000-01-01");
 * assert.deepStrictEqual(LocalDate$.clamp(value, { maximum: LocalDate$.of("1900-01-01") }).toJSON(), "1900-01-01");
 * ```
 *
 * @category Mapping
 */
const clamp: {
  (options: {
    minimum?: LocalDate;
    maximum?: LocalDate;
  }): (a: LocalDate) => LocalDate;
  (
    self: LocalDate,
    options: {
      minimum?: LocalDate;
      maximum?: LocalDate | undefined;
    },
  ): LocalDate;
} = dual(
  2,
  (
    a: LocalDate,
    { minimum, maximum }: { minimum?: LocalDate; maximum?: LocalDate },
  ): LocalDate =>
    pipe(
      a,
      (v) => (P.isNotNullable(minimum) ? Od.max(Order)(v, minimum) : v),
      (v) => (P.isNotNullable(maximum) ? Od.min(Order)(v, maximum) : v),
    ),
);

/**
 * Parses the provided input into a `LocalDate$` instance, returning an Option containing the result
 *
 * This function supports parsing values of type `number`, `string`, `LocalDate$.LocalDate`, `date`, `null`, or `undefined`.
 * - If the input is a string, it is assumed to be in the serialized format `YYYY-mm-dd` or `mm/dd/YYYY`.
 * - If the input is a number, it is treated as an epoch instant.
 * - If the input is a `LocalDate$.LocalDate` object, it is returned
 * - If the input is a `Date` object, it is wrapped in a LocalDate$.LocalDate container
 * - If the input is `null` or `undefined` or invalid, it returns `None`.
 *
 * @example
 * ```ts
 * import { Option as O } from "effect";
 * import { LocalDate$ } from "@ender/shared/core";
 *
 * assert.deepStrictEqual(LocalDate$.parse("1985-10-21"), O.some(...));
 * assert.deepStrictEqual(LocalDate$.parse("10/21/1985"), O.some(...));
 * assert.deepStrictEqual(LocalDate$.parse(498700800000), O.some(...));
 * assert.deepStrictEqual(LocalDate$.parse(LocalDate$.makeFromDate(new Date())), O.some(...));
 * assert.deepStrictEqual(LocalDate$.parse(new Date()), O.some(...));
 * assert.deepStrictEqual(LocalDate$.parse("invalid"), O.none());
 * assert.deepStrictEqual(LocalDate$.parse(null), O.none());
 * ```
 *
 * @category constructors
 */
const parse: (
  v: string | LocalDate | Date | null | undefined,
) => O.Option<LocalDate> = Sch.decodeUnknownOption(Schema);

/**
 * Parses the provided input into a `LocalDate` instance, returning an Either.Right containing the result,
 * or an Either.Left containing the parsing error
 *
 * This function supports parsing values of type `number`, `string`, `LocalDate$.LocalDate`, `date`, `null`, or `undefined`.
 * - If the input is a string, it is assumed to be in the serialized format `YYYY-mm-dd` or `mm/dd/YYYY`.
 * - If the input is a number, it is treated as an epoch instant.
 * - If the input is a `LocalDate$.LocalDate` object, it is returned
 * - If the input is a `Date` object, it is wrapped in a LocalDate$.LocalDate container
 * - If the input is `null` or `undefined` or invalid, it returns `None`.
 *
 * @example
 * ```ts
 * import { Either as E } from "effect";
 * import { LocalDate$ } from "@ender/shared/core";
 *
 * assert.deepStrictEqual(LocalDate$.safeParse("1985-10-21"), E.right(...));
 * assert.deepStrictEqual(LocalDate$.safeParse("10/21/1985"), E.right(...));
 * assert.deepStrictEqual(LocalDate$.safeParse(498700800000), E.right(...));
 * assert.deepStrictEqual(LocalDate$.safeParse(LocalDate$.makeFromDate(new Date())), E.right(...));
 * assert.deepStrictEqual(LocalDate$.safeParse(new Date()), E.right(...));
 * assert.deepStrictEqual(LocalDate$.safeParse("invalid"), E.left(...));
 * assert.deepStrictEqual(LocalDate$.safeParse(null), E.left(...));
 * ```
 *
 * @category constructors
 */
const safeParse: (
  v: string | LocalDate | Date | null | undefined,
) => E.Either<LocalDate, ParseResult.ParseError> =
  Sch.decodeUnknownEither(Schema);

/**
 * Parses the provided input into a `LocalDate` instance, returning a valid `LocalDate` object.
 *
 * This function supports parsing values of type `string`, `LocalDate`, `Date`, `null`, or `undefined`.
 * - If the input is a string, it is assumed to be in the serialized format `YYYY-MM-dd` or `MM/dd/YYYY`.
 * - If the input is a `LocalDate` object, it is returned as is.
 * - If the input is a `Date` object, it is wrapped in a LocalDate container.
 * - If the input is `null`, `undefined`, or invalid, it defaults to `LocalDate$.today()`.
 *
 * This function is particularly useful when you need to ensure you always have a valid LocalDate,
 * even when working with potentially invalid or missing input data.
 *
 * @example
 * ```ts
 * import { LocalDate$ } from "@ender/shared/core";
 *
 * // Parse from a string in ISO format
 * const result = LocalDate$.of("1985-10-21");
 * assert.deepStrictEqual(result.toJSON(), "1985-10-21");
 *
 * // Parse from a Date object
 * const result2 = LocalDate$.of(new Date(1985, 9, 21)); // Note: month is 0-indexed in Date constructor
 * assert.deepStrictEqual(result2.toJSON(), "1985-10-21");
 *
 * // Parse from an existing LocalDate
 * const result3 = LocalDate$.of(LocalDate$.of("1985-10-21"));
 * assert.deepStrictEqual(result3.toJSON(), "1985-10-21");
 *
 * // Invalid input defaults to today
 * const result4 = LocalDate$.of("invalid");
 * assert.deepStrictEqual(result4, LocalDate$.today());
 *
 * const result5 = LocalDate$.of(null);
 * assert.deepStrictEqual(result5, LocalDate$.today());
 * ```
 *
 * @category constructors
 */
const of: (v: string | LocalDate | Date | null | undefined) => LocalDate = flow(
  parse,
  O.getOrElse(today),
);

export {
  Equivalence,
  Formats,
  Order,
  Schema,
  add,
  clamp,
  contains,
  date,
  endOfMonth,
  endOfYear,
  makeFromDate,
  month,
  of,
  parse,
  safeParse,
  startOfMonth,
  startOfYear,
  toFormatted,
  today,
  year,
};

// eslint-disable-next-line import/group-exports
export type { Format, LocalDate, Serialized };
