/**
 * HTTP Method: POST
 * Pathname: /approvalProcess/steps/{stepId}/removeApprover
 * @function removeApprover
 * @memberof ApprovalsAPI
 * @param {ApprovalsAPIRemoveApproverPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type ApprovalsAPIRemoveApproverPathParams = {
  stepId: EnderId;
};

type ApprovalsAPIRemoveApproverSearchParams = {
  token?: string | undefined;
};

type ApprovalsAPIRemoveApproverBodyParams = {
  approverId: EnderId;
};

type ApprovalsAPIRemoveApproverPayload = ApprovalsAPIRemoveApproverPathParams &
  ApprovalsAPIRemoveApproverSearchParams &
  ApprovalsAPIRemoveApproverBodyParams;

function removeApproverUnsafeEffect(
  payload: ApprovalsAPIRemoveApproverPayload,
) {
  const { stepId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: `/approvalProcess/steps/${stepId}/removeApprover`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function removeApprover(
  payload: ApprovalsAPIRemoveApproverPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    removeApproverUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { removeApprover };
export type {
  ApprovalsAPIRemoveApproverBodyParams,
  ApprovalsAPIRemoveApproverPathParams,
  ApprovalsAPIRemoveApproverPayload,
  ApprovalsAPIRemoveApproverSearchParams,
};
