/**
 * HTTP Method: GET
 * Pathname: /addressComponents
 * @function getAddressInformationForPM
 * @memberof EnderAPI
 * @param {EnderAPIGetAddressInformationForPMPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<GetAddressInformationForPMResponse>}
 */
import { Effect, Function as F } from "effect";

import type { GetAddressInformationForPMResponse } from "@ender/shared/generated/ender.api.misc.response";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type EnderAPIGetAddressInformationForPMSearchParams = {
  token?: string | undefined;
};

type EnderAPIGetAddressInformationForPMPayload =
  EnderAPIGetAddressInformationForPMSearchParams;

function getAddressInformationForPMUnsafeEffect(
  payload: EnderAPIGetAddressInformationForPMPayload,
) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, GetAddressInformationForPMResponse>({
        body,
        decode: unsafeDecodeJsonResponse<GetAddressInformationForPMResponse>(
          {},
        ),
        encode: encodeNoBody({ method: "GET" }),
        pathname: "/addressComponents",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getAddressInformationForPM(
  payload: EnderAPIGetAddressInformationForPMPayload,
  options?: { signal?: AbortSignal },
): Promise<GetAddressInformationForPMResponse> {
  return F.pipe(
    payload,
    getAddressInformationForPMUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getAddressInformationForPM };
export type {
  EnderAPIGetAddressInformationForPMPayload,
  EnderAPIGetAddressInformationForPMSearchParams,
};
