/**
 * HTTP Method: POST
 * Pathname: /approvalProcess/default
 * @function getDefaultApprovalProcess
 * @memberof ApprovalsAPI
 * @param {ApprovalsAPIGetDefaultApprovalProcessPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<GetApprovalProcessResponse>}
 */
import { Effect, Function as F } from "effect";

import type { GetApprovalProcessResponse } from "@ender/shared/generated/ender.api.misc.response";
import type { ApprovalProcessType } from "@ender/shared/generated/ender.model.approvals";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type ApprovalsAPIGetDefaultApprovalProcessSearchParams = {
  token?: string | undefined;
};

type ApprovalsAPIGetDefaultApprovalProcessBodyParams = {
  approvalProcessType: ApprovalProcessType;
};

type ApprovalsAPIGetDefaultApprovalProcessPayload =
  ApprovalsAPIGetDefaultApprovalProcessSearchParams &
    ApprovalsAPIGetDefaultApprovalProcessBodyParams;

function getDefaultApprovalProcessUnsafeEffect(
  payload: ApprovalsAPIGetDefaultApprovalProcessPayload,
) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, GetApprovalProcessResponse>({
        body,
        decode: unsafeDecodeJsonResponse<GetApprovalProcessResponse>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/approvalProcess/default",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getDefaultApprovalProcess(
  payload: ApprovalsAPIGetDefaultApprovalProcessPayload,
  options?: { signal?: AbortSignal },
): Promise<GetApprovalProcessResponse> {
  return F.pipe(
    payload,
    getDefaultApprovalProcessUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getDefaultApprovalProcess };
export type {
  ApprovalsAPIGetDefaultApprovalProcessBodyParams,
  ApprovalsAPIGetDefaultApprovalProcessPayload,
  ApprovalsAPIGetDefaultApprovalProcessSearchParams,
};
