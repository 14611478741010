import { createUnitArea } from "./create-unit-area";
import { deleteUnitArea } from "./delete-unit-area";
import { getUnitArea } from "./get-unit-area";
import { getUnitAreas } from "./get-unit-areas";
import { updateUnitArea } from "./update-unit-area";

const UnitAreasAPI = {
  createUnitArea,
  deleteUnitArea,
  getUnitArea,
  getUnitAreas,
  updateUnitArea,
};

export { UnitAreasAPI };
