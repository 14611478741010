/**
 * HTTP Method: DELETE
 * Pathname: /admin/sqlReports/{reportId}
 * @function deleteSqlReport
 * @memberof AdminAPI
 * @param {AdminAPIDeleteSqlReportPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type AdminAPIDeleteSqlReportPathParams = {
  reportId: EnderId;
};

type AdminAPIDeleteSqlReportSearchParams = {
  token?: string | undefined;
};

type AdminAPIDeleteSqlReportPayload = AdminAPIDeleteSqlReportPathParams &
  AdminAPIDeleteSqlReportSearchParams;

function deleteSqlReportUnsafeEffect(payload: AdminAPIDeleteSqlReportPayload) {
  const { reportId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "DELETE" }),
        pathname: `/admin/sqlReports/${reportId}`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function deleteSqlReport(
  payload: AdminAPIDeleteSqlReportPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    deleteSqlReportUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { deleteSqlReport };
export type {
  AdminAPIDeleteSqlReportPathParams,
  AdminAPIDeleteSqlReportPayload,
  AdminAPIDeleteSqlReportSearchParams,
};
