/**
 * HTTP Method: GET
 * Pathname: /users/{tenantId}/emergencyContacts
 * @function getEmergencyContacts
 * @memberof UsersAPI
 * @param {UsersAPIGetEmergencyContactsPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<EmergencyContact[]>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { EmergencyContact } from "@ender/shared/generated/ender.model.leasing";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type UsersAPIGetEmergencyContactsPathParams = {
  tenantId: EnderId;
};

type UsersAPIGetEmergencyContactsSearchParams = {
  token?: string | undefined;
};

type UsersAPIGetEmergencyContactsPayload =
  UsersAPIGetEmergencyContactsPathParams &
    UsersAPIGetEmergencyContactsSearchParams;

function getEmergencyContactsUnsafeEffect(
  payload: UsersAPIGetEmergencyContactsPayload,
) {
  const { tenantId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, EmergencyContact[]>({
        body,
        decode: unsafeDecodeJsonResponse<EmergencyContact[]>({}),
        encode: encodeNoBody({ method: "GET" }),
        pathname: `/users/${tenantId}/emergencyContacts`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getEmergencyContacts(
  payload: UsersAPIGetEmergencyContactsPayload,
  options?: { signal?: AbortSignal },
): Promise<EmergencyContact[]> {
  return F.pipe(
    payload,
    getEmergencyContactsUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getEmergencyContacts };
export type {
  UsersAPIGetEmergencyContactsPathParams,
  UsersAPIGetEmergencyContactsPayload,
  UsersAPIGetEmergencyContactsSearchParams,
};
