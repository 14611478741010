/**
 * Gets various information which is useful for applying to live in a Unit.
 * HTTP Method: GET
 * Pathname: /units/{unitId}/applyInfo
 * @function getApplyInfo
 * @memberof UnitsAPI
 * @param {UnitsAPIGetApplyInfoPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<GetApplyInfoResponse>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { GetApplyInfoResponse } from "@ender/shared/generated/ender.api.leasing.response";
import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type UnitsAPIGetApplyInfoPathParams = {
  /**
   * The Unit being applied to.
   */
  unitId: EnderId;
};

type UnitsAPIGetApplyInfoSearchParams = {
  token?: string | undefined;
};

type UnitsAPIGetApplyInfoPayload = UnitsAPIGetApplyInfoPathParams &
  UnitsAPIGetApplyInfoSearchParams;

function getApplyInfoUnsafeEffect(payload: UnitsAPIGetApplyInfoPayload) {
  const { unitId, token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, GetApplyInfoResponse>({
        body,
        decode: unsafeDecodeJsonResponse<GetApplyInfoResponse>({}),
        encode: encodeNoBody({ method: "GET" }),
        pathname: `/units/${unitId}/applyInfo`,
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getApplyInfo(
  payload: UnitsAPIGetApplyInfoPayload,
  options?: { signal?: AbortSignal },
): Promise<GetApplyInfoResponse> {
  return F.pipe(
    payload,
    getApplyInfoUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getApplyInfo };
export type {
  UnitsAPIGetApplyInfoPathParams,
  UnitsAPIGetApplyInfoPayload,
  UnitsAPIGetApplyInfoSearchParams,
};
