/**
 * Returns the currently logged in User.
 * HTTP Method: POST
 * Pathname: /tokenToUser
 * @function tokenToUser
 * @memberof AuthAPI
 * @param {AuthAPITokenToUserPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<TokenToUserResponse>}
 */
import { Effect, Function as F } from "effect";

import type { TokenToUserResponse } from "@ender/shared/generated/ender.api.misc.response";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type AuthAPITokenToUserSearchParams = {
  token?: string | undefined;
};

type AuthAPITokenToUserPayload = AuthAPITokenToUserSearchParams;

function tokenToUserUnsafeEffect(payload: AuthAPITokenToUserPayload) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, TokenToUserResponse>({
        body,
        decode: unsafeDecodeJsonResponse<TokenToUserResponse>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/tokenToUser",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function tokenToUser(
  payload: AuthAPITokenToUserPayload,
  options?: { signal?: AbortSignal },
): Promise<TokenToUserResponse> {
  return F.pipe(
    payload,
    tokenToUserUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { tokenToUser };
export type { AuthAPITokenToUserPayload, AuthAPITokenToUserSearchParams };
