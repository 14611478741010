/**
 * HTTP Method: POST
 * Pathname: /users
 * @function newUser
 * @memberof UsersAPI
 * @param {UsersAPINewUserPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<User>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId, LocalDate, SerializesInto } from "@ender/shared/core";
import type { User } from "@ender/shared/generated/ender.model.core.user";
import type { VendorUserTagVendorUserTagType } from "@ender/shared/generated/ender.model.core.vendor";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type UsersAPINewUserSearchParams = {
  token?: string | undefined;
};

type UsersAPINewUserBodyParams = {
  birthday?: SerializesInto<LocalDate> | undefined;
  email?: string | undefined;
  firstName?: string | undefined;
  isActiveMilitary?: boolean | undefined;
  lastName?: string | undefined;
  phone?: string | undefined;
  role: string;
  sendWelcomeEmail?: boolean | undefined;
  vendorId?: EnderId | undefined;
  vendorTags?: VendorUserTagVendorUserTagType[] | undefined;
};

type UsersAPINewUserPayload = UsersAPINewUserSearchParams &
  UsersAPINewUserBodyParams;

function newUserUnsafeEffect(payload: UsersAPINewUserPayload) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, User>({
        body,
        decode: unsafeDecodeJsonResponse<User>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/users",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function newUser(
  payload: UsersAPINewUserPayload,
  options?: { signal?: AbortSignal },
): Promise<User> {
  return F.pipe(
    payload,
    newUserUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { newUser };
export type {
  UsersAPINewUserBodyParams,
  UsersAPINewUserPayload,
  UsersAPINewUserSearchParams,
};
