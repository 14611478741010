import { HttpClient } from "@effect/platform";
import type { Scope } from "effect";
import { Effect, Function as F, Layer, Ref } from "effect";

import { ClientConfigService } from "@ender/shared/services/config";

import { RestService } from "./rest-service";
import type { MethodOptions, RestError } from "./rest-types";
import { encodeBodyFor, encodeNoBodyFor, httpExecutor } from "./rest-utils";

const RestServiceLive = Layer.effect(
  RestService,
  Effect.gen(function* () {
    const config = yield* ClientConfigService;
    const client = yield* HttpClient.HttpClient;

    const { baseApiUrl: baseUrl, apiCookies: cookies } = config;
    const encodeBody = encodeBodyFor({ baseUrl });
    const encodeNoBody = encodeNoBodyFor({ baseUrl });

    return <Body extends Partial<{ [key: string]: unknown }>, Result>(
      options: MethodOptions<Body, Result>,
    ): Effect.Effect<Result, RestError, Scope.Scope> => {
      const { decode } = options;
      const clientRequest =
        "body" in options ? encodeBody(options) : encodeNoBody(options);

      return F.pipe(
        Ref.make(cookies()),
        Effect.andThen((cookiesRef) => {
          return httpExecutor({
            client,
            cookiesRef,
          });
        }),
        Effect.ap(clientRequest),
        Effect.flatMap(F.flow(Effect.andThen(decode))),
      );
    };
  }),
);

export { RestServiceLive };
