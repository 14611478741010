/**
 * HTTP Method: POST
 * Pathname: /deleteOwnershipGroup
 * @function deleteOwnershipGroup
 * @memberof OwnershipGroupsAPI
 * @param {OwnershipGroupsAPIDeleteOwnershipGroupPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type OwnershipGroupsAPIDeleteOwnershipGroupSearchParams = {
  token?: string | undefined;
};

type OwnershipGroupsAPIDeleteOwnershipGroupBodyParams = {
  ownershipGroupId: EnderId;
};

type OwnershipGroupsAPIDeleteOwnershipGroupPayload =
  OwnershipGroupsAPIDeleteOwnershipGroupSearchParams &
    OwnershipGroupsAPIDeleteOwnershipGroupBodyParams;

function deleteOwnershipGroupUnsafeEffect(
  payload: OwnershipGroupsAPIDeleteOwnershipGroupPayload,
) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/deleteOwnershipGroup",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function deleteOwnershipGroup(
  payload: OwnershipGroupsAPIDeleteOwnershipGroupPayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    deleteOwnershipGroupUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { deleteOwnershipGroup };
export type {
  OwnershipGroupsAPIDeleteOwnershipGroupBodyParams,
  OwnershipGroupsAPIDeleteOwnershipGroupPayload,
  OwnershipGroupsAPIDeleteOwnershipGroupSearchParams,
};
