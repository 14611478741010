/**
 * HTTP Method: GET
 * Pathname: /admin/enderSoftwareProviderId
 * @function getEnderSoftwareProviderId
 * @memberof AdminAPI
 * @param {AdminAPIGetEnderSoftwareProviderIdPayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<AdminAPIGetEnderSoftwareProviderIdResponse>}
 */
import { Effect, Function as F } from "effect";

import {
  RestService,
  encodeNoBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

import type { AdminAPIGetEnderSoftwareProviderIdResponse } from "../admin-api-get-ender-software-provider-id-response";

type AdminAPIGetEnderSoftwareProviderIdSearchParams = {
  token?: string | undefined;
};

type AdminAPIGetEnderSoftwareProviderIdPayload =
  AdminAPIGetEnderSoftwareProviderIdSearchParams;

function getEnderSoftwareProviderIdUnsafeEffect(
  payload: AdminAPIGetEnderSoftwareProviderIdPayload,
) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, AdminAPIGetEnderSoftwareProviderIdResponse>({
        body,
        decode:
          unsafeDecodeJsonResponse<AdminAPIGetEnderSoftwareProviderIdResponse>(
            {},
          ),
        encode: encodeNoBody({ method: "GET" }),
        pathname: "/admin/enderSoftwareProviderId",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function getEnderSoftwareProviderId(
  payload: AdminAPIGetEnderSoftwareProviderIdPayload,
  options?: { signal?: AbortSignal },
): Promise<AdminAPIGetEnderSoftwareProviderIdResponse> {
  return F.pipe(
    payload,
    getEnderSoftwareProviderIdUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { getEnderSoftwareProviderId };
export type {
  AdminAPIGetEnderSoftwareProviderIdPayload,
  AdminAPIGetEnderSoftwareProviderIdSearchParams,
};
