/**
 * HTTP Method: POST
 * Pathname: /approvalProcess/approve
 * @function approve
 * @memberof ApprovalsAPI
 * @param {ApprovalsAPIApprovePayload} payload
 * @param {Object} [options]
 * @param {AbortSignal} [options.signal]
 * @return {Promise<void>}
 */
import { Effect, Function as F } from "effect";

import type { EnderId } from "@ender/shared/core";
import type { ModelType } from "@ender/shared/generated/com.ender.common.model";
import {
  RestService,
  encodeJsonBody,
  unsafeDecodeJsonResponse,
} from "@ender/shared/services/rest";
import { runPromise } from "@ender/shared/stores/effect-runtime-store";

type ApprovalsAPIApproveSearchParams = {
  token?: string | undefined;
};

type ApprovalsAPIApproveBodyParams = {
  comment?: string | undefined;
  modelId: EnderId;
  modelType: ModelType;
  overrideWarnings?: boolean | undefined;
};

type ApprovalsAPIApprovePayload = ApprovalsAPIApproveSearchParams &
  ApprovalsAPIApproveBodyParams;

function approveUnsafeEffect(payload: ApprovalsAPIApprovePayload) {
  const { token, ...body } = payload;
  return RestService.pipe(
    Effect.andThen((rest) =>
      rest<typeof body, void>({
        body,
        decode: unsafeDecodeJsonResponse<void>({}),
        encode: encodeJsonBody({ method: "POST" }),
        pathname: "/approvalProcess/approve",
        searchParams: [["token", token]],
      }),
    ),
  );
}

function approve(
  payload: ApprovalsAPIApprovePayload,
  options?: { signal?: AbortSignal },
): Promise<void> {
  return F.pipe(
    payload,
    approveUnsafeEffect,
    Effect.scoped,
    runPromise,
  )(options);
}

export { approve };
export type {
  ApprovalsAPIApproveBodyParams,
  ApprovalsAPIApprovePayload,
  ApprovalsAPIApproveSearchParams,
};
